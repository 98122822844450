import { GET_DEALS } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';

export default function useDeals({ first = 6 }) {
  const {
    data: { deals: { data: deals = [] } = {} } = {},
    refetch,
    loading,
  } = useQuery(GET_DEALS, {
    variables: {
      first: first,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetch(),
  });

  return { deals, loading };
}
