import React, { useMemo } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { ProfileDetails, SectionProfile } from '../../../components';
import { LINK_GROUP } from '../../../../utils/links';
import {
  TYPE_JOBTYPES,
  TYPE_SUBJOBTYPES,
  TYPE_WORK_LOCATION,
} from '../../../placeholders/consultant/types';
import CONTENTS_UTIL from '../../../../utils/contents';
import useAuth from '../../../hooks/useAuth';

const br =
  '<br style="content: \'A\' !important; display: block !important; margin-bottom: 0.5rem !important;" />';

export default function ProfilePage() {
  const { authMe: data } = useAuth();

  const contents = useMemo(() => {
    if (!data) {
      return;
    }

    const {
      bio,
      name,
      pronouns,
      skills,
      jobTypes,
      location,
      linkedin,
      resumes,
      supportingDocuments,
      salary_currency,
      minimum_salary,
      maximum_salary,
      genres,
      qualifications,
      otherQualifications,
    } = data;

    const softwareSkills = skills?.filter(({ software }) => software) || [];
    const otherSkills = skills?.filter(({ software }) => !software) || [];
    let types = [],
      workHour = [];

    if (jobTypes.length > 0) {
      const firstJobType = TYPE_JOBTYPES.filter((j) =>
        jobTypes.map(({ key }) => key).includes(j.key)
      );
      const secondJobType = TYPE_SUBJOBTYPES.filter((j) =>
        jobTypes.map(({ key }) => key).includes(j.key)
      );

      if (firstJobType.length > 0) {
        if (firstJobType[0]?.name === 'Both') {
          types = ['Permanent', 'Freelance / Consultancy'];
        } else {
          types = [firstJobType[0].name];
        }
      }

      if (secondJobType.length > 0) {
        if (secondJobType[0]?.name === 'Both') {
          workHour = ['Full-time', 'Part-time'];
        } else {
          workHour = [secondJobType[0].name];
        }
      }
    }
    return CONTENTS_UTIL.generateDetails(
      [
        CONTENTS_UTIL.generateRowDetails(
          'Name',
          `${name} ${pronouns ? `(${pronouns})` : ''}`
        ),
        CONTENTS_UTIL.generateRowDetails('About me', bio ?? '-'),
        otherSkills?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Skills',
              otherSkills?.map(({ name }) => name).join(br)
            )
          : null,
        softwareSkills?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Software Skills',
              softwareSkills?.map(({ name }) => name)?.join(br)
            )
          : null,
        CONTENTS_UTIL.generateRowDetails(
          'Considering',
          types && types?.length > 0 ? types.join(br) : '-'
        ),
        CONTENTS_UTIL.generateRowDetails(
          'Work Hours',
          workHour?.length > 0 ? workHour.join(br) : '-'
        ),
        location?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Job Location',
              TYPE_WORK_LOCATION.find(({ value }) => value === location)?.label
            )
          : null,
        linkedin
          ? CONTENTS_UTIL.generateRowDetails(
              'LinkedIn',
              `<a href=${linkedin} target="_blank">View LinkedIn</a>`
            )
          : null,
        resumes?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'CV',
              `<a href=${resumes[0].fileUrl} target="_blank">View CV</a>`
            )
          : null,
        supportingDocuments?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Supporting Documents',
              supportingDocuments
                .map(
                  ({ fileUrl, name }) =>
                    `<a href=${fileUrl} target="_blank">${name}</a>`
                )
                .join(br)
            )
          : null,
        salary_currency && minimum_salary && maximum_salary
          ? CONTENTS_UTIL.generateRowDetails(
              'Salary Bands',
              `${salary_currency} ${minimum_salary.toLocaleString()} —
          ${maximum_salary.toLocaleString()}`
            )
          : null,
      ],
      [
        genres.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Category Experience',
              genres.length > 0 ? genres.map(({ name }) => name).join(br) : '-'
            )
          : null,
        qualifications.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Qualifications',
              qualifications.map(({ name }) => name).join(br)
            )
          : null,
        otherQualifications?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Other Qualification',
              otherQualifications.map(({ name }) => name).join(br)
            )
          : null,
      ]
    );
  }, [data]);

  return (
    <DefaultLayout showNavigation showSubmenu>
      <SectionProfile
        contentFull
        editURL={`/${LINK_GROUP.CANDIDATE}/edit-profile`}
        profileImage={data?.avatar || '/img/agency-picture-placeholder@2x.jpg'}
        imageRounded
        headline="View Profile"
        vetted={data?.vetted}
        cover
      >
        <ProfileDetails contents={contents} />
      </SectionProfile>
    </DefaultLayout>
  );
}
