import gql from 'graphql-tag';

export const CREATE_CASE_STUDY = gql`
  mutation ($input: CaseStudyCreate!) {
    createCaseStudy(input: $input) {
      id
    }
  }
`;

export const UPDATE_CASE_STUDY = gql`
  mutation ($id: ID!, $input: CaseStudyUpdate) {
    updateCaseStudy(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_CASE_STUDY = gql`
  mutation ($id: ID!) {
    deleteCaseStudy(id: $id) {
      id
    }
  }
`;
