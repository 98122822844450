import React, { forwardRef, useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { ChevronIcon } from '../../icons';

const CustomMultiselect = forwardRef(function CustomMultiselect(props, ref) {
  const updatePlaceholder = props?.updatePlaceholder ?? true;
  const [placeholder, setPlaceholder] = useState(props?.placeholder);
  const [className, setClassName] = useState(props?.className);
  const remove = props?.onRemove;
  const name = props?.name;

  const onFocus = () => {
    if (updatePlaceholder) {
      setPlaceholder(`Search ${props?.placeholder}...`);
    }

    setClassName('');
  };

  const onBlur = () => {
    setPlaceholder(props?.placeholder);
    setClassName(props?.className);
  };

  useEffect(() => {
    if (props?.className) {
      setClassName(props?.className);
    }
  }, [props]);

  return (
    <button
      type="button"
      style={{ border: 'none', padding: 0, backgroundColor: 'transparent' }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Multiselect
        name={name}
        showArrow
        customArrow={<ChevronIcon />}
        {...props}
        placeholder={placeholder}
        ref={ref}
        className={className}
        onRemove={(selectedList) => {
          ref.current.onCloseOptionList();
          ref.current.searchBox.current.blur();
          remove(selectedList);
        }}
      />
    </button>
  );
});
export default CustomMultiselect;
