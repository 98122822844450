import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { googleLogin } from '../../../api/user';
import { registerVar } from '../../../graphql/config';
import { logUserOut } from '../../../utils/helpers';
import DefaultLayout from '../../layouts/defaultLayout';

export default function SocialLoginPage({ history }) {
  const location = useLocation();

  const generateUID = (length) =>
    window
      .btoa(
        Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
          .map((b) => String.fromCharCode(b))
          .join('')
      )
      .replace(/[+/]/g, '')
      .substring(0, length);

  const callGoogleLogin = () => {
    logUserOut();
    googleLogin(location.search).then(({ data }) => {
      if (data) {
        registerVar({
          email: data.email,
          password: generateUID(8),
          google_id: data.id,
          first_name: data.user.given_name,
          last_name: data.user.family_name,
        });
        return history.push(`/${localStorage.getItem('userType')}/register/2`);
      }
    });
  };

  useEffect(() => {
    callGoogleLogin();
  }, []);

  return <DefaultLayout showNavigation={false}>Loading...</DefaultLayout>;
}
