import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import useGroups from '../../hooks/useGroups';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Heading,
  Link,
  FormInput,
  FormBlockItem,
  Button,
  Text,
} from '../../snippets';
import {
  GET_ALL_OPPORTUNITIES,
  GET_SKILLS,
  GET_GENRES,
  GET_INDUSTRIES,
  GET_QUALIFICATIONS,
  GET_ALL_JOB_LEVELS,
} from '../../../graphql';
import {
  arrToDropdownSelectionV2,
  humanReadableTime,
  IMG_AGENCY_PLACEHOLDER,
} from '../../../utils/helpers';
// import useAuth from '../../hooks/useAuth';
import {
  FILTER_JOBTYPES,
  FILTER_SUBJOBTYPES,
  FILTER_WORK_LOCATION,
  TARGET_OPPORTUNITY,
} from '../../placeholders/consultant/types';
import { useForm } from 'react-hook-form';
import { LoadingSection, Switch } from '../../components';
import CustomMultiselect from '../../components/CustomMultiselect';
import queryString from 'query-string';
import { useQueryParams } from '../../hooks/useQueryParams';

export default function OpportunitiesPage() {
  const { register, getValues } = useForm();
  const multiselectSkillRef = useRef(null);
  const multiselectSoftwareSkillRef = useRef(null);
  const multiselectGenreRef = useRef(null);
  const multiselectQualificationRef = useRef(null);
  const multiselectJobLevelRef = useRef(null);
  const multiselectJobTypeRef = useRef(null);
  const multiselectLocationRef = useRef(null);
  const { groupname } = useGroups();
  // const { authMe } = useAuth();
  const [showFilters, setShowFilters] = useState(true);
  const [reset, setReset] = useState(false);

  const [
    getAllOpportunities,
    { data: { allOpportunities = [] } = {}, loading, called },
  ] = useLazyQuery(GET_ALL_OPPORTUNITIES);

  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const { queryParams } = useQueryParams();

  const [getSkills, { data: { skills = [] } = {} }] = useLazyQuery(GET_SKILLS, {
    variables: {
      ...(queryParams?.industry && {
        industries: [queryParams.industry],
      }),
    },
  });

  const [getGenres, { data: { genres = [] } = {} }] = useLazyQuery(GET_GENRES, {
    variables: {
      ...(queryParams?.industry && {
        industries: [queryParams.industry],
      }),
    },
  });

  const [getQualifications, { data: { qualifications = [] } = {} }] =
    useLazyQuery(GET_QUALIFICATIONS, {
      variables: {
        ...(queryParams?.industry && {
          industries: [queryParams.industry],
        }),
      },
    });

  const { data: { industries = [] } = {} } = useQuery(GET_INDUSTRIES);
  const { data: { allJoblevels = [] } = {} } = useQuery(GET_ALL_JOB_LEVELS);

  const nonSoftwareSkills = useMemo(() => {
    return skills?.filter(({ software }) => !software);
  }, [skills]);

  const softwareSkills = useMemo(() => {
    return skills?.filter(({ software }) => software);
  }, [skills]);

  const oppportunities = useMemo(() => {
    return (
      allOpportunities?.map((a) => {
        return {
          img: a?.agency?.avatar ?? null,
          headline: a.title ?? 'NA',
          info: a.agency?.company ?? '-',
          since: humanReadableTime(a?.created_at),
          url: `/${groupname}/opportunities/${a.id}`,
        };
      }) ?? []
    );
  }, [allOpportunities, groupname]);

  // useEffect(() => {
  //   // Set the user's selected industries as the default filter
  //   if (authMe?.industries) {
  //     setSelectedIndustries(authMe.industries?.map((industry) => industry.id));
  //   }
  // }, [authMe]);

  const handleForm = (param, value) => {
    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        ...queryParams,
        [param]: Array.isArray(value)
          ? value.map(({ id, key }) => id || key)
          : value,
      },
    });

    history.push(url);
  };

  const getParamValue = useCallback(
    (param, isMultiple = false) => {
      if (!queryParams) return undefined;

      if (!queryParams[param] && isMultiple) return [];

      if (isMultiple)
        return !Array.isArray(queryParams[param])
          ? [queryParams[param]]
          : queryParams[param];

      return queryParams[param];
    },
    [queryParams]
  );

  useEffect(() => {
    let conditionals = [];

    if (getParamValue('search')) {
      conditionals.push({
        column: 'TITLE',
        value: `%${getParamValue('search')}%`,
        operator: 'LIKE',
      });
    }

    if (getParamValue('jobLevel', true)) {
      conditionals = conditionals.concat(
        ...getParamValue('jobLevel', true)?.map((joblevel) => ({
          column: 'LEVEL',
          value: `%${joblevel}%`,
          operator: 'LIKE',
        }))
      );
    }

    if (getParamValue('jobType', true)) {
      conditionals = conditionals.concat(
        ...getParamValue('jobType', true)?.map((jobType) => ({
          column: 'TYPE',
          value: `%${jobType}%`,
          operator: 'LIKE',
        }))
      );
    }

    if (getParamValue('workingHour', true)) {
      conditionals = conditionals.concat(
        ...getParamValue('workingHour', true)?.map((workingHour) => ({
          column: 'SUB_TYPE',
          value: `%${workingHour}%`,
          operator: 'LIKE',
        }))
      );
    }

    if (getParamValue('jobLocation') === 'REMOTE') {
      conditionals.push({
        column: 'IS_REMOTE',
        value: true,
        operator: 'EQ',
      });
    } else if (getParamValue('jobLocation') === 'ONSITE') {
      conditionals.push({
        column: 'IS_ON_SITE',
        value: true,
        operator: 'EQ',
      });
    } else {
      conditionals.push({
        column: 'IS_REMOTE',
        value: true,
        operator: 'EQ',
      });
      conditionals.push({ column: 'IS_ON_SITE', value: true, operator: 'EQ' });
    }

    const fetchObj = {
      where: {
        AND: [
          {
            OR: conditionals,
          },
        ],
      },
      ...(getParamValue('industry') && {
        hasIndustries: {
          column: 'ID',
          value: getParamValue('industry'),
        },
      }),
      ...((getParamValue('skill', true)?.length ||
        getParamValue('softwareSkill', true)?.length) && {
        hasSkills: {
          OR: [
            {
              column: 'ID',
              operator: 'IN',
              value: [
                ...getParamValue('skill', true),
                ...getParamValue('softwareSkill', true),
              ],
            },
          ],
        },
      }),
      ...(getParamValue('genre', true)?.length && {
        hasGenres: {
          OR: [
            {
              column: 'ID',
              operator: 'IN',
              value: getParamValue('genre', true),
            },
          ],
        },
      }),
      ...(getParamValue('qualification', true)?.length && {
        hasQualifications: {
          OR: [
            {
              column: 'ID',
              operator: 'IN',
              value: getParamValue('qualification', true),
            },
          ],
        },
      }),
    };

    getAllOpportunities({
      fetchPolicy: 'cache-and-network',
      variables: {
        trashed: 'WITHOUT',
        is_active: true,
        orderBy: [
          { column: 'FEATURED', order: 'DESC' },
          { column: 'CREATED_AT', order: 'DESC' },
        ],
        target: TARGET_OPPORTUNITY[0].value,
        ...fetchObj,
      },
    });
  }, [getParamValue, getAllOpportunities]);

  useEffect(() => {
    if (queryParams && queryParams.industry) {
      getSkills();
      getGenres();
      getQualifications();
    }
  }, [queryParams, getSkills, getGenres, getQualifications]);

  const appliedFilter = useMemo(() => {
    return [
      ...(getParamValue('industry', true) || []),
      ...(getParamValue('skill', true) || []),
      ...(getParamValue('softwareSkill', true) || []),
      ...(getParamValue('genre', true) || []),
      ...(getParamValue('qualification', true) || []),
      ...(getParamValue('jobLevel', true) || []),
      ...(getParamValue('jobType', true) || []),
      ...(getParamValue('workingHour', true) || []),
      ...(getParamValue('jobLocation', true) || []),
    ].filter((item) => item !== null && item.length !== 0).length;
  }, [getParamValue]);

  return (
    <DefaultLayout>
      <BackPanel>
        <div className="meta-header--grid">
          <Heading>All Job Opportunities</Heading>
        </div>

        <FilterCont style={{ marginTop: '20px' }}>
          <FormBlockItem style={{ flex: '3 1 0' }}>
            <FormInput
              type="text"
              name="search"
              placeholder="Search"
              register={register}
              defaultValue={getParamValue('search')}
            />
          </FormBlockItem>

          <FormBlockItem style={{ flex: '1 1 0' }}>
            <Button
              onClick={() => handleForm('search', getValues('search'))}
              text="normal"
            >
              Search
            </Button>
          </FormBlockItem>

          <FormBlockItem style={{ flex: '1 1 0' }}>
            <Button onClick={() => history.replace(pathname)} text="normal">
              Clear All
            </Button>
          </FormBlockItem>
        </FilterCont>

        <FilterCont style={{ marginTop: '30px' }}>
          <Text
            as="span"
            weight="bold"
            className=""
            onClick={() => setShowFilters(!showFilters)}
            style={{
              cursor: 'pointer',
            }}
          >
            Show Filters{' '}
            {appliedFilter > 0 && (
              <span className="font-italic text--regular">
                ({appliedFilter} used)
              </span>
            )}
          </Text>
          <Switch
            onChange={() => {
              setShowFilters(!showFilters);
            }}
            isChecked={showFilters}
          />
        </FilterCont>

        <div
          style={{ marginTop: '20px', display: showFilters ? 'block' : 'none' }}
        >
          <FilterCont>
            <FormBlockItem>
              <FormInput
                type="select"
                options={arrToDropdownSelectionV2(industries) ?? []}
                placeholder="Select Professional Discipline"
                selectSetting={{
                  onSelect: (value) => handleForm('industry', value),
                  reset,
                  setReset,
                }}
                defaultValue={getParamValue('industry')}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={nonSoftwareSkills}
                onSelect={(values) => handleForm('skill', values)}
                onRemove={(values) => handleForm('skill', values)}
                selectedValues={nonSoftwareSkills.filter((elm) =>
                  getParamValue('skill', true)?.includes(elm.id)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Skills"
                ref={multiselectSkillRef}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={softwareSkills}
                onSelect={(values) => handleForm('softwareSkill', values)}
                onRemove={(values) => handleForm('softwareSkill', values)}
                selectedValues={softwareSkills.filter((elm) =>
                  getParamValue('softwareSkill', true)?.includes(elm.id)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Software Skills"
                ref={multiselectSoftwareSkillRef}
              />
            </FormBlockItem>
          </FilterCont>

          <FilterCont>
            <FormBlockItem>
              <CustomMultiselect
                options={genres}
                onSelect={(values) => handleForm('genre', values)}
                onRemove={(values) => handleForm('genre', values)}
                selectedValues={genres.filter((elm) =>
                  getParamValue('genre', true)?.includes(elm.id)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Category Experience"
                ref={multiselectGenreRef}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={qualifications}
                onSelect={(values) => handleForm('qualification', values)}
                onRemove={(values) => handleForm('qualification', values)}
                selectedValues={qualifications.filter((elm) =>
                  getParamValue('qualification', true)?.includes(elm.id)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Qualifications"
                ref={multiselectQualificationRef}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={allJoblevels}
                onSelect={(values) => handleForm('jobLevel', values)}
                onRemove={(values) => handleForm('jobLevel', values)}
                selectedValues={allJoblevels.filter((elm) =>
                  getParamValue('jobLevel', true)?.includes(elm.key)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Level of Role"
                ref={multiselectJobLevelRef}
              />
            </FormBlockItem>
          </FilterCont>

          <FilterCont>
            <FormBlockItem>
              <CustomMultiselect
                options={FILTER_JOBTYPES}
                onSelect={(values) => handleForm('jobType', values)}
                onRemove={(values) => handleForm('jobType', values)}
                selectedValues={FILTER_JOBTYPES.filter((elm) =>
                  getParamValue('jobType', true)?.includes(elm.key)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Considering"
                ref={multiselectJobTypeRef}
                updatePlaceholder={false}
              />
            </FormBlockItem>

            <FormBlockItem>
              <FormInput
                type="select"
                options={FILTER_SUBJOBTYPES.map(({ key, name }) => ({
                  value: key,
                  label: name,
                }))}
                defaultValue={getParamValue('workingHour')}
                name="workinghours"
                placeholder="Working Hours"
                selectSetting={{
                  onSelect: (value) => handleForm('workingHour', value),
                  reset,
                  setReset,
                }}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={FILTER_WORK_LOCATION}
                onSelect={(values) => handleForm('jobLocation', values)}
                onRemove={(values) => handleForm('jobLocation', values)}
                selectedValues={FILTER_WORK_LOCATION.filter((elm) =>
                  getParamValue('jobLocation', true)?.includes(elm.key)
                )}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="How would you like to work?"
                ref={multiselectLocationRef}
                updatePlaceholder={false}
              />
            </FormBlockItem>
          </FilterCont>
        </div>

        {loading ? (
          <LoadingSection />
        ) : (
          called &&
          (oppportunities.length === 0 ? (
            <div style={{ marginTop: '30px' }}>
              There are no opportunities that currently match your search.
              Please try again!
            </div>
          ) : (
            <div className="gridjobs-grid">
              {oppportunities.map((d, i) => {
                return (
                  <div className={`gridjobs-grid--item`} key={i}>
                    <Link
                      to={d.url}
                      className="company-logo"
                      external={d.externalLink ?? false}
                    >
                      <img
                        src={d.img === '' ? IMG_AGENCY_PLACEHOLDER : d.img}
                        alt=""
                      />
                    </Link>
                    <div className="grid-divider"></div>
                    <div className="company-info">
                      <div className="company-info--header">
                        <Link
                          to={d.url}
                          className="company-info--header_headline txt__fw-700"
                          external={d.externalLink ?? false}
                        >
                          {d.headline}
                        </Link>
                        <div className="company-info--header_info">
                          {d.info}
                        </div>
                      </div>
                      <div className="company-info--time">
                        <div>
                          {d.since}
                          {d.status ? (
                            <p className="mb-0">
                              <b>{d.status}</b>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))
        )}
      </BackPanel>
    </DefaultLayout>
  );
}

const FilterCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 15px;
  .form-block--item {
    flex: unset;
    width: 100%;
    & + * {
      margin-top: 0;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    flex-direction: row;
  }
`;
