import React, { useCallback, useState } from 'react';
import { GET_AUTH_USER, LOGIN } from '../../graphql';
import useGroups from './useGroups';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  getLoginType,
  getLoginUrl,
  logUserOut,
  saveLoginTypeLocalstorage,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { toast } from 'react-toastify';
import { userVar } from '../../graphql/config';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const reducer = (state, action) => ({ ...state, ...action.payload });
const initialState = {
  skills: [],
  genres: [],
  opportunitiesForYou: [],
  applications: [],
  status: '',
  jobTypes: [],
  preferences: [],
  industries: [],
};

export default function useAuth(props) {
  const { redirectTo = null } = props || {};
  const history = useHistory();
  const { groupname } = useGroups();
  const [status, setStatus] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [me, setMe] = useState(null);
  const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN);
  const [dispatch] = React.useReducer(reducer, initialState);

  const handleLoginUser = (data) => {
    toast.dismiss();
    loginMutation({
      variables: {
        input: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then(({ data: { login } }) => {
        if (!login) {
          return toast.error('Wrong email / password combination!', {
            toastId: 'agLogErr',
            autoClose: 3000,
          });
        }
        userVar(login);
        saveUserLocalstorage(login);
        const cookies = new Cookies();
        cookies.set('lh_user', login.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        let userType = login?.type;
        if (login.type === 'GUEST') {
          userType = login?.teamType;
        }
        saveLoginTypeLocalstorage(userType.toUpperCase());
        history.push(redirectTo ?? `/${getLoginType()}/dashboard`);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Something went wrong!', {
          toastId: 'agLogErr',
          autoClose: 3000,
        });
      });
  };

  const { loading } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl(groupname.toUpperCase());
      }
      setStatus(me?.status);
      setInitialized(true);
      setMe(me);
      dispatch({
        payload: {
          genres: me.genres,
          skills: me.skills,
          opportunitiesForYou: me.preferences,
          applications: me.applications,
          status: me.status,
          preferences: me.preferences,
          industries: me.industries,
          positions: me.positions,
        },
      });
    },
  });

  const logout = useCallback(() => {
    userVar({});
    localStorage.removeItem('user');
    localStorage.removeItem('loginType');
    const cookies = new Cookies();
    cookies.remove('lh_user', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    history.push(`/${groupname}/login`);
  }, [groupname, history]);

  return {
    authenticating: loading,
    authStatus: status,
    authInitialized: initialized,
    authMe: me,
    handleLoginUser,
    loginLoading,
    logout,
  };
}
