import { useRef } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SectionProfile } from '../../../components';
import { AppContext } from '../../../../Store';
import { useHistory } from 'react-router-dom';
import {
  UPDATE_PROFILE,
  GET_GENRES,
  ATTACH_GENRE,
  GET_SERVICES,
  ATTACH_SERVICE,
} from '../../../../graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useGroups from '../../../hooks/useGroups';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormInput,
} from '../../../snippets';
import { userVar, registerVar } from '../../../../graphql/config';
import { getUser, saveUserLocalstorage } from '../../../../utils/helpers';
import DefaultLayout from '../../../layouts/defaultLayout';
import CustomMultiselect from '../../../components/CustomMultiselect';

export default function ProfileEditPage() {
  const [state] = useContext(AppContext);
  const history = useHistory();
  const { groupname } = useGroups();
  const { register, watch, handleSubmit, errors, clearErrors } = useForm();
  const multiselectServicesRef = useRef(null);
  const multiselectIndustryExperienceRef = useRef(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [img, setImg] = useState(null);
  const [bio, setBio] = useState(state?.activeCompany?.user?.bio);
  const [submitted, setSubmitted] = useState(false);

  const { data: { services = [] } = {} } = useQuery(GET_SERVICES);
  const { data: { genres = [] } = {} } = useQuery(GET_GENRES);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [updateServices] = useMutation(ATTACH_SERVICE);
  const [updateGenres] = useMutation(ATTACH_GENRE);

  const updateServicesRequest = () => {
    toast.dismiss();

    updateServices({
      variables: {
        input: {
          sync: selectedServices,
        },
      },
    });
  };

  const updateGenresRequest = () => {
    toast.dismiss();
    updateGenres({
      variables: {
        input: {
          sync: selectedGenres,
        },
      },
    });
  };

  const editProfile = async (data) => {
    toast.dismiss();

    if (selectedServices?.length === 0 || selectedGenres?.length === 0) {
      return;
    }

    updateServicesRequest();
    updateGenresRequest();

    return updateProfile({
      variables: {
        input: {
          ...data,
          bio,
          budget_min: parseFloat(data?.budget_min),
          budget_max: parseFloat(data?.budget_max),
          ...(img !== null && { avatar: img }),
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        return history.push(`/${groupname}/profile`);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Something went wrong!', { toastId: 'resErr' });
      });
  };

  const handleServicesChange = (selectedList, _) => {
    setSelectedServices(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleGenreChange = (selectedList, _) =>
    setSelectedGenres(selectedList.map(({ id, key }) => id ?? key));

  const preselectedServices = useCallback(
    () =>
      services?.filter((service) =>
        selectedServices.find((i) => i === service.id)
      ),
    [services, selectedServices]
  );

  const preselectedGenres = useCallback(
    () => genres?.filter((genre) => selectedGenres.find((i) => i === genre.id)),
    [genres, selectedGenres]
  );

  useEffect(() => {
    if (state?.activeCompany) {
      registerVar({
        agency_name: state?.activeCompany?.user?.agency_name,
        bio: state?.activeCompany?.user?.bio,
        avatar: state?.activeCompany?.user?.avatar,
        services: state?.activeCompany?.user?.services,
        genres: state?.activeCompany?.user?.genres,
        budget_currency: state?.activeCompany?.user?.budget_currency,
        budget_min: state?.activeCompany?.user?.budget_min,
        budget_max: state?.activeCompany?.user?.budget_max,
        website: state?.activeCompany?.user?.website,
      });

      setSelectedServices(
        state?.activeCompany?.user?.services?.map(({ id }) => id)
      );
      setSelectedGenres(state?.activeCompany?.user?.genres.map(({ id }) => id));

      return;
    }
  }, [state]);

  return (
    <DefaultLayout showSubmenu>
      <SectionProfile
        headline="EDIT PROFILE"
        profilePictureHeading={'Profile Picture'}
        isEditor
        setImg={setImg}
        editDefaultImage={state?.activeCompany?.user?.avatar}
      >
        <form onSubmit={handleSubmit(editProfile)}>
          <FormBlock>
            <FormBlockItem label={'Agency Name'}>
              <FormInput
                type="text"
                register={register}
                name="company"
                isRequired
                error={errors.company ? `Add agency Name` : null}
                clearErrors={clearErrors}
                defaultValue={state?.activeCompany?.user?.agency_name}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'About Us'}>
              <FormInput
                isRequired
                type="wysiwyg"
                name="bio"
                error={bio?.length === 0 ? `Add bio` : null}
                maxLength="1000"
                defaultValue={state?.activeCompany?.user?.bio}
                clearErrors={clearErrors}
                setValue={setBio}
                fieldClassName={`${bio?.length === 0 ? 'error-state ' : ''}`}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Services'}>
              <CustomMultiselect
                className={
                  submitted && selectedServices.length === 0
                    ? 'error-state state-error'
                    : ''
                }
                options={services}
                onSelect={handleServicesChange}
                onRemove={handleServicesChange}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Services"
                selectedValues={preselectedServices()}
                ref={multiselectServicesRef}
              />
              {submitted && selectedServices.length === 0 && (
                <FormFieldAddOns
                  style={{ marginTop: 0 }}
                  error={'Please select services'}
                  showWarnError={true}
                />
              )}
            </FormBlockItem>

            <FormBlockItem label={'Category Experience'}>
              <CustomMultiselect
                className={
                  submitted && selectedGenres.length === 0
                    ? 'error-state state-error'
                    : ''
                }
                options={genres}
                onSelect={handleGenreChange}
                onRemove={handleGenreChange}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Category Experience"
                selectedValues={preselectedGenres()}
                ref={multiselectIndustryExperienceRef}
              />
              {submitted && selectedGenres.length === 0 && (
                <FormFieldAddOns
                  style={{ marginTop: 0 }}
                  error={'Please select category experience'}
                  showWarnError={true}
                />
              )}
            </FormBlockItem>

            <FormBlockItem label={'Website'}>
              <FormInput
                type="text"
                register={register}
                name="website"
                defaultValue={state?.activeCompany?.user?.website}
              ></FormInput>
            </FormBlockItem>
          </FormBlock>

          <FormCTA spaceT extraSpace>
            <Button
              type="submit"
              className="w-full text-capitalize"
              size="xxl"
              disabled={loading}
              onClick={() => {
                setSubmitted(true);
              }}
            >
              {loading ? 'Loading...' : 'Save'}
            </Button>
          </FormCTA>
        </form>
      </SectionProfile>
    </DefaultLayout>
  );
}
