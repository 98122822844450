import React, {
  Fragment,
  useEffect,
  useState,
  forwardRef,
  useCallback,
} from 'react';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import Error from '../../components/Error';
import { REGISTER, GET_SKILLS } from '../../graphql';
import jquery from '../../styles/js/app';
import Footer from '../../v2/components/Footer/Footer';
import { registerVar, userVar } from '../../graphql/config';
import {
  inputStyle,
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
  getUser,
} from '../../utils/helpers';
import 'react-phone-input-2/lib/plain.css';
import { LINK_GROUP } from '../../utils/links';
import { AgencyHeader } from '../../components/Agency';
import { IconPlusSmall } from '../../components/Icons';
import Switch from '../../components/Switch';
import UploadFieldV2 from '../../components/UploadFieldV2';

const ACCOUNT_TYPE = 'AGENCY';
const MAX_LOCATION = 2;
const MAX_PHONE = 4;

const FieldSelf = forwardRef(
  (
    { className = '', defaultValue, name, type = 'text', isFocus = false },
    ref
  ) => {
    return (
      <input
        className={'form-v2__input ' + className}
        type={type}
        autoComplete="false"
        name={name}
        defaultValue={defaultValue}
        ref={ref}
        autoFocus={isFocus}
      />
    );
  }
);

const CreateAccountSkills = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const { data: skillsData, loading: loadingSkills } = useQuery(GET_SKILLS);
  const [checkedExpertise, setCheckedExpertise] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [registerMutation, { loading }] = useMutation(REGISTER);
  const [phoneError, setPhoneError] = useState(false);
  const [logo, setLogo] = useState(null);
  const [isLogoError, setIsLogoError] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);
  const [phone, setPhoneNo] = useState(['']);
  const [inputLocation, setInputLocation] = useState(['']);
  const [inputPhone, setInputPhone] = useState(['']);
  const [shortbioText, SetShortbioText] = useState('');

  useEffect(() => {
    if (
      Object.keys(getUser()).length !== 0 &&
      Object.keys(registerVar()).length === 0
    ) {
      registerVar(getUser());
    }
  }, []);

  const onTextChange = useCallback((input) => {
    SetShortbioText(input.currentTarget.value);
  }, []);

  if (
    !Object.keys(registerVar()).length &&
    Object.keys(getUser()).length === 0
  ) {
    // return <Redirect to={`/${LINK_GROUP.AGENCY}/register`} />;
  }

  function checkInvalid() {
    if (!logo) {
      setIsLogoError(true);
    }
    if (checkedExpertise.length === 0) {
      setExpertiseError(true);
    }
    return;
  }

  function RegisterUser(data) {
    toast.dismiss();

    phone.forEach((p) => {
      validatePhone(p);
    });

    if (!phoneError && phone.length > 0) {
      registerMutation({
        variables: {
          ...data,
          ...registerVar(),
          type: ACCOUNT_TYPE,
          agencyLocation: data?.agency_location.join(','),
          agencyPhone: phone.map((p) => `+${p}`).join(','),
          phone: `+${phone[0]}`,
          expertise: checkedExpertise,
        },
      })
        .then((res) => {
          const { data } = res;
          if (!data.register) {
            toast.error('Something is wrong!');
          } else {
            registerVar({});
            const cookies = new Cookies();
            cookies.set('lh_user', data.register.id, {
              path: '/',
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
            userVar(data.register);
            saveUserLocalstorage(data.register);
            saveLoginTypeLocalstorage(ACCOUNT_TYPE);
            return history.push(`/${LINK_GROUP.AGENCY}/complete-profile`);
          }
        })
        .catch((e) => {
          let msg = 'Something went wrong!';
          if (
            'validation' in (e?.graphQLErrors[0]?.extensions ?? {}) &&
            'input.email' in (e?.graphQLErrors[0]?.extensions?.validation ?? {})
          ) {
            msg = 'The email has already been taken!';
          }
          toast.error(msg, { toastId: 'frRegErr' });
        });
    }
  }

  const validatePhone = (phone) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      return setPhoneError(true);
    }
    return setPhoneError(false);
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      return handleSubmit(RegisterUser)();
    }
  };

  function handleAddMoreLocation() {
    setInputLocation((prev) => {
      if (prev.length > MAX_LOCATION) {
        return prev;
      }
      return [...prev, ''];
    });
  }
  function handleAddMorePhone() {
    setInputPhone((prev) => {
      if (prev.length > MAX_PHONE) {
        return prev;
      }
      return [...prev, ''];
    });
  }

  function handleRemoveLocation(idxAt) {
    setInputLocation((prev) => {
      const _curr = prev.slice(0);
      _curr.splice(idxAt, 1);
      return _curr;
    });
  }

  function handleRemovePhone(idxAt) {
    setInputPhone((prev) => {
      const _curr = prev.slice(0);
      _curr.splice(idxAt, 1);
      return _curr;
    });
  }

  return (
    <>
      <div className="lh-wrapper bg__gradient-2 bg-center bg-cover theme theme-agency">
        <AgencyHeader />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="black-card">
                <h1 className="black-card__heading mb-40">
                  Tell Us About Your Agency
                </h1>
                <form
                  className="form-v2 form-agency form-about"
                  onSubmit={handleSubmit(RegisterUser, checkInvalid)}
                >
                  <input
                    type="hidden"
                    name="googleId"
                    defaultValue={registerVar().google_id || null}
                    ref={register({ required: false })}
                  />

                  <div className="form-v2__input-block">
                    <div
                      className={`form-v2__label ${
                        isLogoError && 'form-v2__label-error'
                      }`}
                    >
                      Agency Logo
                    </div>
                    <div className="form-v2__input-wrapper">
                      <UploadFieldV2
                        onSetImage={(im) => {
                          setLogo(im);
                          setIsLogoError(false);
                        }}
                        isError={isLogoError}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>

                  <div className="onboarding__two-cols">
                    <div className="form-v2__input-block onboarding__two-cols__inner">
                      <div
                        className={`form-v2__label ${
                          errors.agencyName && 'form-v2__label-error'
                        }`}
                      >
                        Agency Name
                      </div>
                      <div className="form-v2__input-wrapper">
                        <input
                          className={`form-v2__input ${
                            errors.agencyName && 'form-v2__error'
                          }`}
                          placeholder={
                            errors.agencyName && 'Please enter your agency name'
                          }
                          type="text"
                          autoComplete={'false'}
                          name="agencyName"
                          defaultValue={registerVar().agency_name || ''}
                          ref={register({ required: true })}
                          autoFocus={false}
                        />
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    </div>
                    {/* {errors.agencyName && (
                      <Error text="Please enter your agency name" />
                    )} */}
                    <div className="form-v2__input-block onboarding__two-cols__inner mb-14">
                      <div className="form-v2__label">
                        Locations (City / Remote)
                      </div>
                      <div className="form-v2__input-wrapper">
                        {inputLocation?.map((a, i) => {
                          return (
                            <Fragment key={i + a}>
                              <div className={`${i > 0 ? 'mt-16' : ''} row`}>
                                <div className="col">
                                  <FieldSelf
                                    className={`mt-0 ${
                                      errors.location ? 'control-error' : ''
                                    }`}
                                    name={`agency_location[${i}]`}
                                    defaultValue={''}
                                    ref={register({ required: true })}
                                    key={i + a}
                                    isFocus={true}
                                  />
                                </div>
                                {i > 0 && (
                                  <div className="col-auto pl-0">
                                    <button
                                      type="button"
                                      className="btn btn-secondary fs-28x leading-none h-full"
                                      onClick={() => handleRemoveLocation(i)}
                                    >
                                      -
                                    </button>
                                  </div>
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                        <div className="form__clear form__hidden">
                          <i className="fas fa-times" />
                        </div>
                        {inputLocation.length < MAX_LOCATION && (
                          <button
                            className="btn__add-more-v2"
                            onClick={handleAddMoreLocation}
                          >
                            <IconPlusSmall />
                            <div className="text__white txt__fw-400 txt__fs-14">
                              Add Another Location
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {errors.location && (
                    <Error text="Please enter your location" />
                  )}

                  <div style={{ clear: 'both' }} />
                  <div className="form-v2__input-block mb-14">
                    <div
                      className={`form-v2__label ${
                        errors.shortBio && 'form-v2__label-error'
                      }`}
                    >
                      Short Bio
                    </div>
                    <div className="form-v2__input-wrapper">
                      <textarea
                        className={`form-v2__textarea long-text${
                          errors.shortBio && ' form-v2__error'
                        }`}
                        type="text"
                        autoComplete="false"
                        name="shortBio"
                        ref={register({ required: true })}
                        rows={6}
                        maxLength={1000}
                        onChange={(e) => onTextChange(e)}
                        defaultValue={registerVar().short_bio || ''}
                      ></textarea>
                      <div
                        className={`field__option-text ${
                          errors.shortBio && 'form-v2__label-error'
                        }`}
                      >
                        {shortbioText.length ?? '0'} / 1000
                      </div>
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                  {/* {errors.shortBio && <Error text="Please enter short bio" />} */}

                  <div style={{ clear: 'both' }} />
                  <div className="form-v2__input-block">
                    <div className="form-v2__label">Add Your Phone Number</div>
                    {inputPhone.map((a, i) => {
                      return (
                        <Fragment key={i + a}>
                          <div className={`${i > 0 ? 'mt-16' : ''} row`}>
                            <div className="col">
                              <PhoneInput
                                country="gb"
                                autoFormat
                                inputProps={{
                                  name: `phone[${i}]`,
                                  required: true,
                                  autoFocus: false,
                                }}
                                inputStyle={inputStyle}
                                inputClass={'form-v2__input with-important '}
                                containerClass="form-v2__input-wrapper"
                                buttonClass="field-v2__phone-button"
                                onChange={(phone) => {
                                  setPhoneNo((prev) => {
                                    prev[i] = phone;
                                    return prev;
                                  });
                                }}
                                dropdownStyle={{
                                  background: '#fff',
                                  color: 'black',
                                  borderBottomLeftRadius: '12px',
                                  borderBottomRightRadius: '12px',
                                }}
                                placeholder="44 116 456 5566"
                                onKeyDown={handleEnterKey}
                                isValid={(value) => validatePhone(value)}
                              />
                            </div>
                            {i > 0 && (
                              <div className="col-auto pl-0">
                                <button
                                  type="button"
                                  className="btn btn-secondary fs-28x leading-none h-full"
                                  onClick={() => handleRemovePhone(i)}
                                >
                                  -
                                </button>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      );
                    })}

                    {inputPhone.length < MAX_PHONE && (
                      <button
                        className="btn__add-more-v2"
                        onClick={handleAddMorePhone}
                        type="button"
                      >
                        <div>
                          <IconPlusSmall />
                        </div>
                        <div className="text__white txt__fw-400 txt__fs-14">
                          Add Another Phone Number
                        </div>
                      </button>
                    )}
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                  {phoneError && (
                    <Error text="Please enter a valid phone number" />
                  )}

                  <div className="selected-options">
                    <h5
                      className={`form-v2__label ${
                        expertiseError && 'form-v2__label-error'
                      }`}
                    >
                      Select Your Areas Of Expertise
                    </h5>
                    <div className="switch-area text__gray">
                      {loadingSkills && <div>Loading...</div>}
                      {skillsData?.skills?.map((skill) => {
                        return (
                          <Switch
                            key={skill?.id}
                            isError={expertiseError}
                            isChecked={checkedExpertise.includes(skill?.id)}
                            onChange={({ currentTarget }) => {
                              const { checked } = currentTarget;

                              setCheckedExpertise((prev) => {
                                const nSet = new Set(prev);
                                const val = skill?.id;
                                if (checked) {
                                  nSet.add(val);
                                  expertiseError && setExpertiseError(false);
                                } else {
                                  nSet.delete(val);
                                }
                                return Array.from(nSet);
                              });
                            }}
                            name={skill?.name}
                          >
                            {skill?.name}
                          </Switch>
                        );
                      })}
                    </div>
                    {expertiseError && (
                      <div className="mt-3">
                        <Error text={'Select at least one area of expertise'} />
                      </div>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={!!loading}
                    className="form-v2__btn form-v2__btn-agency-v2 d-block bg__gradient-1"
                  >
                    {loading ? 'Loading...' : 'Next'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateAccountSkills;
