import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { generateClassName } from '../../../utils/helpers';
import { ChevronIcon } from '../../icons';

export default function Dropdown({
  className,
  lists = [
    {
      value: '',
      label: '',
    },
  ],
  defaultActiveValue = '',
  onSelected = () => {},
  register,
  name,
  error,
  placeholder,
  reset = false,
  setReset,
  clearErrors,
  ...rest
}) {
  const [active, setActive] = useState('');
  const [disclouser, setDisclouser] = useState(false);
  const wrapperRef = useRef(null);

  const closeDisclouser = useCallback(() => {
    setDisclouser((_prv) => {
      if (_prv) {
        return false;
      }
    });
  }, [setDisclouser]);

  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeDisclouser();
      }
    },
    [wrapperRef, closeDisclouser]
  );

  useEffect(() => {
    if (reset) {
      setActive('');
      setDisclouser(false);
    }
  }, [reset]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, handleClickOutside]);

  useEffect(() => {
    if (lists?.find((f) => f.value === defaultActiveValue)) {
      setActive(defaultActiveValue);
    }
  }, [lists, defaultActiveValue]);

  const activeLabel = useMemo(() => {
    const _item = lists.find((item) => active === item.value);
    return _item?.label ? _item?.label : active;
  }, [lists, active]);

  return (
    <div className={generateClassName(['app-dropdown'])} ref={wrapperRef}>
      <button
        type="button"
        className={generateClassName([
          'app-dropdown--trigger',
          className,
          error ? 'state-error' : '',
        ])}
        onClick={() => setDisclouser((_prev) => !_prev)}
        {...rest}
      >
        {register && (
          <input
            type="hidden"
            ref={register}
            value={active ?? ''}
            name={name}
          />
        )}
        {placeholder && !activeLabel && (
          <p className="app-dropdown--placeholder">{placeholder}</p>
        )}
        <div className="app-dropdown--label">{activeLabel}</div>

        <div
          className={generateClassName([
            'chevron-icon',
            disclouser ? 'active' : '',
          ])}
        >
          <ChevronIcon />
        </div>
      </button>
      {disclouser && (
        <div className="app-dropdown--list-wrapper">
          <div className="app-dropdown--list-container">
            {lists.map((item, i) => (
              <button
                className="app-dropdown--list-item"
                onClick={() => {
                  if (reset && setReset) {
                    setReset(false);
                  }
                  setDisclouser(false);
                  onSelected(item.value);
                  if (clearErrors) {
                    clearErrors();
                  }
                  setActive(item.value);
                }}
                key={item.value}
              >
                {item.label ?? item.value}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
