import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { GridCards, GridJobs, LoadingSection } from '../../components';
import useGroups from '../../hooks/useGroups';
import useUser from '../../hooks/useUser';
import { useQuery } from '@apollo/react-hooks';
import { humanReadableTime } from '../../../utils/helpers';
import { GET_ALL_OPPORTUNITIES, GET_DEALS } from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import useArticles from '../../hooks/useArticles';
import useAuth from '../../hooks/useAuth';
import { TARGET_OPPORTUNITY } from '../../placeholders/consultant/types';

export default function DashboardPage() {
  const { groupname } = useGroups();

  const { authMe, authenticating: loading } = useAuth();

  const { articles } = useArticles({
    availability: [
      LINK_GROUP.FREELANCER.toUpperCase(),
      LINK_GROUP.FREELANCER_AND_AGENCY.toUpperCase(),
    ],
    first: 4,
  });

  const { data: { allOpportunities = [] } = {} } = useQuery(
    GET_ALL_OPPORTUNITIES,
    {
      variables: {
        trashed: 'WITHOUT',
        is_active: true,
        orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
        target: TARGET_OPPORTUNITY[0].value,
      },
      fetchPolicy: 'network-only',
    }
  );

  const opportunitiesForYou = useMemo(() => {
    let _limit = 6;

    const filtered = authMe?.preferences?.filter((o) => o.is_active === true);
    if (filtered?.length < _limit) {
      _limit = filtered.length;
    }
    const data = filtered?.slice(0, _limit);
    return (
      data?.map((a) => {
        return {
          img: a?.agency?.avatar ?? null,
          headline: a.title ?? 'NA',
          info: a.agency?.company ?? '-',
          since: humanReadableTime(a?.created_at),
          url: `/${groupname}/opportunities/${a.id}`,
          type: a.type,
          genres: a.genres,
          skills: a.skills,
        };
      }) ?? []
    );
  }, [authMe, groupname]);

  const latestJobs = useMemo(() => {
    let _limit = 4;

    if (allOpportunities.length < _limit) {
      _limit = allOpportunities.length;
    }

    const data = allOpportunities
      ?.filter(({ industries }) => {
        // Hardcoded to only show jobs from the 'Media, Marketing & Advertising' industry
        return parseInt(industries[0]?.id) === 1;
      })
      ?.slice(0, _limit);

    return (
      data?.map((a) => {
        return {
          img: a?.agency?.avatar ?? null,
          headline: a.title ?? 'NA',
          info: a.agency?.company ?? '-',
          since: humanReadableTime(a?.created_at),
          url: `/${groupname}/opportunities/${a.id}`,
        };
      }) ?? []
    );
  }, [allOpportunities, authMe, groupname]);

  const appliedJobs = useMemo(() => {
    const data =
      authMe?.applications.filter((a) => a.opportunity && a.is_shown) ?? [];
    return (
      data?.map(({ opportunity, created_at }) => {
        return {
          img: opportunity?.agency?.avatar ?? null,
          headline: opportunity?.title ?? 'NA',
          info: opportunity?.agency?.company ?? '-',
          since: humanReadableTime(created_at),
          status:
            opportunity?.is_active && !opportunity?.deleted_at
              ? 'Open'
              : 'Closed',
          url: `/${groupname}/opportunities/${opportunity.id}`,
        };
      }) ?? []
    );
  }, [authMe, groupname]);

  const {
    data: { deals: { data: deals = [] } = {} } = {},
    refetch: refetchDeal,
  } = useQuery(GET_DEALS, {
    variables: {
      first: 6,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchDeal(),
  });

  const dealsData = useMemo(() => {
    let _limit = 6;

    if (deals.length < _limit) {
      _limit = deals.length;
    }

    const data = deals.slice(0, _limit);

    return (
      data?.map(({ created_at, image, link, subtitle, title }) => {
        return {
          img: image ?? null,
          headline: title ?? 'NA',
          info: subtitle ?? '-',
          since: humanReadableTime(created_at),
          url: link,
          externalLink: true,
        };
      }) ?? []
    );
  }, [deals]);

  const communityArticles = useMemo(() => {
    return articles.map((a) => {
      return {
        img: a.image,
        headline: a.title,
        url: `/${groupname}/resources/${a.slug}`,
      };
    });
  }, [articles, groupname]);

  useUser(); // Check user
  return (
    <DefaultLayout>
      {loading ? (
        <LoadingSection />
      ) : (
        <>
          <GridJobs
            viewMoreUrl={`/${groupname}/opportunities-for-you`}
            data={opportunitiesForYou}
            type="opportunities"
            user={authMe}
          />
          <GridJobs
            type="latest"
            heading="Latest Job Opportunities"
            viewMoreUrl={`/${groupname}/latest-opportunities`}
            data={latestJobs}
          />
          <GridJobs
            type="applied"
            heading="Job Opportunities You've Applied For"
            data={appliedJobs}
          />
          <GridCards
            type="community"
            heading="Liberty Hive Community"
            viewMoreUrl={`/${groupname}/resources`}
            id="resources"
            data={communityArticles}
          />
          <GridJobs
            type="deals"
            heading="Deals For You"
            data={dealsData}
            viewMoreUrl={`/${groupname}/deals`}
          />
        </>
      )}
    </DefaultLayout>
  );
}
