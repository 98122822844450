import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function FormCta({
  children,
  className,
  spaceT = false,
  extraSpace = false,
  center = false,
  sidebyside = false,
  column = false,
}) {
  return (
    <div
      className={generateClassName([
        'form-cta',
        className,
        spaceT ? 'space-t' : '',
        extraSpace ? 'space-t--extra' : '',
        center ? 'j-center' : '',
        sidebyside ? 'sidebyside' : null,
        column ? 'style-column' : null
      ])}
    >
      {children}
    </div>
  );
}
