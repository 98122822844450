import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function Text({
  className = '',
  children,
  as: AsType = 'p',
  weight = null, // med, bold
  size = null, // lg:20px, md: 16px, xs: 14px
  ...rest
}) {
  return (
    <AsType
      className={generateClassName(['text--regular', className, weight ? `text--${weight}` : '', size ? `text--${size}` : ''])}
      {...rest}
    >
      {children}
    </AsType>
  );
}
