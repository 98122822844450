import React, { useEffect, useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldUpload,
  FormInput,
  Heading,
} from '../../snippets';
import useProfile from '../../hooks/useProfile';
import { useForm } from 'react-hook-form';

export default function RegisterLayer3() {
  const {
    checkProfileCompleted,
    myProfile,
    loadingProfile: loading,
    editProfile,
    dataImg,
    setDataImageFile,
    setDataImageErrorStatus,
  } = useProfile({ actionImageMandatory: true });

  const [bio, setBio] = useState(null);
  const [description, setDescription] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const { register, errors, clearErrors, getValues } = useForm();

  useEffect(() => {
    checkProfileCompleted();
  }, [myProfile, checkProfileCompleted]);

  const submitForm = () => {
    setSubmitted(true);

    if (!dataImg.filepath) {
      setDataImageErrorStatus(true);
    }

    editProfile({
      ...getValues(),
      bio,
      description,
    });
  };

  return (
    <div>
      <Heading as="h3" className="text-center">
        Now tell us a little about your company...
      </Heading>
      <FormBlock className={'spacer-t'}>
        <FormBlockItem className="relative" label={'Add Your Company Logo'}>
          <FormFieldUpload
            noOutline
            square
            center
            setImg={setDataImageFile}
            error={dataImg?.error ? 'You must upload a company logo' : null}
            showWarnError
            onError={setDataImageErrorStatus}
            maxFileSize={8388608}
          ></FormFieldUpload>
        </FormBlockItem>
        <FormBlockItem label={'Add Profile Bio'}>
          <FormInput
            isRequired
            type="wysiwyg"
            name="bio"
            error={
              submitted && (!bio || bio?.length === 0)
                ? `Please add your company profile bio`
                : null
            }
            showErrorText
            maxLength="1000"
            clearErrors={clearErrors}
            setValue={setBio}
            fieldClassName={`${
              submitted && (!bio || bio?.length === 0) ? 'error-state ' : ''
            }`}
          ></FormInput>
        </FormBlockItem>
        <FormBlockItem label={'What Sets You Apart'}>
          <FormInput
            isRequired
            type="wysiwyg"
            name="description"
            error={
              submitted && (!description || description?.length === 0)
                ? `Please add a description`
                : null
            }
            showErrorText
            maxLength="1000"
            clearErrors={clearErrors}
            setValue={setDescription}
            fieldClassName={`${
              submitted && (!description || description?.length === 0)
                ? 'error-state '
                : ''
            }`}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem
          label={
            'Are you happy for us to use your logo across our marketing channels?'
          }
        >
          <FormInput
            type="select"
            register={register}
            name="can_use_logo"
            isRequired
            options={[
              {
                value: '',
                label: 'Please Select',
              },
              {
                value: '1',
                label: 'Yes',
              },
              {
                value: '0',
                label: 'No',
              },
            ]}
            error={errors.can_use_logo ? `You must choose one` : null}
          ></FormInput>
        </FormBlockItem>

        <FormCTA spaceT extraSpace>
          <Button
            type="submit"
            className="w-full"
            size="xxl"
            disabled={loading}
            onClick={submitForm}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </FormCTA>
      </FormBlock>
    </div>
  );
}
