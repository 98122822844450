import Cookies from 'universal-cookie';
import { userVar } from '../../graphql/config';
import { logUserOut } from '../../utils/helpers';
import { LINK_GROUP } from '../../utils/links';

const Logout = ({ history }) => {
  logUserOut();
  userVar({});
  const cookies = new Cookies();
  cookies.remove('lh_user', {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  history.push(`/${LINK_GROUP.AGENCY}/login`);
  return null;
};

export default Logout;
