import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_LANDING_PAGES = gql`
  query {
    landingPages {
      id
      name
      slug
      sort_order
      display_in_footer
    }
  }
`;
