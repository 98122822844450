import React from 'react';
import Footer from '../../../components/Footer';

import Header from '../../../components/Agency/Header';
import {
  AgencyAccountSubmenuDesktop,
  AgencyAccountSubmenuMobile,
} from '../../../components/Agency';

const WithSubmenuLayout = ({ pageTitle, children }) => {
  return (
    <div className="lh-wrapper theme-agency theme h-full">
      <Header isLoggedIn withNoMargin />
      <AgencyAccountSubmenuMobile />
      <div className="position-relative">
        <div className="container container-submenu">
          <div className="row row-main-submenu">
            <div className="col col-main-submenu section-spacer-y ">
              <div className="row">
                <div className="col-12">
                  <h1 className="page-h1">{pageTitle}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">{children}</div>
              </div>
            </div>
            <AgencyAccountSubmenuDesktop />
          </div>
        </div>
      </div>
      <Footer withNoMargin />
    </div>
  );
};

export default WithSubmenuLayout;
