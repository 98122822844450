import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GET_SUBSCRIPTION_HISTORY, LOGIN } from '../graphql';
import Error from '../components/Error';
import Footer from '../components/Footer';
import jquery from '../styles/js/app';
import { getUser, saveUserLocalstorage } from '../utils/helpers';
import HeaderCommonLogin from '../components/HeaderCommonLogin';
import { LINK_GROUP } from '../utils/links';

const LoginCommon = ({ location }) => {
  useEffect(() => {
    jquery();
  }, []);

  const notify = (text) => toast.error(text);

  const { register, handleSubmit, errors } = useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  let isEnabled = true;

  const [, { data }] = useLazyQuery(GET_SUBSCRIPTION_HISTORY, {
    onCompleted: () => handleRedirection(),
    onError: () => (window.location.href = `/${LINK_GROUP.COMPANY}/dashboard`),
  });

  const handleRedirection = () => { 
    window.location.href = `/${LINK_GROUP.COMPANY}/dashboard`;
  };

  if (!data) {
    if (getUser().access_token && getUser().type === 'AGENCY') {
      window.location.href = `/${LINK_GROUP.AGENCY}/dashboard`;
    } else if (getUser().access_token && getUser().type === `FREELANCER`) {
      window.location.href = `/${LINK_GROUP.FREELANCER}/dashboard`;
    } else if (getUser().access_token && getUser().type === `COMPANY`) {
      window.location.href = `/${LINK_GROUP.COMPANY}/dashboard`;
    }
  }

  function LoginUser(data) {
    toast.dismiss();
    isEnabled = false;
    loginMutation({
      variables: { email: data.email, password: data.password },
    })
      .then((res) => {
        const { data } = res;
        if (!data.login) {
          return notify(`Wrong email / password combination!`);
        }
        saveUserLocalstorage(data.login);
        if (data.login.type === 'FREELANCER') {
          return (window.location.href = `/${LINK_GROUP.FREELANCER}/dashboard`);
        }
        // subHistory({
        //   variables: {
        //     agency: data.login.id,
        //   },
        // });
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'logErr' })
      );
  }

  return (
    <div className="lh-wrapper">
      <HeaderCommonLogin />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <h1 className="center mb-50">
                {location.state && location.state.passwordReset
                  ? 'Your password has been reset. Please log in.'
                  : 'Login'}
              </h1>
              <form className="form" onSubmit={handleSubmit(LoginUser)}>
                <div className="form__heading mt-0">Email</div>
                <div className="form__input-block">
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
                {errors.email && (
                  <Error text="Please enter a valid email address" />
                )}
                <div className="form__input-block">
                  <div className="form__heading mt-0">Password</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="password"
                      id="onboarding-password"
                      name="password"
                      ref={register({ required: true })}
                      autoComplete="false"
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                    <div
                      toggle="#onboarding-password"
                      className="fas fa-eye form__toggle-password form__hidden"
                    />
                  </div>
                </div>
                {errors.password && <Error text="Please enter a password" />}
                <button
                  type="submit"
                  disabled={!isEnabled}
                  className="form__btn btn d-block mb-50 btn-v2"
                  style={{ background: '#f14a56' }}
                >
                  {loading ? 'Loading...' : 'Login'}
                </button>
                {!location.state && (
                  <div className="d-flex justify-content-between mb-60">
                    <Link
                      to={`/${LINK_GROUP.COMPANY}/register`}
                      className="link-marketing txt__fw-600"
                    >
                      Need an account?
                    </Link>
                    <a href="/#" className="link-marketing txt__fw-600">
                      Forgotten Password
                    </a>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginCommon;
