import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const OpportunityCard = ({ title, createdAt, updatedAt, company, logo }) => {
  const seventhDay = moment().subtract(7, 'd').format('YYYY-MM-DD');
  const isSevenDaysOld = moment(createdAt).isSameOrBefore(seventhDay);
  const isUpdateSevenDayOlds = moment(updatedAt).isSameOrBefore(seventhDay);

  return (
    <JobOpportunityCard>
      <img src={logo} alt="" />
      <div className="vertical-line" />
      <div className="body">
        <div>
          <h2 className="title">{title}</h2>
          <p className="copy">{company}</p>
        </div>
        <div className="date row">
          {!isSevenDaysOld && (
            <p
              style={{
                height: '100%',
                color: '#99cccc',
                fontWeight: '900',
                margin: 0,
                padding: 0,
                textTransform: 'uppercase',
              }}
            >
              New
            </p>
          )}
          {isSevenDaysOld && !isUpdateSevenDayOlds && (
            <p
              style={{
                margin: '1rem 0 0 0',
                color: '#99cccc',
                fontWeight: '900',
                textTransform: 'uppercase',
              }}
            >
              {'Updated'}
            </p>
          )}
          <p style={{ fontWeight: 700, marginBottom: 0 }}>7 Days</p>
        </div>
      </div>
    </JobOpportunityCard>
  );
};

const JobOpportunityCard = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  gap: 16px;
  img {
    height: 100px;
    width: 100px;
    margin-block: auto;
  }
  .vertical-line {
    height: 100%;
    border-left: 1px solid white;
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 12px;
    .title {
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      color: white;
      margin-bottom: 4px;
    }
    .copy {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.1px;
      color: white;
      margin-bottom: 0;
    }
    .date {
      margin: 0;
      font-weight: 700;
      color: white;
      p {
        font-size: 12px;
      }
      gap: 8px;
    }
  }
  @media (min-width: 1024px) {
    height: 140px;
    gap: 27px;
    img {
      height: 140px;
      width: 140px;
    }
    .body {
      padding-block: 26px;
      .title {
        font-size: 16px;
      }
      .copy {
        font-size: 16px;
        line-height: 21.8px;
        margin-bottom: 34px;
      }
      .date {
        p {
          font-size: 14px;
        }
      }
    }
  }
`;

export default OpportunityCard;
