import { Switch, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StartSubscription from '../views/Company/StartSubscription';
import ManageCredits from '../views/Company/ManageCredits';
import BlogIndividualMarketing from '../views/Marketing/BlogIndividual';
import Blog from '../views/Company/Blog';
import SocialLogin from '../views/SocialLogin';
import MarketingLogin from '../views/MarketingLogin';
import MarketingRegister from '../views/MarketingRegister';
import AuthWrapper from '../components/AuthWrapper';
import LandingPage from '../views/Company/LandingPage';
import LoginCommon from '../views/Login';
import { localPrevLink } from '../utils/helpers';
import { LINK_GROUP } from '../utils/links';
import { AGENCY_ROUTES } from './AgencyRoutes';
import { FREELANCER_ROUTES } from './FreelancerRoutes';
import { COMPANY_ROUTES } from './CompanyRoutes';
import { ThemeAppProvider } from '../v2/context/themeAppContext';
import {
  CompanyManageAccountInfoPage,
  CompanyPurchaseJobPostsPage,
  NotFoundPage,
  SocialLoginPage,
} from '../v2/pages';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    localPrevLink.removeLink(location.pathname);
    localPrevLink.setLink(location.pathname);
  }, [location]);

  return (
    <ThemeAppProvider>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/login/google" exact component={SocialLoginPage} />
        <Route path="/login/marketing" exact component={MarketingLogin} />
        <Route path="/register/marketing" exact component={MarketingRegister} />
        <Route path="/login" exact component={LoginCommon} />
        <Route
          path={'/marketing/how-it-works'}
          exact
          component={BlogIndividualMarketing}
        />
        <Route path="/404" exact component={NotFoundPage} />

        {/* Agency URLs */}
        {AGENCY_ROUTES.map(
          ({ component: Component, exact, path, shouldLogIn, users }) => {
            const RouteComponent = shouldLogIn ? AuthWrapper : Route;
            return (
              <RouteComponent
                key={path}
                path={`/${LINK_GROUP.AGENCY}/${path}`}
                exact={exact}
                component={Component}
                user={users ?? []}
              />
            );
          }
        )}

        {/* Freelancer / Consultant URLs */}
        {FREELANCER_ROUTES.map(
          ({ component: Component, exact, path, shouldLogIn, users }) => {
            const RouteComponent = shouldLogIn ? AuthWrapper : Route;
            return (
              <RouteComponent
                key={path}
                path={`/${LINK_GROUP.FREELANCER}/${path}`}
                exact={exact}
                component={Component}
                user={users ?? []}
              />
            );
          }
        )}

        {FREELANCER_ROUTES.map(
          ({ component: Component, exact, path, shouldLogIn, users }) => {
            const RouteComponent = shouldLogIn ? AuthWrapper : Route;
            return (
              <RouteComponent
                key={path}
                path={`/${LINK_GROUP.CANDIDATE}/${path}`}
                exact={exact}
                component={Component}
                user={users ?? []}
              />
            );
          }
        )}

        {/* Company / Consultant URLs */}
        {COMPANY_ROUTES.map(
          ({ component: Component, exact, path, shouldLogIn, users }) => {
            const RouteComponent = shouldLogIn ? AuthWrapper : Route;
            return (
              <RouteComponent
                key={path}
                path={`/${LINK_GROUP.COMPANY}/${path}`}
                exact={exact}
                component={Component}
                user={users ?? []}
              />
            );
          }
        )}

        {COMPANY_ROUTES.map(
          ({ component: Component, exact, path, shouldLogIn, users }) => {
            const RouteComponent = shouldLogIn ? AuthWrapper : Route;
            return (
              <RouteComponent
                key={path}
                path={`/${LINK_GROUP.EMPLOYER}/${path}`}
                exact={exact}
                component={Component}
                user={users ?? []}
              />
            );
          }
        )}

        {/* Stripe related urls */}
        <Elements stripe={stripePromise}>
          <AuthWrapper
            path={'/' + LINK_GROUP.COMPANY + '/subscription'}
            exact
            component={StartSubscription}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            path={'/' + LINK_GROUP.COMPANY + '/manage-payment-info'}
            component={CompanyManageAccountInfoPage}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            path={'/' + LINK_GROUP.COMPANY + '/manage-credits/v1'}
            exact
            component={ManageCredits}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            path={'/' + LINK_GROUP.COMPANY + '/manage-credits'}
            component={CompanyPurchaseJobPostsPage}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            path={'/' + LINK_GROUP.COMPANY + '/resources'}
            exact
            component={Blog}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />

          <AuthWrapper
            key="subscription"
            path={`/${LINK_GROUP.EMPLOYER}/subscription`}
            exact
            component={StartSubscription}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            key="manage-payment-info"
            path={`/${LINK_GROUP.EMPLOYER}/manage-payment-info`}
            exact
            component={CompanyManageAccountInfoPage}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <AuthWrapper
            key="manage-credits"
            path={`/${LINK_GROUP.EMPLOYER}/manage-credits`}
            exact
            component={CompanyPurchaseJobPostsPage}
            user={[LINK_GROUP.COMPANY.toUpperCase(), 'GUEST']}
          />
          <Route component={NotFoundPage} />
        </Elements>
      </Switch>
    </ThemeAppProvider>
  );
};

export default Routes;
