import React from 'react'
import { BackPanel, Heading } from '../../snippets'

export default function LoadingSection() {
  return (
    <BackPanel className='text-center'>
        <Heading as='h2'>Loading...</Heading>
    </BackPanel>
  )
}
