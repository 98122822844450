import { LINK_GROUP } from '../utils/links';
import {
  LoginPage,
  DashboardPage,
  LogoutPage,
  PersonalInformationEditPage,
  PersonalInformationPage,
  RegisterPage,
  ConsultantProfilePage,
  ConsultantEditProfilePage,
  ConsultantViewCompanyProfilePage,
  ForgotPasswordPage,
  ConsultantCompleteProfilePage,
  ResourcesPage,
  OpportunityDetailPage,
  OpportunitiesPage,
  OpportunitiesForYouPage,
  DealsPage,
  NotFoundPage,
  ResetPasswordPage,
} from '../v2/pages';
import ChangePasswordPage from '../v2/pages/change-password';
import NotificationSettingsPage from '../v2/pages/notification-settings';
import ProfilePrivacyPage from '../v2/pages/profile-privacy';
import {
  AdminImpersonateFreelancer,
  BlogIndividual,
  CompleteProfile,
  ManageCV,
  SetNewPassword,
} from '../views/Freelancer';

export const FREELANCER_ROUTES = [
  {
    path: 'login',
    // component: Login,
    component: LoginPage,
    exact: true,
  },
  {
    path: 'logout',
    component: LogoutPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'register',
    component: RegisterPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'register/:step',
    component: RegisterPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'resources/:slug',
    component: BlogIndividual,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'impersonate/:id',
    component: AdminImpersonateFreelancer,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'complete-profile/2',
    component: CompleteProfile,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'complete-profile',
    component: ConsultantCompleteProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'dashboard',
    component: DashboardPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'notification-settings',
    component: NotificationSettingsPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'latest-opportunities',
    component: OpportunitiesPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'opportunities-for-you',
    component: OpportunitiesForYouPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'company-profile/:id',
    component: ConsultantViewCompanyProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'opportunities/:id',
    component: OpportunityDetailPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'manage-cv',
    component: ManageCV,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'personal-information',
    component: PersonalInformationPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'edit-personal-information',
    component: PersonalInformationEditPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'profile',
    component: ConsultantProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'edit-profile',
    component: ConsultantEditProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'profile-privacy',
    component: ProfilePrivacyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'change-password',
    component: ChangePasswordPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'resources',
    component: ResourcesPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: 'deals',
    exact: true,
    shouldLogIn: true,
    component: DealsPage,
    users: [LINK_GROUP.FREELANCER.toUpperCase()],
  },
  {
    path: '404',
    component: NotFoundPage,
  },
];
