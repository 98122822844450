import React from 'react';
import { BackPanel, Heading } from '../../snippets';
import { TablerSectionGrid } from '..';

export default function SectionList({
  headline = '',
  items,
  headers,
  proportions,
}) {
  return (
    <BackPanel>
      <Heading>
        {headline} <span className="text-count">({items.length})</span>
      </Heading>

      <div className="r-side full-size mt-4">
        <TablerSectionGrid
          className=""
          tables={items}
          renderItem={(item) => {
            return <>{item}</>;
          }}
          headers={headers}
          tableProportion={proportions}
        ></TablerSectionGrid>

        <div className="gridjobs-grid for-you">
          {items.map((d, i) => {
            return (
              <div className={`gridjobs-grid--item`} key={i}>
                {d[0]}
                <div className="grid-divider"></div>
                <div className="company-info">
                  {d[1]}

                  <div className="company-info--time">{d[2]}</div>

                  {d[3]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BackPanel>
  );
}
