import React, { useState, useContext } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import countryList from 'country-list';
import { useMutation } from '@apollo/react-hooks';
import hideModal from '../../styles/js/hideModal';
import { getUser } from '../../utils/helpers';
import { AppContext } from '../../Store';
import {
  UPDATE_PROFILE,
  UPDATE_TEAM_PROFILE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_TEAM_PAYMENT_METHOD,
} from '../../graphql';

const UpdatePaymentInfoModal = ({ show, onClose = null }) => {
  const [globalState] = useContext(AppContext);
  const countries = countryList
    .getData()
    .sort((a, b) => a.name.localeCompare(b.name));
  const stripe = useStripe();
  const elements = useElements();
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [updateTeamProfile] = useMutation(UPDATE_TEAM_PROFILE);
  const [updateTeamPaymentMethod] = useMutation(UPDATE_TEAM_PAYMENT_METHOD);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: 'GB',
    },
  });
  const createOptions = () => ({
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
        height: '42px',
        letterSpacing: '0.18px',
        lineHeight: '42px',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
    classes: {
      base: 'StripeCardBase',
    },
  });

  const showError = (message) => {
    toast.error(message, { toastId: 'updatePaymentInfo' });
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      showError(error);
      return;
    }

    if (cardComplete) {
      setLoading(true);
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpire = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    // Use your card Element with other Stripe.js APIs
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: billingDetails,
    });

    if (payload.error) {
      showError(payload.error);
      setLoading(false);
    } else {
      if (getUser()?.type === 'COMPANY') {
        updateProfile({
          variables: {
            input: {
              billing_address_1: billingDetails.address.line1,
              billing_address_2: billingDetails.address.line2,
              billing_city: billingDetails.address.city,
              billing_state: billingDetails.address.state,
              billing_country: billingDetails.address.country,
              billing_zip: billingDetails.address.postal_code,
            },
          },
        });
        updatePaymentMethod({
          variables: {
            input: { payment_method_id: payload.paymentMethod.id },
          },
        })
          .then(() => {
            setLoading(false);
            toast.dismiss();
            if (onClose !== null) {
              onClose();
            }
            hideModal();
            toast.success('Payment info updated!', { autoClose: 4000 });
            setBillingDetails({
              name: '',
              email: '',
              phone: '',
              address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
              },
            });
            cardNumber.clear();
            cardExpire.clear();
            cardCvc.clear();
          })
          .catch((e) => {
            toast.dismiss();
            showError(e.graphQLErrors[0].extensions.reason);
            setLoading(false);
          });
      } else if (getUser()?.type === 'GUEST') {
        updateTeamProfile({
          variables: {
            input: {
              billing_address_1: billingDetails.address.line1,
              billing_address_2: billingDetails.address.line2,
              billing_city: billingDetails.address.city,
              billing_state: billingDetails.address.state,
              billing_country: billingDetails.address.country,
              billing_zip: billingDetails.address.postal_code,
            },
            teamId: Number(globalState?.activeCompany?.team_id),
          },
        });
        updateTeamPaymentMethod({
          variables: {
            input: {
              payment_method_id: payload.paymentMethod.id,
            },
            teamId: Number(globalState?.activeCompany?.team_id),
          },
        })
          .then(() => {
            setLoading(false);
            toast.dismiss();
            toast.success('Your payment info has been updated!', {
              autoClose: 4000,
            });
            setBillingDetails({
              name: '',
              email: '',
              phone: '',
              address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
              },
            });
            cardNumber.clear();
            cardExpire.clear();
            cardCvc.clear();
          })
          .catch((e) => {
            toast.dismiss();
            showError(e.graphQLErrors[0].extensions.reason);
            setLoading(false);
          });
      }
    }
  };

  return (
    <div
      className={`modal fade ${show ? 'display-block' : 'display-none'}`}
      id="update_info_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h3 className="txt__fw-600 text-center">Payment Information</h3>
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="form__input-block">
                  <div className="form__label">Name</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="name"
                      value={billingDetails.name}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">Email</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="email"
                      autoComplete="false"
                      name="email"
                      value={billingDetails.email}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          email: e.target.value,
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">Phone</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="phone"
                      value={billingDetails.phone}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          phone: e.target.value,
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div style={{ clear: 'both' }} />

                <div className="form__input-block">
                  <div className="form__label">Address Line 1</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="address1"
                      value={billingDetails.address.line1}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            line1: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">Address Line 2</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="address2"
                      value={billingDetails.address.line2}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            line2: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">City</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="city"
                      value={billingDetails.address.city}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">County</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="state"
                      value={billingDetails.address.state}
                      required
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            state: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__two-cols">
                  <div className="form__input-block form__two-cols__inner">
                    <div className="form__label">Postcode / Zip Code</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="post-code"
                        required
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            address: {
                              ...billingDetails.address,
                              postal_code: e.target.value,
                            },
                          });
                        }}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>

                  <div className="form__input-block form__two-cols__inner">
                    <div className="form__label">Country</div>
                    <div className="form__input-wrapper">
                      <select
                        className="form__input form__country"
                        name="country"
                        list="countries"
                        id="country"
                        required
                        defaultValue={billingDetails.address.country}
                        onChange={(e) => {
                          setBillingDetails({
                            ...billingDetails,
                            address: {
                              ...billingDetails.address,
                              country: e.target.value,
                            },
                          });
                        }}
                      >
                        {countries.map(({ code, name }) => (
                          <option value={code} key={code}>
                            {name}
                          </option>
                        ))}
                      </select>
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__label">&nbsp;</div>
                  <div className="form__input-wrapper">
                    <CardNumberElement
                      className="form__input"
                      {...createOptions()}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>

                <div className="onboarding__two-cols-constant">
                  <div className="form__input-block form__two-cols-constant__inner">
                    <div className="form__label">&nbsp;</div>
                    <div className="form__input-wrapper">
                      <CardExpiryElement
                        className="form__input"
                        {...createOptions()}
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form__input-block form__two-cols-constant__inner">
                    <div className="form__label">&nbsp;</div>
                    <div className="form__input-wrapper">
                      <CardCvcElement
                        className="form__input"
                        {...createOptions()}
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center">
                <button
                  className="btn form__btn d-block bg-company"
                  type="submit"
                  disabled={!stripe}
                >
                  {loading ? 'Loading...' : 'SAVE'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdatePaymentInfoModal;
