import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button } from '../../snippets';
import {
  getUser,
  handleClickOutside,
  IMG_PLACEHOLDER,
} from '../../../utils/helpers';
import ButtonClose from '../ButtonClose/ButtonClose';
import useTeams from '../../hooks/useTeams';

export default function CompanySwitch() {
  const { otherTeams, user, handleOnSwitchTeam } = useTeams();

  const [active, setActive] = useState(false);
  const popupRef = useRef(null);

  const onClickOutside = (e) => {
    return handleClickOutside(e, popupRef, setActive);
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  if (otherTeams.length === 0) {
    return null;
  }

  return (
    <div className="company-switch-menu">
      <Button
        variant="trigger-profile app-btn-transparent"
        rounded
        onClick={() => setActive((p) => !p)}
        noGradient
      >
        <img src={user?.avatar || IMG_PLACEHOLDER} alt={user?.company ?? ''} />
      </Button>
      {active && (
        <div className="company-switch-menu--popup" ref={popupRef}>
          <ButtonClose onClick={() => setActive(false)} light />
          <div className="popup--content">
            <div className="meta-information">
              <div className="meta-avatar">
                <img
                  src={user?.avatar || IMG_PLACEHOLDER}
                  alt={user?.company ?? ''}
                />
              </div>
              <div className="meta-details">
                <div className="meta--company-name">{user?.company}</div>
                <div className="meta--name">{getUser()?.name}</div>
              </div>
            </div>
            <div className="meta-companies">
              <div className="meta-companies--label">Other Teams</div>
              <div className="meta-companies--list">
                {otherTeams?.map(({ user, ...rest }, i) => {
                  return (
                    <Fragment key={i}>
                      <Button
                        className="meta-companies--list-item"
                        variant="outline"
                        onClick={() => {
                          handleOnSwitchTeam({ user, ...rest });
                        }}
                      >
                        <div className="list-item--avatar frame-rounded">
                          <img
                            src={user?.avatar || IMG_PLACEHOLDER}
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="list-item--name text-capitalize">
                          {user.company}
                        </div>
                      </Button>
                      <div className="meta-companies--break-line" />
                    </Fragment>
                  );
                })}
                {otherTeams?.length === 0 && (
                  <span className="text-disabled">No Other Teams </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
