import gql from 'graphql-tag';

export const UPDATE_PROFILE = gql`
  mutation ($input: UserUpdate!) {
    updateUser(input: $input) {
      id
      bio
      avatar
      company
      email
      address_1
      address_2
      city
      state
      country
      zip
      bio
      description
      phone
      position
      email
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      invoice_company
      invoice_address_1
      invoice_address_2
      invoice_city
      invoice_state
      invoice_country
      invoice_zip
      invoice_phone
      invoice_email
      referee_1
      referee_2
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      linkedin
      salary_currency
      minimum_salary
      maximum_salary
      salary_unit
      looking_for
      payroll_status
      hear_us
      hear_us_additional
      ethnicity
      isProfileComplete {
        complete
        missing
      }
      location
      can_use_logo
      agency_name
      contact_email
      agency_phone {
        key
        attributes {
          phone
        }
      }
      pronouns
      language
      jobtitle
      start_date
      right_to_work
      notice_period
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation ($input: SubscriptionInput!) {
    updatePaymentMethod(input: $input)
  }
`;

export const ADD_SUBSCRIPTION = gql`
  mutation ($input: SubscriptionCreate!) {
    addSubscription(input: $input)
  }
`;

export const CREATE_PREFERENCE = gql`
  mutation ($input: PreferenceInput!) {
    createPreference(input: $input) {
      id
      job_types
      genres {
        id
        name
      }
    }
  }
`;

export const UPDATE_PREFERENCE = gql`
  mutation ($id: ID!, $input: PreferenceInput) {
    updatePreference(id: $id, input: $input) {
      id
      job_types
      genres {
        id
        name
      }
    }
  }
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation ($phone: String!) {
    sendVerificationCode(phone: $phone)
  }
`;

export const VERIFY_NUMBER = gql`
  mutation ($code: String!) {
    verifyNumber(code: $code)
  }
`;

export const RESEND_VERIFICATION_CODE = gql`
  mutation {
    resendVerificationCode
  }
`;
