import { LINK_GROUP } from './links';

export function getGroupByPathname(pathname) {
  let currentGroupType = LINK_GROUP.AGENCY;

  const splitPath = pathname.split('/');

  for (let str of splitPath) {
    if (
      Object.keys(LINK_GROUP)
        .map((a) => a.toLowerCase())
        .includes(str)
    ) {
      currentGroupType = str;
      break;
    }
    continue;
  }
  return currentGroupType;
}
