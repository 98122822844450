import React from 'react';
import { IconArrow, IconThumbUp } from '../../components/Icons';
import { AgencyHeader } from '../../components/Agency';
import Footer from '../../components/Footer';
import { LINK_GROUP } from '../../utils/links';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function CreateAccountSuccess() {
  const history = useHistory();
  function handleGoTo() {
    history.push(`/${LINK_GROUP.AGENCY}/dashboard`);
  }
  function handlePortfolio() {
    history.push(`/${LINK_GROUP.AGENCY}/dashboard`);
  }
  return (
    <div className="lh-wrapper">
      <AgencyHeader />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <div className="card__block-with-icon">
                <div className="item--icon-block success-create-account">
                  <IconThumbUp />
                </div>
                <div className="item--headline">Your All Signed Up!</div>
                <div className="item--description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas tristique condimentum ante, at tincidunt ante aliquam
                  eget.
                </div>
                <div>
                  <button
                    type="button"
                    className="form__btn btn-v2 btn-wider btn form__btn-agency-v2 d-block mb-22 mt-0"
                    onClick={handlePortfolio}
                  >
                    {'MANAGE PORTFOLIO'}
                  </button>
                </div>
                <button className="btn-go-to" onClick={handleGoTo}>
                  <span>
                    <IconArrow />
                  </span>
                  <span className='text__gray--dark'>GO TO DASHBOARD</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
