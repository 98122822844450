import React, { useContext } from 'react';
import Header from '../../components/Agency/Header';
import Footer from '../../v2/components/Footer/Footer';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Heading } from './CaseStudy';
import { GET_CASE_STUDY } from '../../graphql';
import { AppContext } from '../../Store';
import ReactPlayer from 'react-player';

const CaseStudyDetails = () => {
  const { id } = useParams();
  const [state] = useContext(AppContext);
  const { data: { caseStudy = {} } = {} } = useQuery(GET_CASE_STUDY, {
    variables: {
      id,
    },
  });
  return (
    <div className="lh-wrapper bg__gradient-4 bg-cover bg-fixed theme theme-agency">
      <Header isLoggedIn />
      <div style={{ flex: 1 }} className="container gap-3">
        <div
          style={{ flex: 1 }}
          className="container black-card black-card__full mb-50"
        >
          <HeadingCont>
            <div>
              <Heading>{caseStudy.title}</Heading>
              <h4>{state?.activeCompany?.user?.agency_name}</h4>
            </div>
            <img src={state?.activeCompany?.user?.avatar} alt="logo" />
          </HeadingCont>
          <HeroCont>
            <img src={caseStudy.thumbnail} alt="" />
            <div className="copy">
              <p>{caseStudy.description}</p>
              <a
                href={caseStudy.external_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn bg__gradient-1 bg-cover"
                  style={{ letterSpacing: '1px' }}
                >
                  VIEW PROJECT
                </button>
              </a>
            </div>
          </HeroCont>
        </div>
        <div
          style={{ flex: 1 }}
          className="container black-card black-card__full"
        >
          <ContentContainer className="d-flex flex-column gap-100">
            {caseStudy.content?.map((item) => {
              if (item.layout === 'image') {
                return (
                  <img src={item.attributes.image} alt="" key={item.key} />
                );
              } else {
                return (
                  <ReactPlayer
                    url={item.attributes.url}
                    width={'100%'}
                    height={'100%'}
                    key={item.key}
                    controls={true}
                  />
                );
              }
            })}
          </ContentContainer>
        </div>
      </div>
      <div className="mt-50">
        <Footer />
      </div>
    </div>
  );
};

const ContentContainer = styled.div`
  gap: 87px;
  @media (min-width: 768px) {
    gap: 100px;
  }
`;
const HeroCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 70px;
  img {
    height: 100%;
    width: 100%;
  }
  .copy {
    max-width: 100%;
    p {
      color: #929292;
      font-size: 16px;
      line-height: 21.82px;
      font-weight: 400;
    }
    button {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 8px;
      color: #ffffff;
      font-weight: 800;
      font-size: 16px;
      margin-top: 50px;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    img {
      height: 350px;
      width: 48%;
    }
    .copy {
      max-width: 100%;
      width: 400px;
      button {
        width: 148px;
        height: 38px;
        border-radius: 6px;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1400px) {
    img {
      height: 436px;
      width: 532px;
    }
  }
`;

const HeadingCont = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0;
    color: #929292;
    font-size: 16px;
    line-height: 21.82px;
    font-weight: 700;
  }
  img {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 768px) {
    img {
      width: 47px;
      height: 47px;
    }
  }
`;

export default CaseStudyDetails;
