import gql from 'graphql-tag';

export const CREATE_TEAM_USER_ASSOCIATION = gql`
  mutation CreateTeamUserAssociation($input: UserTeamAssociationCreate!) {
    createUserTeamAssociation(input: $input) {
      user_id
      team_id
      associate_id
    }
  }
`;

export const DELETE_TEAM_USER_ASSOCIATION = gql`
  mutation DeleteTeamUserAssociation($input: UserTeamAssociationCreate!) {
    deleteUserTeamAssociation(input: $input) {
      user_id
      team_id
      associate_id
    }
  }
`;

export const SYNC_TEAM_USER_ASSOCIATION = gql`
  mutation SyncTeamUserAssociation($input: UserTeamAssociationInput!) {
    syncUserTeamAssociation(input: $input) {
      id
      teamAssociations {
        id
        user_id
        team_id
        associate_id
      }
    }
  }
`;
