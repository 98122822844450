import React from 'react';
import { Link } from '../../snippets';

const Deal = ({ externalLink, img, url, headline, info }) => {
  return (
    <div className={`deal`}>
      <div className="company-info">
        <Link to={url} external={externalLink ?? false}>
          <div className="company-logo">
            <img src={img || '/img/agency-picture-placeholder@2x.jpg'} alt="" />
          </div>
        </Link>
        <div className="divider"></div>
        <div className="deal--header">
          <div>
            <h3>{headline}</h3>
            <p className="company-info--header_info">{info}</p>
          </div>
          <Link
            external={externalLink ?? false}
            href={url}
            className="hide-on-desktop"
          >
            Find Out More
          </Link>
        </div>
      </div>
      <Link
        external={externalLink ?? false}
        href={url}
        className="hide-on-mobile"
      >
        Find Out More
      </Link>
    </div>
  );
};

export default Deal;
