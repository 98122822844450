import React, { useMemo, useState } from 'react';
import { FormFieldAddOns } from '..';
import PhoneInput from 'react-phone-input-2';
import { Dropdown, Wysiwyg } from '../../components';
import classNamesHelper from 'classnames';
import DatePicker from 'react-date-picker';

const typeWithHelpText = {
  select: true,
};

export default function FormInput({
  fieldClassName = 'field',
  className = '',
  type = 'text',
  settings = { required: false },
  isRequired = false,
  autoComplete = 'false',
  error = null,
  name = 'formname',
  helpChildren,
  checkboxChildren = null,
  register,
  withEmailValidation = false,
  options = [],
  value = '',
  defaultValue = '',
  defaultChecked = false,
  showErrorText = false,
  setPhoneNo = (val) => {},
  setPhoneError = () => {},
  handleEnterKey = () => {},
  watch,
  setValue,
  clearErrors,
  selectSetting = {
    onSelect: (e) => {},
    reset: false,
    setReset: (e) => {},
  },
  placeholder: originPlaceholder = '',
  datePickerSetting = {
    format: 'dd/MM/y',
    name: 'close_date',
    required: false,
    clearIcon: null,
    dayPlaceholder: 'dd',
    monthPlaceholder: 'mm',
    yearPlaceholder: 'yyyy',
    onChange: (e) => {},
    value: '',
  },
  maxLength,
  wysiwygSet = {
    onlyTextFormating: false,
  },
  ...rest
}) {
  const showWarnError = useMemo(() => {
    if (showErrorText) {
      return true;
    }
    return typeWithHelpText[type] ?? false;
  }, [type, showErrorText]);

  const classNames =
    useMemo(() => {
      let _c = ['field-input', className];
      if (type === 'google') {
        _c = ['g-circle'];
      }
      if (type === 'phone') {
        _c.push('field-input--pull-left');
      }
      if (error) {
        _c.push('error-state');
      }
      return _c.join(' ');
    }, [className, error, type]) || [];

  const setup = useMemo(() => {
    let _s = settings;

    if (isRequired) {
      _s = { ..._s, required: true };
    }

    if (type === 'email' || withEmailValidation) {
      return {
        pattern:
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        ..._s,
      };
    }
    if (type === 'password' || withEmailValidation) {
      return {
        pattern: /^(?=.*\W)(?!.* ).{8,}$/,
        ..._s,
      };
    }

    return _s;
  }, [settings, isRequired, type, withEmailValidation]);

  const validatePhone = (phone) => {
    if (isRequired) {
      if (phone.length < 5) {
        return setPhoneError(true);
      }
    }
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      return setPhoneError(true);
    }

    return setPhoneError(false);
  };

  const placeholder = useMemo(() => {
    if (!error) {
      return originPlaceholder;
    }
    return error;
  }, [error, originPlaceholder]);

  function onChangePhone(value) {
    setPhoneNo(value);
    validatePhone(value);
  }

  const watchValue = useMemo(() => {
    if (watch) {
      return watch(name);
    }

    return '';
  }, [watch, name]);

  const [passwordRevealed, setPasswordRevealed] = useState(false);

  return (
    <>
      <div className={fieldClassName}>
        {type === 'textarea' && (
          <textarea
            rows="8"
            ref={register(setup)}
            className={classNames}
            name={name}
            defaultValue={defaultValue}
            {...rest}
            placeholder={placeholder}
            onBlur={clearErrors ? () => clearErrors([name]) : undefined}
          ></textarea>
        )}

        {type === 'wysiwyg' && (
          <>
            <input
              type="hidden"
              name={name}
              ref={register ? register(setup) : null}
              defaultValue={defaultValue}
            />
            <Wysiwyg
              className={classNames}
              name={name + '_avoid'}
              defaultValue={defaultValue}
              onChange={(val) => {
                if (clearErrors) {
                  clearErrors([name]);
                }
                setValue(val);
              }}
              onlyTextFormating={wysiwygSet.onlyTextFormating}
              maxLength={maxLength}
              onBlur={clearErrors ? () => clearErrors([name]) : undefined}
            />
          </>
        )}

        {type === 'select' && (
          <>
            <Dropdown
              lists={options}
              defaultActiveValue={defaultValue}
              className={classNames}
              register={register ? register(setup) : null}
              name={name}
              error={error}
              onSelected={selectSetting.onSelect}
              clearErrors={clearErrors ? () => clearErrors([name]) : undefined}
              placeholder={placeholder}
              reset={selectSetting.reset}
              setReset={selectSetting.setReset}
              {...rest}
            />
          </>
        )}

        {type === 'phone' && (
          <PhoneInput
            country="gb"
            autoFormat
            countryCodeEditable={true}
            inputProps={{
              name: name,
              required: isRequired,
              autoFocus: false,
            }}
            inputStyle={{ ...rest.inputStyle }}
            inputClass={classNames}
            containerClass=""
            buttonClass="phone-button"
            onChange={onChangePhone}
            dropdownClass="phone-input-dropdown"
            dropdownStyle={{
              background: '#fff',
              color: 'black',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              ...rest.dropdownStyle,
            }}
            placeholder="44 116 456 5566"
            // isValid={(value) => validatePhone(value)}
            value={defaultValue}
          />
        )}

        {type === 'checkbox' && (
          <label className="app-custom-checkbox">
            <input
              className={classNames}
              type={type}
              ref={register ? register(setup) : null}
              autoComplete={autoComplete}
              name={name}
              id={name}
              {...rest}
              defaultChecked={defaultChecked}
              placeholder={placeholder}
            />
            <div className="block-box"></div>
            {checkboxChildren && (
              <span className="text-label">{checkboxChildren}</span>
            )}
          </label>
        )}

        {type === 'radio' && (
          <label className="field-input-row" htmlFor={name}>
            <input
              className="radio-gradient"
              type={type}
              ref={register(setup)}
              autoComplete={autoComplete}
              name={name}
              defaultValue={defaultValue}
              defaultChecked={defaultChecked}
              {...rest}
              placeholder={placeholder}
            />{' '}
            {checkboxChildren}
          </label>
        )}

        {type === 'radiobutton' && (
          <label className="field-input-row radio-button">
            <input
              className=""
              type={'radio'}
              ref={register(setup)}
              autoComplete={autoComplete}
              name={name}
              defaultValue={defaultValue}
              defaultChecked={defaultChecked}
              {...rest}
              placeholder={placeholder}
            />
            <div className="radio-bg"></div>
            <span className="radio-text">{checkboxChildren}</span>
          </label>
        )}

        {type === 'password' && (
          <div
            className={classNamesHelper(
              'form__input-wrapper form__input-password',
              watchValue ? 'form__input-password--filled' : ''
            )}
          >
            <input
              className={classNames}
              type={passwordRevealed ? 'text' : 'password'}
              ref={register(setup)}
              autoComplete={autoComplete}
              name={name}
              defaultValue={defaultValue}
              {...rest}
              placeholder={placeholder}
            />
            {watchValue && (
              <div className="d-flex align-items-center action-buttons">
                <button
                  type="button"
                  onClick={() => setPasswordRevealed((prev) => !prev)}
                >
                  <i
                    className={classNamesHelper(
                      'fas',
                      passwordRevealed ? 'fa-eye-slash' : 'fa-eye'
                    )}
                  />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (setValue) {
                      setValue(name, '');
                    }
                  }}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            )}
          </div>
        )}

        {type === 'file' && (
          <label className="field-input-row">
            <input
              type="file"
              ref={register(setup)}
              name={name}
              className={className}
              {...rest}
            />
          </label>
        )}

        {(type === 'text' || type === 'number' || type === 'email') && (
          <input
            className={classNames}
            type={type}
            ref={register(setup)}
            autoComplete={autoComplete}
            name={name}
            defaultValue={defaultValue}
            min={0}
            {...rest}
            placeholder={placeholder}
            onBlur={clearErrors ? () => clearErrors([name]) : undefined}
          />
        )}
      </div>
      {type === 'datepicker' && (
        <DatePicker
          onChange={datePickerSetting?.onChange}
          value={datePickerSetting?.value}
          className={className}
          format={datePickerSetting?.format ?? 'dd/MM/y'}
          name={datePickerSetting?.name ?? 'close_date'}
          required={datePickerSetting?.required ?? false}
          clearIcon={datePickerSetting?.clearIcon ?? null}
          dayPlaceholder={datePickerSetting?.dayPlaceholder ?? 'dd'}
          monthPlaceholder={datePickerSetting?.monthPlaceholder ?? 'mm'}
          yearPlaceholder={datePickerSetting?.yearPlaceholder ?? 'yyyy'}
        />
      )}
      <FormFieldAddOns
        error={error}
        showWarnError={showWarnError}
        helpChildren={helpChildren}
      />
    </>
  );
}
