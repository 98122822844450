import React from 'react';

export default function ChevronIcon({ ...props }) {
  return (
    <svg width="1em" height="1.78em" viewBox="0 0 16 9" {...props}>
      <path
        d="M8 8.99219L7.28125 8.27344L1.28125 2.27344L0.5625 1.55469L2 0.148438L2.6875 0.867188L8 6.14844L13.2812 0.867188L14 0.148438L15.4062 1.55469L14.6875 2.27344L8.6875 8.27344L8 8.99219Z"
        fill="currentColorp"
      />
    </svg>
  );
}
