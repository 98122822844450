import gql from 'graphql-tag';

export const GET_CASE_STUDIES = gql`
  query CaseStudies(
    $orderBy: [QueryCaseStudiesOrderByOrderByClause!]
    $where: QueryCaseStudiesWhereWhereConditions
    $first: Int = 10
    $page: Int
  ) {
    caseStudies(orderBy: $orderBy, where: $where, first: $first, page: $page) {
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
      }
      data {
        id
        title
        description
        thumbnail
        external_url
        video_url
        created_at
        updated_at
        content {
          layout
          key
          attributes {
            image
            url
          }
        }
      }
    }
  }
`;

export const GET_CASE_STUDIES_WITH_AGENCY = gql`
  query CaseStudies(
    $orderBy: [QueryCaseStudiesOrderByOrderByClause!]
    $where: QueryCaseStudiesWhereWhereConditions
    $first: Int = 10
    $page: Int
  ) {
    caseStudies(orderBy: $orderBy, where: $where, first: $first, page: $page) {
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
      }
      data {
        id
        title
        thumbnail
        external_url
        agency {
          company
          services {
            name
          }
        }
        # video_url
        # created_at
        # updated_at
        # content {
        #   layout
        #   key
        #   attributes {
        #     image
        #     url
        #   }
        # }
      }
    }
  }
`;

export const GET_CASE_STUDY = gql`
  query CaseStudy($id: ID!) {
    caseStudy(id: $id) {
      id
      title
      description
      thumbnail
      external_url
      video_url
      created_at
      updated_at
      content {
        layout
        key
        attributes {
          image
          url
        }
      }
      agency {
        agency_name
        avatar
      }
    }
  }
`;
