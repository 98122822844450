import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Text,
} from '../../snippets';
import SectionForm from '../SectionForm/SectionForm';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_CREDIT,
  CALCULATE_PRICE,
  CHECK_PROMO,
  GET_AUTH_USER,
} from '../../../graphql';
import CONTENTS_UTIL from '../../../utils/contents';
import useTeams from '../../hooks/useTeams';
import { toast } from 'react-toastify';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import { getLoginUrl, logUserOut } from '../../../utils/helpers';
import { AppContext } from '../../../Store';
import { LINK_GROUP } from '../../../utils/links';
import useCredits from '../../hooks/useCredits';
import ManagePaymentForm from '../ManagePaymentForm/ManagePaymentForm';
import useModal from '../../hooks/useModal';
import { useHistory } from 'react-router-dom';

const CartTotal = ({
  watchQTY = 0,
  calculatePriceIsLoading = true,
  totals = {},
  promoValue,
  getValues = (name) => {},
}) => {
  return (
    <div className="purchase-checkout">
      <div className="purchase-checkout--row row-header">
        <div className="col-name col-meta-data col-header">Job Posts Added</div>
        <div className="col-value col-meta-data col-header">
          {watchQTY ?? 1}
        </div>
      </div>
      <div className="purchase-checkout--row no-border">
        <div className="col-name col-meta-data ">Subtotal</div>
        <div className="col-value col-meta-data ">
          &pound;{' '}
          {calculatePriceIsLoading
            ? '...'
            : totals.subtotalExcludingDiscount.toFixed(2)}
        </div>
      </div>
      {getValues('qty') > 2 && (
        <div className="purchase-checkout--row no-border">
          <div className="col-name col-meta-data ">Discounts Applied</div>
          <div className="col-value col-meta-data ">
            &pound;{' '}
            {calculatePriceIsLoading ? '...' : totals.discountAmount.toFixed(2)}
          </div>
        </div>
      )}
      {promoValue !== 0 && getValues('promo') && (
        <div className="purchase-checkout--row no-border">
          <div className="col-name col-meta-data ">Promo Code</div>
          <div className="col-value col-meta-data ">
            &pound; {calculatePriceIsLoading ? '...' : promoValue.toFixed(2)}
          </div>
        </div>
      )}
      <div className="purchase-checkout--row">
        <div className="col-name col-meta-data ">
          VAT @{totals.vatPercentage * 100}%
        </div>
        <div className="col-value col-meta-data ">
          &pound;{' '}
          {calculatePriceIsLoading ? '...' : totals.vatAmount.toFixed(2)}
        </div>
      </div>
      <div className="purchase-checkout--row no-border">
        <div className="col-name col-meta-data ">TOTAL</div>
        <div className="col-value col-meta-data ">
          &pound; {totals.total.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

// const ConfirmationModal = ({
//   calculatePriceIsLoading,
//   totals,
//   watchQTY,
//   getValues,
//   promoValue,
//   setOnSaving,
//   onSaving,
//   onConfirmAddCredi,
// }) => {
//   return (
//     <>
//       <Heading>Payment</Heading>
//       <Text className="text-disabled spacer-t thin mb-4">
//         Are you sure to process this payment?
//       </Text>
//       <CartTotal
//         {...{
//           calculatePriceIsLoading,
//           totals,
//           watchQTY,
//           getValues,
//           promoValue,
//         }}
//       />
//       <FormCTA column spaceT>
//         <Button
//           onClick={async () => {
//             onConfirmAddCredi(data);
//             onCloseModal();
//           }}
//         >
//           {onSaving ? 'Processing...' : 'Continue'}
//         </Button>
//         <Button
//           variant="outline"
//           transparent
//           onClick={() => {
//             setOnSaving(false);
//             onCloseModal();
//           }}
//         >
//           Cancel
//         </Button>
//       </FormCTA>
//     </>
//   );
// };

export default function PurchaseJobPostsForm({
  onApplyForCreditAccont = () => null,
}) {
  const history = useHistory();
  const [state] = useContext(AppContext);
  const { ChipJobPosts, refetchTeamBalance } = useTeams();
  const [promoValue, setPromoValue] = useState(0);
  const [promoPercentage, setPromoPercentage] = useState(0);
  const [hasCard, setHasCard] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [lastBalance, setBalance] = useState(null);

  const { onOpeModal, onCloseModal, updateModalLastState, modalState } =
    useModal();

  const [totals, setTotals] = useState({
    unitPriceExcludingDiscount: 495,
    discountPercentage: 0,
    discountAmount: 0,
    discountPerUnitAmount: 0,
    unitPriceIncludingDiscount: 495,
    subtotalExcludingDiscount: 495,
    subtotalIncludingDiscount: 495,
    vatAmount: 59,
    vatPercentage: 0.2,
    total: 554,
  });

  const {
    setValue,
    register,
    getValues,
    setError,
    errors,
    clearErrors,
    watch,
    handleSubmit,
    reset,
  } = useForm();

  const {
    balance: { refetch: refetchCredit },
  } = useCredits();

  const [checkPromoMutation, { loading }] = useMutation(CHECK_PROMO);
  const watchQTY = watch('qty');

  const [calculatePrice, { loading: calculatePriceIsLoading }] = useMutation(
    CALCULATE_PRICE,
    {
      onCompleted: ({ calculatePrice: stuff }) => {
        if (promoPercentage) {
          setPromoValue(
            (promoPercentage / 100) * stuff.subtotalIncludingDiscount
          );
        }
        setTotals({ ...stuff });
      },
    }
  );

  const checkPrice = useCallback(() => {
    const qty = parseInt(watchQTY);
    const promo = getValues('promo');
    if (qty !== '') {
      calculatePrice({
        variables: { qty, promo },
      });
    }
  }, [getValues, calculatePrice, watchQTY]);

  const applyPromo = useCallback(() => {
    if (!getValues('promo')) {
      return;
    }

    checkPromoMutation({
      variables: {
        code: getValues('promo'),
      },
    })
      .then(({ data: { checkPromo } }) => {
        if (checkPromo === null) {
          setError('promo', "Promo code doesn't exist!");
          return;
        }

        checkPrice();

        let promoTotal = 0;
        if (checkPromo?.type === 'percentage') {
          promoTotal =
            (checkPromo?.value / 100) * totals.subtotalIncludingDiscount;
        }
        setPromoPercentage(checkPromo?.value);
        setPromoValue(promoTotal);
        // setError('promo', null);
        toast.success('Promo applied!', {
          autoClose: 2000,
        });
      })
      .catch(() => {
        setError('promo', "Promo code doesn't exist!");
      });
  }, [getValues, checkPromoMutation, checkPrice, totals, setError]);

  const { refetch: refetchUser } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl(LINK_GROUP.EMPLOYER.toUpperCase());
      }
      if (state?.activeCompany !== undefined) {
        const { user } = state?.activeCompany;
        if (user !== undefined && user?.pm_type && user?.pm_last_four) {
          setHasCard(true);
        }
      }
    },
    onError: () => refetchUser(),
  });

  const submittedCard = useMemo(() => {
    if (modalState.lastState === 'closed') {
      return true;
    }
    return false;
  }, [modalState]);

  const contents = useMemo(() => {
    return CONTENTS_UTIL.generateDetails([
      CONTENTS_UTIL.generateRowDetails(
        'Pricing',
        'We tailor all our packages to suit your resource needs so please get in touch for a tailored solution. If you prefer to pay as you go, the cost is £495 to post plus competitive commissions starting at 10%, no matter whether it is permanent or freelance.<br><br>You can buy the job posts online or you can apply for an account to be invoiced directly.',
        50
      ),
      CONTENTS_UTIL.generateRowComponent(
        'How Many Posts Do You Need?',
        () => {
          return (
            <>
              <FormBlock>
                <FormBlockItem label={'£495.00 Per Post'} variant={'half'}>
                  <FormInput
                    register={register}
                    isRequired
                    type="number"
                    min={1}
                    max={10}
                    defaultValue="1"
                    name="qty"
                    settings={{
                      min: 1,
                      max: 10,
                    }}
                    className="dekstop-max"
                    data-size="143"
                    error={errors.qty ? 'Min 1' : null}
                  />
                </FormBlockItem>
              </FormBlock>
              <FormBlock isInline inlineCTA>
                <FormBlockItem label={'Promotion Code'}>
                  <FormInput
                    register={register}
                    clearErrors={clearErrors}
                    name="promo"
                    error={errors.promo ? "Promo code doesn't exist!" : ''}
                    showErrorText
                  />
                </FormBlockItem>
                <FormBlockItem label={':empty:'}>
                  <FormCTA>
                    <Button
                      type="button"
                      capitalize
                      className="app-btn--text-normal"
                      onClick={applyPromo}
                      disabled={loading}
                      size="xxl"
                    >
                      {loading ? 'Loading...' : 'Apply'}
                    </Button>
                  </FormCTA>
                </FormBlockItem>
              </FormBlock>
            </>
          );
        },
        439
      ),
      CONTENTS_UTIL.generateRowComponent(
        '',
        () => {
          return (
            <>
              <CartTotal
                {...{
                  calculatePriceIsLoading,
                  totals,
                  watchQTY,
                  getValues,
                  promoValue,
                }}
              />
              {/* <div className="purchase-checkout">
                <div className="purchase-checkout--row row-header">
                  <div className="col-name col-meta-data col-header">
                    Job Posts Added
                  </div>
                  <div className="col-value col-meta-data col-header">
                    {watchQTY ?? 1}
                  </div>
                </div>
                <div className="purchase-checkout--row no-border">
                  <div className="col-name col-meta-data ">Subtotal</div>
                  <div className="col-value col-meta-data ">
                    &pound;{' '}
                    {calculatePriceIsLoading
                      ? '...'
                      : totals.subtotalExcludingDiscount.toFixed(2)}
                  </div>
                </div>
                {getValues('qty') > 2 && (
                  <div className="purchase-checkout--row no-border">
                    <div className="col-name col-meta-data ">
                      Discounts Applied
                    </div>
                    <div className="col-value col-meta-data ">
                      &pound;{' '}
                      {calculatePriceIsLoading
                        ? '...'
                        : totals.discountAmount.toFixed(2)}
                    </div>
                  </div>
                )}
                {getValues('promo') !== '' && (
                  <div className="purchase-checkout--row no-border">
                    <div className="col-name col-meta-data ">Promo Code</div>
                    <div className="col-value col-meta-data ">
                      &pound;{' '}
                      {calculatePriceIsLoading ? '...' : promoValue.toFixed(2)}
                    </div>
                  </div>
                )}
                <div className="purchase-checkout--row">
                  <div className="col-name col-meta-data ">
                    VAT @{totals.vatPercentage * 100}%
                  </div>
                  <div className="col-value col-meta-data ">
                    &pound;{' '}
                    {calculatePriceIsLoading
                      ? '...'
                      : totals.vatAmount.toFixed(2)}
                  </div>
                </div>
                <div className="purchase-checkout--row no-border">
                  <div className="col-name col-meta-data ">TOTAL</div>
                  <div className="col-value col-meta-data ">
                    &pound; {totals.total.toFixed(2)}
                  </div>
                </div>
              </div> */}
              <div className="purchasing-selection-cta">
                {hasCard ? (
                  <Button
                    type="submit"
                    capitalize
                    size="xxl"
                    text="md"
                    disabled={loading || onSaving || calculatePriceIsLoading}
                  >
                    {onSaving ? 'Processing...' : 'Payment Online'}
                  </Button>
                ) : submittedCard ? (
                  <Button
                    capitalize
                    size="xxl"
                    text="md"
                    disabled={loading || onSaving || calculatePriceIsLoading}
                  >
                    PAY
                  </Button>
                ) : (
                  <Button
                    capitalize
                    size="xxl"
                    onClick={() => {
                      if (hasCard) {
                        onOpeModal(() => (
                          <ManagePaymentForm
                            singleGrid
                            handleSave={() => {
                              updateModalLastState('closed');
                              reset();
                              onCloseModal();
                            }}
                          />
                        ));
                      } else {
                        history.push({
                          pathname: `/${LINK_GROUP.EMPLOYER}/manage-payment-info`,
                        });
                      }
                    }}
                    text="md"
                    disabled={loading || onSaving || calculatePriceIsLoading}
                  >
                    {loading ? 'Loding...' : 'Add Payment Info'}
                  </Button>
                )}
                <Button
                  capitalize
                  size="xxl"
                  onClick={() => onApplyForCreditAccont()}
                  text="md"
                  disabled={loading || calculatePriceIsLoading}
                >
                  {loading ? 'Loding...' : 'Apply For Credit Account'}
                </Button>
              </div>
            </>
          );
        },
        439
      ),
    ]);
  }, [
    loading,
    register,
    clearErrors,
    onApplyForCreditAccont,
    watchQTY,
    errors,
    totals,
    calculatePriceIsLoading,
    onSaving,
    applyPromo,
    getValues,
    hasCard,
    onCloseModal,
    onOpeModal,
    promoValue,
    submittedCard,
    updateModalLastState,
  ]);

  const [addCredit] = useMutation(ADD_CREDIT);

  const addCreditRequest = (data) => {
    if (onSaving) {
      return;
    }
    onOpeModal(() => (
      <>
        <Heading>Payment</Heading>
        <Text className="text-disabled spacer-t thin mb-4">
          Are you sure to process this payment?
        </Text>
        <CartTotal
          {...{
            calculatePriceIsLoading,
            totals,
            watchQTY,
            getValues,
            promoValue,
          }}
        />
        <FormCTA column spaceT>
          <Button
            onClick={() => {
              handleOnConfirmAddCredit(data);
              onCloseModal();
            }}
          >
            {onSaving ? 'Processing...' : 'Continue'}
          </Button>
          <Button
            variant="outline"
            transparent
            onClick={() => {
              setOnSaving(false);
              onCloseModal();
            }}
          >
            Cancel
          </Button>
        </FormCTA>
      </>
    ));
  };

  const handleOnConfirmAddCredit = async (data) => {
    if (onSaving) {
      return;
    }
    setOnSaving(true);
    return addCredit({
      variables: {
        input: {
          ...data,
          qty: parseInt(data.qty),
          company_id: state?.activeCompany?.team_id,
        },
      },
    })
      .then(({ data }) => {
        setBalance(data.addCredit.balance);
        refetchCredit();
        refetchTeamBalance();
        setValue('qty', 1);
        checkPrice();
        toast.dismiss();
        toast.success('Credits added!', { autoClose: 4000 });
        setOnSaving(false);
        onCloseModal();
      })
      .catch((e) => {
        toast.dismiss();
        // showError(e.graphQLErrors[0].extensions.reason);
        toast.error(
          e?.graphQLErrors[0]?.extensions?.reason ?? 'Something went wrong',
          { toastId: 'buyCredits' }
        );
        setOnSaving(false);
        onCloseModal();
      });
  };

  useEffect(() => {
    if (watchQTY && parseInt(watchQTY) > -1) {
      checkPrice();
    }
  }, [watchQTY, checkPrice]);

  return (
    <>
      <SectionForm
        contentFull
        noPanel
        leftWide
        actionComponent={
          <>
            <Heading>Billing Address</Heading>
            <ChipJobPosts
              className="spacer-t"
              current={lastBalance}
              onWaiting={onSaving}
            />
          </>
        }
      >
        <form onSubmit={handleSubmit(addCreditRequest)}>
          <ProfileDetails contents={contents} leftSideFull></ProfileDetails>
        </form>
      </SectionForm>
    </>
  );
}
