import React, { useContext, useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { GridCaseStudies, LoadingSection } from '../../components';
import useGroups from '../../hooks/useGroups';
import useUser from '../../hooks/useUser';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_CASE_STUDIES } from '../../../graphql';
import { AppContext } from '../../../Store';

export default function CaseStudiesPage() {
  const { groupname } = useGroups();
  const [state] = useContext(AppContext);
  const [page, setPage] = useState(1);

  const [
    getCaseStudies,
    { data: { caseStudies: { data = [] } = {} } = {}, loading, refetch },
  ] = useLazyQuery(GET_CASE_STUDIES, {
    variables: {
      first: 8,
      page: page,
      where: {
        AND: [
          {
            column: 'AGENCY_ID',
            operator: 'EQ',
            value: Number(state?.activeCompany?.team_id),
          },
        ],
      },
    },
    onError: () => refetch(),
  });

  useEffect(() => {
    getCaseStudies();
  }, [state.activeCompany, getCaseStudies]);

  const caseStudiesData = useMemo(() => {
    return (
      data?.map(({ id, thumbnail, description, title }) => {
        return {
          img: thumbnail ?? null,
          headline: title ?? 'NA',
          info: description ?? '-',
          externalLink: true,
          id: id,
        };
      }) ?? []
    );
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useUser(); // Check user
  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!loading && (
        <GridCaseStudies
          type="casestudy"
          heading="Case Studies"
          headerButtonUrl={`/${groupname}/case-study/add`}
          headerButtonCopy="Create Case Study"
          data={caseStudiesData}
          showMobileButton
          viewMore={() => {
            setPage(page + 1);
            getCaseStudies();
          }}
          showViewMore={caseStudiesData.length >= 8}
        />
      )}
    </DefaultLayout>
  );
}
