import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SectionList } from '../../components';
import useGroups from '../../hooks/useGroups';
import DefaultLayout from '../../layouts/defaultLayout';
import { GET_OPPORTUNITIES_FOR_USER } from '../../../graphql';
import { Link } from '../../snippets';
import dayjs from 'dayjs';
import { IMG_AGENCY_PLACEHOLDER } from '../../../utils/helpers';

export default function OpportunitiesForYouPage() {
  const { groupname } = useGroups();

  const { data: { me: { preferences = [] } = {} } = {} } = useQuery(
    GET_OPPORTUNITIES_FOR_USER,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const opportunities = useMemo(() => {
    return (
      preferences?.map(
        ({ id, agency: { avatar, company }, title, created_at }) => {
          return [
            <Link
              to={`/${groupname}/opportunities/${id}`}
              className="company-logo"
            >
              <img
                src={avatar === '' ? IMG_AGENCY_PLACEHOLDER : avatar}
                alt={company}
              />
            </Link>,
            <div>
              <Link to={`/${groupname}/opportunities/${id}`}>
                <h6>{title}</h6>
              </Link>
              <p>{company}</p>
            </div>,
            <p>{dayjs(created_at, 'YY-MM-DD').format('DD/MM/YYYY')}</p>,
            <Link
              className="text-underline text--xs"
              style={{ fontWeight: 400 }}
              to={`/${groupname}/opportunities/${id}`}
            >
              View Opportunity
            </Link>,
          ];
        }
      ) ?? []
    );
  }, [preferences, groupname]);

  const heading = useMemo(() => {
    if (groupname === 'agency') {
      return 'Project Opportunities For You';
    } else {
      return 'Opportunities For You';
    }
  }, [groupname]);

  return (
    <DefaultLayout>
      <SectionList
        headline={heading}
        items={opportunities}
        headers={['Company', '', 'Date Posted', '']}
        proportions={[
          'tb-span-2',
          'tb-span-8',
          'tb-span-4 text-center',
          'tb-span-2 tb-remove-inline-padding',
        ]}
      ></SectionList>
    </DefaultLayout>
  );
}
