import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppContext } from '../../../Store';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  SYNC_TEAM_USER_ASSOCIATION,
  GET_TEAM_ASSOCIATIONS,
  UPDATE_TEAM_USER_SETTING,
} from '../../../graphql';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormInput,
  Heading,
} from '../../snippets';
import TablerSimple from '../TablerSimple/TablerSimple';
import LoadingSection from '../LoadingSection/LoadingSection';

export default function UserAssociation({
  members,
  data,
  closeModal,
  onUpdate,
}) {
  const { register, getValues } = useForm();
  const [state] = useContext(AppContext);
  const [selected, setSelected] = useState([]);
  const [canPostJob, setCanPostJob] = useState(data?.canPostJob);

  const [
    getTeamUserAssociation,
    {
      data: teamUserAssociationData,
      loading: loadingTeamUserAssociation,
      refetch,
    },
  ] = useLazyQuery(GET_TEAM_ASSOCIATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      team_id: Number(state?.activeCompany?.team_id),
    },
  });

  const [updateTeamUser, { loading: loadingUpdateTeamUser }] = useMutation(
    UPDATE_TEAM_USER_SETTING
  );
  const [syncTeamUserAssociation, { loading: loadingSyncTeamUserAssociation }] =
    useMutation(SYNC_TEAM_USER_ASSOCIATION);

  const teamAssociations = useMemo(() => {
    return teamUserAssociationData?.viewUserTeamAssociation;
  }, [teamUserAssociationData]);

  const memberList = useMemo(() => {
    if (members !== undefined) {
      const memberFilter = members.filter((a) => a.user_id !== data?.userId);
      getTeamUserAssociation();
      return memberFilter;
    }
  }, [members, data, getTeamUserAssociation]);

  useEffect(() => {
    let res = [];
    if (teamAssociations?.length > 0 && memberList?.length > 0) {
      for (const m of memberList) {
        const find = teamAssociations.find(
          ({ user_id, associate_id }) =>
            (user_id === data.userId && m.user_id === associate_id) || false
        );
        res.push(find ? true : false);
      }
    }
    setSelected(res);
  }, [teamAssociations, memberList, data]);

  const changeCheckbox = (e, indexAt) => {
    if (e.currentTarget.checked) {
      setSelected((prev) => {
        let temp = prev.slice(0);
        temp[indexAt] = true;
        return temp;
      });
    } else {
      setSelected((prev) => {
        let temp = prev.slice(0);
        temp[indexAt] = false;
        return temp;
      });
    }
  };

  const updateTeamUserRole = useCallback(async () => {
    toast.dismiss();
    const { can_post_jobs } = getValues();
    const associateIds = memberList
      ?.filter((_, i) => selected[i])
      ?.map(({ user_id }) => user_id);
    setCanPostJob(can_post_jobs === 'true');
    onUpdate();

    await updateTeamUser({
      variables: {
        input: {
          user_id: data.userId,
          team_id: data.teamId,
          can_post_jobs: can_post_jobs === 'true',
        },
      },
    }).then(async () => {
      await syncTeamUserAssociation({
        variables: {
          input: {
            user_id: data.userId,
            team_id: data.teamId,
            associate_ids: associateIds,
          },
        },
      })
        .then(() => {
          refetch();
          toast.success('User permission updated succesfully!', {
            autoClose: 4000,
          });
          closeModal();
        })
        .catch(() =>
          toast.error('Could not update user permission!', {
            toastId: 'appErr',
          })
        );
    });
  }, [
    closeModal,
    data,
    getValues,
    memberList,
    onUpdate,
    refetch,
    selected,
    syncTeamUserAssociation,
    updateTeamUser,
  ]);

  return (
    <>
      <Heading as="h3">Account Access for {data?.name}</Heading>

      <form
        className="form"
        onSubmit={async (e) => {
          e.preventDefault();
          updateTeamUserRole();
        }}
        autoComplete="off"
      >
        <FormBlock isInline>
          <FormBlockItem label={'Can Post Jobs?'}>
            <FormInput
              className="field-input--variant-rounded"
              type="select"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              register={register}
              name="can_post_jobs"
              max-width="132"
              defaultValue={canPostJob}
              selectSetting={{
                onSelect: (e) => {
                  setCanPostJob(e);
                },
              }}
            />
          </FormBlockItem>
        </FormBlock>

        <FormBlock>
          <FormBlockItem>
            {loadingTeamUserAssociation ? (
              <LoadingSection />
            ) : (
              <TablerSimple
                className="spacer-t"
                headers={['Manage Posts Of', 'Name', 'Email']}
                tables={memberList.map(
                  ({ first_name, last_name, email, team_id, user_id }, i) => {
                    return [
                      <FormInput
                        type="checkbox"
                        checkboxChildren={null}
                        checked={selected[i]}
                        name={`associated_${user_id}`}
                        value={user_id}
                        onChange={(e) => {
                          changeCheckbox(e, i, { user_id, team_id });
                        }}
                      />,
                      <label htmlFor={`associated_${user_id}`}>
                        {first_name + ' ' + last_name}
                      </label>,
                      <label htmlFor={`associated_${user_id}`}>{email}</label>,
                    ];
                  }
                )}
                renderItem={(item) => {
                  return <>{item}</>;
                }}
              />
            )}
          </FormBlockItem>
        </FormBlock>

        <FormBlock isInline>
          <FormBlockItem>
            <Button
              disabled={loadingUpdateTeamUser || loadingSyncTeamUserAssociation}
              type="submit"
              variant="error"
            >
              Save
            </Button>
          </FormBlockItem>
          <FormBlockItem>
            <Button
              disabled={loadingUpdateTeamUser || loadingSyncTeamUserAssociation}
              type="button"
              variant="outline"
              transparent
              onClick={closeModal}
            >
              Cancel
            </Button>
          </FormBlockItem>
        </FormBlock>
      </form>
    </>
  );
}
