import React from 'react';

export default function SearchIcon({...rest}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className='app-icon app-icon-search'
      {...rest}
    >
      <path
        d="M13 6.5C13 7.9375 12.5312 9.28125 11.75 10.3438L15.1875 13.8125L15.9062 14.5L14.5 15.9375L13.7812 15.2188L10.3125 11.75C9.25 12.5625 7.90625 13 6.5 13C2.90625 13 0 10.0938 0 6.5C0 2.9375 2.90625 0 6.5 0C10.0625 0 13 2.9375 13 6.5ZM6.5 11C8.09375 11 9.5625 10.1562 10.375 8.75C11.1875 7.375 11.1875 5.65625 10.375 4.25C9.5625 2.875 8.09375 2 6.5 2C4.875 2 3.40625 2.875 2.59375 4.25C1.78125 5.65625 1.78125 7.375 2.59375 8.75C3.40625 10.1562 4.875 11 6.5 11Z"
        fill="#1E1E1E"
      />
    </svg>
  );
}
