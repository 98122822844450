import React from 'react';
import TablerSection from '../TablerSection/TablerSection';
import LoadingSection from '../LoadingSection/LoadingSection';
import { Button, Heading } from '../../snippets';
import useCredits from '../../hooks/useCredits';
import moment from 'moment';

export default function PurchaseHistory({ headline, headlineSpace }) {
  const { balance } = useCredits();

  return (
    <>
      <Heading className={headlineSpace ?? 'spacer-t thick'}>
        {headline ?? 'Your Balance'}
      </Heading>
      {balance.loading ? (
        <LoadingSection />
      ) : (
        <>
          {balance?.data?.creditHistory?.length > 0 ? (
            <TablerSection
              className="spacer-t thin"
              tables={balance?.data?.creditHistory.map(
                ({ type, qty, total, invoice_id, created_at }) => {
                  return [
                    type === 'IN'
                      ? `${qty} ${total === 0 ? 'Free' : ''} Post${
                          qty > 1 ? 's' : ''
                        }`
                      : '-', //ob Posts In'
                    type === 'OUT' ? `${qty} Post${qty > 1 ? 's' : ''}` : '-',
                    total > 0 && <span>&pound; {total.toFixed(2)}</span>,
                    moment(created_at).format('DD/MM/YYYY'),
                    total > 0 && (
                      <Button
                        type="button"
                        onClick={() => {
                          balance.handleDownloadInvoice(invoice_id);
                        }}
                        transparent
                        variant="variant-link"
                      >
                        Download PDF
                      </Button>
                    ),
                  ];
                }
              )}
              renderItem={(item) => {
                return <>{item}</>;
              }}
              headers={[
                'Job Posts In',
                'Job Posts Out',
                'Amount',
                'Date',
                'Invoice',
              ]}
              tableProportion={[
                'tb-span-3',
                'tb-span-3',
                'tb-span-3',
                'tb-span-4',
                'tb-span-3',
              ]}
            />
          ): <Heading as='h3' className='spacer-t'>No balance available</Heading>}
        </>
      )}
    </>
  );
}
