import { LINK_GROUP } from './links';

const skin = {
  agency: 'theme-agency',
  consultant: 'theme-consultant',
  company: 'theme-company',
};

export function setTheme(type) {
  switch (type) {
    case LINK_GROUP.AGENCY:
      return skin.agency;
    case LINK_GROUP.FREELANCER:
    case LINK_GROUP.CANDIDATE:
    case LINK_GROUP.CONSULTANT:
      return skin.consultant;
    case LINK_GROUP.COMPANY:
    case LINK_GROUP.EMPLOYER:
      return skin.company;

    default:
      return skin.agency;
  }
}

export default {
  skin,
  setTheme,
};
