import React from 'react';
import { AgencyWithSubmenuLayout } from './layout';

const DummyPage = () => {
  return (
    <AgencyWithSubmenuLayout>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum
        similique totam laborum, dolore quidem nulla sequi. Earum corporis
        accusantium corrupti. Modi, officiis odio! Ut, fuga molestiae? Laborum
        vero consequatur nemo.
      </p>
    </AgencyWithSubmenuLayout>
  );
};

export default DummyPage;
