import React, { useContext, useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Text,
} from '../../snippets';
import SectionProfile from '../SectionProfile/SectionProfile';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../Store';
import { toast } from 'react-toastify';
import { getUser, saveUserLocalstorage } from '../../../utils/helpers';
import { userVar } from '../../../graphql/config';
import useProfile from '../../hooks/useProfile';

export default function ApplyCreditCardForm({
  onDone = () => null,
  onCancel = () => null,
}) {
  const {
    register,
    errors,
    clearErrors,
    handleSubmit,
    reset: clearForm,
  } = useForm();
  const [loading] = useState(false);
  const [state] = useContext(AppContext);
  const { updateProfile, loadingProfile } = useProfile();

  const submitInvoiceForm = async (data) => {
    toast.dismiss();

    return updateProfile({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your data has been submitted!', {
          autoClose: 2000,
        });
        clearForm();
        onDone();
      })
      .catch((e) => {
        console.error(e);
        toast.error('Something went wrong!', { toastId: 'resErr' });
      });
  };

  return (
    <>
      <Heading>Apply For Credit Account</Heading>
      <SectionProfile
        hideImage
        noPanel
        actionComponent={
          <Text>
            If you wish to purchase posts on account, please complete the form
            below. Once approved by Liberty Hive, you will be authorised to
            purchase posts and invoiced accordingly. All invoices are due 30
            days after the date of the invoice, by requesting an account you
            accept these terms in full and without exception.
          </Text>
        }
      >
        <form onSubmit={handleSubmit(submitInvoiceForm)}>
          <FormBlock>
            <FormBlockItem label={'Employer Name'}>
              <FormInput
                type="text"
                register={register}
                name="company"
                isRequired
                error={errors.company ? `Add Employer Name` : null}
                defaultValue={state?.activeCompany?.user?.company}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Address Line 1'}>
              <FormInput
                type="text"
                register={register}
                name="address_1"
                isRequired
                error={errors.address_1 ? `Please enter an address` : null}
                defaultValue={state?.activeCompany?.user?.address_1}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Address Line 2'}>
              <FormInput
                type="text"
                register={register}
                name="address_2"
                defaultValue={state?.activeCompany?.user?.address_2}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'City'}>
              <FormInput
                type="text"
                register={register}
                name="city"
                isRequired
                error={errors.city ? `Please enter city` : null}
                defaultValue={state?.activeCompany?.user?.city}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'County'}>
              <FormInput
                type="text"
                register={register}
                name="state"
                defaultValue={state?.activeCompany?.user?.state}
                isRequired
                error={errors.county ? `Please enter county` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Country'}>
              <FormInput
                type="text"
                register={register}
                name="country"
                defaultValue={state?.activeCompany?.user?.country}
                isRequired
                error={errors.country ? `Please enter country` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Postcode / Zip Code'}>
              <FormInput
                type="text"
                register={register}
                name="zip"
                defaultValue={state?.activeCompany?.user?.zip}
                isRequired
                error={errors.zip ? `Please enter postcode / zip code` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Email Address'}>
              <FormInput
                type="email"
                register={register}
                name="invoice_email"
                defaultValue={state?.activeCompany?.user?.email}
                clearErrors={clearErrors}
                isRequired
                error={errors.invoice_email ? `Please add email address` : null}
              ></FormInput>
            </FormBlockItem>
          </FormBlock>

          <FormCTA spaceT extraSpace sidebyside>
            <Button
              type="submit"
              size="xxl"
              disabled={loading || loadingProfile}
              text="short"
            >
              {loading || loadingProfile
                ? 'Loading...'
                : 'Send Approval Request'}
            </Button>
            <Button
              variant="outline"
              onClick={() => onCancel()}
              disabled={loadingProfile || loading}
              text="short"
            >
              Cancel
            </Button>
          </FormCTA>
        </form>
      </SectionProfile>
    </>
  );
}
