import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/Company/Header';
import { useMutation } from '@apollo/react-hooks';
import Footer from '../../components/Footer';
import { AppContext } from '../../Store';
import {
  GET_TEAM_MEMBER_OPPORTUNITY,
  GET_TEAM_OPPORTUNITY,
  GET_ARTICLES,
  GET_AUTH_USER,
} from '../../graphql';
import jquery from '../../styles/js/app';
import { currentOppVar } from '../../graphql/config';
import { logUserOut, getLoginUrl } from '../../utils/helpers';
import ReasonModal from '../../components/Company/ReasonModal';
import AccountInactive from '../../components/AccountInactive';
import Loader from '../../components/Loader';
import { LINK_GROUP } from '../../utils/links';

const Dashboard = () => {
  useEffect(() => {
    jquery();
  }, []);
  const [state] = useContext(AppContext);
  const [activeType, setActiveType] = useState(true);
  const [activeApplicants, setActiveApplicants] = useState([]);
  const [activeOpportunities, setActiveOpportunities] = useState([]);
  const [inactiveOpportunities, setInactiveOpportunities] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [initialized, setInitialized] = useState(undefined);
  const [status, setStatus] = React.useState(undefined);
  /** Retrieve Team Member Opportunities */
  const [getTeamUserOpportunites] = useMutation(GET_TEAM_MEMBER_OPPORTUNITY, {
    onCompleted: ({ viewTeamMemberOpportunity }) => {
      const associatedOpportunities = viewTeamMemberOpportunity.flat();
      setActiveOpportunities(
        associatedOpportunities.filter((o) => !o.deleted_at)
      );
      setInactiveOpportunities(
        associatedOpportunities.filter((o) => o.deleted_at)
      );
      setOpportunities(associatedOpportunities.filter((o) => !o.deleted_at));
    },
    onError: () => {},
  });

  const [getTeamOpportunites] = useMutation(GET_TEAM_OPPORTUNITY, {
    onCompleted: ({ viewTeamOpportunity }) => {
      const associatedOpportunities = viewTeamOpportunity;
      setActiveOpportunities(
        associatedOpportunities.filter((o) => !o.deleted_at)
      );
      setInactiveOpportunities(
        associatedOpportunities.filter((o) => o.deleted_at)
      );
      setOpportunities(associatedOpportunities.filter((o) => !o.deleted_at));
    },
    onError: () => {},
  });

  const { loading, refetch: refetchUser } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('COMPANY');
      }
      setStatus(me?.status);
      setInitialized(true);
    },
    onError: () => refetchUser(),
  });

  useEffect(() => {
    if (initialized && state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        getTeamUserOpportunites({
          variables: {
            userId: Number(state?.activeCompany?.user_id),
            teamId: Number(state?.activeCompany?.team_id),
          },
        });
      } else if (state?.activeCompany?.role === 'admin') {
        getTeamOpportunites({
          variables: {
            teamId: Number(state?.activeCompany?.team_id),
          },
        });
      }
    }
  }, [initialized, state, getTeamUserOpportunites, getTeamOpportunites]);

  const {
    data: { articles: { data: articles = [] } = {} } = {},
    refetch: refetchArticle,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
      type: 'ARTICLE',
      first: 3,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchArticle(),
  });

  const {
    data: { articles: { data: ctas = [] } = {} } = {},
    refetch: refetchCTA,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
      type: ['MENTORING', 'TRAINING'],
      first: 2,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchCTA(),
  });

  useEffect(() => {
    if (activeOpportunities !== undefined) {
      let filteredApplicants = [];
      activeOpportunities.forEach(
        (a) => (filteredApplicants = [...filteredApplicants, ...a?.applicants])
      );
      setActiveApplicants(filteredApplicants.flat());
    }
  }, [activeOpportunities]);

  const getOtherOpportunities = (e) => {
    e.preventDefault();
    setActiveType(!activeType);

    if (activeType) {
      setOpportunities(inactiveOpportunities);
    } else {
      setOpportunities(activeOpportunities);
    }
  };

  /** Listen To Team Change */
  useEffect(() => {
    if (state?.activeCompany !== undefined) {
      setActiveApplicants();
      setActiveOpportunities([]);
      setInactiveOpportunities([]);
      setOpportunities([]);
      refetchUser();
      refetchArticle();
      refetchCTA();
    }
  }, [state, refetchUser, refetchArticle, refetchCTA]);

  return (
    <div className="lh-wrapper">
      <Header isLoggedIn />
      {loading && (
        <div className="container">
          <span>Loading...</span>
        </div>
      )}
      {status === 'INACTIVE' && (
        <div className="container">
          <AccountInactive
            headline="Your account is inactive."
            linkColor="#297b9f"
          />
        </div>
      )}
      {status === 'ACTIVE' && (
        <div className="container">
          <ReasonModal
            refetch={() => {
              window.location.reload();
            }}
          />
          <div className="row">
            <div className="col-12">
              <nav className="dashboard-nav">
                <div className="row">
                  <div className="dashboard-nav__menu col-8">
                    <ul className="dashboard-nav__ul">
                      <li className="dashboard-nav__ul__li">
                        <a
                          href="#application-responses"
                          className="link-company"
                        >
                          Application Responses
                        </a>
                        {activeApplicants && activeApplicants.length > 0 && (
                          <span className="notification">
                            {
                              activeApplicants
                                .filter((a) => a.opportunity)
                                .filter((a) => a.user).length
                            }
                          </span>
                        )}
                      </li>
                      {activeType ? (
                        <li className="dashboard-nav__ul__li">
                          <a
                            href="#active-opportunities"
                            className="link-company"
                          >
                            Active Job Opportunities
                          </a>
                          {activeOpportunities.length > 0 && (
                            <span className="notification">
                              {activeOpportunities.length}
                            </span>
                          )}
                        </li>
                      ) : (
                        <li className="dashboard-nav__ul__li">
                          <a
                            href="#closed-opportunities"
                            className="link-company"
                          >
                            Closed Job Opportunities
                          </a>
                          {inactiveOpportunities.length > 0 && (
                            <span className="notification">
                              {inactiveOpportunities.length}
                            </span>
                          )}
                        </li>
                      )}
                      <li className="dashboard-nav__ul__li">
                        <a href="#resources" className="link-company">
                          Resources
                        </a>
                      </li>
                    </ul>
                  </div>
                  {state?.activeCompany?.can_post_jobs && (
                    <div className="dashboard-nav__button col-4">
                      <Link to={`/${LINK_GROUP.COMPANY}/add-opportunity`}>
                        <div className="button__highlight-blue">
                          Post New Job Opportunity
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
          <div className="row application-responses">
            <div className="col-12">
              <h1 id="application-responses">Application Responses</h1>
              <span className="h1-subheading">
                (
                {activeApplicants && activeApplicants.length
                  ? activeApplicants
                      .filter((a) => a.opportunity)
                      .filter((a) => a.user).length
                  : 0}{' '}
                new)
              </span>
            </div>
          </div>
          <div className="row">
            {activeApplicants ? (
              <div className="grid grid__applications col-12">
                <span className="grid-heading name">Name</span>
                <span className="grid-heading date tablet">Date</span>
                <span className="grid-heading job-number desktop">Job No.</span>
                <span className="grid-heading position desktop">Position</span>
                <span className="grid-heading email desktop">Email</span>
                <span className="grid-heading phone desktop">Phone</span>
                <span className="grid-heading profile-link">&nbsp;</span>
                {activeApplicants
                  .filter((a) => a.opportunity)
                  .filter((a) => a?.opportunity?.deleted_at === null)
                  .filter((a) => a.user)
                  .map(({ user, created_at, opportunity, id }) => (
                    <>
                      <span className="grid-content name">{user.name}</span>
                      <span className="grid-content date tablet">
                        {moment(created_at).format('DD/MM/YYYY')}
                      </span>
                      <span className="grid-content job-number desktop">
                        {opportunity.job_number}
                      </span>
                      <span className="grid-content position desktop">
                        {opportunity.title}
                      </span>
                      <span className="grid-content email desktop">
                        {user.email}
                      </span>
                      <span className="grid-content phone desktop">
                        {user.phone}
                      </span>
                      <span className="grid-content profile-link last-span">
                        <ul className="grid__ul">
                          <li className="grid__ul__li">
                            <Link
                              to={`/${LINK_GROUP.COMPANY}/freelancer-profile/${user.id}-${id}`}
                              className="link-company"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                      </span>
                    </>
                  ))}
              </div>
            ) : (
              <div
                className="col-12"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Loader />
              </div>
            )}
          </div>
          <div className="row active-opportunities">
            <div className="col-12">
              {activeType ? (
                <h1 id="active-opportunities">Active Job Opportunities</h1>
              ) : (
                <h1 id="closed-opportunities">Closed Job Opportunities</h1>
              )}

              <span className="h1-subheading">
                {activeType
                  ? `(${activeOpportunities.length})`
                  : `(${inactiveOpportunities.length})`}
              </span>
            </div>
          </div>
          {loading && (
            <div
              className="col-12"
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Loader />
            </div>
          )}
          {!loading && (
            <>
              <div className="row">
                <div className="grid grid__opportunities__active grid__last col-12">
                  <span className="grid-heading job-title">Job Title</span>
                  <span className="grid-heading date-posted tablet">
                    Date Posted
                  </span>
                  <span className="grid-heading job-number desktop">
                    Job No.
                  </span>
                  <span className="grid-heading applications tablet">
                    Applications
                  </span>
                  <span className="grid-heading featured tablet"></span>
                  <span className="grid-heading edit-close">&nbsp;</span>
                  {opportunities.map(
                    ({
                      title,
                      creator,
                      job_number,
                      agency: { company },
                      created_by,
                      created_at,
                      view,
                      applicants,
                      id,
                      featured,
                    }) => (
                      <>
                        <span className="grid-content job-title">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div>{title}</div>
                            <div
                              style={{
                                marginLeft: 11,
                                fontSize: '14px',
                                color: '#297b9f',
                              }}
                            >
                              {'Added by ' + (creator?.name || company)}
                            </div>
                          </div>
                        </span>
                        <span className="grid-content date-posted tablet">
                          {moment(created_at).format('DD/MM/YYYY')}
                        </span>
                        <span className="grid-content job-number desktop">
                          {job_number}
                        </span>
                        <span className="grid-content applications tablet">
                          {applicants.filter((a) => a.user).length}
                        </span>
                        <span className="grid-content featured tablet">
                          {featured && (
                            <span className="label label__success">
                              Featured
                            </span>
                          )}
                        </span>
                        <span className="grid-content edit-close last-span">
                          <ul className="grid__ul">
                            {activeType && (
                              <>
                                <li className="grid__ul__li">
                                  <Link
                                    to={`/${LINK_GROUP.COMPANY}/view-applicants/${id}`}
                                    className="link-company"
                                  >
                                    View Applicants
                                  </Link>
                                </li>
                                <li className="grid__ul__li">
                                  <Link
                                    to={`/${LINK_GROUP.COMPANY}/edit-opportunity/${id}`}
                                    className="link-company"
                                  >
                                    {state?.activeCompany?.can_post_jobs
                                      ? 'Edit'
                                      : 'View'}
                                  </Link>
                                </li>
                                <li className="grid__ul__li">
                                  <a
                                    href="/#"
                                    className="link-company"
                                    data-toggle="modal"
                                    data-target={
                                      activeType && '#close_opportunity_modal'
                                    }
                                    onClick={(e) =>
                                      currentOppVar({ id, title })
                                    }
                                  >
                                    Close
                                  </a>
                                </li>
                              </>
                            )}
                          </ul>
                        </span>
                      </>
                    )
                  )}
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="inactive-opportunities col-12">
              <a
                href="/#"
                target="_self"
                className="link-company"
                onClick={(e) => getOtherOpportunities(e)}
              >
                View {activeType ? 'Closed' : 'Active'} Job Opportunities
              </a>
            </div>
          </div>
          <div className="row resources">
            <div className="col-12">
              <h1 id="resources">Liberty Hive Community</h1>
            </div>
          </div>
          <div className="row">
            {articles &&
              articles.map(({ title, slug, image }, index) => (
                <div
                  key={index}
                  className={`col-12 col-lg-4 ${
                    index !== 0 ? 'col-sm-6 tablet' : ''
                  } resource`}
                >
                  <Link
                    to={`/${LINK_GROUP.COMPANY}/articles/${slug}`}
                    className="link-company"
                  >
                    <div
                      className="resource__img"
                      style={{
                        backgroundImage: `url(
                        ${image || '/img/cta-placeholder@2x.jpg'}
                      )`,
                      }}
                    />
                    <div className="resource__title">{title}</div>
                  </Link>
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col text-right">
              <Link
                to={`/${LINK_GROUP.COMPANY}/resources`}
                className="link-company font-weight-bold"
              >
                View more
              </Link>
            </div>
          </div>
          <div className="row">
            {ctas &&
              ctas.map(({ title, slug, image, type }, index) => (
                <div key={index} className="col-12 col-lg-6">
                  <Link
                    to={`/${LINK_GROUP.COMPANY}/resources/${slug}`}
                    className="link-company"
                  >
                    <div className={`${type}__card`}>
                      <div className={`${type}__card__title`}>{title}</div>
                      <img
                        src={image}
                        className={`img img__${type}__thumbnail`}
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default Dashboard;
