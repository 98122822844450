import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from '../../Store';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { GET_CURRENT_CREDITS } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';
import { CompanyBrowserStorage, generateClassName } from '../../utils/helpers';
import useGroups from './useGroups';

export default function useTeams() {
  const history = useHistory();
  const { groupname } = useGroups();
  const [state, setState] = useContext(AppContext);
  const { activeCompany = {}, userTeams = [] } = state || {};
  const { user = {} } = activeCompany;
  const [balance, setBalance] = useState(0);

  const { loading, refetch } = useQuery(GET_CURRENT_CREDITS, {
    variables: {
      team_id: activeCompany?.team_id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBalance(data.currentCredit.balance);
    },
    onError: () => refetch(),
  });

  const isAdmin =
    useMemo(() => {
      return activeCompany.role === 'admin';
    }, [activeCompany.role]) ?? false;

  const otherTeams = useMemo(() => {
    return userTeams?.filter((t) => t.team_id !== activeCompany.team_id);
  }, [userTeams, activeCompany]);

  const handleOnSwitchTeam = useCallback(
    (item) => {
      const indexAt = userTeams.findIndex((a) => a.team_id === item.team_id);
      if (indexAt > -1) {
        setState({ ...state, activeCompany: item });
        CompanyBrowserStorage.set(
          activeCompany.user_id,
          item.team_id,
          'setClick'
        );
        history.push(`/${groupname}/dashboard`);
        return;
      }
      toast.error('Could not find the team');
    },
    [userTeams, state, activeCompany, groupname, history, setState]
  );

  const ChipJobPosts = useCallback(
    ({ className, current = null, onWaiting = false }) => {
      const showBalance = current ? current : balance;
      return (
        <div className={generateClassName(['big-chip', className])}>
          <div className="meta--chip">
            {loading || onWaiting ? (
              'Loading Job Posts balance...'
            ) : (
              <span>
                {showBalance} Job Post{showBalance > 1 ? 's ' : ' '}Available
              </span>
            )}
          </div>
        </div>
      );
    },
    [balance, loading]
  );

  return {
    handleOnSwitchTeam,
    activeCompany,
    userTeams,
    user,
    otherTeams,
    isAdmin,
    ChipJobPosts,
    refetchTeamBalance: refetch,
  };
}
