import React from 'react';

export default function InstagramIcon() {
  return (
    <svg
      width="1em"
      height="1.05em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.20801 5.40723C11.792 5.40723 13.9248 7.54004 13.9248 10.124C13.9248 12.749 11.792 14.8408 9.20801 14.8408C6.58301 14.8408 4.49121 12.749 4.49121 10.124C4.49121 7.54004 6.58301 5.40723 9.20801 5.40723ZM9.20801 13.2002C10.8896 13.2002 12.2432 11.8467 12.2432 10.124C12.2432 8.44238 10.8896 7.08887 9.20801 7.08887C7.48535 7.08887 6.13184 8.44238 6.13184 10.124C6.13184 11.8467 7.52637 13.2002 9.20801 13.2002ZM15.1963 5.24316C15.1963 4.62793 14.7041 4.13574 14.0889 4.13574C13.4736 4.13574 12.9814 4.62793 12.9814 5.24316C12.9814 5.8584 13.4736 6.35059 14.0889 6.35059C14.7041 6.35059 15.1963 5.8584 15.1963 5.24316ZM18.3135 6.35059C18.3955 7.86816 18.3955 12.4209 18.3135 13.9385C18.2314 15.415 17.9033 16.6865 16.8369 17.7939C15.7705 18.8604 14.458 19.1885 12.9814 19.2705C11.4639 19.3525 6.91113 19.3525 5.39355 19.2705C3.91699 19.1885 2.64551 18.8604 1.53809 17.7939C0.47168 16.6865 0.143555 15.415 0.0615234 13.9385C-0.0205078 12.4209 -0.0205078 7.86816 0.0615234 6.35059C0.143555 4.87402 0.47168 3.56152 1.53809 2.49512C2.64551 1.42871 3.91699 1.10059 5.39355 1.01855C6.91113 0.936523 11.4639 0.936523 12.9814 1.01855C14.458 1.10059 15.7705 1.42871 16.8369 2.49512C17.9033 3.56152 18.2314 4.87402 18.3135 6.35059ZM16.3447 15.5381C16.8369 14.3486 16.7139 11.4775 16.7139 10.124C16.7139 8.81152 16.8369 5.94043 16.3447 4.70996C16.0166 3.93066 15.4014 3.27441 14.6221 2.9873C13.3916 2.49512 10.5205 2.61816 9.20801 2.61816C7.85449 2.61816 4.9834 2.49512 3.79395 2.9873C2.97363 3.31543 2.3584 3.93066 2.03027 4.70996C1.53809 5.94043 1.66113 8.81152 1.66113 10.124C1.66113 11.4775 1.53809 14.3486 2.03027 15.5381C2.3584 16.3584 2.97363 16.9736 3.79395 17.3018C4.9834 17.7939 7.85449 17.6709 9.20801 17.6709C10.5205 17.6709 13.3916 17.7939 14.6221 17.3018C15.4014 16.9736 16.0576 16.3584 16.3447 15.5381Z"
        fill="currentColor"
      />
    </svg>
  );
}
