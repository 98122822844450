export const LINK_GROUP = {
  AGENCY: 'agency',
  COMPANY: 'company',
  EMPLOYER: 'employer',
  CONSULTANT: 'consultant',
  FREELANCER: 'freelancer',
  CANDIDATE: 'candidate',
  FREELANCER_AND_AGENCY: 'freelancer_and_agency',
};

export const generateAssetImageURL = (filename) => {
  return '/assets/images/' + filename;
};
