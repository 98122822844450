import React from 'react';

export default function UploadCloud({ props }) {
  return (
    <svg width="47px" height="32px" viewBox="0 0 20 14" {...props}>
      <path
        d="M16.9688 5.71875C18.7188 6.25 20 7.84375 20 9.75C20 12.125 18.0938 14 15.75 14H4.5C2 14 0 12 0 9.5C0 7.5625 1.25 5.90625 3 5.28125C2.96875 2.375 5.3125 0 8.25 0C9.96875 0 11.5 0.875 12.4688 2.1875C12.7812 2.09375 13.125 2 13.5 2C15.4062 2 17 3.59375 17 5.5C17 5.59375 16.9688 5.65625 16.9688 5.71875ZM15.75 12.5C17.25 12.5 18.5 11.2812 18.5 9.75C18.5 8.5625 17.6875 7.5 16.5312 7.15625L15.4062 6.8125L15.4688 5.625C15.5312 4.40625 14.4375 3.15625 12.9062 3.625L11.875 3.9375L11.25 3.0625C10.5312 2.09375 9.4375 1.5 8.25 1.5C6.1875 1.5 4.53125 3.15625 4.5 5.1875C4.5 5.3125 4.5 5.125 4.5 6.34375L3.5 6.71875C2.28125 7.125 1.5 8.25 1.5 9.5C1.5 11.1562 2.84375 12.5 4.5 12.5H15.75ZM10.5 4.46875L13.25 7.25C13.5625 7.53125 13.5625 8 13.25 8.28125C13.125 8.4375 12.9375 8.5 12.75 8.5C12.5312 8.5 12.3438 8.4375 12.2188 8.28125L10.75 6.8125V10.75C10.75 11.1875 10.4062 11.5 10 11.5C9.5625 11.5 9.25 11.1875 9.25 10.75V6.8125L7.75 8.28125C7.46875 8.59375 7 8.59375 6.71875 8.28125C6.40625 8 6.40625 7.53125 6.71875 7.21875L9.46875 4.46875C9.53125 4.40625 9.59375 4.375 9.65625 4.3125C9.84375 4.25 10.0625 4.25 10.25 4.3125C10.3438 4.375 10.4062 4.40625 10.5 4.46875Z"
        fill="currentColor"
      />
    </svg>
  );
}
