import React, { useContext, useEffect, useMemo, useState } from 'react';
import useGroups from '../../hooks/useGroups';
import Hamburger from 'hamburger-react';
import { Link } from '../../snippets';
import { useAppTheme } from '../../context/themeAppContext';
import { generateAssetImageURL, LINK_GROUP } from '../../../utils/links';
import { getUser } from '../../../utils/helpers';
import {
  useParams,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
import { AppContext } from '../../../Store';
import CompanySwitch from '../CompanySwitch/CompanySwitch';
import { ChevronLeftIcon } from '../../icons';

const accountNavPath = {
  [LINK_GROUP.AGENCY]: 'profile',
  [LINK_GROUP.FREELANCER]: 'personal-information',
  [LINK_GROUP.CANDIDATE]: 'personal-information',
  [LINK_GROUP.EMPLOYER]: 'account',
  [LINK_GROUP.COMPANY]: 'account',
};

const loggedUserNav = (groupname, options = {}) => {
  const { showOpportunity, showCaseStudyEdit, id, hideSwitchCompany } = options;
  const isCompany =
    groupname === LINK_GROUP.COMPANY || groupname === LINK_GROUP.EMPLOYER;
  return (
    <>
      {isCompany && showOpportunity && (
        <>
          <Link
            to={`/${groupname}/add-opportunity`}
            className="app-btn btn-nav"
          >
            New Job Opportunity
          </Link>
          <Link to={`/${groupname}/add-project`} className="app-btn btn-nav">
            New Agency Project
          </Link>
        </>
      )}
      {/* {isCompany && (
        <Link
          to={`/${groupname}/agency-marketplace`}
          className="app-btn btn-nav"
        >
          Agency Marketplace
        </Link>
      )} */}
      {showCaseStudyEdit && groupname === 'agency' && (
        <Link
          to={`/${groupname}/case-study/edit/${id}`}
          className="app-btn btn-nav"
        >
          Edit Case Study
        </Link>
      )}
      {groupname === LINK_GROUP.AGENCY && !showCaseStudyEdit && (
        <Link to={`/${groupname}/case-studies`} className="app-btn btn-nav">
          Case Studies
        </Link>
      )}
      <Link to={`/${groupname}/dashboard`} className="app-btn btn-nav">
        Dashboard
      </Link>
      <Link
        to={`/${groupname}/${accountNavPath[groupname] ?? 'profile'}`}
        className="app-btn btn-nav"
      >
        My Account
      </Link>
      {isCompany && !hideSwitchCompany && <CompanySwitch />}
    </>
  );
};
const guessUserNav = (groupname) => {
  return (
    <>
      <Link to={`/${groupname}/login`} className="app-btn btn-nav">
        Login
      </Link>
    </>
  );
};

export default function Header({
  showBackButton,
  backButtonUrl,
  showNavigation,
  showCaseStudyEdit,
  hideSwitchCompany = false,
  goBack,
}) {
  const { id } = useParams();
  const { groupname } = useGroups();
  const isLoggedIn = useMemo(() => {
    return getUser().access_token || false;
  }, []);
  const [state] = useContext(AppContext);
  const history = useHistory();

  const [isOpen, setOpen] = useState(false);
  const isCompany =
    groupname === LINK_GROUP.COMPANY || groupname === LINK_GROUP.EMPLOYER;

  const {
    action: { setOpenDrawer, setCloseDrawer },
  } = useAppTheme();

  useEffect(() => {
    if (isOpen) {
      setOpenDrawer();
      return;
    }
    setCloseDrawer();
  }, [isOpen, setCloseDrawer, setOpenDrawer]);

  return (
    <>
      <div className="components-header">
        <div className="logo">
          <Link to={`/${groupname}/${isLoggedIn ? 'dashboard' : 'login'}`}>
            <img src={generateAssetImageURL('lh-logo-v2.png')} alt="" />
          </Link>
        </div>

        <div
          className={`app-header-nav${
            showNavigation ? ' app-hide-on-mobile w-100' : ' d-none'
          }`}
        >
          {showBackButton && (
            <Link
              id="backBtn"
              to={backButtonUrl || `/${groupname}/dashboard`}
              className="app-btn btn-nav d-flex align-items-center"
            >
              <ChevronLeftIcon />
              <p style={{ fontSize: '18px', fontWeight: 700 }} className="ml-2">
                Back
              </p>
            </Link>
          )}

          {goBack && (
            <button
              id="backBtn"
              onClick={() => history.goBack()}
              className="app-btn btn-nav d-flex align-items-center"
            >
              <ChevronLeftIcon />
              <p style={{ fontSize: '18px', fontWeight: 700 }} className="ml-2">
                Back
              </p>
            </button>
          )}

          {isLoggedIn
            ? loggedUserNav(groupname, {
                showOpportunity: state?.activeCompany?.can_post_jobs,
                showCaseStudyEdit,
                id,
                avatar: state?.activeCompany?.user?.avatar,
                hideSwitchCompany,
              })
            : guessUserNav(groupname)}
        </div>

        {showNavigation && (
          <div className="menu-mobile-show">
            {isCompany && <CompanySwitch />}
            <div className="hamburger-wrapper app-hide-on-desktop">
              <Hamburger
                toggled={isOpen}
                toggle={setOpen}
                hideOutline={false}
                size={22}
              />
            </div>
          </div>
        )}
      </div>

      {showBackButton && (
        <div className="mobile-back-button">
          <Link
            to={backButtonUrl || `/${groupname}/dashboard`}
            className="app-btn btn-nav d-flex align-items-center"
          >
            <ChevronLeftIcon />
            <span className="ml-2">Back</span>
          </Link>
        </div>
      )}
    </>
  );
}
