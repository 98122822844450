import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, FormContent, Heading } from '../../snippets';
import { RegisterLayer1, RegisterLayer2, StepCounter } from '../../components';
import { useParams } from 'react-router-dom';
import useGroups from '../../hooks/useGroups';
import { LINK_GROUP } from '../../../utils/links';

export default function RegisterPage() {
  const { step } = useParams();
  const { groupname } = useGroups();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(parseInt(step ?? '1'));
  }, [step]);

  const title = useMemo(
    () =>
      groupname === LINK_GROUP.EMPLOYER
        ? 'Create An Employer Account'
        : 'Create A Candidate Account',
    [groupname]
  );

  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading>{title}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={currentStep}></StepCounter>
          {currentStep === 1 && <RegisterLayer1 heading="Login Information" />}
          {currentStep === 2 && (
            <RegisterLayer2
              heading="Personal Information"
              firsLastNameInline={groupname !== LINK_GROUP.EMPLOYER}
              positionOptional={groupname !== LINK_GROUP.EMPLOYER}
              companyOptional={groupname !== LINK_GROUP.EMPLOYER}
            />
          )}
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
