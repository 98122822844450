import React from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../layouts/defaultLayout';
import { LoginForm } from '../../components';
import { getUser } from '../../../utils/helpers';

export default function LoginPage() {
  if (getUser().access_token) {
    return <Redirect to={`/${getUser().type?.toLowerCase()}/dashboard`} />;
  }
  return (
    <DefaultLayout showNavigation={false}>
      <LoginForm />
    </DefaultLayout>
  );
}
