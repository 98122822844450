import React from 'react';
import { Button } from '../../snippets';
import { GIcon } from '../../icons';
import useGroups from '../../hooks/useGroups';

export default function GoogleSignInAction({
  heading = 'Or Sign In Using',
  className = '',
  children,
}) {
  const { groupname } = useGroups();

  const openInNewTab = (url) => {
    localStorage.setItem('userType', groupname);
    window.location.replace(url);
  };

  return (
    <div className={['form-more-action', className].join(' ')}>
      <div className="form-google-btn">
        <div className="g-title">{heading}</div>
        <Button
          as="google"
          className="g-circle"
          onClick={() => {
            openInNewTab(`${process.env.REACT_APP_API_URL}/redirect/google`);
          }}
        >
          <GIcon />
        </Button>
      </div>
      {children}
    </div>
  );
}
