export const SortingPrioritize = (PrioList = []) => {
  return ({ id: a }, { id: b }) => {
    let aPos = PrioList.indexOf(a),
      bPos = PrioList.indexOf(b);
    return aPos > -1 && bPos > -1
      ? aPos - bPos
      : aPos > -1
      ? -1
      : bPos > -1
      ? +1
      : a - b;
  };
};
