import React, { useMemo } from 'react';
import { BackPanel, Heading, Link } from '../../snippets';
import useGroups from '../../hooks/useGroups';
import { useLocation } from 'react-router-dom';
import subnav from '../../placeholders/subnav';
import useTeams from '../../hooks/useTeams';
import { getRandomGradient } from '../../../utils/helpers';
import styled from 'styled-components';

export default function Subnav({ title = 'Account Settings' }) {
  const { groupname } = useGroups();
  const { isAdmin } = useTeams();

  return (
    <div className="page-content-layout page-content-layout--no-bottom">
      <BackPanel>
        <Heading>{title}</Heading>
        <nav className="submenu-navigation">
          {subnav.menu[groupname].map((m) => {
            if (m.admin && !isAdmin) {
              return null;
            }
            return (
              <div className="submenu-navigation--wrapper" key={m.label}>
                <CustomLink {...m} />
              </div>
            );
          })}
        </nav>
      </BackPanel>
    </div>
  );
}

const CustomLink = ({ label, link }) => {
  const { groupname } = useGroups();
  const { pathname } = useLocation();

  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);

  return (
    <StyledLink
      gradient={gradient}
      to={`/${groupname}/${link}`}
      key={label}
      className={`bg-cover app-badge app-badge-primary${
        pathname.split('/').splice(-1)[0] === link ? ' active' : ''
      }`}
      style={{
        backgroundImage: `${
          pathname.split('/').splice(-1)[0] === link ? gradient : ''
        }`,
      }}
    >
      {label}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  &:hover {
    background-image: ${(props) => props.gradient} !important;
  }
`;
