import React, { forwardRef } from 'react';
import { useMemo } from 'react';
import { GIcon } from '../../icons';
import Link from '../Link/Link';
import { generateClassName, getRandomGradient } from '../../../utils/helpers';
import useGroups from '../../hooks/useGroups';

const Button = forwardRef(function Button(
  {
    className = '',
    scheme = 'primary',
    type = 'button',
    to = '#',
    children,
    as = null,
    rounded = false,
    monochrome = false,
    size = null,
    minradii = false,
    capitalize = false,
    smradii = false,
    xsradii = false,
    variant = null,
    text = null,
    uppercase = null,
    transparent = null,
    noGradient = false,
    ...rest
  },
  ref
) {
  const { groupname } = useGroups();

  const gradient = useMemo(() => {
    // Generate the gradient only once when the component mounts or when the groupname changes
    if (
      variant === 'outline' ||
      transparent ||
      scheme === 'error' ||
      noGradient ||
      scheme === 'black'
    ) {
      return 'none';
    }

    return getRandomGradient(groupname);
  }, [groupname, variant, transparent, scheme, noGradient]);

  const classNames = useMemo(() => {
    let _c = ['app-btn', `app-btn-${scheme}`];
    if (type === 'google') {
      _c = 'g-circle';
    }
    if (rounded) {
      _c.push('app-btn-rounded');
    }
    if (monochrome) {
      _c.push('app-btn-monochrome');
    }

    if (size) {
      _c.push('app-btn-' + size);
    }
    if (minradii) {
      _c.push('app-btn-min-radii');
    }
    if (capitalize) {
      _c.push('text-capitalize');
    }
    if (uppercase) {
      _c.push('text-uppercase');
    }
    if (xsradii) {
      _c.push('app-btn-xs-radii');
    }
    if (smradii) {
      _c.push('app-btn-sm-radii');
    }
    if (variant) {
      _c.push('app-btn-' + variant);
    }
    if (transparent) {
      _c.push('app-btn-transparent');
    }
    if (text) {
      _c.push('app-btn--text-' + text);
    }
    _c.push(className);
    return generateClassName(_c);
  }, [
    className,
    type,
    rounded,
    size,
    uppercase,
    xsradii,
    text,
    capitalize,
    minradii,
    monochrome,
    transparent,
    variant,
    scheme,
    smradii,
  ]);

  switch (as) {
    case 'a':
    case 'link':
      return (
        <Link
          className={classNames}
          to={to}
          {...rest}
          ref={ref}
          style={{
            backgroundImage: `${gradient}`,
          }}
        >
          {children}
        </Link>
      );
    case 'google':
      return (
        <button className={classNames} {...rest}>
          <GIcon />
        </button>
      );
    default:
      return (
        <button
          {...rest}
          type={type}
          className={classNames}
          ref={ref}
          style={{
            backgroundImage: `${gradient}`,
          }}
        >
          {children}
        </button>
      );
  }
});

export default Button;
