import React from 'react';

export default function XIconLight({ props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      className="app-icon app-icon-close"
      {...props}
    >
      <path
        d="M13.4766 2.85156L8.78906 7.5L13.4375 12.1484L14.1016 12.8125L12.8125 14.1406L12.1484 13.4766L7.5 8.82812L2.85156 13.4766L2.1875 14.1406L0.859375 12.8125L1.52344 12.1484L6.17188 7.5L1.52344 2.85156L0.859375 2.1875L2.1875 0.859375L2.85156 1.52344L7.5 6.21094L12.1484 1.5625L12.8125 0.898438L14.1406 2.1875L13.4766 2.85156Z"
        fill="currentColor"
      />
    </svg>
  );
}
