import React from 'react';
import ErrorText from '../ErrorText/ErrorText';

export default function FormFieldAddOns({
  error,
  showWarnError = false,
  helpChildren,
  style = {},
}) {
  return (
    <div className="field-add-ons">
      <div className="warn-text" style={style}>
        {error && showWarnError && <ErrorText>{error}</ErrorText>}
      </div>
      {helpChildren && <div className="help-text">{helpChildren}</div>}
    </div>
  );
}
