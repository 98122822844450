import React, { useContext, useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Heading,
  TeamMember,
  FormBlock,
  FormBlockItem,
  FormInput,
  FormCTA,
  Button,
} from '../../snippets';
import { useForm } from 'react-hook-form';
import {
  GET_TEAM_USERS,
  CREATE_INVITATION,
  VIEW_INVITATIONS,
  UPDATE_INVITATION,
  DELETE_INVITATION,
  DELETE_TEAM_USER_SETTING,
} from '../../../graphql';
import { AppContext } from '../../../Store';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

const ManageTeam = () => {
  const { register, handleSubmit, errors, reset, clearErrors } = useForm();
  const [agencyState] = useContext(AppContext);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showDeleteTeamConfirmModal, setDeleteTeamShowConfirmModal] =
    useState(false);
  const [showDeleteInviteConfirmModal, setDeleteInviteShowConfirmModal] =
    useState(false);

  const { data: { viewUserTeam = [] } = {}, refetch } = useQuery(
    GET_TEAM_USERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        team_id: agencyState?.activeCompany?.team_id,
      },
    }
  );

  const [getInvitationList, { data: { viewInvitations = [] } = {} }] =
    useLazyQuery(VIEW_INVITATIONS, {
      variables: {
        team_id: Number(agencyState?.activeCompany?.team_id),
        where: {
          AND: [
            {
              column: 'INVITE_STATUS',
              operator: 'LIKE',
              value: 'pending',
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    });

  /** Resend Invitation */
  const [resendInvitation] = useMutation(UPDATE_INVITATION);
  const resendInvite = (id) => {
    toast.dismiss();
    resendInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        toast.success('Invite resent succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not resend invite', { toastId: 'appErr' })
      );
  };

  /** Delete Invitation */
  const [deleteInvitation] = useMutation(DELETE_INVITATION);
  const deleteInvite = (id) => {
    toast.dismiss();
    deleteInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        setDeleteInviteShowConfirmModal(false);
        toast.success('Invite deleted succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not delete invite', { toastId: 'appErr' })
      );
  };

  const [createInvitation, { loading }] = useMutation(CREATE_INVITATION);

  const sendInvitation = (data) => {
    toast.dismiss();
    createInvitation({
      variables: {
        input: {
          ...data,
          team_id: Number(agencyState?.activeCompany?.team_id),
          invite_status: 'pending',
        },
      },
    })
      .then(() => {
        getInvitationList();
        toast.success('Invite sent succesfully!', { autoClose: 4000 });
        reset();
      })
      .catch((e) => {
        toast.error(e.graphQLErrors[0].extensions.reason, {
          toastId: 'invitations',
        });
      });
  };

  /** Delete User Team */
  const [deleteTeamUser] = useMutation(DELETE_TEAM_USER_SETTING);
  const deactivateAccount = (user) => {
    deleteTeamUser({
      variables: {
        input: {
          user_id: user?.user_id,
          team_id: user?.team_id,
        },
      },
    })
      .then(() => {
        refetch();
        setDeleteTeamShowConfirmModal(false);
        toast.dismiss();
        toast.success('Team member removed!', { autoClose: 4000 });
      })
      .catch(() => toast.error('Could not delete user', { toastId: 'appErr' }));
  };

  useEffect(() => {
    if (agencyState?.activeCompany !== undefined) {
      getInvitationList();
    }
  }, [agencyState, getInvitationList]);

  return (
    <DefaultLayout showNavigation showSubmenu>
      <BackPanel>
        <Heading>Manage Team</Heading>
        <h2 className="team-heading">Current Team Members</h2>
        <div className="team-cont">
          {viewUserTeam?.map((tm, i) => (
            <TeamMember
              name={tm.team?.first_name + ' ' + tm.team?.last_name}
              email={tm.team?.email}
              key={tm.user_id}
              onDelete={
                viewUserTeam.length > 1
                  ? () => {
                      deactivateAccount(tm);
                      setTeamMembers(teamMembers.splice(i, 1));
                    }
                  : false
              }
              setShowConfirmModal={setDeleteTeamShowConfirmModal}
              showConfirmModal={showDeleteTeamConfirmModal}
            />
          ))}
        </div>
        {viewInvitations.length > 0 && (
          <>
            <h2 className="team-heading">Pending Team Member Invites</h2>
            <div className="team-cont">
              {viewInvitations.map((tm) => (
                <TeamMember
                  name={tm?.first_name + ' ' + tm?.last_name}
                  email={tm.email}
                  key={tm.id}
                  pending={true}
                  onResend={() => resendInvite(tm.id)}
                  onDelete={() => deleteInvite(tm.id)}
                  setShowConfirmModal={setDeleteInviteShowConfirmModal}
                  showConfirmModal={showDeleteInviteConfirmModal}
                />
              ))}
            </div>
          </>
        )}
        <h2 className="team-heading">New Team Member</h2>
        <form className="invite-cont" onSubmit={handleSubmit(sendInvitation)}>
          <FormBlock>
            <FormBlockItem label={'First Name'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                name="first_name"
                error={errors.first_name ? `First name is required` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>
            <FormBlockItem label={'Last Name'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                name="last_name"
                error={errors.last_name ? `Last name is required` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>
            <FormBlockItem label={'Email Address'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                withEmailValidation
                name="email"
                error={errors.email ? `Email is required` : null}
                clearErrors={clearErrors}
              ></FormInput>
              {errors.email && (
                <p
                  style={{ color: 'red', fontWeight: '700', marginTop: '6px' }}
                >
                  Please enter a valid email address
                </p>
              )}
            </FormBlockItem>
          </FormBlock>
          <FormCTA spaceT>
            <Button
              type="submit"
              className="w-full"
              size="xxl"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Send Invite'}
            </Button>
          </FormCTA>
        </form>
      </BackPanel>
    </DefaultLayout>
  );
};

export default ManageTeam;
