import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CommunityCard = ({ id, image, title, name }) => {
  return (
    <Container>
      <Link to="#" className="nochange">
        <img src={image} alt={title} />
        <hr />
        {name && <h3 className="name">{name}</h3>}
        <p className="title">{title}</p>
      </Link>
    </Container>
  );
};

export default CommunityCard;

const Container = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 118px;
    border-radius: 1px;
  }
  hr {
    border: 1px solid #929292;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .title {
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 20.23px;
  }
  .name {
    color: #50524e;
    font-weight: 900;
    font-size: 14px;
    margin: 0;
  }
  @media (min-width: 768px) {
    width: 249px;
    img {
      height: 200px;
    }
  }
  @media (min-width: 1024px) {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 18px;
    }
  }
`;
