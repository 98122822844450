import React, { useCallback, useEffect, useState } from 'react';
import useGroups from '../../../../hooks/useGroups';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  CREATE_TEAM_USER,
  PROCESS_INVITATION,
  REGISTER,
} from '../../../../../graphql';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import {
  saveLoginTypeLocalstorage,
  saveUserLocalstorage,
  validatePhone,
} from '../../../../../utils/helpers';
import { registerVar, userVar } from '../../../../../graphql/config';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../../layouts/defaultLayout';
import { BackPanel, FormContent, Heading } from '../../../../snippets';
import { StepCounter, TeamRegisterLayers2 } from '../../../../components';

export default function CompanyTeamRegisterProfilePage() {
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneNo] = useState('');
  const [tncError, setTncError] = useState('');
  const { register, handleSubmit, errors, clearErrors, watch, setValue } =
    useForm();

  const { groupname } = useGroups();
  const { state } = useLocation();
  const { token, email } = state || {};

  const [registerMutation, { loading }] = useMutation(REGISTER);
  /** Accept Team Invite */
  const [acceptInvite, { loading: loadingAcceptInvite }] =
    useMutation(PROCESS_INVITATION);

  const acceptInvitation = useCallback(async () => {
    toast.dismiss();
    try {
      const { data } = await acceptInvite({
        variables: {
          email: email,
          token: token,
        },
      });
      if (data?.processInvitation?.team_id) {
        const cookies = new Cookies();
        cookies.set('lh_team', data?.processInvitation?.team_id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      }
      toast.success('Invitation accepted successfully', { autoClose: 4000 });
      window.open(`/${groupname}/team/profile/complete`, '_self');
    } catch (error) {
      toast.error('Could not accept invite', { toastId: 'appErr' });
    }
  }, [token, email, acceptInvite, groupname]);

  /** Create New Team User */
  const [createTeamUser] = useMutation(CREATE_TEAM_USER);

  const addNewTeamMember = useCallback(async () => {
    try {
      toast.dismiss();
      const { data } = await createTeamUser({
        variables: {
          input: {
            email: email,
            token: token,
          },
        },
      });
      if (data?.createTeamUser?.team_id) {
        const cookies = new Cookies();
        cookies.set('lh_team', data?.createTeamUser?.team_id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      }
      acceptInvitation();
    } catch (error) {
      console.error(error);
      let msg = 'Something went wrong!';

      toast.error(msg, { toastId: 'frRegErr' });
    }
  }, [token, email, acceptInvitation, createTeamUser]);

  const wField = watch(['tnc']);

  useEffect(() => {
    if (wField.tnc) {
      setTncError(false);
    }
  }, [wField]);

  const RegisterUser = useCallback(
    async (data) => {
      try {
        toast.dismiss();
        if (!data.tnc) {
          setTncError(true);
          toast.error('You must accept the terms and conditions!', {
            autoClose: 3000,
          });
          return;
        }
        const isInvalid = validatePhone(phone);
        if (isInvalid) {
          setPhoneError(true);
        }
        if (!isInvalid && phone !== '') {
          delete data.tnc;
          const { data: resData } = await registerMutation({
            variables: {
              input: {
                ...data,
                ...registerVar(),
                type: 'GUEST',
                phone: `+${phone}`,
              },
            },
          });
          if (!resData.register) {
            throw Error('empty_register_data');
          } else {
            registerVar({});
            const cookies = new Cookies();
            cookies.set('lh_user', resData.register.id, {
              path: '/',
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
            userVar(resData.register);
            saveUserLocalstorage(resData.register);
            saveLoginTypeLocalstorage(groupname.toUpperCase());
            /** Create UserTeam Association */
            addNewTeamMember();
          }
        }
      } catch (error) {
        console.error(error);
        let msg = 'Something went wrong!';

        if (
          error?.graphQLErrors?.length > 0 &&
          'validation' in error?.graphQLErrors[0]?.extensions &&
          'input.email' in error?.graphQLErrors[0]?.extensions?.validation
        ) {
          msg = 'The email has already been taken!';
        }

        toast.error(msg, { toastId: 'frRegErr' });
      }
    },
    [phone, addNewTeamMember, groupname, registerMutation]
  );

  const handleOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // console.log(e)
      if (phone === '' || phone.length < 5 || !e.target.tnc.checked) {
        if (phone === '' || phone.length < 5) {
          setPhoneError(true);
        }

        if (!e.target.tnc.checked) {
          setTncError(true);
        }
      }
      setPhoneError(false);
      await handleSubmit(RegisterUser)(e);
    },
    [phone, RegisterUser, handleSubmit]
  );

  if (!Object.keys(registerVar()).length) {
    return <Redirect to={`/${groupname}/register`} />;
  }

  const tempData = registerVar();

  return (
    <DefaultLayout
      showBackButton={true}
      backButtonUrl={`/${groupname}/team/register/${state?.token}&${state?.email}`}
    >
      <BackPanel isSmall>
        <Heading>{'Create A Team Account'}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={2}></StepCounter>
          <TeamRegisterLayers2
            heading="Personal information"
            forms={{
              onSubmit: handleOnSubmit,
              errors,
              clearErrors,
              input: tempData,
              loading: loadingAcceptInvite || loading,
              setValue,
              watch,
              register,
              tncError,
              phoneError,
              setPhoneError,
              setPhoneNo,
            }}
          />
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
