import React, { useContext, useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import countryList from 'country-list';
import AccountSubmenuDesktop from '../../components/Company/AccountSubmenuDesktop';
import AccountSubmenuMobile from '../../components/Company/AccountSubmenuMobile';
import Header from '../../components/Company/Header';
import Footer from '../../components/Footer';
import Error from '../../components/Error';
import jquery from '../../styles/js/app';
import {
  ADD_SUBSCRIPTION,
  CHECK_PROMO,
  GET_SUBSCRIPTION_HISTORY,
  UPDATE_PROFILE,
} from '../../graphql';
import { AppContext } from '../../Store';
import { LINK_GROUP } from '../../utils/links';

const StartSubscription = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);
  const [state] = useContext(AppContext);

  const countries = countryList
    .getData()
    .sort((a, b) => a.name.localeCompare(b.name));
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    address: {
      country: 'GB',
    },
  });
  const [promo, setPromo] = useState('');
  const [promoError, setPromoError] = useState('');
  const [firstSubscription, setFirstSubscription] = useState(false);
  const [renewAt, setRenewAt] = useState('');
  const [addSubscription] = useMutation(ADD_SUBSCRIPTION);
  const [checkPromoMutation] = useMutation(CHECK_PROMO);
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const { refetch } = useQuery(GET_SUBSCRIPTION_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
      agency: state?.activeCompany?.team_id,
    },
    onCompleted: (data) => {
      if (data.subscriptionHistory.length === 0) {
        setFirstSubscription(true);
      } else {
        setRenewAt(
          moment(data.subscriptionHistory[0].ends_at).format('Do MMMM YYYY')
        );
      }
    },
    onError: () => refetch(),
  });

  const showError = () => {
    toast.error(error, { toastId: 'startSubscription' });
  };

  const createOptions = () => ({
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
        height: '42px',
        letterSpacing: '0.18px',
        lineHeight: '42px',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
    classes: {
      base: 'StripeCardBase',
    },
  });

  const applyPromo = async () => {
    if (!promo) {
      setPromoError('');
      return;
    }
    const {
      data: { checkPromo },
    } = await checkPromoMutation({
      variables: {
        code: promo,
      },
    });

    if (checkPromo === null) {
      setPromoError("Promo code doesn't exist!");
    } else {
      setPromoError('');
      toast.success('Promo applied!', {
        autoClose: 2000,
      });
    }
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      showError(error);
      return;
    }

    if (cardComplete) {
      setLoading(true);
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpire = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    // Use your card Element with other Stripe.js APIs
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: billingDetails,
    });

    if (payload.error) {
      setError(payload.error);
      showError(error);
    } else {
      return updateProfile({
        variables: {
          input: {
            billing_address_1: billingDetails.address.line1,
            billing_address_2: billingDetails.address.line2,
            billing_city: billingDetails.address.city,
            billing_state: billingDetails.address.state,
            billing_country: billingDetails.address.country,
            billing_zip: billingDetails.address.postal_code,
          },
        },
      }).then(() => {
        addSubscription({
          variables: {
            input: {
              promo: promoError === '' ? promo : null,
              payment_method_id: payload.paymentMethod.id,
            },
          },
        })
          .then(() => {
            setLoading(false);
            toast.dismiss();
            toast.success('Thank you for your subscription!', {
              autoClose: 4000,
            });
            setBillingDetails({
              address: {},
            });
            cardNumber.clear();
            cardExpire.clear();
            cardCvc.clear();

            return history.push(`/${LINK_GROUP.COMPANY}/dashboard`);
          })
          .catch((e) => {
            setError(e.error);
            showError();
          });
      });
    }
  };

  return (
    <div className={!firstSubscription ? 'lh-wrapper' : ''}>
      <Header isLoggedIn />
      {!firstSubscription && <AccountSubmenuMobile />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>
              {firstSubscription
                ? 'Start Your Subscription'
                : 'Update Payment Info'}
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="start-subscription__box">
              <p className="">
                Your subscription{' '}
                {firstSubscription ? 'explained' : `will renew on ${renewAt}`}
              </p>
              <div className="start-subscription__box__header">&pound;195</div>
              <div className="start-subscription__box__subheader">monthly</div>
              {firstSubscription && (
                <>
                  <div className="start-subscription__box__caps">
                    Includes 2 Free Job Posts
                  </div>
                  <div className="">
                    £195 monthly. Includes Two Liberty Hive Points. As a member
                    of Liberty Hive you will receive your own personal dashboard
                    where you can connect to our network of experienced
                    consultants whilst managing your account details. All job
                    posts require one LH Point, no matter the role, category
                    experience or experience required.
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <form className="form" onSubmit={handleSubmit}>
              <h3>Billing Address</h3>

              <div className="form__input-block">
                <div className="form__label">Address Line 1</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="address-line-1"
                    required
                    defaultValue={
                      state?.activeCompany?.user?.billing_address_1 ??
                      state?.activeCompany?.user?.address_1
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          line1: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">Address Line 2</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="address-line-2"
                    defaultValue={
                      state?.activeCompany?.user?.billing_address_2 ??
                      state?.activeCompany?.user?.address_2
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          line2: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">City</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="city"
                    required
                    defaultValue={
                      state?.activeCompany?.user?.billing_city ??
                      state?.activeCompany?.user?.city
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          city: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__label">County</div>
                <div className="form__input-wrapper">
                  <input
                    className="form__input"
                    type="text"
                    autoComplete="false"
                    name="state"
                    required
                    defaultValue={
                      state?.activeCompany?.user?.billing_state ??
                      state?.activeCompany?.user?.state
                    }
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        address: {
                          ...billingDetails.address,
                          state: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times" />
                  </div>
                </div>
              </div>

              <div className="form__two-cols">
                <div className="form__input-block form__two-cols__inner">
                  <div className="form__label">Postcode / Zip Code</div>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      autoComplete="false"
                      name="post-code"
                      required
                      defaultValue={
                        state?.activeCompany?.user?.billing_zip ??
                        state?.activeCompany?.user?.zip
                      }
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            postal_code: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>

                <div className="form__input-block form__two-cols__inner mb-60">
                  <div className="form__label">Country</div>
                  <div className="form__input-wrapper">
                    <select
                      className="form__input form__country"
                      name="country"
                      list="countries"
                      id="country"
                      required
                      defaultValue={
                        state?.activeCompany?.user?.billing_country ?? 'GB'
                      }
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          address: {
                            ...billingDetails.address,
                            country: e.target.value,
                          },
                        });
                      }}
                    >
                      {countries.map(({ code, name }) => (
                        <option value={code} key={code}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <div className="form__clear form__hidden">
                      <i className="fas fa-times" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__input-block">
                <div className="form__input-group">
                  <input
                    className="form__input-group__input"
                    type="text"
                    placeholder="Got a promo code?"
                    autoComplete="false"
                    name="promo"
                    onChange={(e) => {
                      setPromo(e.target.value);

                      if (e.target.value.length === 0) {
                        setPromoError('');
                      }
                    }}
                  />

                  <div className="form__input-group-append">
                    <button
                      className="btn link-company"
                      type="button"
                      onClick={applyPromo}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              {promoError !== '' && <Error text={promoError} />}

              <div className="mb-60" style={{ clear: 'both' }} />

              <h3>Payment Information</h3>

              <div className="form__input-block">
                <div className="form__label">&nbsp;</div>
                <div className="form__input-wrapper">
                  <CardNumberElement
                    className="form__input"
                    {...createOptions()}
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </div>

              <div className="onboarding__two-cols-constant">
                <div className="form__input-block form__two-cols-constant__inner">
                  <div className="form__label">&nbsp;</div>
                  <div className="form__input-wrapper">
                    <CardExpiryElement
                      className="form__input"
                      {...createOptions()}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>

                <div className="form__input-block form__two-cols-constant__inner">
                  <div className="form__label">&nbsp;</div>
                  <div className="form__input-wrapper">
                    <CardCvcElement
                      className="form__input"
                      {...createOptions()}
                      onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ clear: 'both' }} />
              <p>
                Your subscrition will renew on the 4th of every month and is
                subject to our{' '}
                <a href="/#" target="_blank" className="link-company">
                  Terms & Conditons
                </a>
                . Your information will be handled in accordance with our{' '}
                <a href="/#" target="_blank" className="link-company">
                  Privacy Policy
                </a>
                .
              </p>

              <div className="col-12 text-center">
                <button
                  className="btn form__btn d-block"
                  type="submit"
                  disabled={!stripe}
                >
                  {loading ? 'Loading...' : 'Subscribe Now'}
                </button>
              </div>
            </form>
          </div>

          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StartSubscription;
