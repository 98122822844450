import gql from 'graphql-tag';

export const GET_ARTICLES = gql`
  query Articles(
    $availability: [ArticleAvailability]
    $type: [ArticleType]
    $excludeSlugs: [String!]
    $first: Int = 10
    $orderBy: [QueryArticlesOrderByOrderByClause!]
  ) {
    articles(
      availability: $availability
      type: $type
      excludeSlugs: $excludeSlugs
      orderBy: $orderBy
      first: $first
    ) {
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
      }
      data {
        id
        title
        slug
        image
        created_at
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  query Article($slug: String!) {
    article(slug: $slug) {
      id
      content
      title
      slug
      image
      created_at
    }
  }
`;
