import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import CaseStudyCard from './components/CaseStudyCard';
import { AppContext } from '../../Store';
import { GET_CASE_STUDIES } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';
import jquery from '../../styles/js/app';
import { Heading, GridFourCol } from './CaseStudy';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MyAccountLayout from './layout/MyAccountLayout';
import { ImageUploadContainer, FormContainer } from './AccountInformation';
import { LINK_GROUP } from '../../utils/links';

const AgencyProfile = () => {
  const history = useHistory();
  useEffect(() => {
    jquery();
  }, []);

  const { data, loading } = useQuery(GET_CASE_STUDIES, {
    variables: {
      first: 10,
      page: 1,
    },
  });

  const [agencyState] = useContext(AppContext);

  const img =
    agencyState?.activeCompany?.user?.avatar ||
    '/img/profile-picture-placeholder@2x.jpg';

  return (
    <MyAccountLayout>
      <div className="black-card black-card__full mb-50">
        <Heading>View Profile</Heading>
        <div className="mt-40 d-flex justify-content-between flex-column flex-lg-row">
          <ImageUploadContainer>
            <img src={img} w="224" h="224" alt="" />
            <Link to="/agency/edit-profile">
              <button className="bg__gradient-1 bg-cover">EDIT PROFILE</button>
            </Link>
          </ImageUploadContainer>
          <FormContainer>
            <div>
              <h3>Agency Name</h3>
              <p>{agencyState?.activeCompany?.user?.agency_name}</p>
            </div>
            <div>
              <h3>About Us</h3>
              <p>{agencyState?.activeCompany?.user?.bio}</p>
            </div>
            <div>
              <h3>Skills</h3>
              {agencyState?.activeCompany?.user?.skills.map((skill) => (
                <p key={skill.id} className="text-underline">
                  {skill.name}
                </p>
              ))}
            </div>
            <div>
              <h3>Software Skills</h3>
              {agencyState?.activeCompany?.user?.skills
                .filter((skill) => skill.software)
                .map((skill) => (
                  <p key={skill.id}>{skill.name}</p>
                ))}
            </div>
            <div>
              <h3>Website</h3>
              <a
                href={agencyState?.activeCompany?.user?.website ?? '#'}
                target="_blank"
                className="text-underline"
                rel="noopener noreferrer"
              >
                {agencyState?.activeCompany?.user?.website}
              </a>
            </div>
            <div>
              <h3>Contact Information</h3>
              <p>{agencyState?.activeCompany?.user?.contact_email}</p>
              <p>
                {
                  agencyState?.activeCompany?.user?.agency_phone[0].attributes
                    .phone
                }
              </p>
            </div>
          </FormContainer>
          <FormContainer>
            <div>
              <h3>Category Experience</h3>
              {agencyState?.activeCompany?.user?.genres.map((experience) => (
                <p key={experience.id} className="text-underline">
                  {experience.name}
                </p>
              ))}
            </div>
          </FormContainer>
        </div>
      </div>
      <div className="black-card black-card__full mb-50">
        <HeadingCont className="d-flex justify-content-between">
          <Heading>Case Studies</Heading>
          <Button
            className="bg__gradient-1 bg-top bg-cover"
            onClick={() => {
              history.push(`/${LINK_GROUP.AGENCY}/case-study/add`);
            }}
          >
            Create Case Study
          </Button>
        </HeadingCont>
        {loading && <div>Loading...</div>}
        <GridFourCol>
          {data?.caseStudies?.data.map((caseStudy) => (
            <CaseStudyCard data={caseStudy} key={caseStudy.id} />
          ))}
        </GridFourCol>
      </div>
    </MyAccountLayout>
  );
};

const Button = styled.button`
  border-radius: 12px;
  padding: 12px 27px;
  border: none;
  font-size: 18px;
  color: white;
  font-weight: 500;
  width: 100%;
  @media (min-width: 768px) {
    width: 224px;
  }
`;

const HeadingCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default AgencyProfile;
