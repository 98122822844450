import gql from 'graphql-tag';

export const GET_ALL_AGENCY = gql`
  query Agencies {
    agencies {
      id
      location
      city
      country
      name
      agency_name
      avatar
      services {
        name
      }
    }
  }
`;
