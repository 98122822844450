import React, { useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Link,
} from '../../snippets';
import { forgotPassword } from '../../../api/auth';
import useGroups from '../../hooks/useGroups';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { formatValidationErrors } from '../../../utils/helpers';

export default function ForgotPasswordPage() {
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const { groupname } = useGroups();

  const sendPasswordReset = async (data) => {
    toast.dismiss();
    setLoading(true);
    const response = await forgotPassword(data);
    if (response.data && response.data.errors) {
      setLoading(false);
      response.status === 422
        ? toast.error(formatValidationErrors(response.data.errors))
        : toast.error(response.data.error);
    } else {
      reset();
      setLoading(false);
      toast.success(response.message);
    }
  };
  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading as="h1">Forgot your password?</Heading>
        <form onSubmit={handleSubmit(sendPasswordReset)}>
          <FormBlock className={'space-t'}>
            <FormBlockItem label={'Email Address'}>
              <FormInput
                type="text"
                name="email"
                isRequired
                error={
                  errors.email
                    ? errors.email.type === 'pattern'
                      ? 'Please enter a valid email address'
                      : 'Please enter an email'
                    : null
                }
                register={register}
                withEmailValidation
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormCTA className={'space-t'}>
              <Button
                className="app-btn-submit"
                type="submit"
                disabled={loading}
                size="xl"
              >
                {loading ? 'Loading...' : 'Send Password Reset'}
              </Button>
            </FormCTA>
            <div className="jump-to text-center spacer-t">
              <Link to={'/' + groupname + '/register'}>Need an account?</Link>
            </div>
          </FormBlock>
        </form>
      </BackPanel>
    </DefaultLayout>
  );
}
