import * as React from 'react';

const VettedIcon = ({ gradientColor = [] }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 49 48"
  >
    <defs>
      <linearGradient id="linearGradient-1">
        {gradientColor.map((color, index) => (
          <stop
            offset={`${index === 0 ? 0 : 50 * index}%`}
            stopColor={color}
          ></stop>
        ))}
      </linearGradient>
    </defs>
    <path
      fill="url(#linearGradient-1)"
      d="M19.5-.5h9q4.872 4.774 12 5.5 4.818 4.096 4.5 10.5 2.107 1.846 3.5 4v8q-4.774 4.872-5.5 12-4.097 4.818-10.5 4.5-1.846 2.107-4 3.5h-8q-3.637-4.893-10-4.5Q3.58 39.415 4 31.5q-3.757-2.735-4.5-7v-2q1.446-3.862 4.5-7Q4.25 3.25 16.5 3a14.7 14.7 0 0 0 3-3.5m11 17h3q-4.234 8.256-12 14-4.818-2.795-6-8 3.643.127 6 3a57.5 57.5 0 0 0 9-9"
      opacity="0.99"
    ></path>
  </svg>
);

export default VettedIcon;
