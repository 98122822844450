import React from 'react';
import { BackPanel, Heading, Link } from '../../snippets';
import { getLoginType } from '../../../utils/helpers';

const AccountInactive = ({ headline = 'Your account is inactive.' }) => (
  <BackPanel>
    <Heading>{headline}</Heading>
    <p>We are currently approving your account.</p>
    <p>
      In the mean time, why not{' '}
      <Link to={`/${getLoginType()}/manage-cv`}>update your profile</Link>?
    </p>
  </BackPanel>
);

export default AccountInactive;
