import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Link,
} from '../../snippets';
import { useForm } from 'react-hook-form';
import useGroups from '../../hooks/useGroups';
import { registerVar, userVar } from '../../../graphql/config';
import { REGISTER } from '../../../graphql';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Cookies from 'universal-cookie';
import { overwrite, getNames } from 'country-list';
import { useMutation } from '@apollo/react-hooks';
import {
  saveLoginTypeLocalstorage,
  saveUserLocalstorage,
} from '../../../utils/helpers';
import { LINK_GROUP } from '../../../utils/links';
import { TYPE_PRONOUNS } from '../../placeholders/consultant/types';

export default function RegisterLayer2({
  heading = 'Personal Information',
  setLoading = (val) => {},
  firsLastNameInline = false,
  companyOptional = false,
  positionOptional = false,
}) {
  const history = useHistory();
  const { groupname } = useGroups();

  const { register, handleSubmit, errors, clearErrors, watch } = useForm();
  const [registerMutation, { loading }] = useMutation(REGISTER);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneNo] = useState('');
  const [tncError, setTncError] = useState('');
  overwrite([
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
  ]);
  const countries = getNames().sort();

  const positionLabel = useMemo(() => {
    if (groupname === LINK_GROUP.CANDIDATE) {
      return 'Latest Job Title';
    } else {
      return 'Position';
    }
  }, [groupname]);

  const wField = watch(['tnc']);

  useEffect(() => {
    if (wField.tnc) {
      setTncError(false);
    }
  }, [wField]);

  const tempData = registerVar();

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  if (!Object.keys(tempData).length) {
    return <Redirect to={`/${groupname}/register`} />;
  }

  const registerUser = (data) => {
    toast.dismiss();
    if (!data.tnc) {
      setTncError(true);
      toast.error('You must accept the terms and conditions!', {
        autoClose: 3000,
      });
      return;
    }

    let userType = 'agency';

    if (groupname === LINK_GROUP.CANDIDATE) {
      userType = LINK_GROUP.FREELANCER;
    } else if (groupname === LINK_GROUP.EMPLOYER) {
      userType = LINK_GROUP.COMPANY;
    }

    if (phone)
      if (!phoneError && phone !== '') {
        delete data.tnc;
        registerMutation({
          variables: {
            input: {
              ...data,
              ...tempData,
              type: userType,
              phone: `+${phone}`,
            },
          },
        })
          .then((res) => {
            const { data: resData } = res;
            if (!resData.register) {
              toast.error('Something is wrong!', {
                autoClose: 3000,
              });
            } else {
              registerVar({});
              const cookies = new Cookies();
              cookies.set('lh_user', resData.register.id, {
                path: '/',
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              });
              userVar(resData.register);
              saveUserLocalstorage(resData.register);
              saveLoginTypeLocalstorage(groupname.toUpperCase());
              return history.push(`/${groupname}/complete-profile`);
            }
          })
          .catch((e) => {
            let msg = 'Something went wrong!';
            console.error(e);
            if (
              'validation' in e?.graphQLErrors[0]?.extensions &&
              'input.email' in e?.graphQLErrors[0]?.extensions.validation
            ) {
              msg = 'The email has already been taken!';
            }

            toast.error(msg, { toastId: 'frRegErr', autoClose: 3000 });
          });
      }
  };

  return (
    <form
      onSubmit={async (e) => {
        await handleSubmit(registerUser)(e);

        if (phone === '' || phone.length < 5 || !e.target.tnc.checked) {
          if (phone === '' || phone.length < 5) {
            setPhoneError(true);
          }

          if (!e.target.tnc.checked) {
            setTncError(true);
          }

          return;
        }
        setPhoneError(false);
      }}
    >
      <Heading as="h3" className="text-center mb-4">
        {heading}
      </Heading>

      <FormBlock isInline={firsLastNameInline}>
        <FormBlockItem label={'First Name'}>
          <FormInput
            name="first_name"
            type="text"
            isRequired
            register={register}
            defaultValue={tempData?.first_name || ''}
            error={errors.first_name ? 'Please enter first name' : null}
            clearErrors={clearErrors}
          />
        </FormBlockItem>
        <FormBlockItem label={'Last Name'}>
          <FormInput
            name="last_name"
            type="text"
            isRequired
            register={register}
            error={errors.last_name ? 'Please enter last name' : null}
            defaultValue={tempData?.last_name || ''}
            clearErrors={clearErrors}
          />
        </FormBlockItem>
      </FormBlock>

      <FormBlock className={'space-t'}>
        {groupname === LINK_GROUP.CANDIDATE && (
          <FormBlockItem label={'What Are Your Pronouns? (optional)'}>
            <FormInput
              type="select"
              register={register}
              name="pronouns"
              defaultValue={tempData.pronouns ?? ''}
              options={TYPE_PRONOUNS}
              placeholder="Pronouns"
            ></FormInput>
          </FormBlockItem>
        )}

        <FormBlockItem
          label={'Employer Name' + (companyOptional ? ' (optional)' : '')}
        >
          <FormInput
            type="text"
            register={register}
            name="company"
            isRequired={!companyOptional}
            error={errors.company ? `Please enter employer name` : null}
            defaultValue={tempData?.name || ''}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem
          label={positionLabel + (positionOptional ? ' (optional)' : '')}
        >
          <FormInput
            name="position"
            type="text"
            isRequired={!positionOptional}
            register={register}
            error={errors.position ? 'Please enter last name' : null}
            defaultValue={tempData?.position || ''}
            clearErrors={clearErrors}
          />
        </FormBlockItem>

        <FormBlockItem label={'Address Line 1'}>
          <FormInput
            type="text"
            register={register}
            name="address_1"
            isRequired
            error={errors.address_1 ? `Please enter an address` : null}
            defaultValue={tempData.address_1}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'Address Line 2'}>
          <FormInput
            type="text"
            register={register}
            name="address_2"
            defaultValue={tempData.address_2}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'City'}>
          <FormInput
            type="text"
            register={register}
            name="city"
            isRequired
            error={errors.city ? `Please enter city` : null}
            defaultValue={tempData.city}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'County'}>
          <FormInput
            type="text"
            register={register}
            name="state"
            defaultValue={tempData.state}
            isRequired
            error={errors.county ? `Please enter county` : null}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>
      </FormBlock>

      <FormBlock isInline={firsLastNameInline} className={'space-t'}>
        <FormBlockItem label={'Postcode / Zip Code'}>
          <FormInput
            type="text"
            register={register}
            name="zip"
            defaultValue={tempData.zip}
            isRequired
            error={errors.zip ? `Please enter postcode / zip code` : null}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'Country'}>
          <FormInput
            type="select"
            register={register}
            name="country"
            defaultValue={tempData.country ?? 'United Kingdom'}
            isRequired
            error={errors.country ? `Please select a country` : null}
            options={countries.map((country) => ({
              value: country,
            }))}
          ></FormInput>
        </FormBlockItem>
      </FormBlock>

      <FormBlock className={'space-t'}>
        <FormBlockItem label={'Phone Number'}>
          <FormInput
            type="phone"
            isRequired
            register={register}
            defaultValue={tempData.phone}
            error={phoneError ? `00 0000 000 00` : null}
            name="phone"
            setPhoneNo={setPhoneNo}
            setPhoneError={setPhoneError}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem>
          <FormInput
            type="checkbox"
            register={register}
            defaultValue={tempData.agree}
            error={tncError ? `You must accept the terms and conditions` : null}
            name="tnc"
            checkboxChildren={
              <div className="tnc">
                I agree to the{' '}
                <Link
                  to={`${process.env.REACT_APP_API_URL}/pages/company-terms-conditions`}
                  external
                >
                  Terms and Conditions
                </Link>
              </div>
            }
          ></FormInput>
        </FormBlockItem>
      </FormBlock>
      <FormCTA className={'space-t'}>
        <Button
          className="app-btn-submit"
          type="submit"
          disabled={loading}
          size="xl"
        >
          {loading ? 'Loading...' : 'Next'}
        </Button>
      </FormCTA>
    </form>
  );
}
