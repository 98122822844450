import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Error from '../../components/Error';
import jquery from '../../styles/js/app';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import {
  isValidImage,
  jobLevelsMultiSelectStyle,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { userVar } from '../../graphql/config';
import { GET_ALL_JOB_LEVELS, UPDATE_PROFILE } from '../../graphql';
import CustomMultiselect from '../../v2/components/CustomMultiselect';

const CompleteProfile = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const { data: { allJoblevels } = {}, loading: loadingRoles } =
    useQuery(GET_ALL_JOB_LEVELS);

  const [img, setImg] = useState({
    url: '/img/profile-pic-placeholder.png',
    error: false,
  });
  const avatarRef = useRef(null);
  const { register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch(['bio', 'hear_us']);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const hearUs = [
    'recommendation',
    'google',
    'trade press',
    'social media',
    'other',
  ];
  const allLocations = [
    {
      key: 'ONSITE',
      name: 'On site',
    },
    {
      key: 'REMOTE',
      name: 'Remote',
    },
    {
      key: 'HYBRID',
      name: 'Hybrid',
    },
  ];

  const [selectedJobLevels, setSelectedJobLevels] = useState(null);
  const handleJobChange = (selectedList, _) =>
    setSelectedJobLevels(selectedList.map((s) => s.key));

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    const file = event.target.files[0];
    isValidImage(file, (valid) => {
      if (!valid) {
        setImg({ url: '/img/profile-pic-placeholder.png', error: true });
        return toast.error('Please select a valid image file.', {
          toastId: 'invalidImage',
        });
      }
      setImg({
        url: valid.url,
        error: false,
        file: valid.file,
      });
    });
  };

  const editProfile = (data) => {
    toast.dismiss();
    if (img.error) {
      return toast.error('Please select a valid image file.');
    }
    return updateProfile({
      variables: {
        input: {
          ...data,
          avatar: img.file,
          looking_for: selectedJobLevels,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        userVar({ ...userVar(), ...updateUser });
        saveUserLocalstorage(userVar());
        toast.success('Profile completed!', { autoClose: 4000 });
        return history.push('/freelancer/manage-cv');
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="onboarding form__freelancer">
                <h1 className="center">You're signed up!</h1>
                <div className="pagination-bubble mb-40">
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    1
                  </div>
                  <div className="pagination-bubble__freelancer__item bg-light-freelancer">
                    2
                  </div>
                  <div className="pagination-bubble__freelancer__item pagination-bubble__freelancer__active">
                    3
                  </div>
                </div>
                <h3>Now tell us a little about you...</h3>
                <div className="form__heading">
                  Add your photo
                  <i className="font-weight-lighter">
                    (or you can leave this for later)
                  </i>
                </div>
                <div className="edit-company-logo">
                  <div
                    className="edit-company-logo__overlay invisible"
                    onClick={() => avatarRef.current.click()}
                  >
                    <p className="edit-profile-picture__text">
                      <i className="far fa-edit" /> Add Profile Picture
                    </p>
                  </div>
                  <img src={img.url} className="img img__company-logo" alt="" />
                </div>
                <input
                  type="file"
                  id="file"
                  ref={avatarRef}
                  style={{ display: 'none' }}
                  onChange={(e) => onChangeFile(e)}
                />
                <div style={{ marginTop: '25px' }} />
                <div className="form__heading">
                  Profile Bio{' '}
                  <span style={{ fontWeight: 'normal' }}>
                    (This is not a CV, just a simple overview)
                  </span>
                </div>
                <textarea
                  maxLength="1000"
                  className="form__textarea"
                  name="bio"
                  id="bio"
                  ref={register({ required: true, maxLength: 1000 })}
                />
                <div className="form__textarea__character-limit">
                  {watchFields && (
                    <>
                      <span id="chars">
                        {'bio' in watchFields
                          ? watchFields.bio
                            ? watchFields.bio.length
                            : 0
                          : 0}
                      </span>{' '}
                      / 1000
                    </>
                  )}
                </div>
                {errors.bio && (
                  <Error text="Please fill in your profile bio" noMargin />
                )}
                <div style={{ marginTop: '25px' }} />
                <div className="form__heading">
                  What level of roles would you like to see?{' '}
                  <span className="d-block font-italic font-weight-normal">
                    Select the most relevant for you (max 2)
                  </span>
                </div>
                {loadingRoles && <span>Loading roles...</span>}
                {allJoblevels && (
                  <CustomMultiselect
                    options={allJoblevels}
                    onSelect={handleJobChange}
                    onRemove={handleJobChange}
                    displayValue="name"
                    closeOnSelect={false}
                    avoidHighlightFirstOption
                    placeholder="-- Select Roles --"
                    style={jobLevelsMultiSelectStyle}
                    selectionLimit={2}
                  />
                )}
                <div style={{ marginTop: '25px' }} />
                <div className="form__heading">
                  Where would you like to work?
                </div>
                <select
                  className="form__input__border-light"
                  name="location"
                  ref={register({ required: true })}
                >
                  <option disabled selected>
                    -- Select --
                  </option>
                  {allLocations.map((location, index) => (
                    <option value={location.key} key={location.key}>
                      {location.name}
                    </option>
                  ))}
                </select>
                <div style={{ marginTop: '25px' }} />
                <div className="form__heading">
                  Do you require going on the payroll or are you a limited
                  company?
                </div>
                <select
                  className="form__input__border-light"
                  name="payroll_status"
                  ref={register({ required: false })}
                >
                  <option disabled selected>
                    -- Select --
                  </option>
                  <option value="1" key="payroll">
                    Payroll
                  </option>
                  <option value="2" key="limited">
                    Limited Company
                  </option>
                  <option value="3" key="limited">
                    Either
                  </option>
                </select>
                <div style={{ marginTop: '25px' }} />
                <div className="form__heading">How did you hear about us?</div>
                <select
                  className="form__input__border-light"
                  name="hear_us"
                  ref={register({ required: false })}
                >
                  <option disabled selected>
                    -- Select --
                  </option>
                  {hearUs.map((h) => (
                    <option value={h} key={h}>
                      {h.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                    </option>
                  ))}
                </select>
                {watchFields && (
                  <>
                    {(watchFields.hear_us === 'recommendation' ||
                      watchFields.hear_us === 'other') && (
                      <div className="row">
                        <div className="col-12">
                          <span className="txt__fw-600">
                            Please specify (optional)
                          </span>
                        </div>
                        <div className="col-12">
                          <input
                            className="form__input__border-light"
                            type="text"
                            name="hear_us_additional"
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <a
                  href="/#"
                  className="form__btn btn-v2 bg-freelancer d-block"
                  onClick={handleSubmit(editProfile)}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CompleteProfile;
