import React, { useMemo } from 'react';
import Checkbox from 'react-custom-checkbox';
import { getRandomGradient } from '../../../utils/helpers';
import useGroups from '../../hooks/useGroups';

const CustomCheckbox = ({
  id,
  label,
  onChange,
  isSelected,
  checked,
  ...rest
}) => {
  const { groupname } = useGroups();

  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);
  return (
    <Checkbox
      name={label}
      checked={checked}
      value={id}
      onChange={(value) => {
        onChange(value);
      }}
      borderColor="white"
      borderRadius="3px"
      borderWidth="1px"
      label={label}
      icon={
        <span
          className="app-checkbox-icon"
          style={{
            backgroundImage: `${checked ? gradient : ''}`,
          }}
        />
      }
      size={12}
      labelStyle={{
        paddingLeft: '8px',
        fontSize: '14px',
        fontWeight: 400,
      }}
      containerStyle={{
        cursor: 'pointer',
      }}
      {...rest}
    />
  );
};

export default CustomCheckbox;
