import React from 'react'
import DefaultLayout from '../../../../layouts/defaultLayout'
import { BackPanel, Button, Heading } from '../../../../snippets'
import useGroups from '../../../../hooks/useGroups'

export default function CompanyTeamWelcomePage() {
    const {groupname} = useGroups()
  return (
    <DefaultLayout hideSwitchCompany showNavigation={false}>
        <BackPanel isSmall>
            <Heading>You've successfully accepted your team invitation.</Heading>
            <Button as="link" className="spacer-t thick w-full" href={`/${groupname}/login`}>LOGIN</Button>
        </BackPanel>
    </DefaultLayout>
  )
}
