import React, { Fragment } from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function StepCounter({
  steps = 3,
  wrapperClassName,
  stepNumberClassName,
  currentAt = 1,
}) {
  return (
    <div className={generateClassName(["step-counter mb-6", wrapperClassName])}>
      {Array(steps)
        .fill('')
        .map((a, i) => {
          return (
            <Fragment key={i}>
              <div
                className={generateClassName(['step-number',  currentAt === i + 1 ? ' active' : '', stepNumberClassName])}
              >
                {i + 1}
              </div>
            </Fragment>
          );
        })}
    </div>
  );
}
