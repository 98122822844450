import React, { useContext, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GridCaseStudies,
  ProfileDetails,
  SectionProfile,
} from '../../../components';
import DefaultLayout from '../../../layouts/defaultLayout';
import useGroups from '../../../hooks/useGroups';
import useTeams from '../../../hooks/useTeams';
import { GET_CASE_STUDIES } from '../../../../graphql';
import { LINK_GROUP } from '../../../../utils/links';
import CONTENTS_UTIL from '../../../../utils/contents';
import { checkAndModifyPhoneNumber } from '../../../../utils/helpers';
import { AppContext } from '../../../../Store';

const br =
  '<br style="content: \'A\' !important; display: block !important; margin-bottom: 0.5rem !important;" />';

export default function ProfilePage() {
  const { groupname } = useGroups();
  const { user, isAdmin } = useTeams();
  const [state] = useContext(AppContext);

  const [
    getCaseStudies,
    { data: { caseStudies: { data = [] } = {} } = {}, loading, refetch },
  ] = useLazyQuery(GET_CASE_STUDIES, {
    variables: {
      first: 10,
      page: 1,
      where: {
        AND: [
          {
            column: 'AGENCY_ID',
            operator: 'EQ',
            value: Number(state?.activeCompany?.team_id),
          },
        ],
      },
    },
    onError: () => refetch(),
  });

  useEffect(() => {
    getCaseStudies();
  }, [state.activeCompany, getCaseStudies]);

  const contents = useMemo(() => {
    if (!user) {
      return;
    }

    const { agency_name, agency_phone, bio, genres, services, website } =
      user || {};

    return CONTENTS_UTIL.generateDetails(
      [
        CONTENTS_UTIL.generateRowDetails('Agency Name', agency_name ?? '-'),
        CONTENTS_UTIL.generateRowDetails('About Us', bio ?? '-'),
        website ? CONTENTS_UTIL.generateRowDetails('Website', website) : null,
        agency_phone?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Contact Information',
              checkAndModifyPhoneNumber(agency_phone[0]?.attributes?.phone)
            )
          : null,
      ],
      [
        services?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Services',
              services.map(({ name }) => name).join(br)
            )
          : null,
        genres?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Category Experience',
              genres?.length > 0 ? genres.map(({ name }) => name).join(br) : '-'
            )
          : null,
      ]
    );
  }, [user]);

  const caseStudiesData = useMemo(() => {
    return (
      data?.map(({ id, thumbnail, description, title }) => {
        return {
          img: thumbnail ?? null,
          headline: title ?? 'NA',
          info: description ?? '-',
          externalLink: true,
          id: id,
        };
      }) ?? []
    );
  }, [data]);

  return (
    <DefaultLayout showNavigation showSubmenu={groupname === 'agency'} goBack>
      <SectionProfile
        profileImage={user?.avatar ?? '/img/agency-picture-placeholder@2x.jpg'}
        headline="View Profile"
        editURL={isAdmin ? `/${LINK_GROUP.AGENCY}/edit-profile` : null}
        contentFull
        uppercaseAction
      >
        <ProfileDetails contents={contents} />
      </SectionProfile>

      <GridCaseStudies
        type="casestudy"
        heading="Case Studies"
        headerButtonUrl={`/${groupname}/case-studies`}
        headerButtonCopy="Edit Case Studies"
        data={caseStudiesData}
        showMobileButton={groupname === 'agency'}
        titleOnly
      />
    </DefaultLayout>
  );
}
