import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function TablerSimple({
  id = '',
  headers = [],
  tables = [],
  className = '',
  footerComponent = null,
  renderItem = (item) => {},
  ...rest
}) {
  return (
    <div className={generateClassName(['app-tabler-simply', className])}>
      <div className="app-tabler-simply--header app-tabler-simply--row">
        {headers.map((h, i) => (
          <div
            className={generateClassName(
              [
                'app-tabler-simply--column',
                'app-tabler-simply--column-header',
              ] ?? ''
            )}
            key={i}
          >
            {h}
          </div>
        ))}
      </div>
      {tables.map((t, i) => {
        return (
          <div
            key={i}
            className="app-tabler-simply--body app-tabler-simply--row space-t"
          >
            {t.map((c, j) => {
              return (
                <div
                  key={j}
                  className={generateClassName(
                    [
                      'app-tabler-simply--column app-tabler-simply--column-body',
                      j === t.length - 1 ? 'last-column' : '',
                      c === 'hide' ? 'd-none' : '',
                      c === 'child-hide' ? 'child-hide' : '',
                    ] ?? ''
                  )}
                >
                  <div className="tb-body-info" data-name={headers[j]}>
                    <div className="tb-body-info--content">
                      {renderItem(c, j)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      {footerComponent && <div className="tabler-footer"></div>}
    </div>
  );
}
