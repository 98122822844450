import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppTheme } from '../../context/themeAppContext';
import useGroups from '../../hooks/useGroups';
import { Button, FormBlock, FormBlockItem, FormInput } from '../../snippets';
import { CREATE_APPLICANT } from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import { getUser } from '../../../utils/helpers';

export default function OpportunityApply({ id, applicationLink }) {
  const {
    action: { setCloseModal },
  } = useAppTheme();
  const { groupname } = useGroups();
  const history = useHistory();
  const { register, handleSubmit, watch } = useForm();
  const [createApplicant, { loading: loadingApplication }] =
    useMutation(CREATE_APPLICANT);

  const sendApplication = async (data) => {
    let input = {
      user: {
        connect: getUser()?.teamOwner ?? getUser()?.id,
      },
      opportunity: {
        connect: id,
      },
    };

    if (data?.resume?.length) {
      input.resume = data.resume[0];
    }

    if (data?.document?.length) {
      input.document = data.document[0];
    }

    if (data?.reason) {
      input.reason = data.reason;
    }

    toast.dismiss();

    createApplicant({
      variables: {
        input: input,
      },
    })
      .then(() => {
        if (applicationLink) {
          window.open(applicationLink, '_blank');
        }

        setCloseModal();
        toast.success('Application sent succesfully!', { autoClose: 4000 });
        history.push(`/${groupname}/dashboard`);
      })
      .catch((e) =>
        toast.error('Could not send application', { toastId: 'appErr' })
      );
  };

  const watchFields = watch(['reason']);

  return (
    <>
      <h1>Please Confirm Before You Apply!</h1>
      <br />
      <br />
      <FormBlock>
        {groupname === LINK_GROUP.CANDIDATE && (
          <>
            <FormBlockItem label="Do you want to use a different CV? (optional)">
              <FormInput
                type="file"
                fieldClassName=""
                className="upload-field"
                name="resume"
                checkboxChildren="Upload Your CV"
                register={register}
              ></FormInput>
            </FormBlockItem>
            <FormBlockItem label="Additional material that would support your application (optional)">
              <FormInput
                type="file"
                fieldClassName=""
                className="upload-field"
                name="document"
                checkboxChildren="Upload Document"
                register={register}
              ></FormInput>
            </FormBlockItem>
          </>
        )}
        <FormBlockItem label="Send a message straight to the employer">
          <FormInput
            type="textarea"
            name="reason"
            placeholder="What is your elevator pitch? Send a message straight to the employer to let them know what skills and experience you have that make you perfect for this role."
            register={register}
            maxLength="400"
            helpChildren={
              <>
                <span>
                  {'reason' in watchFields
                    ? watchFields.reason
                      ? watchFields.reason.length
                      : 0
                    : 0}
                </span>
                /400
              </>
            }
          ></FormInput>
        </FormBlockItem>
      </FormBlock>
      <FormBlock isInline>
        <FormBlockItem>
          <Button
            type="submit"
            disabled={loadingApplication}
            className="app-btn text--capitalize"
            onClick={handleSubmit(sendApplication)}
          >
            {loadingApplication ? 'Loading...' : 'Apply Now'}
          </Button>
        </FormBlockItem>
        <FormBlockItem>
          <Button
            as="link"
            scheme="outline"
            to={`/${groupname}/edit-profile`}
            className="app-btn text--capitalize"
            onClick={() => setCloseModal()}
            noGradient
          >
            Edit Profile
          </Button>
        </FormBlockItem>
      </FormBlock>
    </>
  );
}
