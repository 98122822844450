import { LINK_GROUP } from '../utils/links';
import {
  LoginPage,
  AgencyDashboard,
  AgencyAccountInformation,
  EditAgencyAccountInformation,
  CaseStudyPage,
  AgencyProfilePage,
  AgencyEditProfilePage,
  ManageTeamPage,
  CaseStudyDetailsPage,
  ForgotPasswordPage,
  AgencyViewCompanyProfilePage,
  EditCaseSudyPage,
  AddCaseStudyPage,
  ResourcesPage,
  OpportunityDetailPage,
  AgencyOpportunityPage,
  OpportunitiesForYouPage,
  DealsPage,
  RegisterAboutAgencyPage,
  RegisterAccount,
  NotFoundPage,
  ResetPasswordPage,
} from '../v2/pages';
import ChangePasswordPage from '../v2/pages/change-password';
import NotificationSettingsPage from '../v2/pages/notification-settings';
import ProfilePrivacyPage from '../v2/pages/profile-privacy';
import {
  AgencyCreateAccountSuccess,
  AgencyVerifyEmail,
  AgencyNetwork,
  AgencyLogout,
  AgencyDummyPage,
  AcceptInvite,
} from '../views/Agency';
import AdminImpersonateAgency from '../views/Agency/AdminImpersonateAgency';
import BlogIndividual from '../views/Freelancer/BlogIndividual';

export const AGENCY_ROUTES = [
  {
    path: 'login',
    component: LoginPage,
    exact: true,
  },
  {
    path: 'logout',
    component: AgencyLogout,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'impersonate/:id',
    exact: true,
    component: AdminImpersonateAgency,
  },
  {
    path: 'register',
    component: RegisterAccount,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'register/complete-profile',
    component: RegisterAboutAgencyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'register/done',
    component: AgencyCreateAccountSuccess,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'verify-email',
    component: AgencyVerifyEmail,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'dashboard',
    component: AgencyDashboard,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'network',
    component: AgencyNetwork,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'case-studies',
    exact: true,
    shouldLogIn: true,
    component: CaseStudyPage,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'case-study/add',
    component: AddCaseStudyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'case-study/:id',
    component: CaseStudyDetailsPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'case-study/edit/:id',
    component: EditCaseSudyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'change-password',
    component: ChangePasswordPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'personal-information',
    component: AgencyDummyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase()],
  },
  {
    path: 'edit-account-information',
    component: EditAgencyAccountInformation,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase()],
  },
  {
    path: 'manage-team',
    component: ManageTeamPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase()],
  },
  {
    path: 'account-information',
    component: AgencyAccountInformation,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'profile',
    component: AgencyProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'edit-profile',
    component: AgencyEditProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase()],
  },
  {
    path: 'profile-privacy',
    component: ProfilePrivacyPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'notification-settings',
    component: NotificationSettingsPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'accept-invite/:token',
    component: AcceptInvite,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'company-profile/:id',
    component: AgencyViewCompanyProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'opportunities',
    component: AgencyOpportunityPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'opportunities/:id',
    component: OpportunityDetailPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'opportunities-for-you',
    component: OpportunitiesForYouPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'resources',
    exact: true,
    shouldLogIn: true,
    component: ResourcesPage,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'resources/:slug',
    exact: true,
    component: BlogIndividual,
  },
  {
    path: 'deals',
    exact: true,
    shouldLogIn: true,
    component: DealsPage,
    users: [LINK_GROUP.AGENCY.toUpperCase(), 'GUEST'],
  },
  {
    path: '404',
    component: NotFoundPage,
  },
];
