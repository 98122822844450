import React, { useMemo } from 'react';
import { RegisterLayer3, StepCounter } from '../../../components';
import DefaultLayout from '../../../layouts/defaultLayout';
import { BackPanel, FormContent, Heading } from '../../../snippets';
import useGroups from '../../../hooks/useGroups';

export default function CompanyCompleteProfilePage() {
  const { groupname } = useGroups();

  const title = useMemo(
    () => (groupname === 'company' ? "You're signed up!" : "You're signed up!"),
    [groupname]
  );

  return (
    <DefaultLayout hideSwitchCompany>
      <BackPanel isSmall>
        <Heading>{title}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={3}></StepCounter>
          <RegisterLayer3
            heading="Personal Information"
            firsLastNameInline={groupname !== 'company'}
            positionOptional={groupname !== 'company'}
            companyOptional={groupname !== 'company'}
          />
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
