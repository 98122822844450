import React, { useCallback, useEffect } from 'react';
import { StepCounter, TeamRegisterLayers3 } from '../../../../components';
import DefaultLayout from '../../../../layouts/defaultLayout';
import { BackPanel, FormContent, Heading } from '../../../../snippets';
import useProfile from '../../../../hooks/useProfile';
import { useForm } from 'react-hook-form';

export default function CompanyTeamRegisterCompletePage() {
  const {
    checkProfileCompleted,
    myProfile,
    loadingProfile: loading,
    editProfile,
    dataImg,
    setDataImageFile,
    setDataImageErrorStatus,
  } = useProfile({ actionImageMandatory: true });

  const { register, handleSubmit, watch, errors, clearErrors, getValues } =
    useForm();
  const watchFields = watch(['description']);

  useEffect(() => {
    checkProfileCompleted();
  }, [myProfile, checkProfileCompleted]);

  const handleOnSubmit = useCallback(
    (e) => {
      if (!dataImg.filepath) {
        setDataImageErrorStatus(true);
      }
      handleSubmit(editProfile)(e);
    },
    [dataImg, editProfile, handleSubmit, setDataImageErrorStatus]
  );

  return (
    <DefaultLayout hideSwitchCompany>
      <BackPanel isSmall>
        <Heading>{"You're signed up!"}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={3}></StepCounter>
          <TeamRegisterLayers3
            heading="Now tell us a little about yourself..."
            onSetDataImage={setDataImageFile}
            setDataImageErrorStatus={setDataImageErrorStatus}
            forms={{
              clearErrors,
              errors,
              handleSubmit: handleOnSubmit,
              loading: loading,
              register,
              watchFields,
              getValues,
            }}
          />
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
