import gql from 'graphql-tag';

export const GET_PROMO = gql`
  query Promo($code: String!) {
    promo(code: $code) {
      name
      code
      description
      type
      value
      created_at
    }
  }
`;

export const GET_ALL_PROMO = gql`
  query Promos($orderBy: [QueryPromosOrderByOrderByClause!]) {
    promos(orderBy: $orderBy) {
      name
      code
      description
      type
      value
      created_at
    }
  }
`;
