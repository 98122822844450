import React, { useMemo } from 'react';
import { getRandomGradient } from '../../../utils/helpers';
import useGroups from '../../hooks/useGroups';
import Modal from 'react-modal';

const TeamMember = ({
  name,
  email,
  pending = false,
  onResend,
  onDelete,
  setShowConfirmModal,
  showConfirmModal,
}) => {
  const { groupname } = useGroups();
  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);

  const customStyles = {
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingLeft: 51,
      paddingRight: 51,
      borderRadius: 36,
      backgroundColor: '#0E0E0E',
      border: 'none',
      paddingBlock: '40px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      zIndex: 2,
    },
  };
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (
    <>
      <div className={`teammember ${onDelete ? '' : 'invite-cont'}`}>
        <div>
          <p className="teammember_name">{name}</p>
          <p className="teammember_email">{email}</p>
        </div>
        <div className="teammember_button-cont">
          {pending && (
            <button
              className="teammember_send"
              style={{
                backgroundImage: `${gradient}`,
              }}
              onClick={onResend}
            >
              Send Again
            </button>
          )}
          {onDelete && (
            <button
              className="teammember_deletebutton"
              onClick={() => setShowConfirmModal(true)}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={showConfirmModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h1 style={{ fontSize: '30px', color: 'white' }}>
            Delete {pending ? 'Invite' : 'Team Member'}
          </h1>
          <p style={{ color: 'white' }}>
            Are you sure you want to delete this{' '}
            {pending ? 'invitation' : 'team member'}?
          </p>
          <div className="d-flex" style={{ gap: '14px', marginTop: '40px' }}>
            <button
              className="teammember_send"
              style={{
                backgroundImage: `${gradient}`,
              }}
              onClick={() => setShowConfirmModal(false)}
            >
              Close
            </button>
            <button className="teammember_deletebutton" onClick={onDelete}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TeamMember;
