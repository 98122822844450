import gql from 'graphql-tag';

export const GET_AGENCY_OPPORTUNITIES = gql`
  query Opportunities(
    $agency: Int
    $trashed: Trashed
    $first: Int
    $where: QueryOpportunitiesWhereWhereConditions
    $hasGenres: OpportunitiesHasGenresWhereConditions
    $hasSkills: OpportunitiesHasSkillsWhereConditions
    $orderBy: [QueryOpportunitiesOrderByOrderByClause!]
    $target: OpportunityTarget
  ) {
    opportunities(
      agency: $agency
      first: $first
      trashed: $trashed
      where: $where
      hasGenres: $hasGenres
      hasSkills: $hasSkills
      orderBy: $orderBy
      target: $target
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        title
        job_number
        view
        is_active
        agency {
          id
          avatar
          company
        }
        genres {
          id
          name
        }
        skills {
          id
          name
        }
        services {
          id
          name
        }
        type
        sub_type
        applicants {
          id
          user(active: true) {
            id
            name
            email
          }
          created_at
        }
        created_at
        updated_at
        featured
        target
      }
    }
  }
`;

export const GET_OPPORTUNITY = gql`
  query Opportunity($id: ID!) {
    opportunity(id: $id) {
      id
      title
      job_number
      description
      is_on_site
      is_remote
      location
      type
      sub_type
      level
      position {
        id
        name
      }
      minimumBudget
      maximumBudget
      compensation_unit
      compensation_currency
      is_compensation_displayed
      follow_up
      is_public
      is_active
      duration
      is_ir35
      application_text
      application_link
      created_by
      updated_by
      created_at
      genres {
        id
        name
      }
      skills {
        id
        name
        pivot {
          must_have
        }
        software
      }
      services {
        id
        name
      }
      industries {
        id
        name
      }
      qualifications {
        id
        name
      }
      agency {
        id
        company
        avatar
      }
      applicants {
        user(active: true) {
          id
          name
          email
          phone
          type
          agency_name
          agency_phone {
            key
            attributes {
              phone
            }
          }
        }
        created_at
      }
      featured
      target
    }
  }
`;

export const GET_ALL_OPPORTUNITIES = gql`
  query AllOpportunities(
    $trashed: Trashed
    $orderBy: [QueryAllOpportunitiesOrderByOrderByClause!]
    $where: QueryAllOpportunitiesWhereWhereConditions
    $hasIndustries: QueryAllOpportunitiesHasIndustriesWhereHasConditions
    $hasSkills: QueryAllOpportunitiesHasSkillsWhereHasConditions
    $hasGenres: QueryAllOpportunitiesHasGenresWhereHasConditions
    $hasQualifications: QueryAllOpportunitiesHasQualificationsWhereHasConditions
    $target: OpportunityTarget
  ) {
    allOpportunities(
      trashed: $trashed
      orderBy: $orderBy
      where: $where
      hasIndustries: $hasIndustries
      hasSkills: $hasSkills
      hasGenres: $hasGenres
      hasQualifications: $hasQualifications
      target: $target
    ) {
      id
      title
      job_number
      description
      type
      sub_type
      is_active
      level
      position {
        id
        name
      }
      agency {
        id
        avatar
        company
      }
      created_at
      genres {
        id
        name
      }
      skills {
        id
        name
      }
      services {
        id
        name
      }
      industries {
        id
        name
      }
      qualifications {
        id
        name
      }
      featured
      target
    }
  }
`;

export const GET_OPPORTUNITIES_FOR_USER = gql`
  query GetOpportunitiesForUser {
    me {
      preferences {
        id
        title
        job_number
        view
        agency {
          id
          avatar
          company
        }
        target
        created_at
      }
    }
  }
`;
