import { useLazyQuery } from '@apollo/react-hooks';
import { CHECK_USER } from '../../graphql';
import { useReducer, useState } from 'react';
import { toast } from 'react-toastify';

function reducer(state, action) {
  switch (action.type) {
    case 'add_function': {
      return {
        ...state,
        onCompleted: action.payload,
      };
    }
    case 'on_error': {
      return {
        ...state,
        onError: action.payload,
      };
    }
    case 'add_data': {
      return {
        ...state,
        resData: action.payload,
      };
    }
    case 'skip_check': {
      return {
        ...state,
        skipCheck: true,
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initState = {
  skipCheck: false,
  resData: null,
  // isUniqueEmail: false,
  onCompleted: (e) => {},
  onError: (e) => {},
};

export default function useEmail() {
  const [state, dispatch] = useReducer(reducer, initState);
  const [errorMessage, setErrorMessage] = useState('Email already exists!');
  const [isUniqueEmail] = useState(false);
  //   const [resData, setResData] = useState(null);
  //   const [functionAfterCheck, setFucntionAfterCheck] = useState()

  const [check, { data, loading, refetch }] = useLazyQuery(CHECK_USER, {
    onCompleted: () => checkUniqueEmail(),
    onError: () => refetch(),
  });

  const checkUniqueEmail = () => {
    if (state.skipCheck) {
      state.onCompleted(state.resData);
      return;
    }
    if (data.checkUser) {
      state.onCompleted(state.resData);
      return;
    }
    state.onError();
    toast.error(errorMessage, {
      toastId: 'emXFrlcr',
      autoClose: 3000,
    });
  };

  const setAfterOnCompletedFN = (fn) => {
    dispatch({ type: 'add_function', payload: fn });
  };

  const setOnErrorFN = (fn) => {
    dispatch({ type: 'on_error', payload: fn });
  };

  const handleCheckEmail = (input, skip = false) => {
    if (skip) {
      dispatch({ type: 'skip_check' });
    }
    dispatch({ type: 'add_data', payload: input });

    check({
      variables: {
        email: input.email,
      },
    });
  };

  return {
    handleCheckEmail,
    checkResult: data,
    onChecking: loading,
    setCheckErrorMessage: setErrorMessage,
    checkErrorMessage: errorMessage,
    isUniqueEmail,
    // resData,
    setAfterOnCompletedFN,
    setOnErrorFN,
  };
}
