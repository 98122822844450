import gql from 'graphql-tag';

export const VIEW_INVITATIONS = gql`
  query ViewInvitation($team_id: Mixed) {
    viewInvitations(
      where: {
        AND: [
          { column: TEAM_ID, operator: EQ, value: $team_id }
          { column: INVITE_STATUS, operator: EQ, value: "pending" }
        ]
      }
    ) {
      id
      email
      invite_status
      updated_at
      first_name
      last_name
    }
  }
`;

export const GET_INVITATION = gql`
  query GetInvitation($token: String!) {
    getInvitation(token: $token) {
      id
      inviter {
        id
        agency_name
        company
        address_1
        city
        state
        country
        zip
        phone
      }
      first_name
      last_name
      email
    }
  }
`;
