import React, { useMemo } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { ProfileDetails, SectionProfile } from '../../../components';
import { LINK_GROUP } from '../../../../utils/links';
import CONTENTS_UTIL from '../../../../utils/contents';
import useTeams from '../../../hooks/useTeams';

export default function ProfilePage() {
  const { user, isAdmin } = useTeams();
  const {
    bio,
    avatar,
    description,
    company,
    address_1,
    address_2,
    city,
    state,
    country,
    zip,
    phone,
    email,
  } = user || {};

  const contents = useMemo(() => {
    return CONTENTS_UTIL.generateDetails([
      CONTENTS_UTIL.generateRowDetails('Profile Bio', bio ?? '-'),
      CONTENTS_UTIL.generateRowDetails(
        'What Sets Your Apart',
        description ?? '-'
      ),
      CONTENTS_UTIL.generateRowDetails('Employer Name', company ?? '-'),
      CONTENTS_UTIL.generateRowDetails(
        'Address',
        CONTENTS_UTIL.generateAddress(
          address_1,
          address_2,
          city,
          state,
          country,
          zip
        )
      ),
      CONTENTS_UTIL.generateRowDetails('Phone Number', phone),
      CONTENTS_UTIL.generateRowDetails('Email Address', email),
    ]);
  }, [
    address_1,
    address_2,
    bio,
    city,
    company,
    country,
    description,
    email,
    phone,
    state,
    zip,
  ]);

  return (
    <DefaultLayout showSubmenu>
      <SectionProfile
        profileImage={avatar}
        headline="View Profile"
        editURL={isAdmin ? `/${LINK_GROUP.EMPLOYER}/edit-profile` : null}
        contentFull
        uppercaseAction
      >
        <ProfileDetails contents={contents} />
      </SectionProfile>
    </DefaultLayout>
  );
}
