import React, { useEffect, useState } from 'react';
import UploadField from '../components/UploadFieldV2';
import UploadNoPreview from '../components/UploadNoPreview';
import styled from 'styled-components';
import _ from 'lodash';

// function mergeAndRemoveNulls(array1, array2) {
//   return array1
//     .map((item, index) => (item !== null ? item : array2[index]))
//     .concat(array2.slice(array1.length))
//     .filter((item) => item !== null);
// }

function mergeAndRemoveNulls(array1, array2) {
  let array1Copy = [...array1]; // Create a copy of array1
  let nullIndex = array1Copy.indexOf(null); // Find the first null index

  array1Copy.forEach((item, index) => {
    if (item === null) {
      // Replace null values in array1Copy with corresponding values from array2
      array1Copy[index] = array2[index];
    } else if (item !== null && array2[index] !== null && nullIndex !== -1) {
      // Replace the first null value in array1Copy with array2's value
      array1Copy[nullIndex] = array2[index];
      nullIndex = array1Copy.indexOf(null); // Update the next null index
    }
  });

  return array1Copy
    .concat(array2.slice(array1.length)) // Add any remaining items from array2
    .filter((item) => item !== null); // Filter out any remaining nulls
}

function updateArrays(array1, array2, file) {
  let nullIndex = array1.indexOf(null);

  // Iterate until no null values are found or the file is placed
  while (nullIndex !== -1) {
    if (array2[nullIndex] === null || array2[nullIndex] === undefined) {
      array2[nullIndex] = file; // Place the file in array2 at the nullIndex
      return array2;
    }
    // Find the next null index in array1
    nullIndex = array1.indexOf(null, nullIndex + 1);
  }

  // If no null value in array1 and its length is less than 3, add the file to array1
  if (array1.length < 3) {
    array2.push(file);
  }

  return array2;
}

export default function EditUpload({
  content,
  setOriginalContent,
  setUpdatedContent,
  updatedContent,
}) {
  console.log(content);
  const [previewImages, setPreviewImages] = useState([null, null, null]);
  const [removedItemsIndex, setRemovedItemsIndex] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);

  const handleRemove = (index) => {
    setRemovedItemsIndex([...removedItemsIndex, index]);
    setOriginalContent((prev) => {
      const newContent = [...prev];
      if (newContent[index] !== null) {
        newContent[index] = null;
      } else if (newContent.length < 3) {
        newContent[index + 1] = null;
      }
      return newContent;
    });
    setPreviewImages((prev) => {
      const newContent = [...prev];
      if (newContent[index] !== null) {
        newContent[index] = null;
      } else if (content.every((item) => item === null)) {
        const notNullIndex = newContent.findIndex(
          (item) => item !== null && item !== undefined
        );
        newContent[notNullIndex] = null;
      }
      return newContent;
    });

    setUpdatedContent((prev) => {
      const newValues = [...prev];
      newValues[index] = null;
      return newValues;
    });
  };

  const handleAdd = (file) => {
    if (file) {
      setPreviewImages((prev) => {
        const newContent = [...prev];
        if (newContent[mergedArray.length] === null) {
          newContent[mergedArray.length] = {
            type: file.type.startsWith('video/') ? 'video' : 'image',
            file: URL.createObjectURL(file),
          };
        } else {
          const nullIndex = newContent.indexOf(null);
          newContent[nullIndex] = {
            type: file.type.startsWith('video/') ? 'video' : 'image',
            file: URL.createObjectURL(file),
          };
        }
        return newContent;
      });

      setUpdatedContent(updateArrays(content, updatedContent, file));

      setOriginalContent((prev) => {
        if (prev.length < 3) {
          const newContent = [...prev, null];
          return newContent;
        } else {
          return prev;
        }
      });
    }
  };

  useEffect(() => {
    setMergedArray(mergeAndRemoveNulls(content, previewImages));
  }, [content, previewImages]);

  const handleUpdate = (index, file) => {
    if (file?.length) {
      setPreviewImages((prev) => {
        const newContent = [...prev];
        newContent[index] = {
          type: file[0].type.startsWith('video/') ? 'video' : 'image',
          file: URL.createObjectURL(file[0]),
        };
        return newContent;
      });
      setUpdatedContent((prev) => {
        const newContent = [...prev];
        newContent[index] = file[0];
        return newContent;
      });
      setOriginalContent((prev) => {
        const newContent = [...prev];
        newContent[index] = null;
        return newContent;
      });
    }
  };

  return (
    <>
      <div className="field-v2__upload">
        {mergedArray.map((file, index) => (
          <UploadHover
            content={file}
            index={index}
            handleUpdate={handleUpdate}
            handleRemove={handleRemove}
            key={_.uniqueId()}
          />
        ))}
        {mergedArray.length < 3 && (
          <UploadCont>
            <UploadNoPreview
              onSetImage={(file) => {
                handleAdd(file);
              }}
              accept={'image/*,video/*'}
            />
          </UploadCont>
        )}
      </div>
      <div className="text__gray text-right mt-2">
        {mergedArray.length ?? 0}
        /3
      </div>
    </>
  );
}

const UploadHover = ({ content, index, handleRemove, handleUpdate }) => {
  const [showUpload, setShowUpload] = useState(false);
  return (
    <>
      {(
        typeof content === 'string'
          ? content?.includes('mp4')
          : content?.type === 'video'
      ) ? (
        content !== null ? (
          <div
            className="up-container mb-12"
            key={index}
            onMouseEnter={() => {
              setShowUpload(true);
            }}
            onMouseLeave={() => {
              setShowUpload(false);
            }}
            onTouchStart={() => {
              setShowUpload(true);
            }}
          >
            <video id="videoPlayer" width="100%" height="300" controls>
              <source
                src={typeof content === 'string' ? content : content.file}
                type="video/mp4"
              ></source>
              Your browser does not support the video tag.
            </video>
            <div className="close-button" onClick={() => handleRemove(index)}>
              <i className="fas fa-times"></i>
            </div>
            <div className={showUpload ? 'upload-hover' : 'hidden'}>
              <UploadField
                key={index}
                onSetImage={(file) => {
                  handleUpdate(index, file);
                }}
                multiple
                accept={'image/*,video/*'}
                length={1}
              />
            </div>
          </div>
        ) : (
          <></>
        )
      ) : content !== null ? (
        <div
          className="up-container mb-12"
          key={index}
          onMouseEnter={() => {
            setShowUpload(true);
          }}
          onMouseLeave={() => {
            setShowUpload(false);
          }}
          onTouchStart={() => {
            setShowUpload(true);
          }}
        >
          <img
            src={typeof content === 'string' ? content : content?.file}
            className="object-fit-cover w-full"
            alt=""
          />
          <div className="close-button" onClick={() => handleRemove(index)}>
            <i className="fas fa-times"></i>
          </div>
          <div className={showUpload ? 'upload-hover' : 'hidden'}>
            <UploadField
              key={index}
              onSetImage={(file) => {
                handleUpdate(index, file);
              }}
              multiple
              accept={'image/*,video/*'}
              length={1}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const UploadCont = styled.div`
  padding-top: 28px;
`;
