export const TYPE_WORK_LOCATION = [
  {
    value: 'ONSITE',
    label: 'On site',
  },
  {
    value: 'REMOTE',
    label: 'Remote',
  },
  {
    value: 'HYBRID',
    label: 'Hybrid',
  },
  {
    value: 'ALL',
    label: 'Any of the above',
  },
];

export const TYPE_WORK_LOCATION_FOR_CONSULTANT = [
  {
    value: 'ONSITE',
    label: 'On site',
  },
  {
    value: 'REMOTE',
    label: 'Remote',
  },
  {
    value: 'HYBRID',
    label: 'Hybrid',
  },
  {
    value: 'ALL',
    label: 'Any of the above',
  },
];

export const TYPE_HEAR_US = [
  { value: 'recommendation', label: 'Recommendation' },
  { value: 'google', label: 'Google' },
  { value: 'trade press', label: 'Trade Press' },
  { value: 'social media', label: 'Social Media' },
  { value: 'other', label: 'Other' },
];

export const TYPE_START_DATE = [
  { value: '1', label: 'As Soon As Possible' },
  { value: '2', label: 'In the next year' },
  { value: '3', label: 'Not sure, just window shopping' },
];

export const TYPE_NOTICE_PERIOD_UNIT = [
  { value: 'Days', label: 'Days' },
  { value: 'Months', label: 'Months' },
];

export const TYPE_RIGHT_TO_WORK = [
  { value: '1', label: 'I Don’t Require A Visa' },
  {
    value: '2',
    label: 'I Require A Visa or Sponsorship Now or Within The Next 3 Years',
  },
];

export const TYPE_PAYROLL_STATUS = [
  { value: '1', label: 'Payroll' },
  { value: '2', label: 'Limited Company' },
  { value: '3', label: 'Either' },
];

export const TYPE_PRONOUNS = [
  { label: 'He/Him', value: 'He/Him' },
  { label: 'She/Her', value: 'She/Her' },
  { label: 'They/Them', value: 'They/Them' },
  { label: 'Ze/Zir/Hir', value: 'Ze/Zir/Hir' },
  { label: 'Xe/Xem', value: 'Xe/Xem' },
];

export const TYPE_SUBJOBTYPES = [
  {
    key: 'FULLTIME',
    name: 'Full-time',
  },
  {
    key: 'PARTTIME',
    name: 'Part-time',
  },
  {
    key: 'BOTH_TIME',
    name: 'Both',
  },
];

export const TYPE_JOBTYPES = [
  {
    key: 'PERMANENT',
    name: 'Permanent',
  },
  {
    key: 'CONSULTANCY',
    name: 'Consultancy',
  },
  {
    key: 'FREELANCE/CONTRACT',
    name: 'Freelance',
  },
  {
    key: 'BOTH',
    name: 'Any of the above',
  },
];

export const TYPE_JOBTYPES_FOR_OPPORTUNITY = [
  {
    key: 'PERMANENT',
    name: 'Permanent',
  },
  {
    key: 'CONSULTANCY',
    name: 'Consultancy',
  },
  {
    key: 'FREELANCE/CONTRACT',
    name: 'Freelance / Contract',
  },
];

export const TYPE_CURRENCY = [
  { value: 'GBP', label: '£ (GBP)' },
  { value: 'EUR', label: '€ (EUR)' },
  { value: 'USD', label: '$ (USD)' },
];

export const TYPE_SALARY_UNIT = [
  { value: 'DAY', label: 'Day Rate', step: 25 },
  { value: 'YEAR', label: 'Annual Salary', step: 100 },
];

export const TYPE_FILTERS = [
  {
    key: 'Media Planning & Buying',
    label: 'Media Planning & Buying',
  },
  {
    key: 'Brand Development',
    label: 'Brand Development',
  },
  {
    key: 'Partnerships, Sponsorships & Events',
    label: 'Partnerships, Sponsorships & Events',
  },
  {
    key: 'Web Development',
    label: 'Web Development',
  },
  {
    key: 'Mobile Development',
    label: 'Mobile Development',
  },
  {
    key: 'Design & Creative',
    label: 'Design & Creative',
  },
  {
    key: 'Video Production',
    label: 'Video Production',
  },
  {
    key: 'SEO',
    label: 'SEO',
  },
  {
    key: 'Social & Content Marketing',
    label: 'Social & Content Marketing',
  },
  {
    key: 'PR',
    label: 'PR',
  },
  {
    key: 'Training & Coaching',
    label: 'Training & Coaching',
  },
  {
    key: 'Ecommerce',
    label: 'Ecommerce',
  },
  {
    key: 'Research & Insight',
    label: 'Research & Insight',
  },
  {
    key: 'Measurement',
    label: 'Measurement',
  },
  {
    key: 'Data Solutions & Analytics',
    label: 'Data Solutions & Analytics',
  },
  {
    key: 'Corporate Social Responsibility',
    label: 'Corporate Social Responsibility',
  },
  {
    key: 'PPC & Social Advertising',
    label: 'PPC & Social Advertising',
  },
];

export const FILTER_WORK_LOCATION = [
  {
    key: 'ONSITE',
    name: 'On site',
  },
  {
    key: 'REMOTE',
    name: 'Remote',
  },
  {
    key: 'HYBRID',
    name: 'Hybrid',
  },
];

export const FILTER_SUBJOBTYPES = [
  {
    key: 'FULLTIME',
    name: 'Full-time',
  },
  {
    key: 'PARTTIME',
    name: 'Part-time',
  },
];

export const FILTER_JOBTYPES = [
  {
    key: 'PERMANENT',
    name: 'Permanent',
  },
  {
    key: 'FREELANCE',
    name: 'Freelance',
  },
];

export const TYPE_ETHNICITIES = [
  'Asian or Asian British',
  'Black, Black British, Caribbean or African',
  'Mixed or multiple ethnic groups',
  'White',
  'Other ethnic group',
  'Prefer not to say',
];

export const TARGET_OPPORTUNITY = [
  { value: 'JOB', label: 'Job Role' },
  { value: 'PROJECT', label: 'Agency Project' },
  { value: 'BOTH', label: 'Both' },
];
