import React from 'react';
import { Redirect } from 'react-router-dom';
import { userVar } from '../../graphql/config';
import { LINK_GROUP } from '../../utils/links';

const LandingPage = () => {
  if (userVar()?.access_token) {
    const dashboard =
      userVar().type === LINK_GROUP.COMPANY.toUpperCase()
        ? `/${LINK_GROUP.COMPANY}/dashboard`
        : `/${LINK_GROUP.FREELANCER}/dashboard`;
    return (
      <Redirect
        to={{
          pathname: dashboard,
          state: { referrer: '/' },
        }}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: `/${LINK_GROUP.FREELANCER}/login`,
        state: { referrer: '/' },
      }}
    />
  );
};

export default LandingPage;
