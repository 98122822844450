import React from 'react';
import styled from 'styled-components';
import { LINK_GROUP } from '../../../utils/links';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CaseStudyCard = ({ data, noEdit }) => {
  const history = useHistory();
  return (
    <Container>
      <Link to={`/${LINK_GROUP.AGENCY}/case-study/${data?.id}`}>
        <img
          src={data?.thumbnail || '/img/cta-placeholder@2x.jpg'}
          alt={data?.title ?? ''}
        />
        <hr />
        <h3 className="name">{data?.title}</h3>
        <p className="title">{data?.description}</p>
      </Link>
      {!noEdit && (
        <button
          className="bg__gradient-1 bg-top bg-cover"
          onClick={() => {
            history.push(`/${LINK_GROUP.AGENCY}/case-study/edit/${data?.id}`);
          }}
        >
          Edit
        </button>
      )}
    </Container>
  );
};

export default CaseStudyCard;

const Container = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 257px;
    border-radius: 1px;
  }
  button {
    border-radius: 12px;
    width: 122px;
    height: 37px;
    border: none;
    font-size: 14px;
    color: white;
    font-weight: 700;
  }
  hr {
    border: 1px solid #929292;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .title {
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 20.23px;
    margin-bottom: 14px;
  }
  .name {
    color: white;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 2px;
  }
  @media (min-width: 768px) {
    width: 249px;
    img {
      height: 200px;
    }
    .title {
      margin-bottom: 15px;
    }
  }
  @media (min-width: 1024px) {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;
