import React from 'react';

export default function CheckedIcon({ ...props }) {
  return (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" {...props}>
      <path
        d="M11 1.54688L10.6016 1.94531L4.64844 7.875L4.25 8.27344L3.85156 7.875L0.898438 4.92188L0.5 4.52344L1.27344 3.72656L1.67188 4.125L4.25 6.67969L9.80469 1.14844L10.2031 0.75L11 1.54688Z"
        fill="white"
      />
    </svg>
  );
}
