import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import jquery from '../../styles/js/app';
import { UPDATE_PROFILE } from '../../graphql';
import { userVar } from '../../graphql/config';
import { getUser, saveUserLocalstorage } from '../../utils/helpers';
import MyAccountLayout from './layout/MyAccountLayout';

const ProfilePrivacy = () => {
  useEffect(() => {
    jquery();
  }, []);

  const [privacy, setPrivacy] = useState(getUser().privacy);
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const handlePrivacy = ({ state, field }) => {
    setPrivacy(field);
    updateProfile({
      variables: {
        input: {
          privacy: field,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error('Something went wrong.', { autoClose: 5000 });
      });
  };

  return (
    <MyAccountLayout>
      <div className="black-card black-card__full mb-50 d-flex flex-column form-agency flex-lg-row">
        <div className="row justify-content-between col-md-9 col-12">
          <h6
            style={{
              fontSize: '14px',
              color: 'white',
              fontWeight: 700,
              margin: 0,
            }}
          >
            Manage your profile status
          </h6>
          <div className="row gap-3">
            <div
              style={{
                marginRight: '18px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomRadio
                type="radio"
                name="profile-privacy"
                id="public"
                checked={privacy !== 'INACTIVE'}
                onChange={({ currentTarget }) => {
                  const { checked } = currentTarget;

                  handlePrivacy({
                    state: checked ? true : false,
                    field: 'ACTIVE',
                  });
                }}
              />
              <CustomLabel htmlFor="public">Public</CustomLabel>
            </div>
            <div
              style={{
                marginRight: '18px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomRadio
                type="radio"
                name="profile-privacy"
                id="private"
                checked={privacy === 'INACTIVE'}
                onChange={({ currentTarget }) => {
                  const { checked } = currentTarget;

                  handlePrivacy({
                    state: checked ? false : true,
                    field: 'INACTIVE',
                  });
                }}
              />
              <CustomLabel htmlFor="private">Private</CustomLabel>
            </div>
          </div>
        </div>
      </div>
    </MyAccountLayout>
  );
};
export default ProfilePrivacy;

const CustomRadio = styled.input`
  margin-right: 5px !important;
  width: 12px !important;
  height: 12px !important;
  border: none !important;
  border-radius: 3px !important;
  outline: none;
  background: #dadada;
  &:checked {
    background: #71bfb2;
  }
  &:checked:before {
    font-family: 'Font Awesome 5 Free' !important;
    content: '\f00c' !important;
    padding-right: 0;
    vertical-align: middle;
    font-weight: 600;
    font-size: 9px;
    background: unset !important;
    color: #ffffff !important;
    width: auto !important;
    height: auto !important;
    margin: unset !important;
    border-radius: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CustomLabel = styled.label`
  color: #929292;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;
