import React, { useEffect, useState, useContext, useRef } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_INDUSTRIES,
  GET_SKILLS,
  GET_GENRES,
  GET_QUALIFICATIONS,
  GET_ALL_JOB_LEVELS,
  ADD_OPPORTUNITY,
  GET_TEAM_USERS,
  GET_CURRENT_CREDITS,
  GET_POSITIONS,
} from '../../../../graphql';
import {
  TARGET_OPPORTUNITY,
  TYPE_CURRENCY,
  TYPE_JOBTYPES_FOR_OPPORTUNITY,
  TYPE_SALARY_UNIT,
  TYPE_SUBJOBTYPES,
  TYPE_WORK_LOCATION,
} from '../../../placeholders/consultant/types';
import { LoadingSection, SectionForm, Switch } from '../../../components';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../Store';
import { useAppTheme } from '../../../context/themeAppContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { arrToDropdownSelectionV2 } from '../../../../utils/helpers';
import { SearchBox } from '@mapbox/search-js-react';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { LINK_GROUP } from '../../../../utils/links';

export default function OpportunityCreatePage() {
  const [state] = useContext(AppContext);
  const {
    action: { setOpenModal, setCloseModal },
  } = useAppTheme();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    clearErrors,
    setValue,
    getValues,
  } = useForm();
  const multiselectIndustryExperienceRef = useRef(null);
  const multiselectSkillRef = useRef(null);
  const multiselectTopSkillRef = useRef(null);
  const multiselectSoftwareSkillRef = useRef(null);
  const multiselectTopSoftwareSkillRef = useRef(null);
  const multiselectQualificationRef = useRef(null);
  const multiselectQualificationMustHaveRef = useRef(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSoftwareSkills, setSelectedSoftwareSkills] = useState([]);
  const [topSkills, setTopSkills] = useState([]);
  const [topSoftwareSkills, setTopSoftwareSkills] = useState([]);
  const [selectedIndustryQualifications, setSelectedIndustryQualifications] =
    useState([]);
  const [mustHaveQualifications, setMustHaveQualifications] = useState([]);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [postDate, setPostDate] = useState(new Date());
  const [isUnpublished, setIsUnpublished] = useState(false);
  const [salaryStep, setSalaryStep] = useState(1000);
  const [defaultFollowUpMessage, setDefaultFollowUpMessage] = useState('');
  const [description, setDescription] = useState(null);
  const [location, setLocation] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [level, setLevel] = useState(null);
  const [reset, setReset] = useState(false);

  const [
    getCreditBalance,
    { loading, data: { currentCredit: { balance } = {} } = {} },
  ] = useLazyQuery(GET_CURRENT_CREDITS, {
    variables: { team_id: state?.activeCompany?.team_id },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (balance < 1) {
        setOpenModal(() => <BuyBalanceModal />);
      } else {
        setOpenModal(() => <PostOpportunityModal />);
      }
    },
  });

  const [getTeamUsers, { data: { viewUserTeam: teamList } = {} }] =
    useLazyQuery(GET_TEAM_USERS, {
      variables: {
        team_id:
          state?.activeCompany?.role === 'admin'
            ? Number(state?.activeCompany?.team_id)
            : Number(state?.activeCompany?.user_id),
      },
    });

  useEffect(() => {
    if (state?.activeCompany) {
      getCreditBalance();
      getTeamUsers();
      setDefaultFollowUpMessage(
        `Thank you for your interest on our role at ${state.activeCompany.user.company}. We have got your application and we will review your details and contact you shortly if we'd like to meet you for an interview.`
      );
    }
  }, [state, getCreditBalance, getTeamUsers]);

  const {
    data: { industries: allIndustries } = {},
    loading: loadingIndustries,
  } = useQuery(GET_INDUSTRIES);

  const { data: { allJoblevels } = {}, loading: loadingLevels } =
    useQuery(GET_ALL_JOB_LEVELS);

  const [getSkills, { data: skillsData, loading: loadingSkills }] =
    useLazyQuery(GET_SKILLS, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get skills', { toastId: 'closeGetSkillsErr' }),
    });

  const [
    getQualifications,
    { data: qualificationsData, loading: loadingQualifications },
  ] = useLazyQuery(GET_QUALIFICATIONS, {
    variables: {
      industries: [selectedIndustry],
    },
    onError: () =>
      toast.error('Could not get qualifications', {
        toastId: 'closeGetQualificationsErr',
      }),
  });

  const [getGenres, { data: genresData, loading: loadingGenres }] =
    useLazyQuery(GET_GENRES, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get genres', { toastId: 'closeGetGenresErr' }),
    });

  const [
    getPositions,
    { data: { positions = [] } = {}, loading: loadingPositions },
  ] = useLazyQuery(GET_POSITIONS, {
    variables: {
      industries: [selectedIndustry],
      jobLevels: [getValues('level')],
    },
    onError: () =>
      toast.error('Could not get positions', {
        toastId: 'closeGetPositionsErr',
      }),
  });

  useEffect(() => {
    if (selectedIndustry) {
      getSkills();
      getGenres();
      getQualifications();
    }
  }, [selectedIndustry, getSkills, getGenres, getQualifications]);

  useEffect(() => {
    if (selectedIndustry && level) {
      setReset(true);
      getPositions();
    }
  }, [selectedIndustry, level, getPositions, setReset]);

  useEffect(() => {
    let industry = null;

    if (state?.activeCompany?.user?.industries && allIndustries) {
      let industries = state.activeCompany.user.industries;

      if (industries.length === 0) {
        [industry] = allIndustries?.filter((industry) => industry.id === '1');
        setSelectedIndustry(industry.id);
      } else {
        setSelectedIndustry(industries[0].id);
      }
    }
  }, [state, allIndustries]);

  const handleInterestedChange = (value) => {
    setSelectedIndustry(value);
    setSelectedSkills([]);
    setTopSkills([]);
    setSelectedSoftwareSkills([]);
    setTopSoftwareSkills([]);
    setSelectedIndustryExperience([]);
    setSelectedIndustryQualifications([]);
    setMustHaveQualifications([]);
    setReset(true);
  };

  const handleSkillsChange = (selectedList, _) => {
    const skillIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedSkills(skillIds);
    setTopSkills(topSkills.filter((skillId) => skillIds.includes(skillId)));
  };

  const handleTopSkillsChange = (selectedList, _) => {
    setTopSkills(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleSoftwareSkillsChange = (selectedList, _) => {
    const skillIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedSoftwareSkills(skillIds);
    setTopSoftwareSkills(
      topSoftwareSkills.filter((skillId) => skillIds.includes(skillId))
    );
  };

  const handleTopSoftwareSkillsChange = (selectedList, _) => {
    setTopSoftwareSkills(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleIndustryQualificationsChange = (selectedList, _) => {
    const genreIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedIndustryQualifications(genreIds);
    setMustHaveQualifications(
      mustHaveQualifications.filter((genreId) => genreIds.includes(genreId))
    );
  };

  const handleMustHaveQualificationsChange = (selectedList, _) => {
    setMustHaveQualifications(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));

  const confirmOpportunity = (data) => {
    if (
      selectedIndustry?.includes(2) &&
      selectedIndustryQualifications.length === 0
    ) {
      return;
    }

    setOpenModal(() => <PostOpportunityModal data={data} />);
  };

  const BuyBalanceModal = () => {
    return (
      <div style={{ padding: '25px' }}>
        <h1>Insufficient Balance!</h1>
        <p>Your current balance is {balance} job posts</p>
        <br />
        <br />
        <Button
          as="link"
          scheme="outline"
          onClick={() => setCloseModal()}
          to={`/${LINK_GROUP.EMPLOYER}/manage-credits`}
          className="mt-4 app-btn--text-normal text--capitalize"
        >
          Buy More Job Posts
        </Button>
      </div>
    );
  };

  const PostOpportunityModal = (data = null) => {
    const { data: opportunityData } = data;

    const [addOpportunity, { loading: loadingSubmit }] = useMutation(
      ADD_OPPORTUNITY,
      {
        onCompleted: () => {
          toast.success('Opportunity created successfully');
          setCloseModal();
          history.push(`/${LINK_GROUP.EMPLOYER}/dashboard`);
        },
        onError: (err) => {
          console.error(err);
          toast.dismiss();
          toast.error('Something went wrong!', { toastId: 'resErr' });
        },
      }
    );

    const createOpportunity = (data) => {
      toast.dismiss();

      if (data?.location === 'HYBRID' || data?.location === 'ALL') {
        data = {
          ...data,
          is_on_site: true,
          is_remote: true,
        };
      } else if (data?.location === 'ONSITE') {
        data = {
          ...data,
          is_on_site: true,
          is_remote: false,
        };
      } else if (data?.location === 'REMOTE') {
        data = {
          ...data,
          is_on_site: false,
          is_remote: true,
        };
      }

      const combinedSkills = [...selectedSkills, ...selectedSoftwareSkills];
      const combinedTopSkills = [...topSkills, ...topSoftwareSkills];

      data = {
        ...data,
        target: TARGET_OPPORTUNITY[0].value,
        description: description,
        location: location,
        job_number: postDate.toLocaleDateString('en-GB'),
        is_active: !isUnpublished,
        type: data.considering,
        sub_type: data.workHour,
        is_public: true,
        industries: {
          sync: [selectedIndustry],
        },
        position: {
          connect: data.position,
        },
        skills: {
          sync: combinedSkills.map((skillId) => {
            return {
              id: skillId,
              must_have: combinedTopSkills.includes(skillId),
            };
          }),
        },
        qualifications: {
          sync: selectedIndustryQualifications.map((qualificationId) => {
            return {
              id: qualificationId,
              must_have: mustHaveQualifications.includes(qualificationId),
            };
          }),
        },
        genres: {
          sync: selectedIndustryExperience,
        },
        agency: {
          connect: state?.activeCompany?.team_id,
        },
        minimumBudget: parseFloat(data.minimumBudget),
        maximumBudget: parseFloat(data.maximumBudget),
        is_ir35: parseInt(data.is_ir35),
        duration: parseInt(data.duration),
        created_by: parseInt(
          data.posting_user && data.posting_user !== -1
            ? data.posting_user
            : state?.activeCompany?.user_id
        ),
        updated_by: parseInt(
          data.posting_user && data.posting_user !== -1
            ? data.posting_user
            : state?.activeCompany?.user_id
        ),
      };

      delete data.considering;
      delete data.workHour;
      delete data.posting_user;

      addOpportunity({
        variables: {
          input: data,
        },
      });
    };

    return (
      <div style={{ padding: '25px' }}>
        <Heading>This New Opportunity Will Cost 1 Job Post</Heading>
        <br />
        <p>Your current balance is {balance} job posts</p>
        <br />
        <FormBlock isInline>
          <FormBlockItem>
            <Button
              type="submit"
              disabled={loadingSubmit}
              className="app-btn text--capitalize"
              onClick={() => {
                if (opportunityData) {
                  createOpportunity(opportunityData);
                } else {
                  setCloseModal();
                }
              }}
            >
              Continue
            </Button>
          </FormBlockItem>
          <FormBlockItem>
            <Button
              transparent
              as="link"
              scheme="outline"
              onClick={() => setCloseModal()}
              to={`/${LINK_GROUP.EMPLOYER}/manage-credits`}
              className="app-btn text--capitalize"
            >
              Buy More Job Posts
            </Button>
          </FormBlockItem>
        </FormBlock>
      </div>
    );
  };

  const watchFields = watch(['considering', 'follow_up']);

  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionForm
          actionComponent={
            <Text>
              Fill out the details of the role which will be shown to our
              network of candidates.
            </Text>
          }
          headline="Post New Job Opportunity"
        >
          <form onSubmit={handleSubmit(confirmOpportunity)}>
            <FormBlock>
              <FormBlockItem className="mt-0" label={'Status'}>
                <div
                  className=" d-flex"
                  style={{ alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Text
                    as="span"
                    className={!isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(false)}
                    style={{
                      cursor: 'pointer',
                      marginRight: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Published
                  </Text>
                  <Switch
                    onChange={(e) => {
                      setIsUnpublished(!isUnpublished);
                    }}
                    sameActive
                    isChecked={isUnpublished}
                  />
                  <Text
                    as="span"
                    className={isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(true)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Unpublished
                  </Text>
                </div>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Job Title'}>
                <FormInput
                  isRequired
                  type="text"
                  register={register}
                  name="title"
                  clearErrors={clearErrors}
                  error={errors.title ? 'Please enter title' : ''}
                  showErrorText
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Date Posted'}>
                <DatePicker
                  onChange={setPostDate}
                  value={postDate}
                  className="form__input date"
                  format="dd/M/y"
                  name="job_number"
                  required={false}
                  clearIcon={null}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
              </FormBlockItem>

              <FormBlockItem label={'Professional Discipline'}>
                {loadingIndustries && (
                  <span>Loading Professional Discipline...</span>
                )}
                {allIndustries && (
                  <FormInput
                    isRequired
                    type="select"
                    options={arrToDropdownSelectionV2(allIndustries) ?? []}
                    selectSetting={{
                      onSelect: handleInterestedChange,
                    }}
                    placeholder="Select Professional Discipline"
                  ></FormInput>
                )}
              </FormBlockItem>

              <FormBlockItem label={'Category Experience'}>
                {loadingGenres && <span>Loading experience...</span>}
                {genresData && (
                  <CustomMultiselect
                    className={
                      submitted && selectedIndustryExperience.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      genresData?.genres?.map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                    }
                    onSelect={handleIndustryExperienceChange}
                    onRemove={handleIndustryExperienceChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Category Experience"
                    ref={multiselectIndustryExperienceRef}
                  />
                )}
                {submitted && selectedIndustryExperience.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select category experience'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Job Location'}>
                <SearchBox
                  accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                  placeholder="Please enter location"
                  options={{
                    types: ['place', 'district', 'region'],
                  }}
                  onSuggestError={() => {
                    toast.dismiss();
                    toast.error('Failed to fetch address!', {
                      toastId: 'resErr',
                    });
                  }}
                  onRetrieve={(value) => {
                    const place =
                      value?.features[0]?.properties?.full_address ??
                      value?.features[0]?.properties?.place_formatted;
                    setLocation(place);
                  }}
                  theme={{
                    cssText: 'height: 43px;',
                    icons: {
                      addressMarker: '',
                      close: '',
                      search: '',
                    },
                    variables: {
                      border: 'none',
                      borderRadius: '9px',
                      boxShadow: 'none',
                      color: '#0e0e0e',
                      fontFamily: 'Jost, Arial, Helvetica, sans-serif',
                      fontWeight: 500,
                      // lineHeight: 2.6,
                      // padding: '5px 21px',
                      unit: 16,
                    },
                  }}
                  value={location}
                />
              </FormBlockItem>

              <FormBlockItem label={'How do you work?'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="location"
                  options={TYPE_WORK_LOCATION}
                  placeholder="Select Location"
                  error={errors.location ? 'Please select job location' : ''}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Job Description'}>
                <FormInput
                  isRequired
                  type="wysiwyg"
                  name="description"
                  error={
                    submitted && (!description || description?.length === 0)
                      ? 'Please enter a description'
                      : null
                  }
                  showErrorText
                  maxLength={0}
                  clearErrors={clearErrors}
                  setValue={setDescription}
                  fieldClassName={`${
                    submitted && (!description || description?.length === 0)
                      ? 'error-state state-error'
                      : ''
                  }`}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Type of Role'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="considering"
                  options={TYPE_JOBTYPES_FOR_OPPORTUNITY.map(
                    ({ key, name }) => ({
                      value: key,
                      label: name,
                    })
                  )}
                  placeholder="Select type of role"
                  selectSetting={{
                    onSelect: (value) => {
                      setValue('considering', value);
                    },
                  }}
                  error={errors.considering ? 'Please select type of role' : ''}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Working Hours'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="workHour"
                  options={TYPE_SUBJOBTYPES.map(({ key, name }) => ({
                    value: key,
                    label: name,
                  }))}
                  placeholder="Select Working Hour"
                  error={errors.workHour ? 'Please select working hours' : ''}
                ></FormInput>
              </FormBlockItem>

              {'considering' in watchFields &&
                watchFields.considering ===
                  TYPE_JOBTYPES_FOR_OPPORTUNITY[2].key && (
                  <>
                    <FormBlockItem label={'What is the duration'}>
                      <FormInput
                        type="select"
                        register={register}
                        clearErrors={clearErrors}
                        name="duration"
                        options={[1, 2, 3, 4, 5, 6, 7, 13]?.map((val) => ({
                          value: val,
                          label: `${val <= 6 ? val : val - 1} month${
                            val === 1 ? '' : 's'
                          }${val <= 6 ? '' : '+'}`,
                        }))}
                        placeholder="Select duration"
                      ></FormInput>
                    </FormBlockItem>

                    <FormBlockItem label={'Is this role inside IR35'}>
                      <FormInput
                        type="radio"
                        register={register}
                        clearErrors={clearErrors}
                        name="is_ir35"
                        id="is_ir35_1"
                        defaultValue={1}
                        checkboxChildren={
                          <label htmlFor="is_ir35_1">Yes</label>
                        }
                      ></FormInput>
                      <FormInput
                        type="radio"
                        register={register}
                        clearErrors={clearErrors}
                        name="is_ir35"
                        id="is_ir35_0"
                        defaultValue={0}
                        checkboxChildren={<label htmlFor="is_ir35_0">No</label>}
                      ></FormInput>
                      <FormInput
                        type="radio"
                        register={register}
                        clearErrors={clearErrors}
                        name="is_ir35"
                        id="is_ir35_2"
                        defaultValue={2}
                        checkboxChildren={
                          <label htmlFor="is_ir35_2">Not sure</label>
                        }
                      ></FormInput>
                    </FormBlockItem>
                  </>
                )}

              <FormBlockItem label={'Experience Level'}>
                {loadingLevels && <span>Loading levels...</span>}
                {allJoblevels && (
                  <FormInput
                    isRequired
                    type="select"
                    register={register}
                    clearErrors={clearErrors}
                    name="level"
                    options={
                      allJoblevels?.map(({ key, name }) => ({
                        value: key,
                        label: name,
                      })) ?? []
                    }
                    placeholder="Select Experience Level"
                    selectSetting={{
                      onSelect: (value) => {
                        setValue('level', value);
                        setLevel(value);
                        getPositions();
                        setReset(true);
                      },
                    }}
                    error={errors.level ? 'Please select experience level' : ''}
                  ></FormInput>
                )}
              </FormBlockItem>

              <FormBlockItem label={'Position Sought'}>
                {loadingPositions && <span>Loading positions...</span>}
                {positions && (
                  <FormInput
                    type="select"
                    register={register}
                    clearErrors={clearErrors}
                    name="position"
                    options={
                      positions?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) ?? []
                    }
                    placeholder="Select position sought"
                    selectSetting={{
                      onSelect: (value) => {
                        setValue('position', value);
                      },
                      reset,
                      setReset,
                    }}
                    error={
                      errors.position ? 'Please select position sought' : ''
                    }
                  ></FormInput>
                )}
              </FormBlockItem>

              <FormBlockItem label={'Skills Required'}>
                {loadingSkills && <span>Loading skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    className={
                      submitted && selectedSkills.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      skillsData?.skills
                        ?.filter(({ software }) => !software)
                        .map(({ id, name }) => ({
                          key: id,
                          name: name,
                        })) ?? []
                    }
                    onSelect={handleSkillsChange}
                    onRemove={handleSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Skills"
                    ref={multiselectSkillRef}
                  />
                )}
                {submitted && selectedSkills.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select required skills'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              {selectedSkills?.length > 0 && (
                <FormBlockItem label={'Skills Must Haves (optional)'}>
                  {loadingSkills && <span>Loading skills...</span>}
                  {skillsData && (
                    <CustomMultiselect
                      options={
                        skillsData?.skills
                          ?.filter(({ id }) => selectedSkills.includes(id))
                          .map(({ id, name }) => ({
                            key: id,
                            name: name,
                          })) ?? []
                      }
                      onSelect={handleTopSkillsChange}
                      onRemove={handleTopSkillsChange}
                      displayValue="name"
                      closeOnSelect={true}
                      avoidHighlightFirstOption
                      placeholder="Skills"
                      selectionLimit={3}
                      ref={multiselectTopSkillRef}
                    />
                  )}
                </FormBlockItem>
              )}

              <FormBlockItem
                label={`Software Skills ${
                  selectedIndustry?.includes(2) ? '' : '(optional)'
                }`}
              >
                {loadingSkills && <span>Loading software skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    className={
                      selectedIndustry?.includes(2) &&
                      selectedSoftwareSkills.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      skillsData?.skills
                        ?.filter(({ software }) => software)
                        .map(({ id, name }) => ({
                          key: id,
                          name: name,
                        })) ?? []
                    }
                    onSelect={handleSoftwareSkillsChange}
                    onRemove={handleSoftwareSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Software Skills"
                    ref={multiselectSoftwareSkillRef}
                  />
                )}
                {selectedIndustry?.includes(2) &&
                  selectedSoftwareSkills.length === 0 && (
                    <FormFieldAddOns
                      style={{ marginTop: 0 }}
                      error={'Please select software skills'}
                      showWarnError={true}
                    />
                  )}
              </FormBlockItem>

              {selectedSoftwareSkills?.length > 0 && (
                <FormBlockItem label={'Software Skills Must Haves (optional)'}>
                  {loadingSkills && <span>Loading software skills...</span>}
                  {skillsData && (
                    <CustomMultiselect
                      options={
                        skillsData?.skills
                          ?.filter(({ id }) =>
                            selectedSoftwareSkills.includes(id)
                          )
                          .map(({ id, name }) => ({
                            key: id,
                            name: name,
                          })) ?? []
                      }
                      onSelect={handleTopSoftwareSkillsChange}
                      onRemove={handleTopSoftwareSkillsChange}
                      displayValue="name"
                      closeOnSelect={true}
                      avoidHighlightFirstOption
                      placeholder="Software Skills"
                      selectionLimit={3}
                      ref={multiselectTopSoftwareSkillRef}
                    />
                  )}
                </FormBlockItem>
              )}

              <FormBlockItem
                label={`Qualifications ${
                  selectedIndustry?.includes(2) ? '' : '(optional)'
                }`}
              >
                {loadingQualifications && (
                  <span>Loading qualifications...</span>
                )}
                {qualificationsData && (
                  <CustomMultiselect
                    className={
                      selectedIndustry?.includes(2) &&
                      selectedIndustryQualifications.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      qualificationsData?.qualifications?.map(
                        ({ id, name }) => ({
                          key: id,
                          name: name,
                        })
                      ) ?? []
                    }
                    onSelect={handleIndustryQualificationsChange}
                    onRemove={handleIndustryQualificationsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Qualifications"
                    ref={multiselectQualificationRef}
                  />
                )}
                {selectedIndustry?.includes(2) &&
                  selectedIndustryQualifications.length === 0 && (
                    <FormFieldAddOns
                      style={{ marginTop: 0 }}
                      error={'Please select qualifications'}
                      showWarnError={true}
                    />
                  )}
              </FormBlockItem>

              {selectedIndustryQualifications?.length > 0 && (
                <FormBlockItem label={'Qualifications Must Haves (optional)'}>
                  {loadingQualifications && (
                    <span>Loading qualifications...</span>
                  )}
                  {qualificationsData && (
                    <CustomMultiselect
                      options={
                        qualificationsData?.qualifications
                          ?.filter(({ id }) =>
                            selectedIndustryQualifications.includes(id)
                          )
                          .map(({ id, name }) => ({
                            key: id,
                            name: name,
                          })) ?? []
                      }
                      onSelect={handleMustHaveQualificationsChange}
                      onRemove={handleMustHaveQualificationsChange}
                      displayValue="name"
                      closeOnSelect={true}
                      avoidHighlightFirstOption
                      placeholder="Qualifications"
                      selectionLimit={3}
                      ref={multiselectQualificationMustHaveRef}
                    />
                  )}
                </FormBlockItem>
              )}

              <FormBlockItem label={'Salary / Budget'}>
                <FormInput
                  type="checkbox"
                  register={register}
                  clearErrors={clearErrors}
                  name="is_compensation_displayed"
                  defaultChecked
                  checkboxChildren={<p>Show Compensation</p>}
                ></FormInput>

                <FormBlock isInline>
                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="select"
                      register={register}
                      clearErrors={clearErrors}
                      name="compensation_currency"
                      defaultValue="GBP"
                      options={TYPE_CURRENCY}
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="number"
                      register={register}
                      clearErrors={clearErrors}
                      name="minimumBudget"
                      step={salaryStep}
                      error={
                        errors.minimumBudget
                          ? 'Please enter minimum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="number"
                      register={register}
                      clearErrors={clearErrors}
                      name="maximumBudget"
                      step={salaryStep}
                      error={
                        errors.maximumBudget
                          ? 'Please enter maximum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>
                </FormBlock>

                <FormBlock isInline>
                  {TYPE_SALARY_UNIT.map(({ value, label, step }, index) => {
                    return (
                      <FormBlockItem key={index}>
                        <FormInput
                          isRequired
                          type="radiobutton"
                          register={register}
                          clearErrors={clearErrors}
                          name="compensation_unit"
                          defaultValue={value}
                          defaultChecked={value === TYPE_SALARY_UNIT[1].value}
                          checkboxChildren={label}
                          onChange={() => {
                            setSalaryStep(step);
                          }}
                        ></FormInput>
                      </FormBlockItem>
                    );
                  })}
                </FormBlock>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Follow Up Message'}>
                <Text
                  className="mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Add a message to your applicants to let them know you have
                  received their application and/or add more information about
                  the recruitment process.
                </Text>
                <FormInput
                  isRequired
                  type="textarea"
                  register={register}
                  clearErrors={clearErrors}
                  name="follow_up"
                  defaultValue={defaultFollowUpMessage}
                  helpChildren={
                    <>
                      <span>
                        {'follow_up' in watchFields
                          ? watchFields.follow_up
                            ? watchFields.follow_up.length
                            : 0
                          : 0}
                      </span>{' '}
                      Characters
                    </>
                  }
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Link to ATS'}>
                <FormInput
                  type="text"
                  register={register}
                  clearErrors={clearErrors}
                  name="application_link"
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Team User'}>
                <FormInput
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="posting_user"
                  options={
                    teamList
                      ?.filter(({ team }) => team !== null)
                      ?.map(({ user_id, team }) => ({
                        value: user_id,
                        label: `${team?.first_name} ${team?.last_name}`,
                      })) ?? []
                  }
                  placeholder="Select Team"
                ></FormInput>
              </FormBlockItem>
            </FormBlock>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full"
                size="xxl"
                disabled={balance < 1}
                onClick={() => {
                  setSubmitted(true);
                }}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </form>
        </SectionForm>
      )}
    </DefaultLayout>
  );
}
