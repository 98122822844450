import React from 'react';
import FormFieldAddOns from '../FormFieldAddOns/FormFieldAddOns';
import { FileUploader, UploadContainer } from '../../components';
import { generateClassName } from '../../../utils/helpers';

export default function FormFieldUpload({
  error,
  onError = () => {},
  setImg = () => {},
  showWarnError = true,
  square = false,
  helpText,
  isFile = false,
  noOutline = false,
  center = false,
  finished = false,
  setFinished = () => {},
  maxFileSize = null,
  imageRounded = false,
}) {
  return (
    <>
      <div className={generateClassName(['field', center ? 'd-flex' : ''])}>
        <UploadContainer
          isError={error ? true : false}
          className="aspect-ratio-rectangle"
          fixSize={square}
          noOutline={noOutline}
        >
          <FileUploader
            finished={finished}
            setFinished={setFinished}
            setOnError={onError}
            onSetImage={setImg}
            smallSize={!square}
            noFrame={true}
            helpText={helpText}
            isImage={!isFile}
            isError={error ? true : false}
            maxFileSize={maxFileSize}
            imageRounded={!isFile && imageRounded}
          />
        </UploadContainer>
      </div>
      <FormFieldAddOns error={error} showWarnError={showWarnError} />
    </>
  );
}
