import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IconClose } from '../../../components/Icons';
import CustomMultiselect from '../CustomMultiselect';

export const style = {
  chips: {
    display: 'none',
  },
  searchBox: {
    border: 'none',
    background: 'white',
    borderRadius: '9px',
    paddingBlock: 0,
    height: '43px',
  },
  multiselectContainer: {
    color: '#1E1E1E',
  },
  inputField: {
    height: '43px',
    margin: 0,
    fontWeight: 500,
  },
};

const MultiSelect = ({
  options,
  label,
  onSelect,
  placeholder,
  selectedValues = [],
  removeItem,
  hideSelected = false,
  leftIcon,
  setAppRef,
  ...rest
}) => {
  const multiselectRef = useRef(null);

  useEffect(() => {
    if (setAppRef) setAppRef(multiselectRef);
  }, [multiselectRef, setAppRef]);

  return (
    <div
      style={{ width: '100%' }}
      className="custom-multiselect"
      data-haslefticon={!!leftIcon}
    >
      {label && <div className="form-v2__label">{label}</div>}
      <SelectContainer>
        {leftIcon && <div style={{ paddingLeft: '13px' }}>{leftIcon}</div>}
        <CustomMultiselect
          options={options}
          onSelect={(selectedList, selectedItem) => {
            onSelect(selectedList, selectedItem);
          }}
          displayValue="label"
          closeOnSelect={false}
          avoidHighlightFirstOption
          placeholder={placeholder}
          style={style}
          selectedValues={selectedValues}
          ref={multiselectRef}
          {...rest}
        />
      </SelectContainer>
      {!hideSelected && (
        <div className="d-flex flex-wrap mt-20 gap-12">
          {selectedValues?.map((item) => (
            <Chips key={item.key} onClick={() => removeItem(item)}>
              <div className="close-icon">
                <IconClose />
              </div>
              {item.label}
            </Chips>
          ))}
          {/* {!!selectedValues && (
            <Chips onClick={() => onReset()}>
              <div className="close-icon">
                <IconClose />
              </div>
              Clear
            </Chips>
          )} */}
        </div>
      )}
    </div>
  );
};

const SelectContainer = styled.div`
  display: flex;
  background: white;
  border-radius: 9px;
  align-items: center;
  .searchBox {
    ::placeholder {
      color: #555555;
    }
  }
`;

export const Chips = styled.div`
  border: 1px solid white;
  border-radius: 27px;
  width: fit-content;
  padding-right: 10px;
  padding-left: 5px;
  height: 26px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid white;
    border-radius: 50%;
  }
`;

export default MultiSelect;
