import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { ProfileDetails, SectionProfile } from '../../components';
import { LINK_GROUP } from '../../../utils/links';
import {
  TYPE_PAYROLL_STATUS,
  TYPE_START_DATE,
  TYPE_WORK_LOCATION_FOR_CONSULTANT,
} from '../../placeholders/consultant/types';
import { GET_ALL_JOB_LEVELS } from '../../../graphql';
import { useQuery } from '@apollo/react-hooks';
import CONTENTS_UTIL from '../../../utils/contents';
import { getLoginType, IMG_PLACEHOLDER } from '../../../utils/helpers';
import useAuth from '../../hooks/useAuth';

export default function PersonalInformationPage() {
  const { authMe: data } = useAuth();
  const { data: { allJoblevels } = {} } = useQuery(GET_ALL_JOB_LEVELS);

  const contents = useMemo(() => {
    if (!data) {
      return;
    }

    const {
      bio,
      address_1,
      address_2,
      city,
      state,
      country,
      zip,
      phone,
      email,
      payroll_status,
      name,
      pronouns,
      location: jobLocation,
      jobtitle,
      right_to_work,
      start_date,
      notice_period,
      looking_for,
      positions,
    } = data;

    return CONTENTS_UTIL.generateDetails(
      [
        CONTENTS_UTIL.generateRowDetails(
          'Name',
          `${name} ${pronouns ? `(${pronouns})` : ''}`
        ),
        CONTENTS_UTIL.generateRowDetails('About me', bio ?? '-'),
        CONTENTS_UTIL.generateRowDetails('Latest Job Title', jobtitle ?? '-'),

        CONTENTS_UTIL.generateRowDetails(
          'Address',
          CONTENTS_UTIL.generateAddress(
            address_1,
            address_2,
            city,
            state,
            country,
            zip
          )
        ),
        CONTENTS_UTIL.generateRowDetails('Phone Number', phone ?? '-'),
        CONTENTS_UTIL.generateRowDetails('Email Address', email ?? '-'),
      ],
      [
        looking_for?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Preferred Levels Of Roles',
              allJoblevels
                ?.filter((level) => looking_for?.includes(level.key))
                ?.map(({ name }) => `<p class="text--p mb-2">${name}</p>`)
                ?.join('')
            )
          : '',
        positions?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Position Sought',
              positions
                ?.map(({ name }) => `<p class="text--p mb-2">${name}</p>`)
                ?.join('')
            )
          : '',
        CONTENTS_UTIL.generateRowDetails(
          'Additional Questions',
          `
              ${
                jobLocation
                  ? `<p class="mb-2 text--p">Job Location: ${
                      jobLocation === TYPE_WORK_LOCATION_FOR_CONSULTANT[3].value
                        ? 'Remote, On-site, or Hybrid'
                        : TYPE_WORK_LOCATION_FOR_CONSULTANT.find(
                            (j) => jobLocation === j.value
                          )?.label
                    }</p>`
                  : ''
              }
              ${
                payroll_status && payroll_status !== '0'
                  ? `<p class="mb-2 text--p">Requires going on ${
                      payroll_status === TYPE_PAYROLL_STATUS[2].value
                        ? 'either Payroll or Limited Company'
                        : TYPE_PAYROLL_STATUS.find(
                            (p) => p.value === payroll_status
                          )?.label
                    }</p>`
                  : ''
              }
              ${
                right_to_work
                  ? `<p class="mb-2 text--p">${
                      parseInt(right_to_work) === 1
                        ? "Doesn't require"
                        : 'Require'
                    } a visa to work in the UK</p>`
                  : ''
              }`
        ),
        start_date
          ? CONTENTS_UTIL.generateRowDetails(
              'Start Date',
              TYPE_START_DATE.find(
                (type) => parseInt(type.value) === parseInt(start_date)
              )?.label
            )
          : '',
        notice_period
          ? CONTENTS_UTIL.generateRowDetails('Notice Period', notice_period)
          : '',
      ]
    );
  }, [data, allJoblevels]);

  return (
    <DefaultLayout showNavigation showSubmenu>
      <SectionProfile
        contentFull
        editURL={`/${getLoginType()}/edit-personal-information`}
        editLabel={'EDIT PERSONAL INFORMATION'}
        profileImage={data?.avatar || IMG_PLACEHOLDER}
        imageRounded
        headline="Personal Information"
        vetted={data?.vetted}
        cover={getLoginType() === LINK_GROUP.CANDIDATE}
      >
        <ProfileDetails contents={contents} />
      </SectionProfile>
    </DefaultLayout>
  );
}
