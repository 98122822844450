import React, { useState } from 'react';
import { ProfileDetails, SectionProfile } from '../../../components';
import { LINK_GROUP } from '../../../../utils/links';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_CREDITS } from '../../../../graphql';
import { userVar } from '../../../../graphql/config';
import DefaultLayout from '../../../layouts/defaultLayout';
import CONTENT_UTIL from '../../../../utils/contents';
import useTeams from '../../../hooks/useTeams';
import { IMG_PLACEHOLDER } from '../../../../utils/helpers';

export default function AccountInformationCompanyPage() {
  const { name } = userVar();
  const [balance, setBalance] = useState(0);

  const { user, activeCompany, isAdmin } = useTeams();

  const { loading, refetch } = useQuery(GET_CURRENT_CREDITS, {
    variables: {
      team_id: activeCompany?.team_id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBalance(data.currentCredit.balance);
    },
    onError: () => refetch(),
  });

  return (
    <DefaultLayout showSubmenu showNavigation>
      <SectionProfile
        headline="My Account"
        profileImage={user?.avatar || IMG_PLACEHOLDER}
        profilePictureHeading={null}
        editURL={isAdmin ? `/${LINK_GROUP.EMPLOYER}/edit-profile` : null}
        contentFull
        uppercaseActionLabel
      >
        <ProfileDetails
          className={'profile-company'}
          contents={CONTENT_UTIL.generateDetails([
            CONTENT_UTIL.generateRowDetails(
              user?.company ?? '',
              `<div class="big-chip"><div class="meta--content">${
                name ?? ''
              }</div>
                 <div class="meta--chip">
            ${
              loading
                ? 'Loading Job Posts balance...'
                : `<span>
                    ${balance} Job Post${balance > 1 ? 's ' : ' '}Available
                  </span>`
            }
          </div>
          </div>
                 `
            ),
          ])}
        />
      </SectionProfile>
    </DefaultLayout>
  );
}
