import React from 'react';

export default function Envelope() {
  return (
    <svg width="1em" height="0.824em" viewBox="0 0 68 56" fill="none">
      <path
        d="M60 0C64.375 0 68 3.625 68 8C68 12.5 64.375 16 60 16C55.5 16 52 12.5 52 8C52 3.625 55.5 0 60 0ZM57 19.625C57.875 19.875 58.875 20 60 20C61.375 20 62.625 19.75 64 19.375V48C64 52.5 60.375 56 56 56H8C3.5 56 0 52.5 0 48V16C0 11.625 3.5 8 8 8H48C48 10.25 48.625 12.25 49.625 14H8C6.875 14 6 15 6 16V18.875L26.75 36.25C29.625 38.625 34.25 38.625 37.125 36.25L57 19.625ZM58 48V26.625L40.875 40.875C38.375 42.875 35.25 44 32 44C28.625 44 25.5 42.875 23 40.875L6 26.625V48C6 49.125 6.875 50 8 50H56C57 50 58 49.125 58 48Z"
        fill="currentColor"
      />
    </svg>
  );
}
