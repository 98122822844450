import React, { useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, Heading } from '../../snippets';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { UPDATE_PROFILE } from '../../../graphql';
import { userVar } from '../../../graphql/config';
import { getUser, saveUserLocalstorage } from '../../../utils/helpers';
import { CustomCheckbox } from '../../components';

export default function ProfilePrivacyPage() {
  const [privacy, setPrivacy] = useState(getUser().privacy);
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const handlePrivacy = ({ state, field }) => {
    setPrivacy(field);
    updateProfile({
      variables: {
        input: {
          privacy: field,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error('Something went wrong.', { autoClose: 5000 });
      });
  };

  return (
    <DefaultLayout showNavigation showSubmenu>
      <BackPanel>
        <Heading className="mb-4">Manage Your Privacy Policy</Heading>
        <div>
          <div className="form__heading mt-0" style={{ fontSize: 16 }}>
            Manage Your Profile Status
          </div>
          <div className="form__input-block">
            <div className="form__input-wrapper">
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <CustomCheckbox
                    name="privacy-active"
                    checked={privacy !== 'INACTIVE'}
                    onChange={(value) =>
                      handlePrivacy({
                        state: value,
                        field: 'ACTIVE',
                      })
                    }
                    label="Active"
                  />
                </div>
                <div>
                  <CustomCheckbox
                    name="privacy-inactive"
                    checked={privacy === 'INACTIVE'}
                    onChange={(value) =>
                      handlePrivacy({
                        state: value,
                        field: 'INACTIVE',
                      })
                    }
                    label="Inactive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackPanel>
    </DefaultLayout>
  );
}
