import React, { useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUser } from '../../utils/helpers';
import { LINK_GROUP } from '../../utils/links';
import styled from 'styled-components';

const Header = ({ isLoggedIn, withNoMargin = false }) => {
  if (getUser().access_token) isLoggedIn = true;
  const humbergerRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const location = useLocation();

  const toogleMenu = useCallback(() => {
    if (humbergerRef.current && mobileMenuRef.current) {
      const { current } = humbergerRef;
      const { current: drawMenu } = mobileMenuRef;

      if (
        current.className.includes('open') &&
        drawMenu.className.includes('active')
      ) {
        current.classList.remove('open');
        current.classList.remove('fixed');
        drawMenu.classList.remove('active');
        return;
      }

      current.classList.add('open');
      current.classList.add('fixed');
      drawMenu.classList.add('active');
      return;
    }
  }, [humbergerRef, mobileMenuRef]);

  return (
    <>
      {/* ONLY VISIBLE IN MOBILE */}
      <div className="mobile-menu agency-v2 theme-agency" ref={mobileMenuRef}>
        <nav className="mobile-menu__inner">
          {isLoggedIn ? (
            <>
              <ul className="mobile-menu__list">
                <li className="mobile-menu__list__item-large">
                  <Link
                    to={`/${LINK_GROUP.AGENCY}/case-studies`}
                    className="header__link"
                  >
                    Case Study
                  </Link>
                </li>
                <li className="mobile-menu__list__item-large">
                  {location.pathname.includes('dashboard') ? (
                    <a href={`/${LINK_GROUP.AGENCY}/dashboard`} className="">
                      Dashboard
                    </a>
                  ) : (
                    <Link to={`/${LINK_GROUP.AGENCY}/dashboard`} className="">
                      Dashboard
                    </Link>
                  )}
                </li>
                <li className="mobile-menu__list__item-large">
                  <Link
                    to={`/${LINK_GROUP.AGENCY}/personal-information`}
                    className=""
                  >
                    My Account
                  </Link>
                </li>
              </ul>
              <ul className="mobile-menu__list">
                <li className="mobile-menu__list__item-small">
                  <Link to={`/${LINK_GROUP.AGENCY}/logout`} className="">
                    Sign Out
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <ul className="mobile-menu__list">
              <li className="mobile-menu__list__item-large">
                {location.pathname.includes('login') ? (
                  <Link to={`/${LINK_GROUP.AGENCY}/register`} className="">
                    Sign Up
                  </Link>
                ) : (
                  <Link to={`/${LINK_GROUP.AGENCY}/login`} className="">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          )}

          <div className="mobile-menu__inner__social">
            {/* <a href='/#' target='_self' className='footer__links__img'>
              <img
                src='/img/liberty-hive-logo-icon-white-small@2x.png'
                className='img img__social'
                alt=''
              />
            </a> */}
            <a
              href="https://www.instagram.com/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-instagram@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-linkedin@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
          </div>
        </nav>
      </div>
      {/* END OF ONLY MOBILE */}
      <div
        className={`header-nav header-agency-v2 theme-agency${
          withNoMargin ? ' mb-lg-0' : ''
        }`}
      >
        <div className="header header-nav__inner container-fluid">
          <div className="row">
            <div className="header-nav__inner__logo col-5">
              <Link to={`/${LINK_GROUP.AGENCY}/login`}>
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__mobile"
                  alt=""
                />
              </Link>
              <Link to={`/${LINK_GROUP.AGENCY}/login`}>
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__desktop"
                  alt=""
                />
              </Link>
            </div>
            <nav className="header-nav__inner__menu col-7 desktop">
              <ul className="header__list">
                {isLoggedIn ? (
                  <>
                    <li className="header__list__item">
                      <Link
                        to={`/${LINK_GROUP.AGENCY}/case-studies`}
                        className="header__link"
                      >
                        Case Studies
                      </Link>
                    </li>
                    <li className="header__list__item">
                      {location.pathname.includes('dashboard') ? (
                        <a
                          href={`/${LINK_GROUP.AGENCY}/dashboard`}
                          className="header__link"
                        >
                          Dashboard
                        </a>
                      ) : (
                        <Link
                          to={`/${LINK_GROUP.AGENCY}/dashboard`}
                          className="header__link"
                        >
                          Dashboard
                        </Link>
                      )}
                    </li>
                    <li className="header__list__item">
                      {location.pathname.includes('account') ? (
                        <a
                          href={`/${LINK_GROUP.AGENCY}/account-information`}
                          className="header__link"
                        >
                          My Account
                        </a>
                      ) : (
                        <Link
                          to={`/${LINK_GROUP.AGENCY}/account-information`}
                          className="header__link"
                        >
                          My Account
                        </Link>
                      )}
                    </li>
                  </>
                ) : (
                  <li className="header__list__item">
                    {location.pathname.includes('login') ? (
                      <Link
                        to={`/${LINK_GROUP.AGENCY}/register`}
                        className="header__link"
                      >
                        Sign Up
                      </Link>
                    ) : (
                      <Link
                        to={`/${LINK_GROUP.AGENCY}/login`}
                        className="header__link"
                      >
                        Login
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </nav>
            <div className="header-nav__inner__menu col-7 mobile-tablet">
              <Button ref={humbergerRef} onClick={toogleMenu}>
                <span />
                <span />
                <span />
                <div className="hamburger-black"></div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Button = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  height: 61px;
  width: 61px;
  position: relative;
  transform: rotate(0deg);
  transition: 500ms ease-in-out;
  cursor: pointer;
  z-index: 1001;
  border-radius: 14px;

  span {
    display: block;
    position: absolute;
    background: #ffffff;
    opacity: 1;
    transform: rotate(0deg);
    transition: 200ms ease-in-out;
    z-index: 2;
    background: white;
    height: 4px;
    width: 21px;
    right: 30%;
    border-radius: 0;
  }

  & > span:nth-child(1) {
    top: 34%;
  }
  &.open > span:nth-child(1) {
    top: 35%;
  }
  & > span:nth-child(2) {
    top: 46%;
  }

  & > span:nth-child(3) {
    top: 60%;
  }

  &.open span:nth-child(1),
  &.open span:nth-child(3) {
    left: 30%;
  }

  &.open span:nth-child(3) {
    transform: rotate(45deg);
  }
  &.open span:nth-child(1) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(2) {
    width: 0;
    opacity: 0;
  }

  & > span:nth-child(3) {
    transform-origin: right center;
  }

  & > span:nth-child(1) {
    transform-origin: right center;
  }
`;

export default Header;
