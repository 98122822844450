import React, { useState } from 'react';
import 'bootstrap/js/src/modal';
import DatePicker from 'react-date-picker';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { CREATE_EXPERIENCE } from '../../graphql';
import Error from '../Error';
import { userVar } from '../../graphql/config';
import hideModal from '../../styles/js/hideModal';

const AddExperienceModal = ({ refetch }) => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [present, setPresent] = useState(false);

  const [createExperience, { loading }] = useMutation(CREATE_EXPERIENCE);
  const { register, handleSubmit, errors, reset } = useForm();
  const clearForm = () => {
    reset();
    setFrom(null);
    setTo(null);
    setPresent(false);
  };

  const addExperience = (data) => {
    toast.dismiss();

    const input = {
      ...data,
      user: { connect: userVar().id },
    };

    if (from) {
      input.from = moment(from).format('YYYY-MM-DD');
    }

    if (!present) {
      input.to = moment(to).format('YYYY-MM-DD');
    }

    if (to === null) {
      input.to = null;
    }

    return createExperience({
      variables: {
        input,
      },
    })
      .then(() => {
        clearForm();
        refetch();
        hideModal();
        toast.success('Experience added successfully!', { autoClose: 4000 });
      })
      .catch(() =>
        toast.error('Could not add experience', { toastId: 'addExErr' })
      );
  };

  return (
    <div>
      <div
        className="modal fade"
        id="add_experience_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="add_experience_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h3 className="txt__fw-600 text-center mb-60">Add Experience</h3>
              <form className="row form__freelancer">
                <div className="col-12">
                  <div className="form__input-block mb-50">
                    <div className="form__label">Title</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="title"
                        autoComplete="false"
                        name="title"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                    {errors.title && (
                      <Error text="This field is required" noMargin />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__input-block mb-50">
                    <div className="form__label">Employer</div>
                    <div className="form__input-wrapper">
                      <input
                        className="form__input"
                        type="text"
                        autoComplete="false"
                        name="company"
                        ref={register({ required: true })}
                      />
                      <div className="form__clear form__hidden">
                        <i className="fas fa-times" />
                      </div>
                    </div>
                    {errors.company && (
                      <Error text="This field is required" noMargin />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form__input-block mb-50">
                    <span className="txt__fw-600">Start Date</span>
                    <div className="form__input-wrapper">
                      <DatePicker
                        onChange={setFrom}
                        value={from}
                        className="form__input date"
                        format="MM-y"
                        name="from"
                        required={false}
                        maxDetail="year"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form__input-block mb-50">
                    <span className="txt__fw-600">End Date</span>
                    <input
                      type="checkbox"
                      style={{ marginLeft: '20px', marginRight: '10px' }}
                      onClick={() => setPresent(!present)}
                    />
                    Present
                    <div className="form__input-wrapper">
                      <DatePicker
                        onChange={setTo}
                        value={to}
                        className="form__input date"
                        format="MM-y"
                        name="to"
                        required={false}
                        maxDetail="year"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        disabled={present}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <p className="txt__small txt__fw-600 mb-1">
                    Description of roles and responsibilities
                  </p>
                  <textarea
                    rows="8"
                    className="form__textarea height__unset"
                    placeholder="Add your description here..."
                    name="description"
                    ref={register({ required: true })}
                  />
                  {errors.description && (
                    <Error text="This field is required" noMargin />
                  )}
                </div>
                <div className="col-12 text-center">
                  <a
                    href="/#"
                    className="form__btn btn-v2 d-block bg-freelancer"
                    onClick={handleSubmit(addExperience)}
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExperienceModal;
