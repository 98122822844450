import React, { useEffect, useRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

export default function AppMultiselect({
  leftIcon: LeftIconComponent = null,
  options,
  onSelect,
  displayValue,
  placeholder,
  selectedValues,
  setAppRef,
  closeOnSelect = false,
  hideChip = false,
}) {
  const [updatedPlaceholder, setUpdatedPlaceholder] = useState(placeholder);
  const multiselectRef = useRef(null);

  useEffect(() => {
    if (setAppRef) {
      setAppRef(multiselectRef);
    }
  }, [multiselectRef, setAppRef]);

  const onFocus = () => {
    setUpdatedPlaceholder(`Search ${updatedPlaceholder}...`);
  };

  const onBlur = () => {
    setUpdatedPlaceholder(placeholder);
  };

  return (
    <button
      type="button"
      style={{ border: 'none', padding: 0, backgroundColor: 'transparent' }}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`app-multiselect${hideChip ? ' multiselect-no-chip' : ''}`}
    >
      {LeftIconComponent && <LeftIconComponent />}
      <Multiselect
        options={options}
        onSelect={onSelect}
        ref={multiselectRef}
        displayValue={displayValue}
        closeOnSelect={closeOnSelect}
        avoidHighlightFirstOption
        placeholder={updatedPlaceholder}
        selectedValues={selectedValues}
        disablePreSelectedValues={false}
      />
    </button>
  );
}
