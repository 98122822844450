import React, { useEffect } from 'react';
import Header from '../../components/Agency/Header';
import Footer from '../../v2/components/Footer/Footer';
import styled, { css } from 'styled-components';
import OpportunityCard from './components/OpportunityCard';
import CommunityCard from './components/CommunityCard';
import DealsCard from './components/DealsCard';
import jquery from '../../styles/js/app';

const Dashboard = () => {
  useEffect(() => {
    jquery();
  }, []);

  return (
    <div className="lh-wrapper bg__gradient-4 bg-center bg-cover theme theme-agency">
      <Header isLoggedIn />
      <div style={{ flex: 1 }} className="container gap-3">
        <div className="black-card black-card__full mb-50">
          <div className="d-flex justify-content-between align-items-center">
            <Heading>Job Opportunities Just For You</Heading>
            <Button className="bg__gradient-2 bg-center bg-cover d-none d-md-block">
              View More
            </Button>
          </div>
          <Grid>
            <OpportunityCard
              createdAt={'2024-04-10 14:22:09'}
              updatedAt={'2020-04-10 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo2.png"
            />
            <OpportunityCard
              createdAt={'2024-04-10 14:22:09'}
              updatedAt={'2020-04-10 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo1.png"
            />
          </Grid>
          <div className="d-flex d-sm-none justify-content-center mt-24">
            <Button className="bg__gradient-2 bg-center bg-cover">
              View More
            </Button>
          </div>
        </div>
        <div className="black-card black-card__full mb-50">
          <div className="d-flex justify-content-between align-items-center">
            <Heading>Latest Job Opportunities</Heading>
            <Button className="bg__gradient-1 bg-center bg-cover d-none d-md-block">
              View More
            </Button>
          </div>
          <Grid>
            <OpportunityCard
              createdAt={'2024-04-25 14:22:09'}
              updatedAt={'2020-04-30 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo2.png"
            />
            <OpportunityCard
              createdAt={'2024-04-25 14:22:09'}
              updatedAt={'2020-04-30 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo1.png"
            />
            <OpportunityCard
              createdAt={'2024-04-25 14:22:09'}
              updatedAt={'2020-04-30 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo2.png"
            />
            <OpportunityCard
              createdAt={'2024-04-25 14:22:09'}
              updatedAt={'2020-04-30 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo1.png"
            />
          </Grid>
          <div className="d-flex d-sm-none justify-content-center mt-24">
            <Button className="bg__gradient-2 bg-center bg-cover">
              View More
            </Button>
          </div>
        </div>
        <div className="black-card black-card__full mb-50">
          <Heading>Job Opportunities You’ve Applied For</Heading>
          <Grid>
            <OpportunityCard
              createdAt={'2024-04-10 14:22:09'}
              updatedAt={'2020-04-10 14:22:09'}
              title={'SEO Senior Exec - Manchester'}
              company={'EssenceMediacom'}
              logo="/img/logo1.png"
            />
          </Grid>
        </div>
        <div className="black-card black-card__full mb-50">
          <div className="d-flex justify-content-between align-items-center">
            <Heading>Liberty Hive Community</Heading>
            <Button className="bg__gradient-1 bg-center bg-cover d-none d-lg-block">
              View More
            </Button>
          </div>
          <GridFourCol>
            <CommunityCard
              image={'/img/Rectangle1.png'}
              title="How to enhance your Liberty Hive Profile"
            />
            <CommunityCard
              image={'/img/Rectangle2.png'}
              title={'Webinar: How to make the most of your LinkedIn profile'}
            />
            <CommunityCard
              image={'/img/Rectangle1.png'}
              title={'Webinar: IR35 And What It Means For You'}
            />
            <CommunityCard
              image={'/img/Rectangle2.png'}
              title={'Webinar: IR35 And What It Means For You'}
            />
          </GridFourCol>
          <div className="d-flex d-lg-none justify-content-center mt-24">
            <Button className="bg__gradient-2 bg-center bg-cover">
              View More
            </Button>
          </div>
        </div>
        <div className="black-card black-card__full mb-50">
          <div className="d-flex justify-content-between align-items-center">
            <Heading>Deals For You</Heading>
            <Button className="bg__gradient-2 bg-center bg-cover d-none d-md-block">
              View More
            </Button>
          </div>
          <Grid col3={true}>
            <DealsCard
              title={'Trevisan Social'}
              description={
                '15% off  1-2-1 LinkedIn training using the code: webinar121'
              }
              logo="/img/logo1.png"
            />
            <DealsCard
              title={'Tax Ninja'}
              description={
                '20% off Tax Return Service. 10% off Accounting Services'
              }
              logo="/img/logo2.png"
            />
            <DealsCard
              title={'Trevisan Social'}
              description={
                '15% off  1-2-1 LinkedIn training using the code: webinar121'
              }
              logo="/img/logo1.png"
            />
            <DealsCard
              title={'Tax Ninja'}
              description={
                '20% off Tax Return Service. 10% off Accounting Services'
              }
              logo="/img/logo2.png"
            />
            <DealsCard
              title={'Tax Ninja'}
              description={
                '20% off Tax Return Service. 10% off Accounting Services'
              }
              logo="/img/logo2.png"
            />
          </Grid>
          <div className="d-flex d-sm-none justify-content-center mt-24">
            <Button className="bg__gradient-2 bg-center bg-cover">
              View More
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Dashboard;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  line-height: 34.68px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 43.35px;
  }
`;

const Button = styled.button`
  width: 122px;
  height: 37px;
  border-radius: 12px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-top: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 74px;
    margin-top: 32px;
  }
  @media (min-width: 1024px) {
    ${(props) =>
      props.col3 &&
      css`
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
      `}
  }
`;

const GridFourCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  margin-top: 20px;
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 23px;
    margin-top: 32px;
  }
`;
