import React, { useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Heading,
  Button,
  FormContent,
  FormBlock,
  FormBlockItem,
  FormInput,
  FormCTA,
} from '../../snippets';
import { useMutation } from '@apollo/client';
import { CREATE_CASE_STUDY } from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import UploadField from '../../../components/UploadFieldV2';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const AddCaseStudy = () => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearErrors,
    getValues,
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [thumbImage, setThumbImage] = useState('');
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const notify = (text) => toast.error(text);
  const [addCaseStudy, { loading }] = useMutation(CREATE_CASE_STUDY);
  const watchFields = watch(['video_url', 'description']);
  const history = useHistory();
  const location = useLocation();

  const handleAddCaseStudy = () => {
    const input = {
      ...getValues(),
      thumbnail: thumbImage,
      content: images,
      description,
    };

    addCaseStudy({
      variables: { input: input },
      onCompleted: () => {
        history.push({
          pathname: `/${LINK_GROUP.AGENCY}/case-studies`,
        });
      },
      onError: (err) => {
        notify(err?.message);
      },
    });
  };

  const goToSkipForNow = () => {
    history.push({
      pathname: `/${LINK_GROUP.AGENCY}/register/done`,
    });
  };

  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading>Add Case Study</Heading>
        {location.pathname === '/agency/register/3' && (
          <Button
            className="w-full text-capitalize mt-40"
            xsradii
            onClick={goToSkipForNow}
          >
            Skip For Now
          </Button>
        )}

        <FormContent>
          <FormBlock className={'spacer-t'}>
            <FormBlockItem label={'Project Title'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                name="title"
                error={errors.title ? `Project title is required` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>
            <div className="form__input-block">
              <div className="form-v2__label">
                Thumbnail Image (Recommended size 720x576px)
              </div>
              <div className="form-v2__input-wrapper">
                <UploadField
                  onSetImage={(file) => {
                    setThumbImage(file);
                  }}
                  maxFileSize={5e6}
                />
              </div>
            </div>
            <FormBlockItem label={'Add A Brief Description'}>
              <FormInput
                isRequired
                type="wysiwyg"
                name="description"
                error={
                  submitted && (!description || description?.length === 0)
                    ? `Please add a description`
                    : null
                }
                showErrorText
                maxLength="1000"
                clearErrors={clearErrors}
                setValue={setDescription}
                fieldClassName={`${
                  submitted && (!description || description?.length === 0)
                    ? 'error-state '
                    : ''
                }`}
              ></FormInput>
            </FormBlockItem>
            <div className="form-v2__input-block extra-b-space">
              <div className="field__label text__gray">Images/Videos</div>
              <div className="form__input-wrapper">
                <UploadField
                  onSetImage={(file) => {
                    for (let index = 0; index < file.length; index++) {
                      setImages((prev) => [...prev, file[index]]);
                    }
                  }}
                  onRemoveImage={(newImages) => {
                    setImages(newImages);
                  }}
                  multiple={true}
                  accept={'image/*,video/*'}
                />
              </div>
              <div className="text__gray text-right mt-2">
                {images.length ?? 0}/3
              </div>
            </div>
            <FormBlockItem label={'Youtube/Vimeo Link'}>
              <FormInput
                type="text"
                register={register}
                name="video_url"
                clearErrors={clearErrors}
              ></FormInput>
              {watchFields.video_url && (
                <div className="mt-20 up-container">
                  <div
                    className="embed-responsive embed-responsive-16by9"
                    style={{ borderRadius: '12px' }}
                  >
                    <CloseButton
                      onClick={() => {
                        setValue('video_url', '');
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </CloseButton>
                    <ReactPlayer
                      url={watchFields.video_url}
                      width={'100%'}
                      height={'100%'}
                    />
                  </div>
                </div>
              )}
            </FormBlockItem>
            <FormBlockItem label={'External URL'}>
              <FormInput
                type="text"
                register={register}
                name="external_url"
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full"
                size="xxl"
                disabled={!!loading}
                onClick={(e) => {
                  setSubmitted(true);
                  handleSubmit(handleAddCaseStudy)(e);
                }}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </FormBlock>
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
};

export default AddCaseStudy;

const CloseButton = styled.div`
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 100px;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
