import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LINK_GROUP } from '../../utils/links';
import styled from 'styled-components';

const basepath = LINK_GROUP.AGENCY;

const MenuContent = styled.div`
 margin-top: 0;
`

const Button = styled.button`
  padding-top: var(--submenu-space-top);
  padding-bottom: var(--submenu-space-bottom);
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  border: 0;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  position: relative; 
  font-size: 20px;
  font-weight: 900;
  line-height: 27.28px;
`

const AccountSubmenuMobile = () => {
  const containerRef = useRef(null) 
  const toggleMenu = useCallback(() => { 
    const {current} = containerRef
    if(!current){
      return
    } 
    if(current.className.includes('d-none')){
      current.classList.remove('d-none')
    } else {
      current.classList.add('d-none')
    }
    
  }, [containerRef])
  return(
  <div className="account-submenu__mobile bg-light theme-agency">
    <div className="container">
      <Button className="" onClick={toggleMenu}>
        <div className="row">
          <div className="col-9 account-submenu__mobile__heading txt text-left txt__fw-900">
            Account Settings
          </div>
          <div className="col-3 account-submenu__mobile__arrow txt">
            <i className="fas fa-chevron-down" />
          </div>
        </div>
      </Button>
      <MenuContent className="account-submenu__mobile__content d-none" ref={containerRef}>
        <div className="row">
          <ul className="account-submenu__list">
            <li className="account-submenu__list__item">
              <Link
                to={`/${basepath}/personal-information`}
                className="link"
              >
                Personal Information
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to={`/${basepath}/edit-personal-information`}
                className="link"
              >
                Edit Personal Information
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to={`/${basepath}/profile`} className="link">
                View Profile
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to={`/${basepath}/manage-cv`} className="link">
                Edit Profile
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to={`/${basepath}/notification-settings`}
                className="link"
              >
                Notification Settings
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to={`/${basepath}/profile-privacy`}
                className="link"
              >
                Profile Privacy
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link
                to={`/${basepath}/change-password`}
                className="link"
              >
                Change password
              </Link>
            </li>
            <li className="account-submenu__list__item">
              <Link to={`/${basepath}/logout`} className="link">
                Log Out
              </Link>
            </li>
          </ul>
        </div>
      </MenuContent>
    </div>
  </div>
)};

export default AccountSubmenuMobile;
