import gql from 'graphql-tag';

export const GET_QUALIFICATIONS = gql`
  query Qualifications($name: String, $industries: [ID!]) {
    qualifications(name: $name, industries: $industries) {
      id
      name
    }
  }
`;
