import React from 'react';
import { Heading } from '../../views/Agency/CaseStudy';
import { NavLink } from 'react-router-dom';
import { AgencySubmenuLinks } from '../../components/Agency/AccountSubmenuDesktop';
import styled from 'styled-components';

const AccountNavigation = () => {
  return (
    <div className="black-card black-card__full mb-50">
      <div className="d-flex justify-content-between align-items-center">
        <Heading>Account Settings</Heading>
      </div>
      <LinkContainer className="mt-40 d-flex flex-wrap">
        {AgencySubmenuLinks.map((item) => (
          <NavLink
            to={item.path}
            key={item.label}
            className="btn nav-item"
            activeClassName="bg__gradient-1 bg-cover"
          >
            {item.label}
          </NavLink>
        ))}
      </LinkContainer>
    </div>
  );
};

const LinkContainer = styled.div`
  gap: 12px;
  .nav-item {
    border: 1px solid white;
    border-radius: 27px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
  }
`;

export default AccountNavigation;
