import React from 'react';
import styled from 'styled-components';

const DealsCard = ({ title, description, logo }) => {
  return (
    <Container>
      <img src={logo} alt="" />
      <div className="vertical-line" />
      <div className="body">
        <div>
          <h2 className="title">{title}</h2>
          <p className="copy">{description}</p>
        </div>

        <p className="more" data-text="FIND OUT MORE">
          FIND OUT MORE
        </p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  gap: 16px;
  img {
    height: 100px;
    width: 100px;
    margin-block: auto;
  }
  .vertical-line {
    height: 100%;
    border-left: 1px solid #929292;
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 18px;
    .title {
      margin: 0;
      font-weight: 900;
      font-size: 14px;
      color: white;
    }
    .copy {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.1px;
      color: white;
      margin: 0;
    }
    .more {
      font-size: 12px;
      font-weight: 700;
      margin: 0;
      position: relative;
      color: transparent;
      &::before {
        content: attr(data-text);
        position: absolute;
        background-image: url('/img/agency-gradients/Agency-mesh-gradient-1.jpg');
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-size: cover;
        background-position: center;
        height: 100%;
      }
    }
  }
  @media (min-width: 1024px) {
    height: 140px;
    gap: 16px;
    .body {
      padding-block: 17px;
      .title {
        font-size: 16px;
      }
      .copy {
        font-size: 14px;
        line-height: 21.8px;
      }
      .more {
        font-size: 14px;
      }
    }
  }
`;

export default DealsCard;
