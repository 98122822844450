import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { AppContext } from '../../../../Store';
import {
  DELETE_TEAM_USER_SETTING,
  GET_TEAM_USERS,
  UPDATE_TEAM_USER_SETTING,
} from '../../../../graphql';
import { getUser } from '../../../../utils/helpers';
import { LINK_GROUP } from '../../../../utils/links';
import {
  LoadingSection,
  TablerSection,
  UserAssociation,
} from '../../../components';
import { useAppTheme } from '../../../context/themeAppContext';

export default function CompanyManageTeamsPage() {
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);

  const {
    action: { setOpenModal, setCloseModal },
  } = useAppTheme();

  const [getTeamUsers, { data: teamList, refetch }] = useLazyQuery(
    /** Retrieve Team Members */
    GET_TEAM_USERS,
    {
      variables: {
        team_id: Number(state?.activeCompany?.team_id),
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        setRedirect(true);
      } else if (state?.activeCompany?.role === 'admin') {
        setLoading(false);
      }
    }

    if (
      state?.activeCompany !== undefined ||
      getUser()?.type === LINK_GROUP.COMPANY.toUpperCase()
    ) {
      getTeamUsers();
    }

    if (
      teamList?.viewUserTeam !== undefined &&
      teamList?.viewUserTeam?.length > 0
    ) {
      const holder = [];
      teamList.viewUserTeam.forEach((item, index) => {
        let host = item?.team;

        if (host) {
          holder.push({ ...item, ...host });
        }
      });

      setMemberList(
        holder.filter((a) => a?.id !== state?.activeCompany?.user_id)
      );
    }
  }, [state, getTeamUsers, teamList, refetch]);

  const [updateTeamUser] = useMutation(UPDATE_TEAM_USER_SETTING);
  const [deleteTeamUser, { loading: loadingDelete }] = useMutation(
    DELETE_TEAM_USER_SETTING
  );

  const updateTeamUserRole = useCallback(
    async (e, item) => {
      if (componentLoading) {
        return;
      }
      toast.dismiss();
      setComponentLoading(true);
      try {
        if (e === item.role) {
          return;
        }
        await updateTeamUser({
          variables: {
            input: {
              user_id: item.user_id,
              team_id: item.team_id,
              role: e,
            },
          },
        });
        toast.success('User role updated succesfully!', { autoClose: 4000 });
        getTeamUsers();
      } catch (error) {
        console.error('Error updateTeamUserRole:', error);
        toast.error('Could not update user role!', { toastId: 'appErr' });
      } finally {
        setComponentLoading(false);
      }
    },
    [componentLoading, getTeamUsers, updateTeamUser]
  );

  if (redirect) {
    return <Redirect to="404" />;
  }

  const deactivateAccount = (userId, teamId) => {
    deleteTeamUser({
      variables: {
        input: {
          user_id: userId,
          team_id: teamId,
        },
      },
    })
      .then(() => {
        toast.dismiss();
        toast.success('Team member removed!', { autoClose: 4000 });
        setCloseModal();
        setMemberList(memberList.filter(({ id }) => id !== userId));
      })
      .catch(() => toast.error('Could not delete user', { toastId: 'appErr' }));
  };

  const DeleteModal = ({ userId, teamId }) => {
    return (
      <>
        <Heading>Delete Team User</Heading>
        <Text className="text-error spacer-t thin">
          This action is not reversible!
        </Text>
        <FormCTA column spaceT>
          <Button
            disabled={loadingDelete}
            type="button"
            variant="error"
            onClick={() => {
              deactivateAccount(userId, teamId);
            }}
          >
            Delete
          </Button>
          <Button
            disabled={loadingDelete}
            type="button"
            variant="outline"
            transparent
            onClick={setCloseModal}
          >
            Cancel
          </Button>
        </FormCTA>
      </>
    );
  };

  const AccessModal = ({ name, userId, teamId, canPostJob }) => {
    return (
      <UserAssociation
        members={memberList}
        data={{
          name,
          userId,
          teamId,
          canPostJob,
        }}
        onUpdate={refetch}
        closeModal={setCloseModal}
      />
    );
  };

  return (
    <DefaultLayout showSubmenu isLoading={loading}>
      <BackPanel>
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            <Heading>Manage Team</Heading>
            <TablerSection
              className="spacer-t"
              headers={['Name', 'Email', 'Access Level', 'Actions']}
              tableProportion={[
                'tb-span-3',
                'tb-span-5',
                'tb-span-3 full',
                'tb-span-5 full relative',
                'tb-span-7-push full tb-push-right no-border-bottom no-header-mobile hidden-content',
              ]}
              tables={memberList.map(
                ({
                  first_name,
                  last_name,
                  email,
                  role,
                  team_id,
                  user_id,
                  can_post_jobs,
                }) => {
                  return [
                    first_name + ' ' + last_name,
                    email,
                    <>
                      <FormBlockItem>
                        <FormInput
                          className="field-input--variant-rounded"
                          type="select"
                          defaultValue={role}
                          name="role"
                          options={[
                            { label: 'Admin', value: 'admin' },
                            { label: 'Member', value: 'member' },
                          ]}
                          max-width="132"
                          selectSetting={{
                            onSelect: (e) =>
                              updateTeamUserRole(e, { team_id, user_id, role }),
                          }}
                          disabled={componentLoading}
                        />
                      </FormBlockItem>
                    </>,
                    role !== 'admin' ? (
                      <>
                        <div className="tabler--cta between">
                          <>
                            <Button
                              text="md thin"
                              onClick={() => {
                                setOpenModal(() => (
                                  <AccessModal
                                    name={first_name + ' ' + last_name}
                                    userId={user_id}
                                    teamId={team_id}
                                    canPostJob={can_post_jobs}
                                  />
                                ));
                              }}
                            >
                              Account Access
                            </Button>
                            <Button
                              variant="variant-link"
                              transparent
                              onClick={() => {
                                setOpenModal(() => (
                                  <DeleteModal
                                    userId={user_id}
                                    teamId={team_id}
                                  />
                                ));
                              }}
                            >
                              Remove User
                            </Button>
                          </>
                        </div>
                      </>
                    ) : (
                      ''
                    ),
                  ];
                }
              )}
              renderItem={(item) => {
                return <>{item}</>;
              }}
            />
          </>
        )}
      </BackPanel>
    </DefaultLayout>
  );
}
