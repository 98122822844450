import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import { BackPanel } from '../../../snippets';
import {
  ApplyCreditCardForm,
  LoadingSection,
  PurchaseHistory,
  PurchaseJobPostsForm,
} from '../../../components';
import { AppContext } from '../../../../Store';

export default function CompanyPurchaseJobPostsPage() {
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  useEffect(() => {
    if (state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        setRedirect(true);
      } else if (state?.activeCompany?.role === 'admin') {
        setLoading(false);
      }
    }
  }, [state]);

  if (redirect) {
    return <Redirect to="404" />;
  }

  return (
    <DefaultLayout showSubmenu isLoading={loading}>
      <BackPanel>
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            {showPaymentForm ? (
              <ApplyCreditCardForm
                onCancel={() => setShowPaymentForm(false)}
                onDone={() => {
                  setShowPaymentForm(false);
                }}
              />
            ) : (
              <>
                <PurchaseJobPostsForm
                  onApplyForCreditAccont={() => {
                    setShowPaymentForm(true);
                  }}
                />
                <PurchaseHistory />
              </>
            )}
          </>
        )}
      </BackPanel>
    </DefaultLayout>
  );
}
