import React from 'react';

export default function PlusSmall() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 8" fill="none">
      <path
        d="M7.76264 3.89475C7.76264 4.22041 7.49126 4.49179 7.1837 4.49179H4.57843V7.09705C4.57843 7.40462 4.30705 7.65791 3.99949 7.65791C3.67383 7.65791 3.42054 7.40462 3.42054 7.09705V4.49179H0.815275C0.489618 4.49179 0.236328 4.22041 0.236328 3.89475C0.236328 3.58718 0.489618 3.33389 0.815275 3.33389H3.42054V0.728631C3.42054 0.402973 3.67383 0.131592 3.99949 0.131592C4.30705 0.131592 4.57843 0.402973 4.57843 0.728631V3.33389H7.1837C7.49126 3.3158 7.76264 3.58718 7.76264 3.89475Z"
        fill="currentColor"
      />
    </svg>
  );
}
