import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import { BackPanel, Button, Heading } from '../../../snippets';
import { LoadingSection, TablerSection } from '../../../components';
import useCredits from '../../../hooks/useCredits';
import { AppContext } from '../../../../Store';
import moment from 'moment';

export default function CompanyBillingHistoryPage() {
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const { billing } = useCredits();

  useEffect(() => {
    if (state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        setRedirect(true);
      } else if (state?.activeCompany?.role === 'admin') {
        setLoading(false);
      }
    }
  }, [state]);

  if (redirect) {
    return <Redirect to="404" />;
  }

  return (
    <DefaultLayout showSubmenu isLoading={loading}>
      <BackPanel>
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            <Heading>Billing History</Heading>
            {billing.loading ? (
              <LoadingSection />
            ) : (
              <TablerSection
                className="spacer-t thin"
                tables={billing?.list.map(
                  ({ invoice_id, total, qty, created_at }) => {
                    return [
                      `${qty} ${total === 0 ? 'Free' : ''} Job Post${
                        qty > 1 ? 's' : ''
                      }`,
                      moment(created_at).format('DD/MM/YYYY'),
                      total > 0 && <span>&pound; {total.toFixed(2)}</span>,
                      total > 0 && (
                        <Button
                          type="button"
                          onClick={() => {
                            billing.handleDownloadInvoice(invoice_id);
                          }}
                          transparent
                          variant="variant-link"
                        >
                          Download PDF
                        </Button>
                      ),
                    ];
                  }
                )}
                renderItem={(item) => {
                  return <>{item}</>;
                }}
                headers={['Item', 'Date', 'Amount', 'Invoice']}
                tableProportion={[
                  'tb-span-4',
                  'tb-span-4',
                  'tb-span-4',
                  'tb-span-4',
                ]}
              />
            )}
          </>
        )}
      </BackPanel>
    </DefaultLayout>
  );
}
