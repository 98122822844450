import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { userVar, registerVar } from '../../graphql/config';
import Error from '../../components/Error';
import { getUser, saveUserLocalstorage } from '../../utils/helpers';
import {
  UPDATE_PROFILE,
  GET_SKILLS,
  GET_GENRES,
  ATTACH_SKILL,
  ATTACH_GENRE,
} from '../../graphql';
import { AppContext } from '../../Store';
import { LINK_GROUP } from '../../utils/links';
import UploadField from '../../components/UploadFieldV2';
import jquery from '../../styles/js/app';
import { useHistory } from 'react-router-dom';
import { MultiSelectInput, CustomCheckbox } from '../../v2/components';
import { arrToDropdownSelection } from '../../utils/helpers';
import { IconChevronDownDark } from '../../components/Icons';
import { agencyBudgetFrom, agencyBudgetTo } from '../../utils/values';
import MyAccountLayout from './layout/MyAccountLayout';

const CURRENCIES = [
  {
    label: '£ (GBP)',
    value: 'GBP',
  },
  {
    label: '$ (USD)',
    value: 'USD',
  },
  {
    label: '€ (EUR)',
    value: 'EUR',
  },
];

const ProfileEdit = () => {
  const history = useHistory();
  useEffect(() => {
    jquery();
  }, []);
  const [state] = useContext(AppContext);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [updateSkills] = useMutation(ATTACH_SKILL);
  const [updateGenres] = useMutation(ATTACH_GENRE);
  const [checkedExpertise, setCheckedExpertise] = useState([]);
  const [topThreeSkills, setTopThreeSkills] = useState([]);
  const [selectedSoftwareSkills, setSelectedSoftwareSkills] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [img, setImg] = useState(null);

  const { data: { skills = [] } = {} } = useQuery(GET_SKILLS, {
    variables: {
      software: false,
    },
  });

  const { data: { genres = [] } = {} } = useQuery(GET_GENRES);

  const { data: { skills: softwareSkills = [] } = {} } = useQuery(GET_SKILLS, {
    variables: {
      software: true,
    },
  });

  const { register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch([
    'budget_currency',
    'name',
    'about',
    'budget_min',
    'budget_max',
  ]);

  useEffect(() => {
    if (Object.keys(registerVar()).length === 0 && state?.activeCompany) {
      registerVar({
        agency_name: state?.activeCompany?.user?.agency_name,
        bio: state?.activeCompany?.user?.bio,
        avatar: state?.activeCompany?.user?.avatar,
        skills: state?.activeCompany?.user?.skills,
        genres: state?.activeCompany?.user?.genres,
        budget_currency: state?.activeCompany?.user?.budget_currency,
        budget_min: state?.activeCompany?.user?.budget_min,
        budget_max: state?.activeCompany?.user?.budget_max,
        website: state?.activeCompany?.user?.website,
      });
      return;
    }
  }, [state]);

  useEffect(() => {
    if (Object.keys(registerVar()).length > 0) {
      setCheckedExpertise(
        arrToDropdownSelection(
          registerVar()?.skills?.filter((s) => !s.software)
        )
      );
      setSelectedSoftwareSkills(
        arrToDropdownSelection(registerVar()?.skills?.filter((s) => s.software))
      );
      setSelectedGenres(arrToDropdownSelection(registerVar()?.genres));
      setTopThreeSkills(
        arrToDropdownSelection(
          registerVar()?.skills.filter((s) => s.pivot.is_top)
        )
      );
    }
  }, []);

  const validateIsFilled = (value) => {
    if (value === '') {
      return false;
    }
    if (
      watchFields?.budget_currency?.length === 0 &&
      registerVar()?.budget_currency === 0
    ) {
      return false;
    }
    return true;
  };

  const validateNotFilled = (value) => {
    if (value !== '') {
      return true;
    }
    if (
      watchFields?.budget_currency?.length === 0 &&
      registerVar()?.budget_currency === 0
    ) {
      return true;
    }
    return false;
  };

  const updateSkillsRequest = () => {
    toast.dismiss();

    return updateSkills({
      variables: {
        input: {
          sync: [...checkedExpertise, ...selectedSoftwareSkills].map(
            (s) => s.key
          ),
          isTop: [...checkedExpertise, ...selectedSoftwareSkills].map((s) =>
            topThreeSkills.map((t) => t.key).includes(s.key)
          ),
        },
      },
    });
  };

  const updateGenresRequest = () => {
    toast.dismiss();
    return updateGenres({
      variables: {
        input: { sync: selectedGenres.map((g) => g.key) },
      },
    });
  };

  const removeItem = (itemId, arr) => {
    const selected = arr.filter((item) => item !== itemId);
    return selected;
  };

  const removeArrItem = (itemId, arr) => {
    const selected = arr.filter((item) => item.key !== itemId);
    return selected;
  };

  const editProfile = async (data) => {
    toast.dismiss();

    updateSkillsRequest();
    updateGenresRequest();

    return updateProfile({
      variables: {
        input: {
          ...data,
          ...(img !== null && { avatar: img }),
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        toast.success('Profile update successfull!');
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        return history.push(`/${LINK_GROUP.AGENCY}/profile`);
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'resErr' })
      );
  };

  const optionsArrSkills = arrToDropdownSelection(skills);
  const optionsArrSoftwareSkills = arrToDropdownSelection(softwareSkills);
  const optionsArrGenres = arrToDropdownSelection(genres);

  return (
    <MyAccountLayout>
      <div className="black-card black-card__full">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-left-xs">
            <div className="form-v2__label">Avatar</div>
            <div className="form-v2__input-wrapper">
              <UploadField
                onSetImage={(file) => {
                  setImg(file);
                }}
                defaultImage={registerVar().avatar}
              />
            </div>
            <div style={{ marginTop: '25px' }}></div>
          </div>
          {/* Col FORM */}
          <div className="col-right-xs">
            <form onSubmit={handleSubmit(editProfile)}>
              <div className="form-v2__input-block">
                <div className="form-v2__label">Agency Name</div>
                <div className="form-v2__input-wrapper">
                  <input
                    className="form-v2__input text-bold changed"
                    type="text"
                    autoComplete="false"
                    name="agency_name"
                    defaultValue={registerVar().agency_name || null}
                    ref={register({ required: true })}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times"></i>
                  </div>
                </div>
                {errors.agency_name && (
                  <Error text="Agency name is required" noMargin />
                )}
              </div>
              <div className="form-v2__input-block">
                <div className="form-v2__label">About Us</div>
                <div className="form__input-wrapper">
                  <textarea
                    className="form-v2__textarea long-text"
                    type="text"
                    autoComplete="false"
                    name="bio"
                    rows="6"
                    maxLength="1000"
                    ref={register({ required: true })}
                    defaultValue={registerVar().bio || ''}
                  ></textarea>
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times"></i>
                  </div>
                  {errors.about && (
                    <Error text="About us is required" noMargin />
                  )}
                </div>
              </div>
              <div className="form-v2__input-block">
                <MultiSelectInput
                  options={optionsArrSkills}
                  onSelect={(selectedList, selectedItem) => {
                    setCheckedExpertise([
                      ...checkedExpertise,
                      { ...selectedItem },
                    ]);
                  }}
                  displayValue="label"
                  placeholder="Skills"
                  selectedValues={checkedExpertise}
                  removeItem={(skill) =>
                    setCheckedExpertise(removeItem(skill, checkedExpertise))
                  }
                  label={'Skills'}
                  emptyRecordMsg="Select Interested Fields"
                />
              </div>
              <div className="form-v2__input-block">
                <div className="form-v2__label">Top Three Skills</div>
                <div className="row flex-wrap space-x-3 space-y-3">
                  {checkedExpertise?.map((skill) => (
                    <CustomCheckbox
                      key={skill.key}
                      id={skill.key}
                      label={skill.label}
                      onChange={(value) => {
                        if (value) {
                          if (topThreeSkills.length < 3) {
                            setTopThreeSkills([...topThreeSkills, skill]);
                          }
                        } else {
                          setTopThreeSkills(
                            removeArrItem(skill.key, topThreeSkills)
                          );
                        }
                      }}
                      isSelected={topThreeSkills.some(
                        (item) => item.key === skill.key
                      )}
                      checked={topThreeSkills.some(
                        (item) => item.key === skill.key
                      )}
                      disabled={
                        !topThreeSkills.some(
                          (item) => item.key === skill.key
                        ) && topThreeSkills.length >= 3
                      }
                    />
                  ))}
                </div>
              </div>
              <div className="form-v2__input-block">
                <MultiSelectInput
                  options={optionsArrSoftwareSkills}
                  onSelect={(selectedList, selectedItem) => {
                    setSelectedSoftwareSkills([
                      ...selectedSoftwareSkills,
                      { ...selectedItem },
                    ]);
                  }}
                  displayValue="label"
                  placeholder="Skills"
                  selectedValues={selectedSoftwareSkills}
                  removeItem={(skill) =>
                    setSelectedSoftwareSkills(
                      removeItem(skill, selectedSoftwareSkills)
                    )
                  }
                  label={'Software Skills'}
                  emptyRecordMsg="Select software skills"
                />
              </div>
              <div className="form-v2__input-block">
                <MultiSelectInput
                  options={optionsArrGenres}
                  onSelect={(selectedList, selectedItem) => {
                    setSelectedGenres([...selectedGenres, { ...selectedItem }]);
                  }}
                  displayValue="label"
                  placeholder="Skills"
                  selectedValues={selectedGenres}
                  removeItem={(item) =>
                    setSelectedGenres(removeItem(item, selectedGenres))
                  }
                  label={'Category Experience'}
                  emptyRecordMsg="Select category experience"
                />
              </div>
              <div className="form-v2__input-block">
                <div className="form-v2__label">Project Budget</div>
                <div className="row">
                  <div className="col pr-0">
                    <p className="text-primary text-sm mb-1">Currency</p>
                    <div className="select__wrapper">
                      {Object.keys(registerVar()).length > 0 && (
                        <select
                          name="budget_currency"
                          className="form-v2__input"
                          ref={register({ required: true })}
                          defaultValue={registerVar()?.budget_currency}
                        >
                          {CURRENCIES?.map((c, i) => {
                            return (
                              <option value={c.value} key={i}>
                                {c.label}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      <div className="select_chevron">
                        <IconChevronDownDark />
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <p className="text-primary text-sm mb-1">From</p>
                    <div className="select__wrapper">
                      {Object.keys(registerVar()).length > 0 && (
                        <select
                          name="budget_min"
                          className="form-v2__input"
                          ref={register({
                            validate: {
                              isFilled: (value) => validateIsFilled(value),
                              notFilled: (value) => validateNotFilled(value),
                            },
                          })}
                          defaultValue={registerVar().budget_min}
                        >
                          {agencyBudgetFrom?.map((f) => {
                            return (
                              <option value={f.value} key={f.value}>
                                {watchFields.currency ??
                                  registerVar()?.currency ??
                                  ''}{' '}
                                {Intl.NumberFormat('en-IN').format(f.label)}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      <div className="select_chevron">
                        <IconChevronDownDark />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <p className="text-primary text-sm mb-1">To</p>
                    <div className="select__wrapper">
                      {Object.keys(registerVar()).length > 0 && (
                        <select
                          name="budget_max"
                          className="form-v2__input"
                          ref={register({
                            validate: {
                              isFilled: (value) => {
                                return (
                                  value >
                                  (watchFields?.budget_min
                                    ? watchFields?.budget_min
                                    : registerVar()?.budget_min)
                                );
                              },
                              notFilled: (value) => {
                                return value !== '';
                              },
                            },
                          })}
                          defaultValue={registerVar().budget_max}
                        >
                          {agencyBudgetTo?.map((j, i) => {
                            return (
                              <option value={j.value} key={j.value}>
                                {i === 0
                                  ? ''
                                  : (watchFields?.currency ??
                                      registerVar()?.currency ??
                                      '') + ' '}
                                {i === 0
                                  ? j.label
                                  : Intl.NumberFormat('en-IN').format(j.label)}
                              </option>
                            );
                          })}
                        </select>
                      )}
                      <div className="select_chevron">
                        <IconChevronDownDark />
                      </div>
                    </div>
                    {errors.budget_max && (
                      <Error text="Invalid project budget" noMargin />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-v2__input-block">
                <div className="form-v2__label">Website</div>
                <div className="form__input-wrapper">
                  <input
                    className="form-v2__input text-bold changed"
                    type="text"
                    autoComplete="false"
                    name="website"
                    ref={register({
                      require: false,
                    })}
                    defaultValue={registerVar().website || ''}
                  />
                  <div className="form__clear form__hidden">
                    <i className="fas fa-times"></i>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={!!loading}
                className="form-v2__btn form-v2__btn-agency-v2 d-block bg__gradient-1 mb-0"
              >
                {loading ? 'Loading...' : 'Save'}
              </button>
            </form>
          </div>
          {/* END of Col Form */}
        </div>
      </div>
    </MyAccountLayout>
  );
};

export default ProfileEdit;
