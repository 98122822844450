import React from 'react';
import { XIcon, XIconLight } from '../../icons';
import { Button } from '../../snippets';
import { generateClassName } from '../../../utils/helpers';

export default function ButtonClose({onClick = () => {}, stay = false, light = false, clean = false}) {
  return (
    <Button
      className={generateClassName(["app-btn-transparent app-btn-modal-close", stay ? 'no-sticky' : '', clean ? 'text-upsize' : '' ])}
      rounded
      monochrome={!clean}
      onClick={onClick} 
      noGradient
    >
      {light ? <XIconLight /> : <XIcon />}

    </Button>
  );
}
