import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Header from '../components/Header/Header';
import theme from '../../utils/theme';
import MainLayout from './main';
import { Footer, Sidenav, Subnav } from '../components';
import AppModal from '../components/AppModal/AppModal';
import { Gradient } from 'whatamesh';
import { LINK_GROUP } from '../../utils/links';

export default function DefaultLayout({
  className,
  children,
  showSubmenu = false,
  showNavigation = true,
  showBackButton = false,
  goBack = false,
  backButtonUrl = null,
  showCaseStudyEdit = false,
  hideSwitchCompany = false,
  isLoading = false,
}) {
  let { pathname } = useLocation();

  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient('#gradient-background');
  }, [pathname]);

  const cn = useMemo(() => {
    let _cn = ['theme', 'main-wrapper'];
    let type = pathname.split('/')[1];

    if (!Object.values(LINK_GROUP).includes(type)) {
      type = localStorage.getItem('userType');
    }

    _cn.push(theme.setTheme(type));

    return _cn.join(' ');
  }, [pathname, localStorage]);

  return (
    <MainLayout>
      <div className={cn}>
        <canvas
          id="gradient-background"
          className="display-background"
          data-transition-in
        ></canvas>
        <div className="page-layout">
          <AppModal />
          <Sidenav />
          <Header
            showBackButton={showBackButton}
            backButtonUrl={backButtonUrl}
            showNavigation={showNavigation}
            showCaseStudyEdit={showCaseStudyEdit}
            hideSwitchCompany={hideSwitchCompany}
            goBack={goBack}
          />
          {showSubmenu && !isLoading && <Subnav />}
          <main className="page-content-layout">{children}</main>
          <Footer />
        </div>
      </div>
    </MainLayout>
  );
}
