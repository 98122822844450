import React, { useMemo } from 'react';
import { generateClassName, getRandomGradient } from '../../../utils/helpers';
import useGroups from '../../hooks/useGroups';

export default function Switch({
  children,
  onChange,
  isError,
  name,
  isChecked = false,
  sameActive = false,
  className = '',
}) {
  const { groupname } = useGroups();
  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);

  return (
    <label className="app-switch-checkbox">
      <input
        type="checkbox"
        name={name ?? 'checkbox'}
        value={1}
        onChange={onChange}
        checked={isChecked}
      />
      <span
        className={generateClassName([
          'switch-slider',
          sameActive ? 'no-change-inactive' : '',
        ])}
        style={{
          backgroundImage: `${isChecked ? gradient : ''}`,
        }}
      >
        <div className="switch-slider--bullet"></div>
      </span>
      <span className="switch-label"></span>
    </label>
  );
}
