import React, { useContext, useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormInput,
  Heading,
} from '../../snippets';
import { AppContext } from '../../../Store';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useReactiveVar } from '@apollo/react-hooks';
import { currentOppVar } from '../../../graphql/config';
import { useForm } from 'react-hook-form';
import { DELETE_OPPORTUNITY, GET_AGENCY_APPLICANTS } from '../../../graphql';
import moment from 'moment';
import Error from '../../../components/Error';
import FormCta from '../../snippets/FormCta/FormCta';
import { useAppTheme } from '../../context/themeAppContext';
import useTeams from '../../hooks/useTeams';

export default function OpportunityClose({ type = 'job opportunity' }) {
  const [state] = useContext(AppContext);
  const showError = () => {
    toast.error('Something went wrong', { toastId: 'OppDashboard' });
  };

  const { id } = useReactiveVar(currentOppVar);
  const [closeDate, setCloseDate] = useState(new Date());
  const { activeCompany } = useTeams();

  const {
    action: { setCloseModal },
  } = useAppTheme();

  const {
    watch,
    register,
    handleSubmit,
    errors,
    getValues,
    clearErrors,
    setValue,
  } = useForm();
  const watchFields = watch(['reason']);
  const [deleteOpportunity, { loading }] = useMutation(DELETE_OPPORTUNITY);
  const { data: { applicants = [] } = {} } = useQuery(GET_AGENCY_APPLICANTS, {
    variables: {
      agency: activeCompany.team_id,
      opportunity: parseInt(id),
    },
    onError: () =>
      toast.error('Could not get applicants', { toastId: 'closeGetAppErr' }),
  });

  const validateIsFilled = (value) => {
    if (getValues('reason') === 'Filled by applicant') {
      return value !== '' || getValues('freelancer_name') !== '';
    }
    return true;
  };

  const validateNotFilled = (value) => {
    if (
      getValues('reason') !== 'Filled by applicant' &&
      (value !== '' || getValues('freelancer_name') !== '')
    ) {
      return false;
    }
    return true;
  };

  const deleteOpportunityRequest = (data) =>
    deleteOpportunity({
      variables: {
        id,
        input: {
          reason: data.reason,
          closed_at: moment(closeDate).format('YYYY-MM-DD'),
          ...(data.freelancer && { freelancer: { connect: data.freelancer } }),
          ...(data.freelancer_name && {
            freelancer_name: data.freelancer_name,
          }),
        },
      },
    })
      .then((data) => {
        toast.dismiss();
        setCloseModal();
        currentOppVar({});

        toast.success(`${type} closed!`, { autoClose: 4000 });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((e) => showError());

  return (
    <>
      <Heading>Closing the {type}</Heading>
      <FormBlock className={'spacer-t'}>
        <FormBlockItem label={'Reason'}>
          <FormInput
            type="select"
            isRequired
            name="reason"
            options={[
              {
                value: 'Filled elsewhere',
              },
              { value: 'Job postponed' },
              { value: 'Job no longer exists' },
              { value: 'Filled by applicant' },
            ]}
            error={errors.reason ? 'Reason should be not empty' : ''}
            register={register}
            selectSetting={{
              onSelect: (v) => setValue('reason', v),
            }}
          />
        </FormBlockItem>

        {watchFields.reason && watchFields.reason === 'Filled by applicant' && (
          <>
            <FormBlockItem>
              <FormInput
                type="select"
                register={register}
                name="freelancer"
                settings={{
                  validate: {
                    isFilled: (value) => validateIsFilled(value),
                    notFilled: (value) => validateNotFilled(value),
                  },
                }}
                options={[
                  {
                    value: '',
                    label: '- Select applicant -',
                  },
                  ...(applicants
                    ?.filter((a) => a.user)
                    ?.map(({ user: { name, id } }) => ({
                      value: id,
                      label: name,
                    })) ?? []),
                ]}
              ></FormInput>
            </FormBlockItem>
            <FormBlockItem label={'or'}>
              <FormInput
                name="freelancer_name"
                register={register}
                placeholder="Please input the selected applicant name"
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>
          </>
        )}
        {errors.reason && <Error text="Please select an option." />}
        {errors.freelancer?.type === 'isFilled' && (
          <Error text="Please select an applicant." />
        )}
        <FormBlockItem label={'Date Closed'}>
          <FormInput
            type="datepicker"
            datePickerSetting={{
              onChange: setCloseDate,
              value: closeDate,
              className: 'form__input date',
              format: 'dd/MM/y',
              name: 'close_date',
              required: false,
              clearIcon: null,
              dayPlaceholder: 'dd',
              monthPlaceholder: 'mm',
              yearPlaceholder: 'yyyy',
            }}
          />
        </FormBlockItem>

        <FormCta center>
          <Button
            size="xxl"
            onClick={handleSubmit(deleteOpportunityRequest)}
            disabled={loading}
          >
            {loading ? 'Loading...' : `Close ${type}`}
          </Button>
        </FormCta>
      </FormBlock>
    </>
  );
}
