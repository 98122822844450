import React from 'react';

export default function ChevronLeftIcon({ ...props }) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.179688 7.25L0.808594 6.64844L6.05859 1.39844L6.6875 0.769531L7.91797 2L7.28906 2.62891L2.66797 7.25L7.28906 11.8984L7.91797 12.5L6.6875 13.7578L6.05859 13.1289L0.808594 7.87891L0.179688 7.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
