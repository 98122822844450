import gql from 'graphql-tag';

export const GET_POSITIONS = gql`
  query Positions($name: String, $industries: [ID!], $jobLevels: [ID!]) {
    positions(name: $name, industries: $industries, jobLevels: $jobLevels) {
      id
      name
    }
  }
`;
