import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../../../layouts/defaultLayout';
import { BackPanel, FormContent, Heading } from '../../../../snippets';
import { StepCounter, TeamRegisterLayers1 } from '../../../../components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import useGroups from '../../../../hooks/useGroups';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  CHECK_REGISTERABLE_USER,
  CREATE_TEAM_USER,
  PROCESS_INVITATION,
} from '../../../../../graphql';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { registerVar } from '../../../../../graphql/config';

export default function CompanyTeamRegisterPage() {
  const { id } = useParams();
  const history = useHistory();
  const { groupname } = useGroups();
  const { register, handleSubmit, errors, clearErrors, watch, setValue } =
    useForm();
  const [dontAllow, setDontAllow] = useState(false);

  const tokens = useMemo(() => {
    const split = id?.split('&');
    return {
      token: split[0],
      email: split[1],
    };
  }, [id]);

  const [check, { data, refetch, loading }] = useLazyQuery(
    CHECK_REGISTERABLE_USER,
    {
      onCompleted: () => isUniqueEmail({ showFeedback: true }),
      onError: () => refetch(),
    }
  );

  useEffect(() => {
    if (tokens?.email) {
      check({ variables: { email: tokens?.email } });
    }
  }, [tokens.email, check]);

  const isUniqueEmail = () => {
    toast.dismiss();
    if (
      data?.checkRegisterableUser !== null &&
      data?.checkRegisterableUser !== undefined &&
      data?.checkRegisterableUser?.type !== 'FREELANCER'
    ) {
      addExistingUser();
    }
  };

  const [createTeamUser] = useMutation(CREATE_TEAM_USER);
  const [setAcceptInvite] = useMutation(PROCESS_INVITATION);

  const handleOnAcceptInvitation = useCallback(async () => {
    try {
      toast.dismiss();
      const { data } = await setAcceptInvite({
        variables: tokens,
      });

      if (data?.processInvitation?.team_id) {
        const cookies = new Cookies();
        cookies.set('lh_team', data?.processInvitation?.team_id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      }
      history.push({
        pathname: `/${groupname}/team/welcome`,
        state: {
          mode: 'Invitation_Accepted',
        },
      });
      toast.success('Invitation accepted successfully', { autoClose: 4000 });
    } catch (error) {
      console.error(error);
      toast.error('Could not accept invite', { toastId: 'appErr' });
    }
  }, [groupname, history, setAcceptInvite, tokens]);

  const addExistingUser = useCallback(async () => {
    toast.dismiss();

    try {
      const { data } = await createTeamUser({
        variables: {
          input: tokens,
        },
      });

      if (data?.createTeamUser?.team_id) {
        const cookies = new Cookies();
        cookies.set('lh_team', data?.createTeamUser?.team_id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      }
      await handleOnAcceptInvitation();
    } catch (error) {
      console.error(error?.message ?? 'unknown_error');
      if (error?.graphQLErrors[0]?.extensions?.reason) {
        setDontAllow(true);
        toast.error(error?.graphQLErrors[0]?.extensions?.reason, {
          toastId: 'appErr',
        });
        return;
      }
      toast.error('Could not create team account', { toastId: 'appErr' });
    }
  }, [tokens, createTeamUser, handleOnAcceptInvitation]);

  const CheckEmail = (input) => {
    if (dontAllow) {
      return;
    }
    registerVar({
      email: tokens.email,
      password: input?.password,
    });
    return history.push({
      pathname: `/${groupname}/team/register/profile`,
      state: tokens,
    });
  };
  return (
    <DefaultLayout>
      <BackPanel isSmall>
        <Heading>{'Create A Team Account'}</Heading>
        <FormContent>
          <StepCounter steps={3} currentAt={1}></StepCounter>
          <TeamRegisterLayers1
            heading="Login Information"
            forms={{
              onSubmit: handleSubmit(CheckEmail),
              errors,
              clearErrors,
              input: tokens,
              loading,
              setValue,
              watch,
              register,
              disabled: dontAllow,
            }}
          />
        </FormContent>
      </BackPanel>
    </DefaultLayout>
  );
}
