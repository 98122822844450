import React, { useMemo } from 'react';
import { BackPanel, Text } from '../../snippets';
import ProfileOtherDetails from '../ProfileOtherDetails/ProfileOtherDetails';
import VettedIcon from '../VettedIcon/VettedIcon';
import useGroups from '../../hooks/useGroups';
import { getRandomGradient } from '../../../utils/helpers';

export default function SectionViewOtherProfile({
  imageComponent: ImageComponent,
  actionComponent: ActionComponent,
  headline = '',
  pronouns = null,
  subTitle = '',
  profilePictureHeading,
  imageRounded = false,
  profileImage = null,
  desktop,
  mobile,
  vetted = false,
}) {
  const { groupname } = useGroups();

  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);

  return (
    <BackPanel>
      <div className="profile-layout-other">
        <div className="l-side">
          <div className={`profile-block-other--avatar`}>
            {profilePictureHeading && (
              <div className="avatar-meta-name">{profilePictureHeading}</div>
            )}
            <div
              className={`avatar-meta-image${
                imageRounded ? ' full-rounded' : ''
              }`}
              style={{ overflow: 'visible' }}
            >
              <div className="aspect-ratio-square">
                {profileImage && (
                  <img
                    src={profileImage}
                    className="cover"
                    alt={'Profile Picture'}
                  />
                )}
              </div>
              {vetted && (
                <div className="profile-block-other--vetted">
                  <VettedIcon
                    gradientColor={['#3DC2EC', '#6B8AFE', '#4c3bcf']}
                  />
                  <Text
                    as="span"
                    className="active"
                    style={{
                      backgroundImage: gradient,
                    }}
                  >
                    LH Vetted
                  </Text>
                </div>
              )}
            </div>
          </div>
          <h1 className="mb-0" style={{ fontWeight: 600, lineHeight: 1.5 }}>
            {headline}{' '}
            {pronouns && (
              <span
                className="h1-subheading mb-0"
                style={{ display: 'inline' }}
              >
                ({pronouns})
              </span>
            )}
          </h1>
          <br />
          {subTitle && (
            <h1
              className="h1-subheading"
              style={{ display: 'inline-block', fontWeight: 600 }}
            >
              {subTitle}
            </h1>
          )}

          {desktop && (
            <div className="hide-on-mobile">
              <ProfileOtherDetails
                className="mt-4 column"
                contents={desktop?.left}
              ></ProfileOtherDetails>
            </div>
          )}
          {mobile && (
            <div className="hide-on-desktop">
              <ProfileOtherDetails
                className="mt-4"
                contents={mobile?.left}
              ></ProfileOtherDetails>
              <div className="r-side">
                <ProfileOtherDetails
                  className="mt-4"
                  contents={mobile?.right}
                ></ProfileOtherDetails>
              </div>
            </div>
          )}
        </div>
        <div className="r-side hide-on-mobile">
          {desktop && (
            <ProfileOtherDetails
              fullWidth
              contents={desktop?.right}
            ></ProfileOtherDetails>
          )}
        </div>
      </div>
    </BackPanel>
  );
}
