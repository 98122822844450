import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, Heading, Text, Button } from '../../snippets';
import {
  AgencyNetworkAgencyView,
  AgencyNetworkCaseStudy,
  ServicesFilter,
  Switch,
} from '../../components';
import { updateUrl } from '../../../utils/helpers';

export default function AgencyNetworkCompanyPage() {
  const [checked, setChecked] = useState(false);
  const [clear, setClear] = useState(false);
  const [selected, setSelected] = useState([]);

  const getUrlParams = () => {
    const url = new URL(window.location);
    const filter = url.searchParams.get('filter');
    if (filter === 'agency') {
      setChecked(false);
      return;
    }

    if (filter === 'casestudies') {
      setChecked(true);
      return;
    }
  };

  useEffect(() => {
    getUrlParams();
  }, []);

  return (
    <DefaultLayout>
      <BackPanel>
        <div className="meta-header--grid">
          <Heading>The Agency Marketplace</Heading>
          <Button onClick={() => setClear(true)} text="normal">
            Clear Filters
          </Button>
        </div>
        <div className="filter-agency d-flex">
          <Text
            as="span"
            className={!checked ? 'active text--mesh' : ''}
            onClick={() => {
              setChecked(false);
              updateUrl(checked ? 'agency' : 'casestudies', 'filter');
            }}
          >
            Filter By Agency
          </Text>
          <Switch
            onChange={(e) => {
              setChecked(e.currentTarget.checked);
              updateUrl(checked ? 'agency' : 'casestudies', 'filter');
            }}
            sameActive
            isChecked={checked}
          />{' '}
          <Text
            as="span"
            className={checked ? 'active text--mesh' : ''}
            onClick={() => {
              setChecked(true);
              updateUrl(checked ? 'agency' : 'casestudies', 'filter');
            }}
          >
            Filter By Case Studies
          </Text>
        </div>
        <ServicesFilter
          noSpaceBottom={!checked}
          onSetFilter={setSelected}
          clear={clear}
          setClear={setClear}
          hideClear
        />

        {checked && <AgencyNetworkCaseStudy filters={selected} />}
      </BackPanel>
      {!checked && <AgencyNetworkAgencyView filters={selected} />}
    </DefaultLayout>
  );
}
