import React, { forwardRef, useMemo } from 'react';
import { Link as RLInk, NavLink } from 'react-router-dom';
import { generateClassName } from '../../../utils/helpers';

const Link = forwardRef(function Link(
  {
    className = '',
    to = '#',
    isNavLink = false,
    children,
    external = false,
    jumper = false,
    href = null,
    isMinimalism = false,
    isCapitalize,
    ...rest
  },
  ref
) {
  const classNames = useMemo(() => {
    const _c = ['app-link', className];
    if (isMinimalism) {
      _c.push('app-link-minimalism');
    }
    if (isCapitalize) {
      _c.push('text-capitalize');
    }
    return generateClassName(_c);
  }, [className, isCapitalize, isMinimalism]);

  if (
    external ||
    to?.includes('http') ||
    href?.includes('http') ||
    to?.includes('www') ||
    href?.includes('www')
  ) {
    return (
      <a
        href={href ?? to}
        className={classNames}
        target="_blank"
        {...rest}
        ref={ref}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  if (jumper) {
    return (
      <a href={href ?? to} className={classNames} {...rest} ref={ref}>
        {children}
      </a>
    );
  }

  const linkProps = {
    to: href ?? to,
    className: classNames,
    ...rest,
    ref,
  };

  if (isNavLink) {
    return <NavLink {...linkProps}>{children}</NavLink>;
  }

  return <RLInk {...linkProps}>{children}</RLInk>;
});

export default Link;
