import React from 'react';

export default function LinkedinIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16" 
    >
      <path
        d="M3.51562 15.9999H0.246094V5.48821H3.51562V15.9999ZM1.86328 4.08196C0.84375 4.08196 0 3.20305 0 2.14836C0 0.706956 1.54688 -0.207107 2.8125 0.531175C3.41016 0.847581 3.76172 1.48039 3.76172 2.14836C3.76172 3.20305 2.91797 4.08196 1.86328 4.08196ZM12.4805 15.9999V10.9023C12.4805 9.6718 12.4453 8.12492 10.7578 8.12492C9.07031 8.12492 8.82422 9.42571 8.82422 10.7968V15.9999H5.55469V5.48821H8.68359V6.92961H8.71875C9.17578 6.12102 10.2305 5.24211 11.8125 5.24211C15.1172 5.24211 15.75 7.4218 15.75 10.2343V15.9999H12.4805Z"
        fill="currentColor"
      />
    </svg>
  );
}
