import React from 'react';
import { BackPanel, Heading } from '../../snippets';
import { generateClassName } from '../../../utils/helpers';

function SectionFormContent({
  actionComponent: ActionComponent,
  children,
  headline = '',
  contentFull = false,
  className,
  leftWide = false,
  singleGrid
}) {
  return (
    <div className={className}>
      {headline !== '' && <Heading>{headline}</Heading>}
      <div
        className={generateClassName([
          'profile-layout',
          leftWide ? 'left-wide' : '',
          singleGrid ? 'single-grid' : '',
        ])}
      >
        <div className={generateClassName([
          'l-side',
          leftWide ? 'left-wide' : '',
        ])}>
          <div className="profile-action--block">{ActionComponent}</div>
        </div>
        <div className={`r-side${contentFull ? ' full-size' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default function SectionForm({
  actionComponent,
  children,
  headline = '',
  contentFull = false,
  noPanel = false,
  className = '',
  leftWide = false,
  singleGrid = false
}) {
  const props = {
    headline,
    actionComponent,
    contentFull,
    children,
    className,
    leftWide,
    singleGrid
  };
  if (noPanel) {
    return <SectionFormContent {...props}></SectionFormContent>;
  }
  return (
    <BackPanel>
      <SectionFormContent {...props}></SectionFormContent>
    </BackPanel>
  );
}
