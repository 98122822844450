import React from 'react';

export default function XIcon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 9" className='app-icon app-icon-close'>
      <path
        d="M7.96265 6.83898C8.28726 7.13863 8.28726 7.663 7.96265 7.96265C7.81283 8.11247 7.61306 8.18738 7.4133 8.18738C7.18857 8.18738 6.98881 8.11247 6.83898 7.96265L4.2171 5.34077L1.57025 7.96265C1.42043 8.11247 1.22066 8.18738 1.0209 8.18738C0.79617 8.18738 0.596407 8.11247 0.446585 7.96265C0.121972 7.663 0.121972 7.13863 0.446585 6.83898L3.06847 4.19213L0.446585 1.57025C0.121972 1.27061 0.121972 0.746229 0.446585 0.446585C0.746229 0.121972 1.27061 0.121972 1.57025 0.446585L4.2171 3.06847L6.83898 0.446585C7.13863 0.121972 7.663 0.121972 7.96265 0.446585C8.28726 0.746229 8.28726 1.27061 7.96265 1.57025L5.34077 4.2171L7.96265 6.83898Z"
        fill="currentColor"
      />
    </svg>
  );
}
