import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../../snippets';
import {
  LoadingSection,
  SectionProfile,
  TablerSection,
} from '../../../components';
import { useForm } from 'react-hook-form';
import {
  CREATE_INVITATION,
  DELETE_INVITATION,
  UPDATE_INVITATION,
  VIEW_INVITATIONS,
} from '../../../../graphql';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { AppContext } from '../../../../Store';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CompanyInvitationsPage() {
  const { register, handleSubmit, errors, reset } = useForm();
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [invitationList, setInvitationList] = useState([]);

  /** Retrieve Open Invitations */
  const [getInvitationList] = useLazyQuery(VIEW_INVITATIONS, {
    variables: {
      team_id: Number(state?.activeCompany?.team_id),
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ viewInvitations }) => {
      setLoading(false);
      const _tablerData = viewInvitations.map((item, index) => {
        return [
          index + 1,
          item.email,
          moment(item?.updated_at).format('DD/MM/YYYY'),
          item?.invite_status?.charAt(0).toUpperCase() +
            item?.invite_status.slice(1),
          <FormCTA className={'half-by-half '}>
            <Button
              onClick={() => resendInvite(item?.id)}
              size={'xxl'}
              text="2line"
              smradii
            >
              Send Again
            </Button>
            <div className="space-x thin">
              <Button
                smradii
                onClick={() => deleteInvite(item?.id)}
                scheme={'error'}
              >
                Cancel
              </Button>
            </div>
          </FormCTA>,
        ];
      });
      setInvitationList(_tablerData);
    },
  });

  /** Create New Invitation */
  const [createInvitation, { loading: onSaving }] =
    useMutation(CREATE_INVITATION);

  const sendInvitation = (data) => {
    toast.dismiss();
    createInvitation({
      variables: {
        input: {
          ...data,
          team_id: Number(state?.activeCompany?.team_id),
          invite_status: 'pending',
        },
      },
    })
      .then(() => {
        getInvitationList();
        reset();
        toast.success('Invite sent succesfully!', { autoClose: 4000 });
      })
      .catch((e) => {
        toast.error(e.graphQLErrors[0].extensions.reason, {
          toastId: 'invitations',
        });
      });
  };

  /** Resend Invitation */
  const [resendInvitation] = useMutation(UPDATE_INVITATION);
  const resendInvite = (id) => {
    toast.dismiss();
    resendInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        toast.success('Invite resent succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not resend invite', { toastId: 'appErr' })
      );
  };

  /** Delete Invitation */
  const [deleteInvitation] = useMutation(DELETE_INVITATION);
  const deleteInvite = (id) => {
    toast.dismiss();
    deleteInvitation({ variables: { id: id } })
      .then(() => {
        getInvitationList();
        toast.success('Invite deleted succesfully!', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not delete invite', { toastId: 'appErr' })
      );
  };

  useEffect(() => {
    if (state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        setRedirect(true);
      } else if (state?.activeCompany?.role === 'admin') {
        getInvitationList();
      }
    }
  }, [state, getInvitationList]);

  if (redirect) {
    return <Redirect to="404" />;
  }

  return (
    <DefaultLayout showSubmenu isLoading={loading}>
      <BackPanel>
        {loading ? (
          <LoadingSection />
        ) : (
          <>
            <Heading>Invitations</Heading>
            <SectionProfile
              hideImage
              noPanel
              actionComponent={
                <>
                  <Heading as="h3" upsize>
                    Invite Team Member
                  </Heading>
                </>
              }
            >
              <form onSubmit={handleSubmit(sendInvitation)}>
                <FormBlock className={'mb-4'}>
                  <FormBlockItem label="Team Member Email">
                    <FormInput
                      type="email"
                      register={register}
                      name="email"
                      isRequired
                      error={
                        errors.email ? 'Please add a valid email address' : ''
                      }
                    />
                  </FormBlockItem>
                </FormBlock>
                <FormCTA>
                  <Button
                    type="submit"
                    variant="outline"
                    className="w-full app-btn--bulky"
                    disabled={onSaving}
                  >
                    Send Invite
                  </Button>
                </FormCTA>
              </form>
            </SectionProfile>

            {invitationList.length > 0 && (
              <>
                <Heading as="h3" upsize className="spacer-t thick-xl">
                  Previous Invites
                </Heading>
                <TablerSection
                  className="spacer-t thin"
                  tables={invitationList}
                  renderItem={(item) => {
                    return <>{item}</>;
                  }}
                  headers={['#', 'Email', 'Date Sent', 'Status', 'Action']}
                  tableProportion={[
                    'tb-span-1',
                    'tb-span-5',
                    'tb-span-3',
                    'tb-span-2',
                    'tb-span-5 no-header-mobile',
                  ]}
                />
              </>
            )}
          </>
        )}
      </BackPanel>
    </DefaultLayout>
  );
}
