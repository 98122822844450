import React, { useMemo, useContext } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { ProfileDetails, SectionProfile } from '../../components';
import { userVar } from '../../../graphql/config';
import { LINK_GROUP } from '../../../utils/links';
import { useQuery } from '@apollo/react-hooks';
import CONTENTS_UTIL from '../../../utils/contents';
import { GET_TEAM_USERS } from '../../../graphql';
import { AppContext } from '../../../Store';
import { checkAndModifyPhoneNumber } from '../../../utils/helpers';
import { Button } from '../../snippets';

export default function PersonalInformationPage() {
  const data = userVar();
  const [agencyState] = useContext(AppContext);
  const { data: { viewUserTeam } = {} } = useQuery(GET_TEAM_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      team_id: agencyState?.activeCompany?.team_id,
    },
  });

  const contents = useMemo(() => {
    const {
      bio,
      address_1,
      address_2,
      city,
      state,
      country,
      zip,
      email,
      agency_name,
      agency_phone,
    } = data;

    const phoneOutput = (agency_phone || [])
      .map(
        (p) =>
          `<p class="mb-17" style="font-size: 14px; font-weight: 400">${checkAndModifyPhoneNumber(
            p?.attributes?.phone
          )} </p>`
      )
      .join('');

    const teamOutput = viewUserTeam
      ?.map(
        (tm) =>
          `<p class="mb-17" style="font-size: 14px; font-weight: 400">${
            tm.team?.first_name + ' ' + tm.team?.last_name
          } </p>`
      )
      .join('');

    return CONTENTS_UTIL.generateDetails(
      [
        {
          title: 'Agency Name',
          value: `${agency_name}`,
        },
        {
          title: 'About Us',
          value: bio ?? '-',
        },
        {
          title: 'Address',
          value: `<p class="mb-17" style="font-size: 14px; font-weight: 400">
          ${address_1}</p>
          <p class="mb-17" style="font-size: 14px; font-weight: 400">
          ${address_2 ?? ''}</p>
          <p class="mb-17" style="font-size: 14px; font-weight: 400">
          ${city}</p>
          <p class="mb-17" style="font-size: 14px; font-weight: 400">
          ${state}</p>
          <p class="mb-17" style="font-size: 14px; font-weight: 400">
          ${country}</p>
          <p style="font-size: 14px; font-weight: 400">${zip}</p>
            `,
        },

        {
          title: 'Phone Number',
          value: `${phoneOutput}`,
        },
        {
          title: 'Email Address',
          value: email ?? '-',
        },
      ],
      [
        {
          title: 'Team Members',
          value: `${teamOutput ?? '-'}`,
        },
      ]
    );
  }, [data, viewUserTeam]);
  return (
    <DefaultLayout showNavigation showSubmenu>
      <SectionProfile
        contentFull
        profileImage={data?.avatar || '/img/agency-picture-placeholder@2x.jpg'}
        headline="Account Information"
        actionComponent={
          <Button
            as="link"
            size="xxl"
            to={`/${LINK_GROUP.AGENCY}/edit-account-information`}
            className="w-full app-btn--text-xs"
            minradii
          >
            EDIT ACCOUNT INFORMATION
          </Button>
        }
      >
        <ProfileDetails contents={contents} />
      </SectionProfile>
    </DefaultLayout>
  );
}
