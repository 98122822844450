import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../snippets';

import { useForm } from 'react-hook-form';
import { LoadingSection, SectionForm, TablerSection } from '../';
import useBilling from '../../hooks/useBilling';
import { AppContext } from '../../../Store';
import { GET_DEFAULT_PAYMENT_METHOD } from '../../../graphql';
import { useLazyQuery } from '@apollo/react-hooks';

export default function ManagePaymentForm({
  singleGrid = false,
  handleSave = () => {},
}) {
  const [state] = useContext(AppContext);
  const { register, errors, clearErrors } = useForm();
  const [filled, setFilled] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);
  const [cardExpiry, setCardExpiry] = useState(null);
  const [getDefaultPaymentMethod, { loading: loadingPaymentMethod }] =
    useLazyQuery(GET_DEFAULT_PAYMENT_METHOD, {
      fetchPolicy: 'network-only',
      variables: {
        teamId: state?.activeCompany?.team_id,
      },
      onCompleted: (data) => {
        const { defaultPaymentMethod } = data;

        if (defaultPaymentMethod) {
          setCardNumber(`**** **** **** ${defaultPaymentMethod?.last4}`);
          setCardExpiry(
            `${('0' + defaultPaymentMethod?.exp_month).slice(-2)}/${
              defaultPaymentMethod?.exp_year
            }`
          );
          setFilled(true);
        }
      },
    });

  const {
    billings: { address = {} } = {},
    handleUpdateAddress,
    handleOnSaveBilling,
    savingAddress,
    savingBilling,
    stripe,
    setCardComplete,
    setError,
    onChangeBillingAddress,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
  } = useBilling();

  useEffect(() => {
    if (state?.activeCompany?.team_id) {
      getDefaultPaymentMethod();
    }
  }, [state, getDefaultPaymentMethod]);

  const createOptions = () => ({
    style: {
      base: {
        fontSize: '16px',
        color: '#0e0e0e',
        '::placeholder': {
          color: '#acacac',
        },
        height: '43px',
        letterSpacing: '0.18px',
        lineHeight: '43px',
        fontWeight: 'bold',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
    variables: {
      colorPrimary: '#0570de',
      colorBackground: 'blue',
    },
    classes: {
      base: 'StripeCardBase',
    },
  });
  return (
    <>
      <Heading>Manage Payment Info</Heading>

      <SectionForm
        noPanel
        actionComponent={<Heading as="h3">Billing Address</Heading>}
        singleGrid={singleGrid}
      >
        <form
          className="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const res = await handleUpdateAddress(e);

            if (res) {
              handleSave();
            }
          }}
          autoComplete="off"
        >
          <FormBlock>
            <FormBlockItem label={'Address 1'}>
              <FormInput
                type="text"
                register={register}
                name="line1"
                isRequired
                error={errors.line1 ? `Please enter an address` : null}
                defaultValue={address.line1}
                clearErrors={clearErrors}
                onChange={onChangeBillingAddress}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Address 2'}>
              <FormInput
                type="text"
                register={register}
                name="line2"
                defaultValue={address?.line2}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'City'}>
              <FormInput
                type="text"
                register={register}
                name="city"
                isRequired
                error={errors.city ? `Please enter city` : null}
                defaultValue={address?.city}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'County'}>
              <FormInput
                type="text"
                register={register}
                name="state"
                defaultValue={address?.state}
                isRequired
                error={errors.county ? `Please enter county` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Country'}>
              <FormInput
                type="text"
                register={register}
                name="country"
                defaultValue={address?.country}
                isRequired
                error={errors.country ? `Please enter country` : null}
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Postcode / Zip Code'}>
              <FormInput
                type="text"
                register={register}
                name="postal_code"
                defaultValue={address?.postal_code}
                isRequired
                error={
                  errors.postal_code ? `Please enter postcode / zip code` : null
                }
                clearErrors={clearErrors}
              ></FormInput>
            </FormBlockItem>

            <FormCTA className={'space-t'}>
              <Button
                className="app-btn-submit"
                type="submit"
                disabled={savingAddress}
                size="xxl"
              >
                {savingAddress ? 'Loading...' : 'Save Address'}
              </Button>
            </FormCTA>
          </FormBlock>
        </form>
      </SectionForm>

      {loadingPaymentMethod ? (
        <LoadingSection />
      ) : (
        <>
          <SectionForm
            actionComponent={<Heading as="h3">Payment Information</Heading>}
            noPanel
            className="space-t--xxl"
            singleGrid={singleGrid}
          >
            {filled ? (
              <>
                <TablerSection
                  headers={['Current card saved', 'Expires']}
                  tables={[[cardNumber, cardExpiry]]}
                  tableProportion={['tb-span-8', 'tb-span-8']}
                  renderItem={(item) => {
                    return <>{item}</>;
                  }}
                ></TablerSection>
                <FormCTA className={'space-t'}>
                  <Button
                    className="app-btn-submit"
                    disabled={savingBilling}
                    size="xxl"
                    type="button"
                    onClick={() => {
                      setFilled(false);
                    }}
                  >
                    {savingBilling ? 'Loading...' : 'Update Card Information'}
                  </Button>
                </FormCTA>
              </>
            ) : (
              <>
                <form
                  className="form"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const res = await handleOnSaveBilling(e);

                    if (res) {
                      handleSave();
                      getDefaultPaymentMethod();
                    }
                  }}
                  autoComplete="off"
                >
                  <FormBlock>
                    <FormBlockItem label={'Card Number'}>
                      <CardNumberElement
                        className="form__input"
                        {...createOptions()}
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </FormBlockItem>

                    <FormBlockItem label={'Expiry Date'}>
                      <CardExpiryElement
                        className="form__input"
                        {...createOptions()}
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </FormBlockItem>
                    <FormBlockItem label={'CVC'}>
                      <CardCvcElement
                        className="form__input"
                        {...createOptions()}
                        onChange={(e) => {
                          setError(e.error);
                          setCardComplete(e.complete);
                        }}
                      />
                    </FormBlockItem>
                  </FormBlock>
                  <FormCTA className={'space-t'}>
                    <Button
                      className="app-btn-submit"
                      type="submit"
                      disabled={!stripe || savingBilling}
                      size="xxl"
                    >
                      {savingBilling ? 'Loading...' : 'Save'}
                    </Button>
                  </FormCTA>
                </form>
              </>
            )}
          </SectionForm>
        </>
      )}
    </>
  );
}
