import React from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, Heading } from '../../snippets';

export default function NotFoundPage() {
  return (
    <DefaultLayout>
      <BackPanel>
        <Heading>Page Not Found!</Heading>
      </BackPanel>
    </DefaultLayout>
  );
}
