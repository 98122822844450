import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_INDUSTRIES,
  GET_SKILLS,
  GET_GENRES,
  GET_OPPORTUNITY,
  GET_QUALIFICATIONS,
  GET_ALL_JOB_LEVELS,
  UPDATE_OPPORTUNITY,
  GET_POSITIONS,
} from '../../../../graphql';
import {
  TYPE_CURRENCY,
  TYPE_JOBTYPES_FOR_OPPORTUNITY,
  TYPE_SALARY_UNIT,
  TYPE_SUBJOBTYPES,
  TYPE_WORK_LOCATION,
} from '../../../placeholders/consultant/types';
import { LoadingSection, SectionForm, Switch } from '../../../components';
import { toast } from 'react-toastify';
import { arrToDropdownSelectionV2 } from '../../../../utils/helpers';
import { SearchBox } from '@mapbox/search-js-react';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { LINK_GROUP } from '../../../../utils/links';
import moment from 'moment';

export default function OpportunityEditPage() {
  const { id } = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const multiselectIndustryExperienceRef = useRef(null);
  const multiselectSkillRef = useRef(null);
  const multiselectTopSkillRef = useRef(null);
  const multiselectSoftwareSkillRef = useRef(null);
  const multiselectTopSoftwareSkillRef = useRef(null);
  const multiselectQualificationRef = useRef(null);
  const multiselectQualificationMustHaveRef = useRef(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSoftwareSkills, setSelectedSoftwareSkills] = useState([]);
  const [topSkills, setTopSkills] = useState([]);
  const [topSoftwareSkills, setTopSoftwareSkills] = useState([]);
  const [selectedIndustryQualifications, setSelectedIndustryQualifications] =
    useState([]);
  const [mustHaveQualifications, setMustHaveQualifications] = useState([]);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [postDate, setPostDate] = useState(new Date());
  const [isUnpublished, setIsUnpublished] = useState(true);
  const [salaryStep, setSalaryStep] = useState(1000);
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState(null);
  const [jobLocation, setJobLocation] = useState(null);
  const [considering, setConsidering] = useState(null);
  const [level, setLevel] = useState(null);
  const [reset, setReset] = useState(false);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    onCompleted: () => {
      const {
        description,
        location,
        skills,
        industries,
        genres,
        qualifications,
        is_on_site,
        is_remote,
        is_active,
        job_number,
        level,
        type,
      } = opportunity;

      if (is_on_site && !is_remote) {
        setLocation('ONSITE');
      } else if (is_remote && !is_on_site) {
        setLocation('REMOTE');
      } else {
        setLocation('HYBRID');
      }

      setPostDate(moment(job_number, 'DD/MM/YYYY').toDate());
      setDescription(description);
      setJobLocation(location);
      setIsUnpublished(!is_active);
      setSelectedIndustry(industries.length > 0 ? industries[0]?.id : null);
      setSelectedSkills(
        skills.filter(({ software }) => !software).map(({ id }) => id)
      );
      setTopSkills(
        skills
          .filter(({ software }) => !software)
          .filter(({ pivot: { must_have } }) => must_have)
          .map(({ id }) => id)
      );
      setSelectedSoftwareSkills(
        skills.filter(({ software }) => software).map(({ id }) => id)
      );
      setTopSoftwareSkills(
        skills
          .filter(({ software }) => software)
          .filter(({ pivot: { must_have } }) => must_have)
          .map(({ id }) => id)
      );
      setSelectedIndustryQualifications(qualifications.map(({ id }) => id));
      setSelectedIndustryExperience(genres.map(({ id }) => id));
      setLevel(level);
      setConsidering(type);
    },
    onError: () => refetch(),
  });

  const {
    data: { industries: allIndustries } = {},
    loading: loadingIndustries,
  } = useQuery(GET_INDUSTRIES);

  const { data: { allJoblevels } = {}, loading: loadingLevels } =
    useQuery(GET_ALL_JOB_LEVELS);

  const [getSkills, { data: skillsData, loading: loadingSkills }] =
    useLazyQuery(GET_SKILLS, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get skills', { toastId: 'closeGetSkillsErr' }),
    });

  const [
    getQualifications,
    { data: qualificationsData, loading: loadingQualifications },
  ] = useLazyQuery(GET_QUALIFICATIONS, {
    variables: {
      industries: [selectedIndustry],
    },
    onError: () =>
      toast.error('Could not get qualifications', {
        toastId: 'closeGetQualificationsErr',
      }),
  });

  const [getGenres, { data: genresData, loading: loadingGenres }] =
    useLazyQuery(GET_GENRES, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get genres', { toastId: 'closeGetGenresErr' }),
    });

  const [
    getPositions,
    { data: { positions = [] } = {}, loading: loadingPositions },
  ] = useLazyQuery(GET_POSITIONS, {
    variables: {
      industries: [selectedIndustry],
      jobLevels: [getValues('level')],
    },
    onError: () =>
      toast.error('Could not get positions', {
        toastId: 'closeGetPositionsErr',
      }),
  });

  useEffect(() => {
    if (selectedIndustry) {
      getSkills();
      getGenres();
      getQualifications();
    }
  }, [selectedIndustry, getSkills, getGenres, getQualifications]);

  useEffect(() => {
    if (selectedIndustry && level) {
      setReset(true);
      getPositions();
    }
  }, [selectedIndustry, level, getPositions, setReset]);

  const [updateOpportunity, { loading: loadingSubmit }] = useMutation(
    UPDATE_OPPORTUNITY,
    {
      onCompleted: () => {
        toast.success('Opportunity updated successfully');
        history.push(`/${LINK_GROUP.EMPLOYER}/dashboard`);
      },
      onError: (err) => {
        console.error(err);
        toast.dismiss();
        toast.error('Something went wrong!', { toastId: 'resErr' });
      },
    }
  );

  const handleInterestedChange = (value) => {
    setSelectedIndustry(value);
    setSelectedSkills([]);
    setTopSkills([]);
    setSelectedSoftwareSkills([]);
    setTopSoftwareSkills([]);
    setSelectedIndustryExperience([]);
    setSelectedIndustryQualifications([]);
    setMustHaveQualifications([]);
    setReset(true);
  };

  const handleSkillsChange = (selectedList, _) => {
    const skillIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedSkills(skillIds);
    setTopSkills(topSkills.filter((skillId) => skillIds.includes(skillId)));
  };

  const handleTopSkillsChange = (selectedList, _) => {
    setTopSkills(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleSoftwareSkillsChange = (selectedList, _) => {
    const skillIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedSoftwareSkills(skillIds);
    setTopSoftwareSkills(
      topSoftwareSkills.filter((skillId) => skillIds.includes(skillId))
    );
  };

  const handleTopSoftwareSkillsChange = (selectedList, _) => {
    setTopSoftwareSkills(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleIndustryQualificationsChange = (selectedList, _) => {
    const genreIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedIndustryQualifications(genreIds);
    setMustHaveQualifications(
      mustHaveQualifications.filter((genreId) => genreIds.includes(genreId))
    );
  };

  const handleMustHaveQualificationsChange = (selectedList, _) => {
    setMustHaveQualifications(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));

  const preselectedSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) =>
          !skill.software && selectedSkills.find((id) => id === skill.id)
      ),
    [skillsData, selectedSkills]
  );

  const preselectedTopSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) => !skill.software && topSkills.find((id) => id === skill.id)
      ),
    [skillsData, topSkills]
  );

  const preselectedSoftwareSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) =>
          skill.software && selectedSoftwareSkills.find((id) => id === skill.id)
      ),
    [skillsData, selectedSoftwareSkills]
  );

  const preselectedTopSoftwareSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) =>
          skill.software && topSoftwareSkills.find((id) => id === skill.id)
      ),
    [skillsData, topSoftwareSkills]
  );

  const preselectedQualifications = useCallback(
    () =>
      qualificationsData?.qualifications?.filter((qualification) =>
        selectedIndustryQualifications.find((id) => id === qualification.id)
      ),
    [qualificationsData, selectedIndustryQualifications]
  );

  const preselectedMustHaveQualifications = useCallback(
    () =>
      qualificationsData?.qualifications?.filter((qualification) =>
        mustHaveQualifications.find((id) => id === qualification.id)
      ),
    [qualificationsData, mustHaveQualifications]
  );

  const preselectedIndustryExperience = useCallback(
    () =>
      genresData?.genres?.filter((genre) =>
        selectedIndustryExperience.find((id) => id === genre.id)
      ),
    [genresData, selectedIndustryExperience]
  );

  const submitUpdate = (data) => {
    if (
      selectedIndustry?.includes(2) &&
      selectedIndustryQualifications.length === 0
    ) {
      return;
    }

    toast.dismiss();

    if (data?.location === 'HYBRID' || data?.location === 'ALL') {
      data = {
        ...data,
        is_on_site: true,
        is_remote: true,
      };
    } else if (data?.location === 'ONSITE') {
      data = {
        ...data,
        is_on_site: true,
        is_remote: false,
      };
    } else if (data?.location === 'REMOTE') {
      data = {
        ...data,
        is_on_site: false,
        is_remote: true,
      };
    }

    const combinedSkills = [...selectedSkills, ...selectedSoftwareSkills];
    const combinedTopSkills = [...topSkills, ...topSoftwareSkills];

    const _data = {
      ...data,
      description: description,
      location: jobLocation,
      position: {
        connect: data.position,
      },
      is_active: !isUnpublished,
      type: data.considering,
      sub_type: data.workHour,
      minimumBudget: parseFloat(data.minimumBudget),
      maximumBudget: parseFloat(data.maximumBudget),
      is_ir35: parseInt(data.is_ir35),
      duration: parseInt(data.duration),
      industries: {
        sync: [selectedIndustry],
      },
      skills: {
        sync: combinedSkills.map((skillId) => {
          return {
            id: skillId,
            must_have: combinedTopSkills.includes(skillId),
          };
        }),
      },
      qualifications: {
        sync: selectedIndustryQualifications.map((qualificationId) => {
          return {
            id: qualificationId,
            must_have: mustHaveQualifications.includes(qualificationId),
          };
        }),
      },
      genres: {
        sync: selectedIndustryExperience,
      },
    };

    delete _data.considering;
    delete _data.workHour;

    updateOpportunity({
      variables: {
        input: _data,
        id,
      },
    });
  };

  const watchFields = watch(['considering', 'follow_up']);

  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!opportunity && !loading && (
        <BackPanel>
          <Heading>No opportunity data available!</Heading>
        </BackPanel>
      )}
      {!loading && !!opportunity && (
        <SectionForm
          actionComponent={
            <Text>
              Fill out the details of the role which will be shown to our
              network of candidates.
            </Text>
          }
          headline="Edit Job Opportunity"
        >
          <FormBlock>
            <FormBlockItem className="mt-0" label={'Status'}>
              <div
                className=" d-flex"
                style={{ alignItems: 'center', justifyContent: 'flex-start' }}
              >
                <Text
                  as="span"
                  className={!isUnpublished ? 'active text--mesh' : ''}
                  onClick={() => setIsUnpublished(false)}
                  style={{
                    cursor: 'pointer',
                    marginRight: '10px',
                    fontWeight: 400,
                  }}
                >
                  Published
                </Text>
                <Switch
                  onChange={(e) => {
                    setIsUnpublished(!isUnpublished);
                  }}
                  sameActive
                  isChecked={isUnpublished}
                />
                <Text
                  as="span"
                  className={isUnpublished ? 'active text--mesh' : ''}
                  onClick={() => setIsUnpublished(true)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                    fontWeight: 400,
                  }}
                >
                  Unpublished
                </Text>
              </div>
            </FormBlockItem>

            <FormBlockItem className="mt-4" label={'Job Title'}>
              <FormInput
                isRequired
                type="text"
                register={register}
                name="title"
                defaultValue={opportunity?.title}
                error={errors.title ? 'Please enter title' : ''}
                showErrorText
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Date Posted'}>
              <DatePicker
                onChange={setPostDate}
                value={postDate}
                className="form__input date"
                format="dd/M/y"
                name="job_number"
                required={false}
                clearIcon={null}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
              />
            </FormBlockItem>

            <FormBlockItem label={'Professional Discipline'}>
              {loadingIndustries && (
                <span>Loading Professional Discipline...</span>
              )}
              {allIndustries && (
                <FormInput
                  type="select"
                  options={arrToDropdownSelectionV2(allIndustries) ?? []}
                  selectSetting={{
                    onSelect: handleInterestedChange,
                  }}
                  defaultValue={selectedIndustry}
                  placeholder="Select Professional Discipline"
                ></FormInput>
              )}
            </FormBlockItem>

            <FormBlockItem label={'Category Experience'}>
              {loadingGenres && <span>Loading experience...</span>}
              {genresData && (
                <CustomMultiselect
                  required
                  options={
                    genresData?.genres?.map(({ id, name }) => ({
                      key: id,
                      name: name,
                    })) ?? []
                  }
                  onSelect={handleIndustryExperienceChange}
                  onRemove={handleIndustryExperienceChange}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Category Experience"
                  selectedValues={preselectedIndustryExperience()}
                  ref={multiselectIndustryExperienceRef}
                />
              )}
            </FormBlockItem>

            <FormBlockItem label={'Job Location'}>
              <SearchBox
                accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                placeholder="Please enter location"
                options={{
                  types: ['place', 'district', 'region'],
                }}
                onSuggestError={() => {
                  toast.dismiss();
                  toast.error('Failed to fetch address!', {
                    toastId: 'resErr',
                  });
                }}
                onRetrieve={(value) => {
                  const place =
                    value?.features[0]?.properties?.full_address ??
                    value?.features[0]?.properties?.place_formatted;
                  setJobLocation(place);
                }}
                theme={{
                  cssText: 'height: 43px;',
                  icons: {
                    addressMarker: '',
                    close: '',
                    search: '',
                  },
                  variables: {
                    border: 'none',
                    borderRadius: '9px',
                    boxShadow: 'none',
                    color: '#0e0e0e',
                    fontFamily: 'Jost, Arial, Helvetica, sans-serif',
                    fontWeight: 500,
                    // lineHeight: 2.6,
                    // padding: '5px 21px',
                    unit: 16,
                  },
                }}
                value={jobLocation}
              />
            </FormBlockItem>

            <FormBlockItem label={'How do you work?'}>
              <FormInput
                isRequired
                type="select"
                register={register}
                name="location"
                options={TYPE_WORK_LOCATION}
                placeholder="Select Location"
                defaultValue={location}
                error={errors.location ? 'Please select job location' : ''}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Job Description'}>
              <FormInput
                isRequired
                type="wysiwyg"
                name="description"
                showErrorText
                maxLength={0}
                clearErrors={clearErrors}
                setValue={setDescription}
                defaultValue={description}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Type of Role'}>
              <FormInput
                isRequired
                type="select"
                register={register}
                name="considering"
                options={TYPE_JOBTYPES_FOR_OPPORTUNITY.map(({ key, name }) => ({
                  value: key,
                  label: name,
                }))}
                placeholder="Select type of role"
                selectSetting={{
                  onSelect: (value) => {
                    setValue('considering', value);
                    setConsidering(value);
                  },
                }}
                defaultValue={considering}
                error={errors.considering ? 'Please select type of role' : ''}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Working Hours'}>
              <FormInput
                isRequired
                type="select"
                register={register}
                name="workHour"
                options={TYPE_SUBJOBTYPES.map(({ key, name }) => ({
                  value: key,
                  label: name,
                }))}
                placeholder="Select Working Hour"
                defaultValue={opportunity?.sub_type ?? TYPE_SUBJOBTYPES[0]?.key}
                error={errors.workHour ? 'Please select working hours' : ''}
              ></FormInput>
            </FormBlockItem>

            {'considering' in watchFields &&
              watchFields.considering ===
                TYPE_JOBTYPES_FOR_OPPORTUNITY[2].key && (
                <>
                  <FormBlockItem label={'What is the duration'}>
                    <FormInput
                      type="select"
                      register={register}
                      name="duration"
                      options={[1, 2, 3, 4, 5, 6, 7, 13]?.map((val) => ({
                        value: val,
                        label: `${val <= 6 ? val : val - 1} month${
                          val === 1 ? '' : 's'
                        }${val <= 6 ? '' : '+'}`,
                      }))}
                      placeholder="Select duration"
                      defaultValue={opportunity?.duration}
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem label={'Is this role inside IR35'}>
                    <FormInput
                      type="radio"
                      register={register}
                      name="is_ir35"
                      id="is_ir35_1"
                      defaultValue={1}
                      defaultChecked={opportunity?.is_ir35 === 1}
                      checkboxChildren={<label htmlFor="is_ir35_1">Yes</label>}
                    ></FormInput>
                    <FormInput
                      type="radio"
                      register={register}
                      name="is_ir35"
                      id="is_ir35_0"
                      defaultValue={0}
                      defaultChecked={opportunity?.is_ir35 === 0}
                      checkboxChildren={<label htmlFor="is_ir35_0">No</label>}
                    ></FormInput>
                    <FormInput
                      type="radio"
                      register={register}
                      name="is_ir35"
                      id="is_ir35_2"
                      defaultValue={2}
                      defaultChecked={opportunity?.is_ir35 === 2}
                      checkboxChildren={
                        <label htmlFor="is_ir35_2">Not sure</label>
                      }
                    ></FormInput>
                  </FormBlockItem>
                </>
              )}

            <FormBlockItem label={'Experience Level'}>
              {loadingLevels && <span>Loading levels...</span>}
              {allJoblevels && (
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="level"
                  options={
                    allJoblevels?.map(({ key, name }) => ({
                      value: key,
                      label: name,
                    })) ?? []
                  }
                  placeholder="Select Experience Level"
                  selectSetting={{
                    onSelect: (value) => {
                      setValue('level', value);
                      setLevel(value);
                      getPositions();
                      setReset(true);
                    },
                  }}
                  defaultValue={level}
                  error={errors.level ? 'Please select experience level' : ''}
                ></FormInput>
              )}
            </FormBlockItem>

            <FormBlockItem label={'Position Sought'}>
              {loadingPositions && <span>Loading positions...</span>}
              {positions && (
                <FormInput
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="position"
                  options={
                    positions?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  placeholder="Select position sought"
                  selectSetting={{
                    onSelect: (value) => {
                      setValue('position', value);
                    },
                    reset,
                    setReset,
                  }}
                  defaultValue={opportunity?.position?.id}
                  error={errors.position ? 'Please select position sought' : ''}
                ></FormInput>
              )}
            </FormBlockItem>

            <FormBlockItem label={'Skills Required'}>
              {loadingSkills && <span>Loading skills...</span>}
              {skillsData && (
                <CustomMultiselect
                  required
                  options={
                    skillsData?.skills
                      ?.filter(({ software }) => !software)
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                  }
                  onSelect={handleSkillsChange}
                  onRemove={handleSkillsChange}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Skills"
                  selectedValues={preselectedSkills()}
                  ref={multiselectSkillRef}
                />
              )}
            </FormBlockItem>

            <FormBlockItem label={'Skills Must Haves (optional)'}>
              {loadingSkills && <span>Loading skills...</span>}
              {skillsData && (
                <CustomMultiselect
                  options={
                    skillsData?.skills
                      ?.filter(({ software }) => !software)
                      ?.filter(({ id }) => selectedSkills.includes(id))
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                  }
                  onSelect={handleTopSkillsChange}
                  onRemove={handleTopSkillsChange}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Skills"
                  selectionLimit={3}
                  selectedValues={preselectedTopSkills()}
                  ref={multiselectTopSkillRef}
                />
              )}
            </FormBlockItem>

            <FormBlockItem
              label={`Software Skills ${
                selectedIndustry?.includes(2) ? '' : '(optional)'
              }`}
            >
              {loadingSkills && <span>Loading software skills...</span>}
              {skillsData && (
                <CustomMultiselect
                  className={
                    selectedIndustry?.includes(2) &&
                    selectedSoftwareSkills.length === 0 &&
                    'error-state state-error'
                  }
                  options={
                    skillsData?.skills
                      ?.filter(({ software }) => software)
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                  }
                  onSelect={handleSoftwareSkillsChange}
                  onRemove={handleSoftwareSkillsChange}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Software Skills"
                  selectedValues={preselectedSoftwareSkills()}
                  ref={multiselectSoftwareSkillRef}
                />
              )}
              {selectedIndustry?.includes(2) &&
                selectedSoftwareSkills.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select software skills'}
                    showWarnError={true}
                  />
                )}
            </FormBlockItem>

            {selectedSoftwareSkills?.length > 0 && (
              <FormBlockItem label={'Software Skills Must Haves (optional)'}>
                {loadingSkills && <span>Loading software skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    options={
                      skillsData?.skills
                        ?.filter(({ software }) => software)
                        ?.filter(({ id }) =>
                          selectedSoftwareSkills.includes(id)
                        )
                        .map(({ id, name }) => ({
                          key: id,
                          name: name,
                        })) ?? []
                    }
                    onSelect={handleTopSoftwareSkillsChange}
                    onRemove={handleTopSoftwareSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Software Skills"
                    selectionLimit={3}
                    selectedValues={preselectedTopSoftwareSkills()}
                    ref={multiselectTopSoftwareSkillRef}
                  />
                )}
              </FormBlockItem>
            )}

            <FormBlockItem
              label={`Qualifications ${
                selectedIndustry?.includes(2) ? '' : '(optional)'
              }`}
            >
              {loadingQualifications && <span>Loading qualifications...</span>}
              {qualificationsData && (
                <CustomMultiselect
                  className={
                    selectedIndustry?.includes(2) &&
                    selectedIndustryQualifications.length === 0 &&
                    'error-state state-error'
                  }
                  options={
                    qualificationsData?.qualifications?.map(({ id, name }) => ({
                      key: id,
                      name: name,
                    })) ?? []
                  }
                  onSelect={handleIndustryQualificationsChange}
                  onRemove={handleIndustryQualificationsChange}
                  displayValue="name"
                  closeOnSelect={true}
                  avoidHighlightFirstOption
                  placeholder="Qualifications"
                  selectedValues={preselectedQualifications()}
                  ref={multiselectQualificationRef}
                />
              )}
              {selectedIndustry?.includes(2) &&
                selectedIndustryQualifications.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select qualifications'}
                    showWarnError={true}
                  />
                )}
            </FormBlockItem>

            {selectedIndustryQualifications?.length > 0 && (
              <FormBlockItem label={'Qualifications Must Haves (optional)'}>
                {loadingQualifications && (
                  <span>Loading qualifications...</span>
                )}
                {qualificationsData && (
                  <CustomMultiselect
                    options={
                      qualificationsData?.qualifications
                        ?.filter(({ id }) =>
                          selectedIndustryQualifications.includes(id)
                        )
                        .map(({ id, name }) => ({
                          key: id,
                          name: name,
                        })) ?? []
                    }
                    onSelect={handleMustHaveQualificationsChange}
                    onRemove={handleMustHaveQualificationsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Qualifications"
                    selectionLimit={3}
                    selectedValues={preselectedMustHaveQualifications()}
                    ref={multiselectQualificationMustHaveRef}
                  />
                )}
              </FormBlockItem>
            )}

            <FormBlockItem label={'Salary / Budget'}>
              <FormInput
                type="checkbox"
                register={register}
                name="is_compensation_displayed"
                checkboxChildren={<p>Show Compensation</p>}
                defaultChecked={opportunity?.is_compensation_displayed}
              ></FormInput>

              <FormBlock isInline>
                <FormBlockItem>
                  <FormInput
                    isRequired
                    type="select"
                    register={register}
                    name="compensation_currency"
                    defaultValue={
                      opportunity?.compensation_currency ??
                      TYPE_CURRENCY[0].value
                    }
                    options={TYPE_CURRENCY}
                  ></FormInput>
                </FormBlockItem>

                <FormBlockItem>
                  <FormInput
                    type="number"
                    register={register}
                    name="minimumBudget"
                    step={salaryStep}
                    defaultValue={opportunity?.minimumBudget}
                    error={
                      errors.minimumBudget ? 'Please enter minimum budget' : ''
                    }
                    showErrorText
                  ></FormInput>
                </FormBlockItem>

                <FormBlockItem>
                  <FormInput
                    type="number"
                    register={register}
                    name="maximumBudget"
                    step={salaryStep}
                    defaultValue={opportunity?.maximumBudget}
                    error={
                      errors.maximumBudget ? 'Please enter maximum budget' : ''
                    }
                    showErrorText
                  ></FormInput>
                </FormBlockItem>
              </FormBlock>

              <FormBlock isInline>
                {TYPE_SALARY_UNIT.map(({ value, label, step }, index) => {
                  return (
                    <FormBlockItem key={index}>
                      <FormInput
                        isRequired
                        type="radiobutton"
                        register={register}
                        name="compensation_unit"
                        defaultValue={value}
                        defaultChecked={
                          opportunity?.compensation_unit === value
                        }
                        checkboxChildren={label}
                        onChange={() => {
                          setSalaryStep(step);
                        }}
                      ></FormInput>
                    </FormBlockItem>
                  );
                })}
              </FormBlock>
            </FormBlockItem>

            <FormBlockItem className="mt-4" label={'Follow Up Message'}>
              <Text
                className="mb-2"
                style={{
                  color: '#AAAAAA',
                }}
              >
                Add a message to your applicants to let them know you have
                received their application and/or add more information about the
                recruitment process.
              </Text>
              <FormInput
                isRequired
                type="textarea"
                register={register}
                name="follow_up"
                defaultValue={opportunity?.follow_up}
                helpChildren={
                  <>
                    <span>
                      {'follow_up' in watchFields
                        ? watchFields.follow_up
                          ? watchFields.follow_up.length
                          : 0
                        : 0}
                    </span>{' '}
                    Characters
                  </>
                }
                error={
                  errors.follow_up ? 'Please enter a follow up message' : null
                }
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label={'Link to ATS'}>
              <FormInput
                type="text"
                register={register}
                name="application_link"
                defaultValue={opportunity?.application_link}
              ></FormInput>
            </FormBlockItem>

            {/* <FormBlockItem label={'Team User'}>
              <FormInput
                type="select"
                register={register}
                name=""
                options={
                  teamList?.map(({ user_id, team }) => ({
                    value: user_id,
                    label: `${team?.first_name} ${team?.last_name}`,
                  })) ?? []
                }
                placeholder="Select Team"
                defaultValue={opportunity?.posting_user}
              ></FormInput>
            </FormBlockItem> */}
          </FormBlock>

          <FormCTA spaceT extraSpace>
            <Button
              className="w-full"
              size="xxl"
              disabled={loadingSubmit}
              onClick={(e) => {
                handleSubmit(submitUpdate)(e);
              }}
            >
              {loadingSubmit ? 'Loading...' : 'Save'}
            </Button>
          </FormCTA>
        </SectionForm>
      )}
    </DefaultLayout>
  );
}
