import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import 'bootstrap/js/src/modal';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { currentOppVar } from '../../graphql/config';
import { DELETE_OPPORTUNITY } from '../../graphql/mutations';
import Error from '../Error';
import hideModal from '../../styles/js/hideModal';
import { GET_AGENCY_APPLICANTS } from '../../graphql';
import { getUser } from '../../utils/helpers';
import { AppContext } from '../../Store';

export const ReasonModal = ({ refetch }) => {
  const [state] = useContext(AppContext);
  const showError = () => {
    toast.error('Something went wrong', { toastId: 'OppDashboard' });
  };

  const { id, title } = useReactiveVar(currentOppVar);
  const [closeDate, setCloseDate] = useState(new Date());

  const { watch, register, handleSubmit, errors, getValues } = useForm();
  const watchFields = watch(['reason']);
  const [deleteOpportunity, { loading }] = useMutation(DELETE_OPPORTUNITY);
  const [getAgencyApplicants, { called, data }] = useLazyQuery(
    GET_AGENCY_APPLICANTS,
    {
      variables: {
        agency:
          getUser()?.type === 'COMPANY'
            ? getUser()?.id
            : state?.activeCompany?.role === 'member'
            ? state?.activeCompany?.user_id
            : state?.activeCompany?.team_id,
        opportunity: id,
      },
      onError: () =>
        toast.error('Could not get applicants', { toastId: 'closeGetAppErr' }),
    }
  );

  useEffect(() => {
    if (!called && id) {
      getAgencyApplicants();
    }
  }, [state, id, called, getAgencyApplicants]);

  const validateIsFilled = (value) => {
    if (getValues('reason') === 'Filled by applicant') {
      return value !== '' || getValues('freelancer_name') !== '';
    }
    return true;
  };

  const validateNotFilled = (value) => {
    if (
      getValues('reason') !== 'Filled by applicant' &&
      (value !== '' || getValues('freelancer_name') !== '')
    ) {
      return false;
    }
    return true;
  };

  const deleteOpportunityRequest = (data) =>
    deleteOpportunity({
      variables: {
        id,
        input: {
          reason: data.reason,
          closed_at: moment(closeDate).format('YYYY-MM-DD'),
          ...(data.freelancer && { freelancer: { connect: data.freelancer } }),
          ...(data.freelancer_name && {
            freelancer_name: data.freelancer_name,
          }),
        },
      },
    })
      .then((data) => {
        toast.dismiss();
        hideModal();
        currentOppVar({});
        refetch();
        toast.success('Opportunity closed!', { autoClose: 4000 });
      })
      .catch((e) => showError());

  return (
    <div
      className="modal fade"
      id="close_opportunity_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="mySmallModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h6>Closing The Job Opportunity</h6>
            <h1 className="txt__fw-600">{title}</h1>
            <form className="row">
              <div className="col-12">
                <div className="form__input-block">
                  <div className="form__input-wrapper mb-4">
                    <label
                      className="font-weight-bold d-block"
                      htmlFor="reason"
                    >
                      Reason
                    </label>
                    <input
                      type="radio"
                      id="filledElsewhere"
                      name="reason"
                      value="Filled elsewhere"
                      ref={register({ required: true })}
                    />
                    <label htmlFor="filledElsewhere">Filled elsewhere</label>
                    <br />
                    <input
                      type="radio"
                      id="postponed"
                      name="reason"
                      value="Job postponed"
                      ref={register({ required: true })}
                    />
                    <label htmlFor="postponed">Job postponed</label>
                    <br />
                    <input
                      type="radio"
                      id="noLongerExists"
                      name="reason"
                      value="Job no longer exists"
                      ref={register({ required: true })}
                    />
                    <label htmlFor="noLongerExists">Job no longer exists</label>
                    <br />
                    <input
                      type="radio"
                      id="filledBy"
                      name="reason"
                      value="Filled by applicant"
                      ref={register({ required: true })}
                    />
                    <label htmlFor="filledBy">Filled by applicant</label>
                    {watchFields.reason &&
                      watchFields.reason === 'Filled by applicant' && (
                        <>
                          <br />
                          <div className="form__input-wrapper">
                            <select
                              name="freelancer"
                              className="form__input form__country"
                              ref={register({
                                validate: {
                                  isFilled: (value) => validateIsFilled(value),
                                  notFilled: (value) =>
                                    validateNotFilled(value),
                                },
                              })}
                            >
                              <option value="">-- Select applicant --</option>
                              {data &&
                                data.applicants
                                  .filter((a) => a.user)
                                  .map(({ created_at, user: { name, id } }) => (
                                    <option value={id} key={created_at}>
                                      {name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          <br />
                          <div className="form__input-wrapper">
                            <label htmlFor="freelancer_name">or</label>
                            <input
                              className="form__input"
                              type="text"
                              autoComplete="false"
                              name="freelancer_name"
                              placeholder="Please input the selected applicant name"
                              ref={register({ required: false })}
                            />
                          </div>
                        </>
                      )}
                  </div>
                  {errors.reason && <Error text="Please select an option." />}
                  {errors.freelancer?.type === 'isFilled' && (
                    <Error text="Please select an applicant." />
                  )}
                  <div className="form__input-wrapper mt-5">
                    <label className="font-weight-bold" htmlFor="close_date">
                      Date Closed
                    </label>
                    <DatePicker
                      onChange={setCloseDate}
                      value={closeDate}
                      className="form__input date"
                      format="dd/MM/y"
                      name="close_date"
                      required={false}
                      clearIcon={null}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      yearPlaceholder="yyyy"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn form__btn d-block"
                  onClick={handleSubmit(deleteOpportunityRequest)}
                >
                  {loading ? 'Loading...' : 'Close Opportunity'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReasonModal;
