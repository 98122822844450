export const areaExpertise = [
  {
    name: 'Web Development',
    label: 'Web Development',
  },
  {
    name: 'Mobile App Development',
    label: 'Mobile App Development',
  },
  {
    name: 'Video Production',
    label: 'Video Production',
  },
  {
    name: 'Copywriting',
    label: 'Copywriting',
  },

  {
    name: 'SEO Services',
    label: 'SEO Services',
  },
  {
    name: 'Paid Social',
    label: 'Paid Social',
  },
  {
    name: 'ppc',
    label: 'PPC',
  },
  {
    name: 'Digital Design',
    label: 'Digital Design',
  },
  {
    name: 'Print Design',
    label: 'Print Design',
  },
  {
    name: '3D & Animations',
    label: '3D & Animations',
  },
  {
    name: 'Brand Identity',
    label: 'Brand Identity',
  },
];

export const agencyBudgetFrom = [
  {
    label: '0',
    value: 0,
  },
];

export const agencyBudgetTo = [
  {
    label: 'No Cap',
    value: 99999999,
  },
];

export const agencyInterest = [
  'Marketing, Media & Advertising',
  'Finance',
  'HR & Operations',
];

for (let i = 1; i < 10; i++) {
  agencyBudgetFrom.push({
    value: 1000 * i,
    label: (1000 * i).toString(),
  });
  agencyBudgetTo.push({
    value: 1000 * i,
    label: (1000 * i).toString(),
  });
}
