import gql from 'graphql-tag';

export const CHECK_USER = gql`
  query CheckUser($email: String!) {
    checkUser(email: $email)
  }
`;

export const CHECK_REGISTERABLE_USER = gql`
  query CheckRegisterableUser($email: String!, $type: String = "FREELANCER") {
    checkRegisterableUser(email: $email, type: $type) {
      type
    }
  }
`;

export const GET_AUTH_USER_BIO_AVATAR = gql`
  query GetAuthUser {
    me {
      bio
      avatar
    }
  }
`;

export const GET_AUTH_USER = gql`
  query GetAuthUser {
    me {
      id
      name
      pronouns
      type
      email
      bio
      description
      phone
      avatar
      address_1
      address_2
      city
      state
      country
      zip
      referee_1
      referee_2
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      linkedin
      salary_currency
      minimum_salary
      maximum_salary
      salary_unit
      looking_for
      payroll_status
      hear_us
      hear_us_additional
      ethnicity
      location
      language
      jobtitle
      start_date
      right_to_work
      notice_period
      website
      vetted
      isProfileComplete {
        complete
        missing
      }
      subscriptions {
        stripe_id
      }
      education {
        id
        school_name
        qualification
        description
        from
        to
      }
      experiences {
        id
        title
        company
        description
        from
        to
        years
      }
      services {
        id
        name
      }
      skills {
        id
        name
        industries {
          id
          name
        }
        software
        pivot {
          is_top
        }
      }
      jobTypes {
        key
        name
      }
      industries {
        id
        name
      }
      genres {
        id
        name
        industries {
          id
          name
        }
      }
      qualifications {
        id
        name
        industries {
          id
          name
        }
      }
      otherQualifications {
        id
        name
        industry
      }
      resumes {
        id
        file
        fileUrl
        active
        created_at
      }
      supportingDocuments {
        id
        name
        file
        fileUrl
        created_at
      }
      pm_type
      pm_last_four
      status
      preferences {
        id
        title
        job_number
        view
        type
        agency {
          id
          avatar
          company
        }
        services {
          id
          name
        }
        skills {
          id
          name
        }
        genres {
          id
          name
        }
        is_active
        created_at
        updated_at
      }
      applications {
        id
        opportunity(trashed: WITH) {
          id
          title
          agency {
            id
            company
            avatar
          }
          is_active
          deleted_at
        }
        created_at
        is_shown
      }
      teamUser {
        user_id
        team_id
        role
        active
        can_post_jobs
        user {
          type
          company
          agency_name
          address_1
          address_2
          city
          state
          country
          zip
          avatar
          bio
          description
          phone
          email
          pm_type
          pm_last_four
          status
          billing_address_1
          billing_address_2
          billing_city
          billing_state
          billing_country
          billing_zip
          contact_email
          agency_location
          website
          budget_currency
          budget_min
          budget_max
          industries {
            name
            id
          }
          genres {
            name
            id
          }
          services {
            id
            name
          }
          skills {
            name
            id
            software
            pivot {
              is_top
            }
          }
          experiences {
            id
            title
          }
          agency_phone {
            key
            attributes {
              phone
            }
          }
        }
      }
      invoice_company
      invoice_address_1
      invoice_address_2
      invoice_city
      invoice_state
      invoice_country
      invoice_zip
      invoice_phone
      invoice_email
      is_payment_active
      agency_name
      contact_email
      agency_location
      agency_phone {
        key
        attributes {
          phone
        }
      }
      positions {
        id
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID) {
    user(id: $id) {
      id
      name
      pronouns
      type
      email
      company
      position
      bio
      description
      avatar
      address_1
      address_2
      city
      state
      country
      zip
      referee_1
      referee_2
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      linkedin
      salary_currency
      minimum_salary
      maximum_salary
      salary_unit
      looking_for
      payroll_status
      hear_us
      hear_us_additional
      ethnicity
      location
      language
      jobtitle
      start_date
      right_to_work
      notice_period
      website
      vetted
      isProfileComplete {
        complete
        missing
      }
      subscriptions {
        stripe_id
      }
      education {
        id
        school_name
        qualification
        description
        from
        to
      }
      experiences {
        id
        title
        company
        description
        from
        to
        years
      }
      services {
        id
        name
      }
      skills {
        id
        name
        industries {
          id
          name
        }
        software
        pivot {
          is_top
        }
      }
      jobTypes {
        key
        name
      }
      industries {
        id
        name
      }
      genres {
        id
        name
        industries {
          id
          name
        }
      }
      qualifications {
        id
        name
        industries {
          id
          name
        }
      }
      otherQualifications {
        id
        name
        industry
      }
      resumes {
        id
        file
        fileUrl
        active
        created_at
      }
      supportingDocuments {
        id
        name
        file
        fileUrl
        created_at
      }
      pm_type
      pm_last_four
      status
      preferences {
        id
        title
        job_number
        view
        type
        agency {
          id
          avatar
          company
        }
        services {
          id
          name
        }
        skills {
          id
          name
        }
        genres {
          id
          name
        }
        is_active
        created_at
        updated_at
      }
      applications {
        id
        opportunity(trashed: WITH) {
          id
          title
          agency {
            id
            company
            avatar
          }
          is_active
          deleted_at
        }
        created_at
        is_shown
      }
      teamUser {
        user_id
        team_id
        role
        active
        can_post_jobs
        user {
          type
          company
          agency_name
          address_1
          address_2
          city
          state
          country
          zip
          avatar
          bio
          description
          phone
          email
          pm_type
          pm_last_four
          status
          billing_address_1
          billing_address_2
          billing_city
          billing_state
          billing_country
          billing_zip
          contact_email
          agency_location
          website
          budget_currency
          budget_min
          budget_max
          industries {
            name
            id
          }
          genres {
            name
            id
          }
          services {
            id
            name
          }
          skills {
            name
            id
            software
            pivot {
              is_top
            }
          }
          experiences {
            id
            title
          }
          agency_phone {
            key
            attributes {
              phone
            }
          }
        }
      }
      invoice_company
      invoice_address_1
      invoice_address_2
      invoice_city
      invoice_state
      invoice_country
      invoice_zip
      invoice_phone
      invoice_email
      is_payment_active
      agency_name
      contact_email
      agency_location
      agency_phone {
        key
        attributes {
          phone
        }
      }
      caseStudies {
        id
        title
        description
        thumbnail
        content {
          layout
          key
          attributes {
            image
            url
          }
        }
        video_url
        external_url
      }
      positions {
        id
        name
      }
    }
  }
`;

export const GET_PREFERENCE = gql`
  query GetPreference($user: Int!) {
    preference(user: $user) {
      id
      job_types
      genres {
        id
        name
      }
    }
  }
`;

export const GET_DEFAULT_PAYMENT_METHOD = gql`
  query GetDefaultPaymentMethod($teamId: Int!) {
    defaultPaymentMethod(team_id: $teamId) {
      last4
      exp_month
      exp_year
    }
  }
`;
