import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  GET_TEAM_MEMBER_OPPORTUNITY,
  GET_TEAM_OPPORTUNITY,
} from '../../graphql';
import { useMutation } from '@apollo/react-hooks';
import { AppContext } from '../../Store';
import useAuth from './useAuth';
import moment from 'moment';
import TABLER_UTIL from '../../utils/tabler';
import { LINK_GROUP } from '../../utils/links';
import { OpportunityClose } from '../components';
import { currentOppVar } from '../../graphql/config';
import { useAppTheme } from '../context/themeAppContext';
import { TARGET_OPPORTUNITY } from '../placeholders/consultant/types';

export default function useOpportunity() {
  // const {groupname} = useGroups()
  const [state] = useContext(AppContext);
  const { authInitialized, authenticating } = useAuth();
  const [opportunities, setOpportunities] = useState([]);
  const [activeType, setActiveType] = useState([true, true]);
  const [onLoading, setOnLoading] = useState(false);

  const {
    action: { setOpenModal },
  } = useAppTheme();

  /** Retrieve Team Member Opportunities */
  const [getTeamUserOpportunites] = useMutation(GET_TEAM_MEMBER_OPPORTUNITY, {
    onCompleted: ({ viewTeamMemberOpportunity }) => {
      const associatedOpportunities = viewTeamMemberOpportunity.flat();
      setOpportunities(associatedOpportunities);
    },
    onError: () => {},
  });

  const [getTeamOpportunites] = useMutation(GET_TEAM_OPPORTUNITY, {
    onCompleted: ({ viewTeamOpportunity }) => {
      const associatedOpportunities = viewTeamOpportunity;
      setOpportunities(associatedOpportunities);
    },
    onError: () => {},
  });

  useEffect(() => {
    setOnLoading(authenticating);
  }, [authenticating]);

  useEffect(() => {
    if (authInitialized && state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        getTeamUserOpportunites({
          variables: {
            userId: Number(state?.activeCompany?.user_id),
            teamId: Number(state?.activeCompany?.team_id),
          },
        });
      } else if (state?.activeCompany?.role === 'admin') {
        getTeamOpportunites({
          variables: {
            teamId: Number(state?.activeCompany?.team_id),
          },
        });
      }
    }
  }, [authInitialized, state, getTeamUserOpportunites, getTeamOpportunites]);

  const getOtherOpportunities = (id) => (e) => {
    e.preventDefault();
    const el = document.getElementById(id);

    if (id === 'active-opportunities') {
      setActiveType([!activeType[0], activeType[1]]);
    } else {
      setActiveType([activeType[0], !activeType[1]]);
    }

    const sectionTop = el.offsetTop;

    window.scrollTo({
      top: sectionTop,
      behavior: 'smooth',
    });
  };

  const jobs = useMemo(() => {
    return (
      opportunities
        ?.filter(
          (o) =>
            o.target === TARGET_OPPORTUNITY[0].value &&
            (activeType[0] ? !o.deleted_at : o.deleted_at)
        )
        ?.map(
          ({
            title,
            created_at,
            job_number,
            applicants,
            featured,
            id,
            creator,
            company,
            agency,
          }) => {
            return [
              TABLER_UTIL.generateConntent.nameby(
                title,
                'Added by ' +
                  (creator?.name || agency?.company || company?.name || '')
              ),
              TABLER_UTIL.generateConntent.text(
                moment(created_at).format('DD/MM/YYYY')
              ),
              TABLER_UTIL.generateConntent.text(job_number),
              TABLER_UTIL.generateConntent.text(
                applicants.filter((a) => a.user).length
              ),
              TABLER_UTIL.generateConntent.text(featured ? 'Featured' : ' '),
              activeType[0]
                ? TABLER_UTIL.generateConntent.cta([
                    TABLER_UTIL.generateConntent.ctaCreateAttributes(
                      'View Applicants',
                      `/${LINK_GROUP.EMPLOYER}/view-applicants/${id}`
                    ),
                    TABLER_UTIL.generateConntent.ctaCreateAttributes(
                      state?.activeCompany?.can_post_jobs ? 'Edit' : 'View',
                      `/${LINK_GROUP.EMPLOYER}/${
                        state?.activeCompany?.can_post_jobs ? 'edit' : 'view'
                      }-opportunity/${id}`
                    ),
                    activeType[0]
                      ? TABLER_UTIL.generateConntent.ctaCreateAttributes(
                          'Close',
                          () => {
                            currentOppVar({ id, title });
                            setOpenModal(() => <OpportunityClose />);
                          },
                          true
                        )
                      : '',
                  ])
                : TABLER_UTIL.generateConntent.text(' '),
            ];
          }
        ) ?? []
    );
  }, [activeType, setOpenModal, opportunities, state.activeCompany]);

  const projects = useMemo(() => {
    return (
      opportunities
        ?.filter(
          (o) =>
            o.target === TARGET_OPPORTUNITY[1].value &&
            (activeType[1] ? !o.deleted_at : o.deleted_at)
        )
        ?.map(
          ({
            title,
            created_at,
            job_number,
            applicants,
            featured,
            id,
            creator,
            company,
            agency,
          }) => {
            return [
              TABLER_UTIL.generateConntent.nameby(
                title,
                'Added by ' +
                  (creator?.name || agency?.company || company?.name || '')
              ),
              TABLER_UTIL.generateConntent.text(
                moment(created_at).format('DD/MM/YYYY')
              ),
              TABLER_UTIL.generateConntent.text(job_number),
              TABLER_UTIL.generateConntent.text(
                applicants.filter((a) => a.user).length
              ),
              TABLER_UTIL.generateConntent.text(featured ? 'Featured' : ' '),
              activeType[1]
                ? TABLER_UTIL.generateConntent.cta([
                    TABLER_UTIL.generateConntent.ctaCreateAttributes(
                      'View Applicants',
                      `/${LINK_GROUP.EMPLOYER}/view-applicants/${id}`
                    ),
                    TABLER_UTIL.generateConntent.ctaCreateAttributes(
                      state?.activeCompany?.can_post_jobs ? 'Edit' : 'View',
                      `/${LINK_GROUP.EMPLOYER}/${
                        state?.activeCompany?.can_post_jobs ? 'edit' : 'view'
                      }-project/${id}`
                    ),
                    activeType[1]
                      ? TABLER_UTIL.generateConntent.ctaCreateAttributes(
                          'Close',
                          () => {
                            currentOppVar({ id, title });
                            setOpenModal(() => (
                              <OpportunityClose type={'agency project'} />
                            ));
                          },
                          true
                        )
                      : '',
                  ])
                : TABLER_UTIL.generateConntent.text(' '),
            ];
          }
        ) ?? []
    );
  }, [activeType, setOpenModal, opportunities, state.activeCompany]);

  return {
    opportunities,
    activeType,
    getOtherOpportunities,
    onLoading,
    jobs,
    projects,
  };
}
