import React, { useEffect, useState, useContext, useRef } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_SERVICES,
  GET_GENRES,
  ADD_OPPORTUNITY,
  GET_TEAM_USERS,
  GET_CURRENT_CREDITS,
} from '../../../../graphql';
import {
  TARGET_OPPORTUNITY,
  TYPE_CURRENCY,
} from '../../../placeholders/consultant/types';
import { LoadingSection, SectionForm, Switch } from '../../../components';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../Store';
import { useAppTheme } from '../../../context/themeAppContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { SearchBox } from '@mapbox/search-js-react';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { LINK_GROUP } from '../../../../utils/links';

export default function ProjectCreatePage() {
  const [state] = useContext(AppContext);
  const {
    action: { setOpenModal, setCloseModal },
  } = useAppTheme();
  const history = useHistory();
  const { register, handleSubmit, errors, watch, clearErrors } = useForm();
  const multiselectIndustryExperienceRef = useRef(null);
  const multiselectServiceRef = useRef(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [postDate, setPostDate] = useState(new Date());
  const [isUnpublished, setIsUnpublished] = useState(false);
  const [defaultFollowUpMessage, setDefaultFollowUpMessage] = useState('');
  const [description, setDescription] = useState(null);
  const [location, setLocation] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [
    getCreditBalance,
    { loading, data: { currentCredit: { balance } = {} } = {} },
  ] = useLazyQuery(GET_CURRENT_CREDITS, {
    variables: { team_id: state?.activeCompany?.team_id },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (balance < 1) {
        setOpenModal(() => <BuyBalanceModal />);
      } else {
        setOpenModal(() => <PostOpportunityModal />);
      }
    },
  });

  const [getTeamUsers, { data: { viewUserTeam: teamList } = {} }] =
    useLazyQuery(GET_TEAM_USERS, {
      variables: {
        team_id:
          state?.activeCompany?.role === 'admin'
            ? Number(state?.activeCompany?.team_id)
            : Number(state?.activeCompany?.user_id),
      },
    });

  useEffect(() => {
    if (state?.activeCompany) {
      getCreditBalance();
      getTeamUsers();
      setDefaultFollowUpMessage(
        `Thank you for your interest on our role at ${state.activeCompany.user.company}. We have got your application and we will review your details and contact you shortly if we'd like to meet you for an interview.`
      );
    }
  }, [state, getCreditBalance, getTeamUsers]);

  const { data: servicesData, loading: loadingServices } = useQuery(
    GET_SERVICES,
    {
      variables: {},
      onError: () =>
        toast.error('Could not get services', {
          toastId: 'closeGetServicesErr',
        }),
    }
  );

  const { data: genresData, loading: loadingGenres } = useQuery(GET_GENRES, {
    variables: {},
    onError: () =>
      toast.error('Could not get genres', { toastId: 'closeGetGenresErr' }),
  });

  const handleServicesChange = (selectedList, _) => {
    const serviceIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedServices(serviceIds);
  };

  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));

  const confirmOpportunity = (data) => {
    setOpenModal(() => <PostOpportunityModal data={data} />);
  };

  const BuyBalanceModal = () => {
    return (
      <div style={{ padding: '25px' }}>
        <h1>Insufficient Balance!</h1>
        <p>Your current balance is {balance} job posts</p>
        <br />
        <br />
        <Button
          as="link"
          scheme="outline"
          onClick={() => setCloseModal()}
          to={`/${LINK_GROUP.EMPLOYER}/manage-credits`}
          className="mt-4 app-btn--text-normal text--capitalize"
        >
          Buy More Job Posts
        </Button>
      </div>
    );
  };

  const PostOpportunityModal = (data = null) => {
    const { data: opportunityData } = data;

    const [addOpportunity, { loading: loadingSubmit }] = useMutation(
      ADD_OPPORTUNITY,
      {
        onCompleted: () => {
          toast.success('Opportunity created successfully');
          setCloseModal();
          history.push(`/${LINK_GROUP.EMPLOYER}/dashboard`);
        },
        onError: (err) => {
          console.error(err);
          toast.dismiss();
          toast.error('Something went wrong!', { toastId: 'resErr' });
        },
      }
    );

    const createOpportunity = (data) => {
      toast.dismiss();

      data = {
        ...data,
        target: TARGET_OPPORTUNITY[1].value,
        description: description,
        location: location,
        job_number: postDate.toLocaleDateString('en-GB'),
        is_active: !isUnpublished,
        is_on_site: false,
        is_remote: false,
        type: 'PROJECT',
        sub_type: 'PROJECT',
        level: 'PROJECT',
        is_public: true,
        services: {
          sync: selectedServices,
        },
        genres: {
          sync: selectedIndustryExperience,
        },
        agency: {
          connect: state?.activeCompany?.team_id,
        },
        minimumBudget: parseFloat(data.minimumBudget),
        maximumBudget: parseFloat(data.maximumBudget),
        duration: parseInt(data.duration),
        created_by: parseInt(
          data.posting_user && data.posting_user !== -1
            ? data.posting_user
            : state?.activeCompany?.user_id
        ),
        updated_by: parseInt(
          data.posting_user && data.posting_user !== -1
            ? data.posting_user
            : state?.activeCompany?.user_id
        ),
      };

      delete data.posting_user;

      addOpportunity({
        variables: {
          input: data,
        },
      });
    };

    return (
      <div style={{ padding: '25px' }}>
        <Heading>This New Project Will Cost 1 Job Post</Heading>
        <br />
        <p>Your current balance is {balance} job posts</p>
        <br />
        <FormBlock isInline>
          <FormBlockItem>
            <Button
              type="submit"
              disabled={loadingSubmit}
              className="app-btn text--capitalize"
              onClick={() => {
                if (opportunityData) {
                  createOpportunity(opportunityData);
                } else {
                  setCloseModal();
                }
              }}
            >
              Continue
            </Button>
          </FormBlockItem>
          <FormBlockItem>
            <Button
              transparent
              as="link"
              scheme="outline"
              onClick={() => setCloseModal()}
              to={`/${LINK_GROUP.EMPLOYER}/manage-credits`}
              className="app-btn text--capitalize"
            >
              Buy More Job Posts
            </Button>
          </FormBlockItem>
        </FormBlock>
      </div>
    );
  };

  const watchFields = watch(['follow_up']);

  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionForm
          actionComponent={
            <Text>
              Fill out the details of the project which will be shown to our
              network of agencies.
            </Text>
          }
          headline="Post New Agency Project"
        >
          <form onSubmit={handleSubmit(confirmOpportunity)}>
            <FormBlock>
              <FormBlockItem className="mt-0" label={'Status'}>
                <div
                  className=" d-flex"
                  style={{ alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Text
                    as="span"
                    className={!isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(false)}
                    style={{
                      cursor: 'pointer',
                      marginRight: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Published
                  </Text>
                  <Switch
                    onChange={(e) => {
                      setIsUnpublished(!isUnpublished);
                    }}
                    sameActive
                    isChecked={isUnpublished}
                  />
                  <Text
                    as="span"
                    className={isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(true)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Unpublished
                  </Text>
                </div>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Project Title'}>
                <FormInput
                  isRequired
                  type="text"
                  register={register}
                  name="title"
                  clearErrors={clearErrors}
                  error={errors.title ? 'Please enter title' : ''}
                  showErrorText
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Date Posted'}>
                <DatePicker
                  onChange={setPostDate}
                  value={postDate}
                  className="form__input date"
                  format="dd/M/y"
                  name="job_number"
                  required={false}
                  clearIcon={null}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
              </FormBlockItem>

              <FormBlockItem label={'Category Experience'}>
                {loadingGenres && <span>Loading experience...</span>}
                {genresData && (
                  <CustomMultiselect
                    className={
                      submitted && selectedIndustryExperience.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      genresData?.genres?.map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                    }
                    onSelect={handleIndustryExperienceChange}
                    onRemove={handleIndustryExperienceChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Category Experience"
                    ref={multiselectIndustryExperienceRef}
                  />
                )}
                {submitted && selectedIndustryExperience.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select category experience'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Project Location'}>
                <SearchBox
                  accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                  placeholder="Please enter location"
                  options={{
                    types: ['place', 'district', 'region'],
                  }}
                  onSuggestError={() => {
                    toast.dismiss();
                    toast.error('Failed to fetch address!', {
                      toastId: 'resErr',
                    });
                  }}
                  onRetrieve={(value) => {
                    const place =
                      value?.features[0]?.properties?.full_address ??
                      value?.features[0]?.properties?.place_formatted;
                    setLocation(place);
                  }}
                  theme={{
                    cssText: 'height: 43px;',
                    icons: {
                      addressMarker: '',
                      close: '',
                      search: '',
                    },
                    variables: {
                      border: 'none',
                      borderRadius: '9px',
                      boxShadow: 'none',
                      color: '#0e0e0e',
                      fontFamily: 'Jost, Arial, Helvetica, sans-serif',
                      fontWeight: 500,
                      // lineHeight: 2.6,
                      // padding: '5px 21px',
                      unit: 16,
                    },
                  }}
                  value={location}
                />
              </FormBlockItem>

              <FormBlockItem label={'Project Description'}>
                <FormInput
                  isRequired
                  type="wysiwyg"
                  name="description"
                  error={
                    submitted && (!description || description?.length === 0)
                      ? 'Please enter a description'
                      : null
                  }
                  showErrorText
                  maxLength={0}
                  clearErrors={clearErrors}
                  setValue={setDescription}
                  fieldClassName={`${
                    submitted && (!description || description?.length === 0)
                      ? 'error-state state-error'
                      : ''
                  }`}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Project Duration'}>
                <FormInput
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="duration"
                  options={[
                    {
                      value: 1,
                      label: '1-3 months',
                    },
                    {
                      value: 4,
                      label: '4-6 months',
                    },
                    {
                      value: 99,
                      label: 'Ongoing',
                    },
                  ]}
                  placeholder="Select duration"
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Services Required'}>
                {loadingServices && <span>Loading services...</span>}
                {servicesData && (
                  <CustomMultiselect
                    className={
                      submitted && selectedServices.length === 0
                        ? 'error-state state-error'
                        : ''
                    }
                    options={
                      servicesData?.services.map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                    }
                    onSelect={handleServicesChange}
                    onRemove={handleServicesChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Services"
                    ref={multiselectServiceRef}
                  />
                )}
                {submitted && selectedServices.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select required services'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Budget'}>
                <FormInput
                  type="checkbox"
                  register={register}
                  clearErrors={clearErrors}
                  name="is_compensation_displayed"
                  defaultChecked
                  checkboxChildren={<p>Show Compensation</p>}
                ></FormInput>

                <FormBlock isInline>
                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="select"
                      register={register}
                      clearErrors={clearErrors}
                      name="compensation_currency"
                      defaultValue="GBP"
                      options={TYPE_CURRENCY}
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="number"
                      register={register}
                      clearErrors={clearErrors}
                      name="minimumBudget"
                      step={50}
                      error={
                        errors.minimumBudget
                          ? 'Please enter minimum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="number"
                      register={register}
                      clearErrors={clearErrors}
                      name="maximumBudget"
                      step={50}
                      error={
                        errors.maximumBudget
                          ? 'Please enter maximum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>
                </FormBlock>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Follow Up Message'}>
                <Text
                  className="mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Add a message to your applicants to let them know you have
                  received their application and/or add more information about
                  the recruitment process.
                </Text>
                <FormInput
                  isRequired
                  type="textarea"
                  register={register}
                  clearErrors={clearErrors}
                  name="follow_up"
                  defaultValue={defaultFollowUpMessage}
                  helpChildren={
                    <>
                      <span>
                        {'follow_up' in watchFields
                          ? watchFields.follow_up
                            ? watchFields.follow_up.length
                            : 0
                          : 0}
                      </span>{' '}
                      Characters
                    </>
                  }
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Team User'}>
                <FormInput
                  type="select"
                  register={register}
                  clearErrors={clearErrors}
                  name="posting_user"
                  options={
                    teamList
                      ?.filter(({ team }) => team !== null)
                      ?.map(({ user_id, team }) => ({
                        value: user_id,
                        label: `${team?.first_name} ${team?.last_name}`,
                      })) ?? []
                  }
                  placeholder="Select Team"
                ></FormInput>
              </FormBlockItem>
            </FormBlock>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full"
                size="xxl"
                disabled={balance < 1}
                onClick={() => {
                  setSubmitted(true);
                }}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </form>
        </SectionForm>
      )}
    </DefaultLayout>
  );
}
