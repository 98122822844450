import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { LoadingSection, GridCards } from '../../components';
import useUser from '../../hooks/useUser';
import { LINK_GROUP } from '../../../utils/links';
import useGroups from '../../hooks/useGroups';
import { GET_ARTICLES } from '../../../graphql';
import { useQuery } from '@apollo/react-hooks';

export default function ResourcesPage() {
  const { groupname } = useGroups();

  const {
    data: { articles: { data: articles = [] } = {} } = {},
    refetch: refetchArticle,
    loading,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability: [
        LINK_GROUP.AGENCY.toUpperCase(),
        LINK_GROUP.FREELANCER_AND_AGENCY.toUpperCase(),
        'PUBLIC',
      ],
      type: 'ARTICLE',
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchArticle(),
  });

  const communityArticles = useMemo(() => {
    return (
      articles?.map((a) => {
        return {
          img: a?.image ?? null,
          headline: a.title ?? 'NA',
          url: `/${groupname}/resources/${a.slug}`,
        };
      }) ?? []
    );
  }, [articles, groupname]);

  useUser(); // Check user
  return (
    <DefaultLayout>
      {loading ? (
        <LoadingSection />
      ) : (
        <>
          <GridCards
            type="community"
            heading="Liberty Hive Community"
            data={communityArticles}
            lightRadius
            withBottomSpace
            fullWidthMobile
            boldTitle
          />
        </>
      )}
    </DefaultLayout>
  );
}
