import React, { useEffect, useRef, useState } from 'react';
import { IconUploadCloud } from './Icons';
import { toast } from 'react-toastify';
import { isValidImage } from '../utils/helpers';

export default function UploadFieldV2({
  onSetImage = (image) => {},
  isError,
  defaultImage,
  multiple = false,
  length = 3,
  accept = 'image/*',
  onRemoveImage = (images) => {},
  maxFileSize = null,
}) {
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const hiddenFileInput = useRef();

  useEffect(() => {
    if (multiple) {
      onSetImage(images);
    } else {
      onSetImage(image);
    }
  }, []);

  useEffect(() => {
    if (defaultImage) {
      setPreviewImage(defaultImage);
    }
  }, [defaultImage]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    hiddenFileInput.current.click();
  };

  // Function to handle image drop
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (!file) {
      return;
    }

    if (maxFileSize && file.size > maxFileSize) {
      const maxFileSizeString = Math.ceil(maxFileSize / 1024 / 1024);
      return toast.error(
        `Your image's file size is too large (max ${maxFileSizeString} MB)`,
        {
          toastId: 'invalidImage',
        }
      );
    }

    isValidImage(file, (res) => {
      if (!res) {
        return toast.error(`We only support JPEGs and PNGs for image upload`, {
          toastId: 'invalidImage',
        });
      }

      setPreviewImage(URL.createObjectURL(file));
      setImage(file);
      onSetImage(file);
    });
  };

  const handleChangeImage = (event) => {
    setErrorMessage('');
    if (multiple) {
      if (event.target.files.length > 3) {
        setErrorMessage('You can only select a maximum of 3 files');
        return;
      } else {
        onSetImage(event.target.files);
        for (let index = 0; index < event.target.files.length; index++) {
          setImages((prev) => [...prev, event.target.files[index]]);
          event.persist();
          setPreviewImages((prev) => [
            ...prev,
            {
              type: event.target.files[index].type.startsWith('video/')
                ? 'video'
                : 'image',
              file: URL.createObjectURL(event.target.files[index]),
            },
          ]);
        }
      }
    } else {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      if (maxFileSize && file.size > maxFileSize) {
        const maxFileSizeString = Math.ceil(maxFileSize / 1024 / 1024);
        return toast.error(
          `Your image's file size is too large (max ${maxFileSizeString} MB)`,
          {
            toastId: 'invalidImage',
          }
        );
      }

      isValidImage(file, (res) => {
        if (!res) {
          return toast.error(
            `We only support JPEGs and PNGs for image upload`,
            {
              toastId: 'invalidImage',
            }
          );
        }

        setPreviewImage(URL.createObjectURL(file));
        setImage(file);
        onSetImage(file);
      });
    }
  };

  return (
    <div className="field-v2__upload">
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleChangeImage}
        ref={hiddenFileInput}
        multiple={multiple}
        accept={accept}
      />
      {previewImage && (
        <div className="up-container" style={{ borderRadius: '12px' }}>
          <img src={previewImage} className="object-fit-cover w-full" alt="" />
          <div
            className="close-button"
            onClick={() => {
              onRemoveImage(null);
              setImage(null);
              setPreviewImage(null);
            }}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
      )}
      {previewImages.length > 0 && (
        <div className="images-cont">
          {previewImages.map((file, index) =>
            file?.type === 'video' ? (
              <div className="up-container" key={index}>
                <video id="videoPlayer" width="100%" height="300" controls>
                  <source src={file.file} type="video/mp4"></source>
                  Your browser does not support the video tag.
                </video>
                <div
                  className="close-button"
                  onClick={() => {
                    const newImages = images?.filter((item, i) => i !== index);
                    const newPreview = previewImages.filter(
                      (item, i) => i !== index
                    );
                    onRemoveImage(newImages);
                    setPreviewImages(newPreview);
                    setImages(newImages);
                  }}
                >
                  <i className="fas fa-times"></i>
                </div>
              </div>
            ) : (
              <div className="up-container" key={index}>
                <img
                  src={file.file}
                  className="object-fit-cover w-full"
                  alt=""
                />
                <div
                  className="close-button"
                  onClick={() => {
                    const newImages = images?.filter((item, i) => i !== index);
                    const newPreview = previewImages.filter(
                      (item, i) => i !== index
                    );
                    onRemoveImage(newImages);
                    setPreviewImages(newPreview);
                    setImages(newImages);
                  }}
                >
                  <i className="fas fa-times"></i>
                </div>
              </div>
            )
          )}
        </div>
      )}
      {!previewImage && previewImages.length !== length && (
        <div
          className={`d-flex flex-col align-items-center ${
            previewImages.length && 'mt-36'
          }`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={openFileInput}
        >
          <div
            className={`up-icon-cyrcle bg__gradient-1 bg-center bg-cover ${
              isError && 'bg-red bg-image-unset'
            }`}
          >
            <IconUploadCloud width={'47px'} />
          </div>
          <div
            className={`up-text txt__fs-14 text--400 ${
              isError && 'form-v2__label-error'
            }`}
          >
            Drag & Drop or{' '}
            <i className={`text--700 ${isError && 'form-v2__label-error'}`}>
              browse
            </i>{' '}
            to upload
          </div>
        </div>
      )}
      <p className="form-v2__label-error text-center">
        {errorMessage
          ? errorMessage
          : isError
          ? 'You must upload an agency logo'
          : ''}
      </p>
    </div>
  );
}
