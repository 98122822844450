import React, { useMemo } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { LoadingSection, SectionViewOtherProfile } from '../../../components';
import { GET_USER } from '../../../../graphql';
import { useQuery } from '@apollo/react-hooks';
import CONTENTS_UTIL from '../../../../utils/contents';

export default function CompanyProfilePage({
  match: {
    params: { id },
  },
}) {
  const {
    data: { user } = {},
    loading,
    refetch,
  } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onError: () => refetch(),
  });

  const contents = useMemo(() => {
    if (!user) {
      return;
    }
    const {
      company,
      address_1,
      address_2,
      city,
      state,
      country,
      zip,
      bio,
      description,
    } = user;

    return {
      desktop: CONTENTS_UTIL.generateDetails(
        [
          CONTENTS_UTIL.generateRowDetails(
            'Address',
            CONTENTS_UTIL.generateAddress(
              address_1,
              address_2,
              city,
              state,
              country,
              zip
            )
          ),
        ],
        [
          CONTENTS_UTIL.generateRowDetails(`About ${company}`, bio ?? '-'),
          CONTENTS_UTIL.generateRowDetails(
            'What Sets Us Apart',
            description ?? '-'
          ),
        ]
      ),
      mobile: CONTENTS_UTIL.generateDetails(
        [
          CONTENTS_UTIL.generateRowDetails(`About ${company}`, bio ?? '-'),
          CONTENTS_UTIL.generateRowDetails(
            'What Sets Us Apart',
            description ?? '-'
          ),
        ],
        [
          CONTENTS_UTIL.generateRowDetails(
            'Address',
            CONTENTS_UTIL.generateAddress(
              address_1,
              address_2,
              city,
              state,
              country,
              zip
            )
          ),
        ]
      ),
    };
  }, [user]);

  return (
    <DefaultLayout goBack={true}>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionViewOtherProfile
          profileImage={
            user?.avatar || '/img/agency-picture-placeholder@2x.jpg'
          }
          headline={user?.company}
          desktop={contents?.desktop}
          mobile={contents?.mobile}
        />
      )}
    </DefaultLayout>
  );
}
