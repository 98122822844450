import React from 'react';

export default function Panel({
  isSmall = false,
  className = '',
  children,
  smallPaddingTop,
  ...rest
}) {
  return (
    <div
      className={`panel-back${isSmall ? ` panel-back--sm` : ''} ${
        smallPaddingTop ? ` panel-back--st` : ''
      } ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}
