import React from 'react';

export default function ThumbUp() {
  return (
    <svg width="1em" height="0.875em" viewBox="0 0 64 56" fill="none">
      <path
        d="M12 19.875C14.125 19.875 16 21.75 16 23.875V51.625C16 53.75 14.125 55.5 12 55.5H4C1.75 55.5 0 53.75 0 51.625V23.75C0 21.625 1.75 19.875 4 19.875H12ZM64 24.25C64 27.25 62.375 29.75 60.125 31.25C60.25 32 60.375 32.625 60.375 33.25C60.375 36.125 58.875 38.625 56.75 40.125C56.75 40.5 56.75 40.875 56.75 41.25C56.75 44.125 55.375 46.625 53.125 48.125C52.875 52.5 49.25 55.875 44.875 55.875H38.25C33.75 55.875 29.375 54.5 25.75 51.75L21.125 48.375C20.375 47.75 19.875 46.875 19.875 45.875C19.875 44.375 21.25 42.875 23 42.875C23.625 42.875 24.25 43.125 24.75 43.5L29.375 47C31.875 48.875 35 50 38.25 50H44.875C46.125 50 47.25 48.875 47.25 47.625C47.25 47.125 47 47 47 46.375C47 43 50.75 44.125 50.75 41.25C50.75 40.125 50 39.875 50 38.5C50 34.75 54.375 36.375 54.375 33.25C54.375 31.625 52.875 31.625 52.875 29.625C52.875 28.125 54.125 26.75 55.75 26.625C57 26.625 58 25.5 58 24.25C58 23 56.875 22 55.5 21.875H38.375C36.75 21.875 35.375 20.75 35.375 19C35.375 18.5 35.5 18 35.75 17.5C38 13.5 39 9.5 39 8.5C39 7.5 38.25 6 36.125 6C32 6 34.375 13.625 24.75 21.25C24.25 21.75 23.625 21.875 23 21.875C21.25 21.875 20 20.5 20 18.875C20 15.5 23.5 17.375 27.5 7.25C28.75 4 30.375 0 36.125 0C41.625 0 45 4.25 45 8.5C45 10.125 44.25 12.875 43.125 16H55.625C60.125 16 64 19.75 64 24.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
