import React, { useState, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GET_AUTH_USER } from '../graphql';
import { AppContext } from '../Store';
import { userVar } from '../graphql/config';
import {
  getUser,
  getLoginType,
  logUserOut,
  getLoginUrl,
  saveLoginTypeLocalstorage,
  CompanyBrowserStorage,
} from '../utils/helpers';
import * as Sentry from '@sentry/browser';
import { LINK_GROUP } from '../utils/links';

const AuthWrapper = ({ component: Component, user, ...rest }) => {
  const location = useLocation();
  const isTeamRole = useMemo(() => {
    return user.find((o) => {
      if (o === 'GUEST') {
        return true;
      }

      if (LINK_GROUP[o]) {
        return true;
      }

      return false;
    });
  }, [user]);

  const isLoggedIn = getUser().access_token || false;

  const [loginUrl, setLoginUrl] = useState(
    getLoginUrl(isTeamRole, location.pathname)
  );
  const [authenticatedUser, setAuthenticatedUser] = useState(undefined);
  const [state, setState] = useContext(AppContext);
  const { pathname } = location;
  const route = pathname.split('/');
  const cookies = new Cookies();

  useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      console.error('error GET_AUTH_USER', e);
    },
    onCompleted: ({ me }) => {
      let activeCompany;
      const teams = me?.teamUser;
      if (teams?.length > 0) {
        const newlyInvitedCompanyId = cookies.get('lh_team');

        if (newlyInvitedCompanyId) {
          activeCompany = teams.find(
            (tu) => tu.team_id === newlyInvitedCompanyId
          );
          cookies.remove('lh_team', {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          CompanyBrowserStorage.delete();
        }

        if (!activeCompany && CompanyBrowserStorage.name) {
          activeCompany = CompanyBrowserStorage.getActiveCompany(me.id, teams);
        }

        if (!activeCompany) {
          // activeCompany = teams.find((a) => a.user.email === me.email) ?? teams[0];
          activeCompany = teams[0];
        }

        CompanyBrowserStorage.set(me.id, activeCompany.team_id);
      }

      setAuthenticatedUser(me);
      setLoginUrl(
        teams && teams?.length > 0 && isTeamRole
          ? `/${activeCompany?.type?.toLowerCase()}/login?redirectTo=${
              location.pathname
            }`
          : `/${LINK_GROUP.CANDIDATE}/login?redirectTo=${location.pathname}`
      );
      setState({
        ...state,
        userTeams: teams,
        activeCompany: activeCompany,
      });

      if (!me) {
        Sentry.setUser(null);
        userVar({});
        setState({});
        logUserOut();
        window.location.href = loginUrl;
      } else {
        Sentry.setUser({
          id: me.id,
          email: me.email,
          username: me.name,
        });

        setLoginUrl(
          `${me?.type?.toLowerCase()}/login?redirectTo=${location.pathname}`
        );
      }
    },
  });

  /** Security Check On Role Access */
  if (authenticatedUser && user) {
    if (!user.find((o) => o === getUser()?.type)) {
      if (route[route.length - 1] !== 'login' && isLoggedIn) {
        return (
          <Redirect
            to={
              authenticatedUser?.teamUser &&
              authenticatedUser?.teamUser?.length > 0
                ? `/${getUser()?.type?.toLowerCase()}/dashboard`
                : `/${LINK_GROUP.CANDIDATE}/dashboard`
            }
          />
        );
      } else {
        userVar({});
        setState({});
        logUserOut();
        return <Redirect to={loginUrl} />;
      }
    }
  }

  /** Security Check On Restricted Team Member Access | Restricted Team Admin Only Pages */
  if (state?.activeCompany?.role === 'member') {
    switch (pathname) {
      case `/${LINK_GROUP.EMPLOYER}/manage-payment-info`:
      case `/${LINK_GROUP.COMPANY}/manage-payment-info`:
      case `/${LINK_GROUP.EMPLOYER}/manage-credits`:
      case `/${LINK_GROUP.COMPANY}/manage-credits`:
      case `/${LINK_GROUP.EMPLOYER}/manage-team`:
      case `/${LINK_GROUP.COMPANY}/manage-team`:
      case `/${LINK_GROUP.EMPLOYER}/invitations`:
      case `/${LINK_GROUP.COMPANY}/invitations`:
      case `/${LINK_GROUP.EMPLOYER}/billing-history`:
      case `/${LINK_GROUP.COMPANY}/billing-history`:
        return <Redirect to={`/${LINK_GROUP.EMPLOYER}/404`} />;
      default:
        break;
    }
  }

  /** Security Check On Route Address */
  saveLoginTypeLocalstorage(getUser()?.type);
  if (isLoggedIn && route && route[1] && getLoginType()) {
    if (
      getLoginType()?.toLowerCase() !== 'guest' &&
      String(route[1])?.toLowerCase() !== getLoginType()?.toLowerCase()
    ) {
      return (
        <Redirect
          to={{
            pathname: `/${getLoginType()?.toLowerCase()}/404`,
            state: { from: location },
          }}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={loginUrl} />
      }
    />
  );
};

export default AuthWrapper;
