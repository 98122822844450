import React, { useEffect } from 'react';
import Header from '../../components/Agency/Header';
import Footer from '../../v2/components/Footer/Footer';
import styled from 'styled-components';
import CaseStudyCard from './components/CaseStudyCard';
import { useQuery } from '@apollo/client';
import { GET_CASE_STUDIES } from '../../graphql';
import { LINK_GROUP } from '../../utils/links';
import { useHistory } from 'react-router-dom';

const CaseStudy = () => {
  const history = useHistory();
  const { data, loading, refetch } = useQuery(GET_CASE_STUDIES, {
    variables: {
      first: 10,
      page: 1,
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="lh-wrapper bg__gradient-6 bg-center bg-cover theme theme-agency">
      <Header isLoggedIn />
      <div style={{ flex: 1 }} className="container gap-3">
        <div className="black-card black-card__full mb-50">
          <div className="d-flex justify-content-between align-items-center">
            <Heading>Case Studies</Heading>

            <Button
              className="bg__gradient-1 bg-top bg-cover"
              onClick={() => {
                history.push(`/${LINK_GROUP.AGENCY}/case-study/add`);
              }}
            >
              Create Case Study
            </Button>
          </div>
          <GridFourCol>
            {loading && !data?.caseStudies?.data && <div>Loading...</div>}
            {data?.caseStudies?.data.map((caseStudy) => (
              <CaseStudyCard data={caseStudy} key={caseStudy.id} />
            ))}
          </GridFourCol>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CaseStudy;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  line-height: 34.68px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 43.35px;
  }
`;

const Button = styled.button`
  border-radius: 12px;
  padding: 12px 27px;
  border: none;
  font-size: 18px;
  color: white;
  font-weight: 500;
`;

export const GridFourCol = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  justify-items: center;
  margin-top: 20px;
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 22px;
    row-gap: 60px;
    margin-top: 32px;
  }
`;
