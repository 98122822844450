import gql from 'graphql-tag';

export const GET_TEAM_USERS = gql`
  query GetTeamUsers($team_id: Int!) {
    viewUserTeam(team_id: $team_id) {
      id
      user_id
      team_id
      team {
        id
        avatar
        first_name
        last_name
        email
        company
        agency_name
      }
      user {
        id
        first_name
        last_name
        email
        company
        agency_name
      }
      role
      active
      can_post_jobs
    }
  }
`;
