import React, { useContext } from 'react';
import { AppContext } from '../../Store';
import { Link } from 'react-router-dom';
import { getUser } from '../../utils/helpers';
import { LINK_GROUP } from '../../utils/links';

const AccountSubmenuMobile = () => {
  const [state] = useContext(AppContext);

  return (
    <div className="account-submenu__mobile bg-light-agency">
      <div className="container">
        <div className="account-submenu__mobile__fixed bg-light-agency">
          <div className="row">
            <div className="col-9 account-submenu__mobile__heading">
              Account Settings
            </div>
            <div className="col-3 account-submenu__mobile__arrow">
              <i className="fas fa-chevron-down" />
            </div>
          </div>
        </div>
        <div className="account-submenu__mobile__content bg-light-agency">
          <div className="row">
            <ul className="account-submenu__list">
              <li className="account-submenu__list__item">
                <Link
                  to={`/${LINK_GROUP.COMPANY}/account`}
                  className="link-company"
                >
                  My Account
                </Link>
              </li>
              {getUser()?.type !== 'GUEST' && (
                <li className="account-submenu__list__item">
                  <Link
                    to={`/${LINK_GROUP.COMPANY}/profile`}
                    className="link-company"
                  >
                    Company Information
                  </Link>
                </li>
              )}

              {state?.activeCompany?.role !== 'member' && (
                <li className="account-submenu__list__item">
                  <Link
                    to={`/${LINK_GROUP.COMPANY}/edit-profile`}
                    className="link-company"
                  >
                    Edit Profile
                  </Link>
                </li>
              )}
              {state?.activeCompany?.role !== 'member' && (
                <>
                  <li className="account-submenu__list__item">
                    <Link
                      to={`/${LINK_GROUP.COMPANY}/manage-payment-info`}
                      className="link-company"
                    >
                      Manage Payment Info
                    </Link>
                  </li>
                  {getUser().status === 'ACTIVE' && (
                    <li className="account-submenu__list__item">
                      <Link
                        to={`/${LINK_GROUP.COMPANY}/manage-credits`}
                        className="link-company"
                      >
                        Purchase Job Posts
                      </Link>
                    </li>
                  )}
                  <li className="account-submenu__list__item">
                    <Link
                      to={`/${LINK_GROUP.COMPANY}/manage-team`}
                      className="link-company"
                    >
                      Manage Team
                    </Link>
                  </li>
                  <li className="account-submenu__list__item">
                    <Link
                      to={`/${LINK_GROUP.COMPANY}/invitations`}
                      className="link-company"
                    >
                      Invitations
                    </Link>
                  </li>
                  <li className="account-submenu__list__item">
                    <Link
                      to={`/${LINK_GROUP.COMPANY}/billing-history`}
                      className="link-company"
                    >
                      Billing History
                    </Link>
                  </li>
                </>
              )}
              <li className="account-submenu__list__item">
                <Link
                  to={`/${LINK_GROUP.COMPANY}/change-password`}
                  className="link-company"
                >
                  Change password
                </Link>
              </li>

              <li className="account-submenu__list__item">
                <Link
                  to={`/${LINK_GROUP.COMPANY}/logout`}
                  className="link-company"
                >
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSubmenuMobile;
