import React, { useState } from 'react';
import Header from '../../components/Agency/Header';
import Footer from '../../v2/components/Footer/Footer';
import styled from 'styled-components';
import Switch from 'react-switch';
import Checkbox from 'react-custom-checkbox';
import CaseStudyCard from './components/CaseStudyCard';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_CASE_STUDIES } from '../../graphql';

const Network = () => {
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState([]);

  const { data } = useQuery(GET_CASE_STUDIES, {
    variables: {
      first: 10,
      page: 1,
    },
  });

  const removeItem = (tag) => {
    const selected = filter.filter((item) => item.value !== tag.value);
    setFilter(selected);
  };

  const isSelected = (tag) => {
    return filter.some(
      (item) => item.value === tag.value && item.label === tag.label
    );
  };

  return (
    <div className="lh-wrapper bg__gradient-1 bg-center bg-cover theme theme-agency">
      <Header isLoggedIn />
      <div style={{ flex: 1, marginTop: '65px' }} className="container">
        <div
          className="black-card black-card__full"
          style={{
            borderBottomRightRadius: `${active ? 0 : '30px'}`,
            borderBottomLeftRadius: `${active ? 0 : '30px'}`,
            marginTop: 0,
          }}
        >
          <Heading>The Agency Marketplace</Heading>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <p
              style={{ marginBottom: 0 }}
              className={!active ? 'text_withbg' : ''}
              data-text="Filter By Agency"
            >
              Filter By Agency
            </p>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#71BFB2"
              offColor="#71BFB2"
              onChange={(checked) => {
                setActive(checked);
              }}
              checked={active}
              width={30}
              height={16}
              className="agency_switch"
            />
            <p
              style={{ marginBottom: 0 }}
              className={active ? 'text_withbg' : ''}
              data-text="Filter By Case Studies"
            >
              Filter By Case Studies
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexWrap: 'wrap',
              marginTop: '20px',
            }}
          >
            {tags.map((tag) => (
              <Checkbox
                key={tag.value}
                name={tag.value}
                checked={false}
                value={tag.value}
                onChange={(value) => {
                  value ? setFilter([...filter, tag]) : removeItem(tag);
                }}
                borderColor={'white'}
                borderRadius={10}
                borderWidth={1}
                style={{
                  cursor: 'pointer',
                  background: isSelected(tag) ? '#000' : '',
                  width: '16px',
                  height: '16px',
                }}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: 'none',
                  color: '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
                containerStyle={{
                  border: '1px solid #FFFFFF',
                  borderRadius: '27px',
                  width: 'fit-content',
                  paddingBlock: '4px',
                  paddingInlineStart: '4px',
                  paddingInlineEnd: '14px',
                  backgroundImage: isSelected(tag)
                    ? 'url(/img/agency-gradients/Agency-mesh-gradient-1.jpg)'
                    : '',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                label={tag.label}
                icon={
                  <i className="fas fa-check" style={{ fontSize: '12px' }} />
                }
                size={16}
              />
            ))}
          </div>
        </div>
        {!active && (
          <div
            className="mt-50"
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            {agencies.map((agency) => (
              <Agency key={agency.name}>
                <img src={agency.logo} alt={agency.name} />
                <div className="body">
                  <div>
                    <h3>{agency.name}</h3>
                    <p>{agency.location}</p>
                  </div>
                  <ul class="tags-list">
                    {agency.tags.map((tag) => (
                      <li key={tag.value}>{tag.label}</li>
                    ))}
                  </ul>
                  <Link
                    to={`/agency/network/${agency.name
                      .toLowerCase()
                      .replace(' ', '-')}`}
                  >
                    <button
                      type="button"
                      className="btn view-profile bg__gradient-1 bg-cover"
                    >
                      VIEW PROFILE
                    </button>
                  </Link>
                </div>
              </Agency>
            ))}
          </div>
        )}
        {active && (
          <Grid
            className="black-card black-card__full"
            style={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              marginTop: 0,
              paddingTop: 0,
            }}
          >
            {data?.caseStudies?.data.map((caseStudy) => (
              <CaseStudyCard data={caseStudy} key={caseStudy.id} />
            ))}
          </Grid>
        )}
      </div>
      <Footer />
    </div>
  );
};

const tags = [
  {
    label: 'Web Development',
    value: 'web-development',
  },
  {
    label: 'Mobile App Development',
    value: 'mobile-development',
  },
  {
    label: 'Video Production',
    value: 'video-production',
  },
  {
    label: 'Copywriting',
    value: 'copywriting',
  },
  {
    label: 'SEO Services',
    value: 'seo-services',
  },
  {
    label: 'Paid Social',
    value: 'paid-social',
  },
  {
    label: 'PPC',
    value: 'ppc',
  },
  {
    label: 'Digital Design',
    value: 'digital-design',
  },
  {
    label: 'Print Design',
    value: 'print-design',
  },
  {
    label: '3D & Animations',
    value: '3d-animations',
  },
  {
    label: 'Brand Identity',
    value: 'brand-identity',
  },
];

const agencies = [
  {
    name: 'Analog Republic',
    logo: '/img/ARlogo1.png',
    location: 'London / Remote',
    tags: [
      {
        label: 'Web Development',
        value: 'web-development',
      },
      {
        label: 'Mobile App Development',
        value: 'mobile-development',
      },
      {
        label: 'Video Production',
        value: 'video-production',
      },
    ],
  },
  {
    name: 'Pentagram',
    logo: '/img/ARlogo2.png',
    location: 'Multiple HQs / Remote',
    tags: [
      {
        label: 'Web Development',
        value: 'web-development',
      },
      {
        label: 'Mobile App Development',
        value: 'mobile-development',
      },
      {
        label: 'Video Production',
        value: 'video-production',
      },
      {
        label: 'Digital Design',
        value: 'digital-design',
      },
      {
        label: 'Print Design',
        value: 'print-design',
      },
      {
        label: '3D & Animations',
        value: '3d-animations',
      },
      {
        label: 'Brand Identity',
        value: 'brand-identity',
      },
    ],
  },
];

const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  line-height: 34.68px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 43.35px;
    margin-bottom: 40px;
  }
`;

const Agency = styled.div`
  background-color: #0e0e0e;
  width: 100%;
  padding-block: 20px;
  padding-inline: 16px;
  border-radius: 12px;
  display: flex;
  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 16px;
    .view-profile {
      color: #ffffff;
      font-size: 12px;
      font-weight: 800;
      text-decoration: none;
      border-radius: 6px;
      background-repeat: no-repeat;
      width: 122px;
      height: 38px;
    }
    .tags-list {
      display: flex;
      flex-direction: column;
      list-style-position: inside;
      column-gap: 10px;
      row-gap: 5px;
      flex-wrap: wrap;
      margin: 0;
      padding-left: 0;
      li {
        font-size: 14px;
        line-height: 19.1px;
        font-weight: 400;
        color: white;
      }
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 900;
    color: white;
    line-height: 24.55px;
    margin: 0;
  }
  p {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.1px;
    margin: 0;
  }
  img {
    width: 48px;
    height: 48px;
  }
  @media (min-width: 768px) {
    align-items: center;
    border-radius: 12px;
    padding: 8px;
    img {
      width: 57px;
      height: 57px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    .body {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      width: 100%;
      .tags-list {
        flex-direction: row;
        width: 45%;
      }
    }
  }
  @media (min-width: 1024px) {
    .body {
      gap: 60px;
      .tags-list {
        flex-direction: row;
        width: 50%;
      }
    }
  }
  @media (min-width: 1400px) {
    .body {
      .tags-list {
        flex-direction: row;
        width: 600px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 100px;
  justify-content: center;
  justify-items: center;
  img {
    height: 289px;
    width: 100%;
    object-fit: contain;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    img {
      width: 100%;
      height: 200px;
    }
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    justify-content: start;
  }
  @media (min-width: 1400px) {
    grid-template-columns: 249px 249px 249px 249px;
    gap: 20px;
    img {
      height: 200px;
    }
  }
`;

export default Network;
