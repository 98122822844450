import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROFILE, VERIFY_NUMBER } from '../../../../graphql';
import Error from '../../../../components/Error';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { getUser, saveUserLocalstorage } from '../../../../utils/helpers';
import { userVar } from '../../../../graphql/config';

const PhoneVerificationModal = ({ onSubmitCallback, onBackCallback }) => {
  const { register, handleSubmit } = useForm();

  const [error, setError] = useState('');
  const [verifyNumber, { loading }] = useMutation(VERIFY_NUMBER);
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const verificationConfirmation = () => {
    updateProfile({
      variables: {
        input: {
          send_text_notification: true,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => toast.error('Something went wrong.', { autoClose: 5000 }));
  };

  const verifyCode = ({ code }) => {
    if (!code) {
      setError('Please enter a valid code');
      return;
    }

    verifyNumber({
      variables: {
        code: code,
      },
    })
      .then(() => {
        verificationConfirmation();
        onSubmitCallback();
      })
      .catch((e) => {
        toast.error(
          e?.graphQLErrors[0]?.extensions?.reason ?? 'Something went wrong.',
          { autoClose: 5000 }
        );
      });
  };

  return (
    <form
      autoComplete="off"
      className="form"
      onSubmit={handleSubmit(verifyCode)}
      style={{
        width: '100%',
      }}
    >
      <Heading>SMS Notification</Heading>

      <FormBlock className="mt-4">
        <FormBlockItem
          label={
            "We've sent you a 6 digit verification code, please enter it below."
          }
        >
          <FormInput type="text" register={register} name="code"></FormInput>
          {error && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: '30px',
              }}
            >
              <Error text={error} />
            </div>
          )}
        </FormBlockItem>
      </FormBlock>

      <FormCTA spaceT>
        <Button type="submit" className="w-full" size="xxl" disabled={loading}>
          {loading ? 'Loading...' : 'Verify'}
        </Button>
        <Text className="mt-4 mx-auto">
          I didn't get the code.&nbsp;
          <span className="link-freelancer" onClick={() => onBackCallback()}>
            Try again
          </span>
        </Text>
      </FormCTA>
    </form>
  );
};

export default PhoneVerificationModal;
