import React from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, Heading } from '../../snippets';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN, UPDATE_PROFILE } from '../../../graphql';
import { userVar } from '../../../graphql/config';
import useGroups from '../../hooks/useGroups';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
} from '../../snippets';
import { LINK_GROUP } from '../../../utils/links';
import { generateErrorPasswordText } from '../../../utils/helpers';

export default function ChangePasswordPage({ history }) {
  const { groupname } = useGroups();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    setValue,
    clearErrors,
  } = useForm({ mode: 'onChange' });
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [updateProfile, state] = useMutation(UPDATE_PROFILE);

  const editProfile = async (data) => {
    toast.dismiss();
    const landingPath = {
      [LINK_GROUP.FREELANCER]: 'personal-information',
      [LINK_GROUP.CANDIDATE]: 'personal-information',
      [LINK_GROUP.AGENCY]: 'profile',
      [LINK_GROUP.COMPANY]: 'account',
      [LINK_GROUP.EMPLOYER]: 'account',
    };
    loginMutation({
      variables: {
        input: {
          email: userVar().email,
          password: data.current_password,
        },
      },
    })
      .then((res) => {
        const { data: loginData } = res;
        if (!loginData.login) {
          toast.error('Incorrect password!');
          return;
        }
        return updateProfile({
          variables: {
            input: { password: data.password },
          },
        })
          .then(() => {
            toast.success('Password changed successfully!', {
              autoClose: 4000,
            });
            return history.push(`/${groupname}/${landingPath[groupname]}`);
          })
          .catch((e) => {
            console.error(e);
            toast.error('Something went wrong!', { toastId: 'resErr' });
          });
      })
      .catch((e) => {
        toast.error('Something went wrong!', { toastId: 'resErr2' });
      });
  };

  return (
    <DefaultLayout showNavigation showSubmenu>
      <BackPanel>
        <Heading>Change Password</Heading>
        <form onSubmit={handleSubmit(editProfile)}>
          <FormBlock className={'space-t app-form-half'}>
            <FormBlockItem label="Current Password">
              <FormInput
                isRequired
                type="password"
                settings={{ minLength: 8, required: true }}
                register={register}
                watch={watch}
                setValue={setValue}
                name="current_password"
                showErrorText
                clearErrors={clearErrors}
                error={generateErrorPasswordText(errors, 'current_password')}
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label="New Password">
              <FormInput
                isRequired
                type="password"
                settings={{
                  minLength: 8,
                  required: true,
                }}
                register={register}
                watch={watch}
                setValue={setValue}
                name="password"
                clearErrors={clearErrors}
                error={generateErrorPasswordText(errors)}
                showErrorText
              ></FormInput>
            </FormBlockItem>

            <FormBlockItem label="Confirm Password">
              <FormInput
                isRequired
                type="password"
                settings={{
                  validate: (value) => value === getValues('password'),
                }}
                register={register}
                watch={watch}
                setValue={setValue}
                name="password_confirmation"
                showErrorText
                clearErrors={clearErrors}
                error={
                  errors.password_confirmation ? 'Passwords do not match' : ''
                }
              ></FormInput>
            </FormBlockItem>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full "
                size="xxl"
                capitalize
                disabled={loading || state.loading}
              >
                {loading || state.loading ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </FormBlock>
        </form>
      </BackPanel>
    </DefaultLayout>
  );
}
