import React, { useState } from 'react';
import {
  BackPanel,
  Heading,
  Button,
  Link,
  FormBlock,
  FormBlockItem,
  FormInput,
  FormCTA,
} from '../../snippets';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import useGroups from '../../hooks/useGroups';
import { toast } from 'react-toastify';
import { userVar } from '../../../graphql/config';
import {
  getLoginType,
  saveLoginTypeLocalstorage,
  saveUserLocalstorage,
} from '../../../utils/helpers';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../../../graphql';
import Cookies from 'universal-cookie';
import GoogleSignInAction from '../GoogleSignInAction/GoogleSignInAction';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LoginForm() {
  const history = useHistory();
  const { groupname } = useGroups();
  const { register, handleSubmit, errors, watch, setValue, clearErrors } =
    useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [isEnabled, setIsEnabled] = useState(true);
  const redirectTo = useQuery().get('redirectTo');

  const loginUser = (data) => {
    toast.dismiss();
    setIsEnabled(false);
    loginMutation({
      variables: {
        input: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then(({ data: { login } }) => {
        if (!login) {
          setIsEnabled(true);
          return toast.error('Wrong email / password combination!', {
            toastId: 'agLogErr',
          });
        }
        userVar(login);
        saveUserLocalstorage(login);
        const cookies = new Cookies();
        cookies.set('lh_user', login.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        let userType = login?.type;
        if (login.type === 'GUEST') {
          userType = login?.teamType;
        }
        saveLoginTypeLocalstorage(userType.toUpperCase());
        history.push(redirectTo ?? `/${getLoginType()}/dashboard`);
      })
      .catch((e) => {
        console.error(e);
        setIsEnabled(true);
        toast.error('Something went wrong!', {
          toastId: 'agLogErr',
          autoClose: 3000,
        });
      });
  };

  return (
    <BackPanel className="form-wrapper" isSmall>
      <form onSubmit={handleSubmit(loginUser)}>
        <Heading as="h1">Login</Heading>
        <div className="form-content">
          <FormBlock>
            <FormBlockItem label={'Email Address'}>
              <FormInput
                type="text"
                name="email"
                isRequired
                error={
                  errors.email
                    ? errors.email.type === 'pattern'
                      ? 'Please enter a valid email address'
                      : 'Please enter an email'
                    : null
                }
                register={register}
                withEmailValidation
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Password'}>
              <FormInput
                register={register}
                type="password"
                name="password"
                watch={watch}
                setValue={setValue}
                isRequired
                error={errors.password ? 'Please enter a password' : null}
                helpChildren={
                  <Link to={'/' + groupname + '/forgot-password'}>
                    Forgot Password?
                  </Link>
                }
                clearErrors={clearErrors}
              />
            </FormBlockItem>
          </FormBlock>
          <FormCTA className={'space-t'}>
            <Button
              className="app-btn-submit"
              type="submit"
              disabled={!isEnabled}
              size="xl"
            >
              {loading ? 'Loading...' : 'Login'}
            </Button>
          </FormCTA>
          <GoogleSignInAction>
            <div className="jump-to">
              <Link to={'/' + groupname + '/register'}>Need an account?</Link>
            </div>
          </GoogleSignInAction>
        </div>
      </form>
    </BackPanel>
  );
}
