import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import useGroups from '../../hooks/useGroups';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  BackPanel,
  Heading,
  Link,
  FormBlockItem,
  Button,
  Text,
  FormInput,
} from '../../snippets';
import {
  GET_ALL_OPPORTUNITIES,
  GET_SKILLS,
  GET_GENRES,
} from '../../../graphql';
import {
  humanReadableTime,
  IMG_AGENCY_PLACEHOLDER,
} from '../../../utils/helpers';
import { LoadingSection, Switch } from '../../components';
import { useForm } from 'react-hook-form';
import CustomMultiselect from '../../components/CustomMultiselect';
import { TARGET_OPPORTUNITY } from '../../placeholders/consultant/types';

export default function OpportunitiesPage() {
  const { register, setValue, getValues } = useForm();
  const multiselectSkillRef = useRef(null);
  const multiselectSoftwareSkillRef = useRef(null);
  const multiselectGenreRef = useRef(null);
  const { groupname } = useGroups();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSoftwareSkills, setSelectedSoftwareSkills] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(0);

  const [
    getAllOpportunities,
    { data: { allOpportunities = [] } = {}, loading, called },
  ] = useLazyQuery(GET_ALL_OPPORTUNITIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      trashed: 'WITHOUT',
      is_active: true,
      orderBy: [
        { column: 'FEATURED', order: 'DESC' },
        { column: 'CREATED_AT', order: 'DESC' },
      ],
      target: TARGET_OPPORTUNITY[1].value,
      where: {
        AND: [
          {
            OR: [
              getValues('search') !== ''
                ? {
                    column: 'TITLE',
                    value: `%${getValues('search')}%`,
                    operator: 'LIKE',
                  }
                : {},
            ],
          },
        ],
      },
      ...((selectedSkills.length || selectedSoftwareSkills.length) && {
        hasSkills: {
          OR: [
            {
              column: 'ID',
              operator: 'IN',
              value: [...selectedSkills, ...selectedSoftwareSkills],
            },
          ],
        },
      }),
      ...(selectedGenres.length && {
        hasGenres: {
          OR: [{ column: 'ID', operator: 'IN', value: selectedGenres }],
        },
      }),
    },
  });

  const { data: { skills = [] } = {} } = useQuery(GET_SKILLS);
  const { data: { genres = [] } = {} } = useQuery(GET_GENRES);

  const oppportunities = useMemo(() => {
    return (
      allOpportunities?.map((a) => {
        return {
          img: a?.agency?.avatar ?? null,
          headline: a.title ?? 'NA',
          info: a.agency?.company ?? '-',
          since: humanReadableTime(a?.created_at),
          url: `/${groupname}/opportunities/${a.id}`,
        };
      }) ?? []
    );
  }, [allOpportunities, groupname]);

  const handleOnReset = useCallback(() => {
    if (multiselectSkillRef?.current) {
      multiselectSkillRef.current.resetSelectedValues();
    }

    if (multiselectSoftwareSkillRef?.current) {
      multiselectSoftwareSkillRef.current.resetSelectedValues();
    }

    if (multiselectGenreRef?.current) {
      multiselectGenreRef.current.resetSelectedValues();
    }

    setValue('search', '');
    setSelectedSkills([]);
    setSelectedSoftwareSkills([]);
    setSelectedGenres([]);
  }, [
    multiselectSkillRef,
    multiselectSoftwareSkillRef,
    multiselectGenreRef,
    setValue,
  ]);

  const handleSkillsChange = (selectedList, _) =>
    setSelectedSkills(selectedList.map(({ id }) => id));

  const handleSoftwareSkillsChange = (selectedList, _) =>
    setSelectedSoftwareSkills(selectedList.map(({ id }) => id));

  const handleGenreChange = (selectedList, _) =>
    setSelectedGenres(selectedList.map((s) => s.id));

  useEffect(() => {
    setAppliedFilter(
      [selectedSkills, selectedSoftwareSkills, selectedGenres].filter(
        (item) => item !== null && item.length !== 0
      ).length
    );
    getAllOpportunities();
  }, [
    selectedSkills,
    selectedSoftwareSkills,
    selectedGenres,
    getAllOpportunities,
  ]);

  return (
    <DefaultLayout>
      <BackPanel>
        <div className="meta-header--grid">
          <Heading>All Project Opportunities</Heading>
        </div>

        <FilterCont style={{ marginTop: '20px' }}>
          <FormBlockItem style={{ flex: '3 1 0' }}>
            <FormInput
              type="text"
              name="search"
              placeholder="Search"
              register={register}
            />
          </FormBlockItem>

          <FormBlockItem style={{ flex: '1 1 0' }}>
            <Button onClick={() => getAllOpportunities()} text="normal">
              Search
            </Button>
          </FormBlockItem>

          <FormBlockItem style={{ flex: '1 1 0' }}>
            <Button onClick={handleOnReset} text="normal">
              Clear All
            </Button>
          </FormBlockItem>
        </FilterCont>

        <FilterCont style={{ marginTop: '30px' }}>
          <Text
            as="span"
            weight="bold"
            className=""
            onClick={() => setShowFilters(!showFilters)}
            style={{
              cursor: 'pointer',
            }}
          >
            Show Filters{' '}
            {appliedFilter > 0 && (
              <span className="font-italic text--regular">
                ({appliedFilter} used)
              </span>
            )}
          </Text>
          <Switch
            onChange={() => {
              setShowFilters(!showFilters);
            }}
            isChecked={showFilters}
          />
        </FilterCont>

        <div
          style={{ marginTop: '20px', display: showFilters ? 'block' : 'none' }}
        >
          <FilterCont style={{ marginTop: '20px' }}>
            <FormBlockItem>
              <CustomMultiselect
                options={genres}
                onSelect={handleGenreChange}
                onRemove={handleGenreChange}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Category Experience"
                ref={multiselectGenreRef}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={skills?.filter(({ software }) => !software)}
                onSelect={handleSkillsChange}
                onRemove={handleSkillsChange}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Skills"
                ref={multiselectSkillRef}
              />
            </FormBlockItem>

            <FormBlockItem>
              <CustomMultiselect
                options={skills?.filter(({ software }) => software)}
                onSelect={handleSoftwareSkillsChange}
                onRemove={handleSoftwareSkillsChange}
                displayValue="name"
                closeOnSelect={false}
                avoidHighlightFirstOption
                placeholder="Software Skills"
                ref={multiselectSoftwareSkillRef}
              />
            </FormBlockItem>
          </FilterCont>
        </div>

        {loading ? (
          <LoadingSection />
        ) : (
          called &&
          (oppportunities.length === 0 ? (
            <div style={{ marginTop: '30px' }}>
              There are no opportunities that currently match your search.
              Please try again!
            </div>
          ) : (
            <div className="gridjobs-grid">
              {oppportunities.map((d, i) => {
                return (
                  <div className={`gridjobs-grid--item`} key={i}>
                    <Link
                      to={d.url}
                      className="company-logo"
                      external={d.externalLink ?? false}
                    >
                      <img
                        src={d.img === '' ? IMG_AGENCY_PLACEHOLDER : d.img}
                        alt=""
                      />
                    </Link>
                    <div className="grid-divider"></div>
                    <div className="company-info">
                      <div className="company-info--header">
                        <Link
                          to={d.url}
                          className="company-info--header_headline txt__fw-700"
                          external={d.externalLink ?? false}
                        >
                          {d.headline}
                        </Link>
                        <div className="company-info--header_info">
                          {d.info}
                        </div>
                      </div>
                      <div className="company-info--time">
                        <div>
                          {d.since}
                          {d.status ? (
                            <p className="mb-0">
                              <b>{d.status}</b>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))
        )}
      </BackPanel>
    </DefaultLayout>
  );
}

const FilterCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 15px;
  .form-block--item {
    flex: unset;
    width: 100%;
    & + * {
      margin-top: 0;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    flex-direction: row;
  }
`;
