import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import { BackPanel } from '../../../snippets';
import { LoadingSection, ManagePaymentForm } from '../../../components';
import { AppContext } from '../../../../Store';

export default function CompanyManageAccountInfoPage() {
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (state?.activeCompany) {
      if (state?.activeCompany?.role === 'member') {
        setRedirect(true);
      } else if (state?.activeCompany?.role === 'admin') {
        setLoading(false);
      }
    }
  }, [state]);

  if (redirect) {
    return <Redirect to="404" />;
  }

  return (
    <DefaultLayout showSubmenu isLoading={loading}>
      <BackPanel>
        {loading ? <LoadingSection /> : <ManagePaymentForm />}
      </BackPanel>
    </DefaultLayout>
  );
}
