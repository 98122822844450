import gql from 'graphql-tag';

export const GET_SKILLS = gql`
  query Skills($name: String, $industries: [ID!], $software: Boolean) {
    skills(name: $name, industries: $industries, software: $software) {
      id
      name
      software
    }
  }
`;
