import $ from 'jquery';

const jquery = () => {
  $('#hamburger').on('click', function () {
    $(this).toggleClass('open');
    $(this).toggleClass('fixed');
    $('.mobile-menu').toggleClass('active');
  });

  /* Agency Dashboard CTA Cards */

  $('.training__card').on('hover', () => {
    $('.img__training__thumbnail').toggleClass('cta-hover');
  });

  $('.mentoring__card').on('hover', () => {
    $('.img__mentoring__thumbnail').toggleClass('cta-hover');
  });

  /* Edit Profile Picture Interaction */

  $('.edit-profile-picture').hover(() => {
    $('.edit-profile-picture__overlay').toggleClass('invisible');
    $('.img__profile').toggleClass('img__profile__hover');
  });

  /* Add/Edit Company Logo Interaction */

  $('.edit-company-logo').hover(() => {
    $('.edit-company-logo__overlay').toggleClass('invisible');
    $('.img__company-logo').toggleClass('img__company-logo__hover');
  });

  /* Agency Sub-Menu Interaction */

  $('.account-submenu__mobile__fixed').on('click', () => {
    $('.fa-chevron-down').toggleClass('oneeighty');
    $('.account-submenu__mobile__content').toggleClass('open');
  });

  $(document).on('click', () => {
    $('.fa-chevron-down').removeClass('oneeighty');
    $('.account-submenu__mobile__content').removeClass('open');
  });
  $('.account-submenu__mobile__fixed').on('click', (e) => {
    e.stopPropagation();
    return false;
  });

  /* Credits Cart Promotion Input Interaction */

  $('.credits-cart__promotion__input').on('focus', () => {
    $('.credits-cart__promotion__input-area').addClass(
      'credits-cart__promotion__form__underline'
    );
  });

  $('.credits-cart__promotion__input').on('focusout', () => {
    $('.credits-cart__promotion__input-area').removeClass(
      'credits-cart__promotion__form__underline'
    );
  });

  /* Form Interactions */

  $('.form__input').on('focus', function () {
    $(this)
      .parents('.form__input-block')
      .find('.form__label')
      .addClass('form__active')
      .removeClass('form__grey');
    $(this)
      .parents('.form__input-block')
      .find('.form__input')
      .addClass('form__underline');
  });

  $('.form__input').on('focusout', function () {
    if ($(this).val() && $(this).val().length > 0) {
      $(this)
        .parents('.form__input-block')
        .find('.form__label')
        .addClass('form__grey');
    } else {
      $(this)
        .parents('.form__input-block')
        .find('.form__label')
        .removeClass('form__active');
    }
    $(this)
      .parents('.form__input-block')
      .find('.form__input')
      .removeClass('form__underline');
  });

  $('.date').on('focusout', function () {
    if ($(this).val() && $(this).val().length > 0) {
      $(this)
        .parents('.form__input-block')
        .find('.form__label')
        .removeClass('form__grey');
    } else {
      $(this)
        .parents('.form__input-block')
        .find('.form__label')
        .addClass('form__active');
    }
    $(this)
      .parents('.form__input-block')
      .find('.form__input')
      .removeClass('form__underline');
  });

  $('.form__input').on('keyup', function () {
    if ($(this).val() === '') {
      $(this)
        .parents('.form__input-block')
        .find('.form__clear')
        .addClass('form__hidden');
      $(this)
        .parents('.form__input-block')
        .find('.form__toggle-password')
        .addClass('form__hidden');
      $(this)
        .parents('.form__input-block')
        .find('.form__forgetpassword')
        .removeClass('form__hidden');
    } else {
      $(this)
        .parents('.form__input-block')
        .find('.form__clear')
        .removeClass('form__hidden');
      $(this)
        .parents('.form__input-block')
        .find('.form__toggle-password')
        .removeClass('form__hidden');
      $(this)
        .parents('.form__input-block')
        .find('.form__forgetpassword')
        .addClass('form__hidden');
    }
  });

  $('.form-v2__input').on('keyup', function () {
    if ($(this).val() === '') {
      $(this)
        .parents('.form-v2__input-block')
        .find('.form-v2__toggle-password')
        .addClass('form-v2__hidden');
      $(this)
        .parents('.form-v2__input-block')
        .find('.form-v2__forgetpassword')
        .removeClass('form-v2__hidden');
    } else {
      $(this)
        .parents('.form-v2__input-block')
        .find('.form-v2__toggle-password')
        .removeClass('form-v2__hidden');
      $(this)
        .parents('.form-v2__input-block')
        .find('.form-v2__forgetpassword')
        .addClass('form-v2__hidden');
    }
  });

  $('.form__clear').on('click', function () {
    $(this).addClass('form__hidden');
    $(this).parents('.form__input-block').find('input[type="text"]').val('');
    $(this)
      .parents('.form__input-block')
      .find('input[type="password"]')
      .val('');
    $(this)
      .parents('.form__input-block')
      .find('.form__label')
      .removeClass('form__active')
      .removeClass('form__grey');
    $(this)
      .parents('.form__input-block')
      .find('.form__input')
      .removeClass('form__underline');
    $(this)
      .parents('.form__input-block')
      .find('.form__toggle-password')
      .addClass('form__hidden');
    $(this)
      .parents('.form__input-block')
      .find('.form__forgetpassword')
      .removeClass('form__hidden');
  });

  $('.onboarding__show-password').on('click', () => {
    $('.onboarding__show-password').toggleClass('form__hidden');
  });

  const inputs = $('.form__input');
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].value && inputs[i].value.length > 0) {
      $(inputs[i].parentElement.previousSibling).addClass('form__active');
    }
  }

  const inputsV2 = $('.form-v2__input');
  for (let i = 0; i < inputsV2.length; i++) {
    if (inputsV2[i].value && inputsV2[i].value.length > 0) {
      $(inputsV2[i].parentElement.previousSibling).addClass('form-v2__active');
    }
  }

  $('.form__toggle-password').on('click', function () {
    $(this).toggleClass('fa-eye fa-eye-slash');
    const input = $($(this).attr('toggle'));
    if (input.attr('type') === 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });

  $('.form-v2__toggle-password').on('click', function () {
    $(this).toggleClass('fa-eye fa-eye-slash');
    const input = $($(this).attr('toggle'));
    if (input.attr('type') === 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });

  $('.form__input__search').on('keyup', function () {
    if ($(this).val().length > 0) {
      $(this)
        .parent('.form__input-wrapper')
        .find('.form__search')
        .addClass('form__search__active');
    } else {
      $(this)
        .parent('.form__input-wrapper')
        .find('.form__search')
        .removeClass('form__search__active');
    }
  });

  $('.form__search__remove-selection').on('click', function () {
    $(this).parent('.form__search__selection').addClass('hidden');
  });

  $('.form__search').on('click', function () {
    if ($(this).hasClass('form__search__active')) {
      $(this).parents('.form__input-block').find('input[type="text"]').val('');
      $(this)
        .parents('.form__input-block')
        .find('.form__search')
        .removeClass('form__search__active');
    } else {
    }
  });

  /* Job Type Selector */

  $('.form-control').focus(function () {
    $(this).css({ outline: 'none', 'box-shadow': 'none' });
  });
  // $('.form-control').focusout(function () {
  //   $(this)
  //     .parents('.form__input-block')
  //     .find('.form__label')
  //     .removeClass('form__active');
  // });
};

export default jquery;
