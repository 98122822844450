import { makeVar } from '@apollo/react-hooks';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'react-toastify';
import { getUser, logUserOut } from '../utils/helpers';
import { LINK_GROUP } from '../utils/links';

const userObj = getUser();
export const userVar = makeVar(userObj);
export const registerVar = makeVar({});
export const currentOppVar = makeVar({});
export const currentEducationVar = makeVar({});
export const currentExperienceVar = makeVar({});
export const currentTypeVar = makeVar({});
export const currentCaseStudyVar = makeVar({});

const graphQLServerURL = process.env.REACT_APP_API_URL;
const httpLink = createUploadLink({
  uri: `${graphQLServerURL}/graphql`,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${getUser().access_token}`,
  },
}));

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  onError: (error) => {
    if (
      error.graphQLErrors &&
      error.graphQLErrors[0].message === 'Unauthenticated.'
    ) {
      client.clearStore();
      logUserOut();
      toast.error(
        'You have been logged out. Please click here to log in again.',
        {
          onClick: () =>
            window.location.replace(`/${LINK_GROUP.COMPANY}/login`),
        }
      );
    }
  },
  cache,
});

export default client;
