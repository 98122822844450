import React from 'react';
import { Leading, Text } from '../../snippets';
import { generateClassName } from '../../../utils/helpers';

export default function ProfileOtherDetails({
  contents,
  className,
  fullWidth,
}) {
  const filteredContents = contents?.filter((c) => c !== null && c !== 'null');
  return (
    <div className={generateClassName(['profile-details--grid', className])}>
      <div
        className={generateClassName([
          'profile-details--grid-item mt-0',
          fullWidth ? 'profile-details--grid-item--full' : '',
        ])}
      >
        {filteredContents?.map(({ title, value, button, type, index }) => (
          <div
            className={`meta-item ${index === 0 ? 'mt-lg-42' : ''}`}
            key={title}
          >
            {type === 'component' ? (
              <>{value()}</>
            ) : button ? (
              <>{button}</>
            ) : (
              value !== '-' && (
                <>
                  {title && <Leading>{title}</Leading>}
                  {Array.isArray(value) ? (
                    <div className="mt-3">
                      {value.map((v) => {
                        return v;
                      })}
                    </div>
                  ) : (
                    <Text
                      style={{ lineHeight: 1.8 }}
                      className="mt-25"
                      as="div"
                      dangerouslySetInnerHTML={{ __html: value }}
                    ></Text>
                  )}
                </>
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
