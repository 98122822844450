import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { GridCards, GridJobs, LoadingSection } from '../../components';
import useGroups from '../../hooks/useGroups';
import { useQuery } from '@apollo/react-hooks';
import {
  getLoginUrl,
  humanReadableTime,
  logUserOut,
} from '../../../utils/helpers';
import { GET_AUTH_USER, GET_ALL_OPPORTUNITIES } from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import useArticles from '../../hooks/useArticles';
import useDeals from '../../hooks/useDeals';
import { TARGET_OPPORTUNITY } from '../../placeholders/consultant/types';

const reducer = (state, action) => ({ ...state, ...action.payload });
const initialState = {
  skills: [],
  genres: [],
  jobTypes: [],
};

export default function DashboardPage() {
  const { groupname } = useGroups();

  const [user, dispatch] = React.useReducer(reducer, initialState);

  const {
    data: { me } = {},
    loading,
    refetch: refetchUser,
  } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl(LINK_GROUP.AGENCY);
      }
      dispatch({
        payload: {
          genres: me.genres ?? user.genres,
          skills: me.skills ?? user.skills,
          jobTypes: me.jobTypes ?? user.jobTypes,
        },
      });
    },
    onError: () => refetchUser(),
  });

  const { articles, loading: loadingArticles } = useArticles({
    availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
    first: 4,
  });

  const { deals, loading: loadingDeals } = useDeals({ first: 6 });

  const {
    data: { allOpportunities = [] } = {},
    refetch: refetchOpportunities,
  } = useQuery(GET_ALL_OPPORTUNITIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      trashed: 'WITHOUT',
      orderBy: [
        { column: 'FEATURED', order: 'DESC' },
        { column: 'CREATED_AT', order: 'DESC' },
        { column: 'TITLE', order: 'ASC' },
      ],
      target: TARGET_OPPORTUNITY[1].value,
    },
    onError: () => refetchOpportunities(),
  });

  const communityArticles = useMemo(() => {
    return (
      articles?.map(({ image, title, slug }) => {
        return {
          img: image ?? null,
          headline: title ?? 'NA',
          url: `/${groupname}/resources/${slug}`,
        };
      }) ?? []
    );
  }, [articles, groupname]);

  const dealsData = useMemo(() => {
    let _limit = 6;

    if (deals.length < _limit) {
      _limit = deals?.length;
    }

    const data = deals?.slice(0, _limit);

    return (
      data?.map(({ created_at, image, link, subtitle, title }) => {
        return {
          img: image ?? null,
          headline: title ?? 'NA',
          info: subtitle ?? '-',
          since: humanReadableTime(created_at),
          url: link,
          externalLink: true,
        };
      }) ?? []
    );
  }, [deals]);

  const opportunities = useMemo(() => {
    if (!me) {
      return;
    }

    const _limit = 6;
    const { applications, preferences } = me;

    return {
      forYou:
        preferences
          ?.slice(0, _limit)
          ?.map(
            ({
              agency: { avatar, company },
              title,
              created_at,
              id,
              type,
              genres,
              skills,
            }) => {
              return {
                img: avatar ?? null,
                headline: title ?? 'NA',
                info: company ?? '-',
                since: humanReadableTime(created_at),
                url: `/${groupname}/opportunities/${id}`,
                type: type,
                genres: genres,
                skills: skills,
              };
            }
          ) ?? [],
      applied:
        applications?.slice(0, _limit)?.map(
          ({
            opportunity: {
              id,
              title,
              agency: { avatar, company },
              is_active,
              deleted_at,
            },
            created_at,
          }) => {
            return {
              img: avatar ?? null,
              headline: title ?? 'NA',
              info: company ?? '-',
              since: humanReadableTime(created_at),
              status: is_active && !deleted_at ? 'Open' : 'Closed',
              url: `/${groupname}/opportunities/${id}`,
            };
          }
        ) ?? [],
    };
  }, [me, groupname]);

  const latestJobs = useMemo(() => {
    const _limit = 4;

    return (
      allOpportunities
        ?.slice(0, _limit)
        ?.map(({ agency: { avatar, company }, title, created_at, id }) => {
          return {
            img: avatar ?? null,
            headline: title ?? 'NA',
            info: company ?? '-',
            since: humanReadableTime(created_at),
            url: `/${groupname}/opportunities/${id}`,
          };
        }) ?? []
    );
  }, [allOpportunities, groupname]);

  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!loading && (
        <>
          <GridJobs
            heading="Project Opportunities Just For You"
            viewMoreUrl={`/${groupname}/opportunities-for-you`}
            data={opportunities?.forYou}
            user={user}
            type="opportunities"
          />
          <GridJobs
            type="latest"
            heading="Latest Project Opportunities"
            viewMoreUrl={`/${groupname}/opportunities`}
            data={latestJobs}
          />
          <GridJobs
            type="applied"
            heading="Project Opportunities You've Applied For"
            data={opportunities?.applied}
          />
        </>
      )}
      {loadingArticles && <LoadingSection />}
      {!loadingArticles && (
        <GridCards
          type="community"
          heading="Liberty Hive Community"
          viewMoreUrl={`/${groupname}/resources`}
          data={communityArticles}
        />
      )}
      {loadingDeals && <LoadingSection />}
      {!loadingDeals && (
        <GridJobs
          type="deals"
          heading="Deals For You"
          data={dealsData}
          viewMoreUrl={`/${groupname}/deals`}
        />
      )}
    </DefaultLayout>
  );
}
