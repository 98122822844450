import { GET_ARTICLES } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';
import { LINK_GROUP } from '../../utils/links';

export default function useArticles({
  availability = [
    LINK_GROUP.AGENCY.toUpperCase(),
    LINK_GROUP.FREELANCER_AND_AGENCY.toUpperCase(),
  ],
  first = 3,
}) {
  const {
    data: { articles: { data: articles = [] } = {} } = {},
    refetch: refetchArticle,
    loading,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability,
      type: 'ARTICLE',
      first: first,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchArticle(),
  });

  const {
    data: { articles: { data: ctas = [] } = {} } = {},
    refetch: refetchCTA,
  } = useQuery(GET_ARTICLES, {
    variables: {
      availability,
      type: ['MENTORING', 'TRAINING'],
      first: 2,
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchCTA(),
  });

  return { articles, ctas, loading };
}
