import { useAppTheme } from "../context/themeAppContext";

export default function useModal() {
  const {
    state: modalState,
    action: { setOpenModal, updateModalLastState, setCloseModal },
  } = useAppTheme();

  return {
    modalState,
    onOpeModal: setOpenModal,
    updateModalLastState,
    onCloseModal: setCloseModal,
  };
}
