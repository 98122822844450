import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import {
  AccountInactive,
  GridCards,
  LoadingSection,
  TablerRender,
  TablerSection,
} from '../../components';
import useGroups from '../../hooks/useGroups';
import useUser from '../../hooks/useUser';
import { BackPanel, Heading } from '../../snippets';
import { LINK_GROUP } from '../../../utils/links';
import useArticles from '../../hooks/useArticles';
import useOpportunity from '../../hooks/useOpportunity';
import useAuth from '../../hooks/useAuth';

export default function DashboardCompanyPage() {
  const { groupname } = useGroups();

  const { articles } = useArticles({
    availability: [
      LINK_GROUP.AGENCY.toUpperCase(),
      LINK_GROUP.FREELANCER_AND_AGENCY.toUpperCase(),
    ],
    first: 4,
  });

  const { activeType, getOtherOpportunities, jobs, projects } =
    useOpportunity();

  const { authStatus, authenticating } = useAuth();

  const communityArticles = useMemo(() => {
    return articles.map((a) => {
      return {
        img: a.image,
        headline: a.title,
        url: `/${groupname}/resources/${a.slug}`,
      };
    });
  }, [articles, groupname]);

  useUser(); // Check user

  return (
    <DefaultLayout>
      {authenticating ? (
        <LoadingSection />
      ) : authStatus === 'INACTIVE' ? (
        <div className="container">
          <BackPanel>
            <AccountInactive />
          </BackPanel>
        </div>
      ) : (
        <>
          <BackPanel id={'active-opportunities'}>
            <Heading>
              {activeType[0] ? 'Active' : 'Closed'} Job Opportunities{' '}
              <span className="text--span">{`(${jobs.length})`}</span>
            </Heading>
            <TablerSection
              className="spacer-t"
              tables={jobs}
              headers={[
                'Job Title',
                'Date Posted',
                'Job No.',
                'Applications',
                '',
                '',
              ]}
              tableProportion={[
                'tb-span-4',
                'tb-span-2',
                'tb-span-2',
                'tb-span-2',
                'tb-span-2',
                'tb-span-4 tb-push-right',
              ]}
              renderItem={(item) => {
                return <TablerRender item={item} />;
              }}
            ></TablerSection>
            <div className="app-tabler--column text-lg-right">
              <a
                href="/#"
                onClick={(e) =>
                  getOtherOpportunities('active-opportunities')(e)
                }
                className="app-link app-link-minimalism"
              >
                View {activeType[0] ? 'Closed' : 'Active'} Job Opportunities
              </a>
            </div>
          </BackPanel>

          <BackPanel id={'active-projects'}>
            <Heading>
              {activeType[1] ? 'Active' : 'Closed'} Agency Projects{' '}
              <span className="text--span">{`(${projects.length})`}</span>
            </Heading>
            <TablerSection
              className="spacer-t"
              tables={projects}
              headers={[
                'Job Title',
                'Date Posted',
                'Job No.',
                'Applications',
                '',
                '',
              ]}
              tableProportion={[
                'tb-span-4',
                'tb-span-2',
                'tb-span-2',
                'tb-span-2',
                'tb-span-2',
                'tb-span-4 tb-push-right',
              ]}
              renderItem={(item) => {
                return <TablerRender item={item} />;
              }}
            ></TablerSection>
            <div className="app-tabler--column text-lg-right">
              <a
                href="/#"
                onClick={(e) => getOtherOpportunities('active-projects')(e)}
                className="app-link app-link-minimalism"
              >
                View {activeType[1] ? 'Closed' : 'Active'} Agency Projects
              </a>
            </div>
          </BackPanel>

          <GridCards
            type="community"
            heading="Liberty Hive Community"
            viewMoreUrl={`/${groupname}/resources`}
            id="resources"
            data={communityArticles}
          />
        </>
      )}
    </DefaultLayout>
  );
}
