import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormFieldUpload,
  FormInput,
  Link,
  Text,
} from '../../../snippets';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  UPDATE_PROFILE,
  GET_INDUSTRIES,
  GET_AUTH_USER,
  GET_SKILLS,
  GET_GENRES,
  GET_QUALIFICATIONS,
  CREATE_RESUME,
  DELETE_RESUME,
  CREATE_SUPPORTING_DOCS,
  DELETE_SUPPORTING_DOCS,
  ATTACH_SKILL,
  ATTACH_GENRE,
  ATTACH_JOB_TYPE,
  ATTACH_INDUSTRY,
  ATTACH_QUALIFICATION,
} from '../../../../graphql';
import { userVar } from '../../../../graphql/config';
import {
  TYPE_CURRENCY,
  TYPE_JOBTYPES,
  TYPE_SALARY_UNIT,
  TYPE_SUBJOBTYPES,
} from '../../../placeholders/consultant/types';
import {
  LoadingSection,
  SectionProfile,
  TablerSection,
} from '../../../components';
import {
  logUserOut,
  getLoginUrl,
  getUser,
  saveUserLocalstorage,
  getLoginType,
} from '../../../../utils/helpers';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import dayjs from 'dayjs';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { LINK_GROUP } from '../../../../utils/links';

export default function ProfileEditPage() {
  const { register, handleSubmit, getValues, clearErrors, errors, setValue } =
    useForm();
  const history = useHistory();
  const multiselectSkillRef = useRef(null);
  const multiselectTopSkillRef = useRef(null);
  const multiselectSoftwareSkillRef = useRef(null);
  const multiselectQualificationRef = useRef(null);
  const multiselectIndustryExperienceRef = useRef(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [topSkills, setTopSkills] = useState([]);
  const [selectedSoftwareSkills, setSelectedSoftwareSkills] = useState([]);
  const [selectedIndustryQualifications, setSelectedIndustryQualifications] =
    useState([]);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [resumes, setResumes] = useState([]);
  const [newResume, setNewResume] = useState(null);
  const [supportingDocs, setSupportingDocs] = useState([]);
  const [newSupportingDoc, setNewSupportingDoc] = useState(null);

  const {
    data: { industries: allIndustries } = {},
    loading: loadingIndustries,
  } = useQuery(GET_INDUSTRIES);

  const [getSkills, { data: skillsData, loading: loadingSkills }] =
    useLazyQuery(GET_SKILLS, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get skills', { toastId: 'closeGetSkillsErr' }),
    });

  const [
    getQualifications,
    { data: qualificationsData, loading: loadingQualifications },
  ] = useLazyQuery(GET_QUALIFICATIONS, {
    variables: {
      industries: [selectedIndustry],
    },
    onError: () =>
      toast.error('Could not get qualifications', {
        toastId: 'closeGetQualificationsErr',
      }),
  });

  const [getGenres, { data: genresData, loading: loadingGenres }] =
    useLazyQuery(GET_GENRES, {
      variables: {
        industries: [selectedIndustry],
      },
      onError: () =>
        toast.error('Could not get genres', { toastId: 'closeGetGenresErr' }),
    });

  const [createResume, { loading: loadingUploadCV }] =
    useMutation(CREATE_RESUME);
  const [removeResume] = useMutation(DELETE_RESUME);
  const [createSupportingDoc, { loading: loadingUploadDoc }] = useMutation(
    CREATE_SUPPORTING_DOCS
  );
  const [removeSupportingDoc] = useMutation(DELETE_SUPPORTING_DOCS);
  const [updateProfile, { loading: loadingUpdate }] =
    useMutation(UPDATE_PROFILE);
  const [updateIndustries] = useMutation(ATTACH_INDUSTRY);
  const [updateSkills] = useMutation(ATTACH_SKILL);
  const [updateGenres] = useMutation(ATTACH_GENRE);
  const [updateJobType] = useMutation(ATTACH_JOB_TYPE);
  const [updateQualifications] = useMutation(ATTACH_QUALIFICATION);
  const [salaryStep, setSalaryStep] = useState(1000);
  const [finishUpload, setFinishUpload] = useState(false);

  const {
    data: { me } = {},
    loading,
    refetch,
  } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl(LINK_GROUP.CANDIDATE);
      }
    },
    onError: () => refetch(),
  });

  const contents = useMemo(() => {
    if (!me) {
      return;
    }
    const {
      bio,
      industries,
      skills,
      genres,
      qualifications,
      jobTypes,
      linkedin,
      resumes,
      supportingDocuments,
      salary_currency,
      minimum_salary,
      maximum_salary,
      salary_unit,
      referee_1,
      referee_2,
    } = me;
    setSelectedIndustry(industries.length > 0 ? industries[0]?.id : null);
    setSelectedSkills(
      skills.filter(({ software }) => !software).map(({ id }) => id)
    );
    setTopSkills(
      skills.filter(({ pivot: { is_top } }) => is_top).map(({ id }) => id)
    );
    setSelectedSoftwareSkills(
      skills.filter(({ software }) => software).map(({ id }) => id)
    );
    setSelectedIndustryQualifications(qualifications.map(({ id }) => id));
    setSelectedIndustryExperience(genres.map(({ id }) => id));
    setResumes(
      [...resumes].sort((a, b) => {
        const dateA = dayjs(a.created_at);
        const dateB = dayjs(b.created_at);
        return dateB - dateA;
      })
    );
    setSupportingDocs(
      [...supportingDocuments].sort((a, b) => {
        const dateA = dayjs(a.created_at);
        const dateB = dayjs(b.created_at);
        return dateB - dateA;
      })
    );
    setValue('bio', bio);

    return {
      bio,
      industries,
      considering: jobTypes.length > 0 ? jobTypes[0].key : [],
      workHour: jobTypes.length > 1 ? jobTypes[1].key : [],
      linkedin,
      salary_currency,
      minimum_salary,
      maximum_salary,
      salary_unit,
      referee_1,
      referee_2,
    };
  }, [
    me,
    setValue,
    setSelectedIndustry,
    setSelectedSkills,
    setTopSkills,
    setSelectedIndustryQualifications,
    setSelectedIndustryExperience,
  ]);

  useEffect(() => {
    if (selectedIndustry) {
      getSkills();
      getGenres();
      getQualifications();
    }
  }, [selectedIndustry, getSkills, getGenres, getQualifications]);

  const handleInterestedChange = (id) => {
    setSelectedIndustry(id);
    setSelectedSkills([]);
    setTopSkills([]);
    setSelectedSoftwareSkills([]);
    setSelectedIndustryExperience([]);
    setSelectedIndustryQualifications([]);
  };

  const handleSkillsChange = (selectedList, _) => {
    const skillIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedSkills(skillIds);
    setTopSkills(topSkills.filter((skillId) => skillIds.includes(skillId)));
  };

  const handleTopSkillsChange = (selectedList, _) => {
    setTopSkills(selectedList.map(({ id, key }) => id ?? key));
  };

  const handleSoftwareSkillsChange = (selectedList, _) =>
    setSelectedSoftwareSkills(selectedList.map(({ id, key }) => id ?? key));

  const handleIndustryQualificationsChange = (selectedList, _) =>
    setSelectedIndustryQualifications(
      selectedList.map(({ id, key }) => id ?? key)
    );

  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));

  const preselectedSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) =>
          !skill.software && selectedSkills.find((id) => id === skill.id)
      ),
    [skillsData, selectedSkills]
  );

  const preselectedTopSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) => !skill.software && topSkills.find((id) => id === skill.id)
      ),
    [skillsData, topSkills]
  );

  const preselectedSoftwareSkills = useCallback(
    () =>
      skillsData?.skills?.filter(
        (skill) =>
          skill.software && selectedSoftwareSkills.find((id) => id === skill.id)
      ),
    [skillsData, selectedSoftwareSkills]
  );

  const preselectedIndustryQualifications = useCallback(
    () =>
      qualificationsData?.qualifications?.filter((qualification) =>
        selectedIndustryQualifications.find((id) => id === qualification.id)
      ),
    [qualificationsData, selectedIndustryQualifications]
  );

  const preselectedIndustryExperience = useCallback(
    () =>
      genresData?.genres?.filter((genre) =>
        selectedIndustryExperience.find((id) => id === genre.id)
      ),
    [genresData, selectedIndustryExperience]
  );

  // Add new CV or Supporting Document
  const addItem = (type) => {
    if (type === 'cv') {
      createResume({
        variables: {
          input: {
            file: newResume,
          },
        },
      })
        .then(({ data }) => {
          setNewResume(null);
          setFinishUpload(true);
          setResumes([{ ...data.createResume }, ...resumes]);
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not add CV');
        });
    } else {
      createSupportingDoc({
        variables: {
          input: {
            name: getValues('supportDocName'),
            file: newSupportingDoc,
          },
        },
      })
        .then(({ data }) => {
          setNewSupportingDoc(null);
          setFinishUpload(true);
          setSupportingDocs([
            { ...data.createSupportingDocument },
            ...supportingDocs,
          ]);
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not add Supporting Document');
        });
    }
  };

  // Remove CV or Supporting Document
  const removeItem = (type, id) => {
    if (type === 'cv') {
      removeResume({
        variables: {
          id,
        },
      })
        .then(() => {
          setResumes(resumes?.filter((r) => r.id !== id));
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not remove CV');
        });
    } else {
      removeSupportingDoc({
        variables: {
          id,
        },
      })
        .then(() => {
          setSupportingDocs(supportingDocs?.filter((r) => r.id !== id));
        })
        .catch(() => {
          toast.dismiss();
          toast.error('Could not remove Supporting Document');
        });
    }
  };

  const editProfile = (data) => {
    if (selectedIndustryExperience.length < 1 || selectedSkills.length < 3) {
      return;
    }

    toast.dismiss();
    const jobTypes = [data.considering, data.workHour];
    delete data.considering;
    delete data.workHour;
    delete data.supportDocName;
    const combinedSkills = [...selectedSkills, ...selectedSoftwareSkills];

    updateIndustries({
      variables: {
        input: {
          sync: [selectedIndustry],
        },
      },
    });
    updateSkills({
      variables: {
        input: {
          sync: combinedSkills,
          isTop: combinedSkills.map((s) => topSkills.includes(s)),
        },
      },
    });
    updateGenres({
      variables: {
        input: {
          sync: selectedIndustryExperience,
        },
      },
    });
    updateJobType({
      variables: {
        input: {
          create: jobTypes,
        },
      },
    });

    if (selectedIndustryQualifications) {
      updateQualifications({
        variables: {
          input: {
            sync: selectedIndustryQualifications,
          },
        },
      });
    }

    updateProfile({
      variables: {
        input: {
          ...data,
          minimum_salary: parseFloat(data?.minimum_salary),
          maximum_salary: parseFloat(data?.maximum_salary),
        },
      },
    })
      .then(({ data }) => {
        const updatedUserObj = { ...getUser(), ...data.updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        history.push(`/${getLoginType()}/profile`);
      })
      .catch(() => {
        toast.dismiss();
        toast.error('Something went wrong!', { toastId: 'resErr' });
      });
  };

  return (
    <DefaultLayout showNavigation showSubmenu>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionProfile
          actionComponent={
            <Text>
              Add your qualifications, client / category experience, skills and
              references. We'll use this to build your profile which will be
              shown to employers.
            </Text>
          }
          headline="Edit Profile"
          hideImage
        >
          {contents && (
            <FormBlock>
              <FormBlockItem label={'About Me'}>
                <FormInput
                  isRequired
                  type="wysiwyg"
                  register={register}
                  name="bio"
                  error={errors.bio ? `Add bio` : null}
                  defaultValue={contents?.bio}
                  maxLength="1000"
                  clearErrors={clearErrors}
                  setValue={(val) => {
                    setValue('bio', val);
                  }}
                  showErrorText
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Interested In'}>
                {loadingIndustries && <span>Loading industries...</span>}
                {allIndustries && (
                  <FormInput
                    type="select"
                    defaultValue={selectedIndustry}
                    options={allIndustries?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    placeholder="Select Professional Discipline"
                    selectSetting={{
                      onSelect: (e) => {
                        handleInterestedChange(e);
                      },
                    }}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Skills'}>
                {loadingSkills && <span>Loading skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    required
                    className={
                      selectedSkills.length < 3 && 'error-state state-error'
                    }
                    options={skillsData?.skills
                      ?.filter(({ software }) => !software)
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      }))}
                    onSelect={handleSkillsChange}
                    onRemove={handleSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Skills"
                    selectedValues={preselectedSkills()}
                    ref={multiselectSkillRef}
                  />
                )}
                {selectedSkills.length < 3 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select at least 3 skills'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Top Skills'}>
                {loadingSkills && <span>Loading skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    options={skillsData?.skills
                      ?.filter(({ software }) => !software)
                      ?.filter(({ id }) => selectedSkills.includes(id))
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      }))}
                    onSelect={handleTopSkillsChange}
                    onRemove={handleTopSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Skills"
                    selectionLimit={3}
                    selectedValues={preselectedTopSkills()}
                    ref={multiselectTopSkillRef}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem
                label={`Software Skills ${
                  selectedIndustry?.includes(2) ? '' : '(optional)'
                }`}
              >
                {loadingSkills && <span>Loading software skills...</span>}
                {skillsData && (
                  <CustomMultiselect
                    className={
                      selectedIndustry?.includes(2) &&
                      selectedSoftwareSkills.length === 0 &&
                      'error-state state-error'
                    }
                    options={skillsData?.skills
                      ?.filter(({ software }) => software)
                      .map(({ id, name }) => ({
                        key: id,
                        name: name,
                      }))}
                    onSelect={handleSoftwareSkillsChange}
                    onRemove={handleSoftwareSkillsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Software Skills"
                    selectedValues={preselectedSoftwareSkills()}
                    ref={multiselectSoftwareSkillRef}
                  />
                )}
                {selectedIndustry?.includes(2) &&
                  selectedSoftwareSkills.length === 0 && (
                    <FormFieldAddOns
                      style={{ marginTop: 0 }}
                      error={'Please select software skills'}
                      showWarnError={true}
                    />
                  )}
              </FormBlockItem>

              <FormBlockItem
                label={`Qualifications ${
                  selectedIndustry?.includes(2) ? '' : '(optional)'
                }`}
              >
                {loadingQualifications && (
                  <span>Loading qualifications...</span>
                )}
                {qualificationsData && (
                  <CustomMultiselect
                    className={
                      selectedIndustry?.includes(2) &&
                      selectedIndustryQualifications.length === 0 &&
                      'error-state state-error'
                    }
                    options={qualificationsData?.qualifications?.map(
                      ({ id, name }) => ({
                        key: id,
                        name: name,
                      })
                    )}
                    onSelect={handleIndustryQualificationsChange}
                    onRemove={handleIndustryQualificationsChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Qualifications"
                    selectedValues={preselectedIndustryQualifications()}
                    ref={multiselectQualificationRef}
                  />
                )}
                {selectedIndustry?.includes(2) &&
                  selectedIndustryQualifications.length === 0 && (
                    <FormFieldAddOns
                      style={{ marginTop: 0 }}
                      error={'Please select qualifications'}
                      showWarnError={true}
                    />
                  )}
              </FormBlockItem>

              <FormBlockItem label={'Category Experience'}>
                {loadingGenres && <span>Loading experience...</span>}
                {genresData && (
                  <CustomMultiselect
                    required
                    className={
                      selectedIndustryExperience.length === 0 &&
                      'error-state state-error'
                    }
                    options={genresData?.genres?.map(({ id, name }) => ({
                      key: id,
                      name: name,
                    }))}
                    onSelect={handleIndustryExperienceChange}
                    onRemove={handleIndustryExperienceChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Experience"
                    selectedValues={preselectedIndustryExperience()}
                    ref={multiselectIndustryExperienceRef}
                  />
                )}
                {selectedIndustryExperience.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select at least 1 category experience'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Considering'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="considering"
                  defaultValue={contents?.considering}
                  options={TYPE_JOBTYPES.map(({ key, name }) => ({
                    value: key,
                    label: name,
                  }))}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Working Hours'}>
                <FormInput
                  isRequired
                  type="select"
                  register={register}
                  name="workHour"
                  defaultValue={contents?.workHour}
                  options={TYPE_SUBJOBTYPES.map(({ key, name }) => ({
                    value: key,
                    label: name,
                  }))}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'LinkedIn Profile'}>
                <FormInput
                  type="text"
                  register={register}
                  name="linkedin"
                  defaultValue={contents?.linkedin}
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Your CV'}>
                {resumes.length > 0 && (
                  <TablerSection
                    className=""
                    tables={resumes.map(({ id, fileUrl, file, created_at }) => [
                      <a href={fileUrl}>{file}</a>,
                      dayjs(created_at, 'YY-MM-DD').format('DD/MM/YYYY'),
                      <Link onClick={() => removeItem('cv', id)}>Delete</Link>,
                    ])}
                    renderItem={(item) => {
                      return <>{item}</>;
                    }}
                    headers={['File', 'Date', '']}
                    tableProportion={['tb-span-6', 'tb-span-6', 'tb-span-4']}
                  ></TablerSection>
                )}
                <FormFieldUpload
                  finished={finishUpload}
                  setFinished={setFinishUpload}
                  setImg={setNewResume}
                  isFile
                  maxFileSize={8388608}
                ></FormFieldUpload>
                {newResume && (
                  <Button
                    style={{ textTransform: 'none' }}
                    type="button"
                    className="mt-4 w-full"
                    size="xxl"
                    disabled={loadingUploadCV}
                    onClick={() => addItem('cv')}
                  >
                    {loadingUploadCV ? 'Loading...' : 'Upload CV'}
                  </Button>
                )}
              </FormBlockItem>

              <FormBlockItem label={'Supporting Documents (optional)'}>
                <p className="mb-3 ml-2 text-sm">
                  Includes qualifications, certifications, portfolio,
                  references, testimonials
                </p>
                {supportingDocs.length > 0 && (
                  <TablerSection
                    className=""
                    tables={supportingDocs.map(
                      ({ id, fileUrl, name, created_at }) => [
                        <a href={fileUrl}>{name}</a>,
                        dayjs(created_at, 'YY-MM-DD').format('DD/MM/YYYY'),
                        <Link onClick={() => removeItem('supporting-doc', id)}>
                          Delete
                        </Link>,
                      ]
                    )}
                    renderItem={(item) => {
                      return <>{item}</>;
                    }}
                    headers={['File', 'Date', '']}
                    tableProportion={['tb-span-6', 'tb-span-6', 'tb-span-4']}
                  ></TablerSection>
                )}
                <FormFieldUpload
                  finished={finishUpload}
                  setFinished={setFinishUpload}
                  setImg={setNewSupportingDoc}
                  isFile
                  maxFileSize={5e6}
                ></FormFieldUpload>
                {newSupportingDoc && (
                  <>
                    <Text
                      className="mt-3 mb-2"
                      style={{
                        color: '#AAAAAA',
                      }}
                    >
                      Supporting Document Name
                    </Text>
                    <FormInput
                      isRequired
                      type="text"
                      register={register}
                      name="supportDocName"
                      clearErrors={clearErrors}
                    ></FormInput>
                    <Button
                      style={{ textTransform: 'none' }}
                      type="button"
                      className="mt-4 w-full"
                      size="xxl"
                      disabled={loadingUploadDoc}
                      onClick={() => addItem('supporting-doc')}
                    >
                      {loadingUploadDoc ? 'Loading...' : 'Upload Document'}
                    </Button>
                  </>
                )}
              </FormBlockItem>
              <hr className="w-full" />
              <p>
                <em>For Liberty Hive purposes only</em>
              </p>
              <FormBlock isInline label={'Salary Bands'}>
                <FormBlockItem>
                  <FormInput
                    type="select"
                    register={register}
                    name="salary_currency"
                    defaultValue={contents?.salary_currency}
                    options={TYPE_CURRENCY}
                  ></FormInput>
                </FormBlockItem>

                <FormBlockItem>
                  <FormInput
                    type="number"
                    register={register}
                    name="minimum_salary"
                    step={salaryStep}
                    defaultValue={contents?.minimum_salary}
                    clearErrors={clearErrors}
                  ></FormInput>
                </FormBlockItem>

                <FormBlockItem>
                  <FormInput
                    type="number"
                    register={register}
                    name="maximum_salary"
                    step={salaryStep}
                    defaultValue={contents?.maximum_salary}
                    clearErrors={clearErrors}
                  ></FormInput>
                </FormBlockItem>
              </FormBlock>

              <FormBlock isInline>
                {TYPE_SALARY_UNIT.map(({ value, label, step }, index) => {
                  return (
                    <FormBlockItem key={index}>
                      <FormInput
                        type="radiobutton"
                        register={register}
                        name="salary_unit"
                        defaultValue={value}
                        defaultChecked={contents?.salary_unit === value}
                        checkboxChildren={label}
                        onChange={() => {
                          setSalaryStep(step);
                        }}
                      ></FormInput>
                    </FormBlockItem>
                  );
                })}
              </FormBlock>

              <FormBlockItem className="mt-4" label={'References'}>
                <Text
                  className="mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Reference 1 — Name, Company and Position
                </Text>
                <FormInput
                  type="text"
                  register={register}
                  name="referee_1"
                  defaultValue={contents?.referee_1}
                  clearErrors={clearErrors}
                ></FormInput>
                <Text
                  className="mt-4 mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Reference 2 — Name, Company and Position
                </Text>
                <FormInput
                  type="text"
                  register={register}
                  name="referee_2"
                  defaultValue={contents?.referee_2}
                  clearErrors={clearErrors}
                ></FormInput>
              </FormBlockItem>
            </FormBlock>
          )}

          <FormCTA spaceT extraSpace>
            <Button
              type="submit"
              className="w-full"
              size="xxl"
              disabled={loading}
              onClick={handleSubmit(editProfile)}
            >
              {loadingUpdate ? 'Loading...' : 'Save'}
            </Button>
          </FormCTA>
        </SectionProfile>
      )}
    </DefaultLayout>
  );
}
