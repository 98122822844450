import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function FormBlock({
  children,
  isInline = false,
  inlineCTA = false,
  className,
  label,
}) {
  return isInline ? (
    <div className={generateClassName(['form-block', className, 'inline', inlineCTA ? 'inline--cta': ''])}>
      {label && (
        <label className="app-label">
          {label}
        </label>
      )}
      <div className="form-field-row">{children}</div>
    </div>
  ) : (
    <div className={['form-block', className].join(' ')}>{children}</div>
  );
}
