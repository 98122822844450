import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function FormBlockItem({
  label,
  children,
  className = '',
  style = {},
  isInline = false,
}) {
  return (
    <div
      className={['form-block--item', className, isInline ? 'inline' : ''].join(
        ' '
      )}
      style={style}
    >
      {label && (
        <label
          className={generateClassName([
            'app-label',
            label === ':empty:' ? 'as-blocker' : '',
          ])}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
