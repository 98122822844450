import React, { useEffect } from 'react';
import { REGISTER } from '../../../../graphql';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { overwrite, getNames } from 'country-list';
import jquery from '../../../../styles/js/app';
import { registerVar, userVar } from '../../../../graphql/config';
import 'react-phone-input-2/lib/plain.css';
import {
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../../../utils/helpers';
import DefaultLayout from '../../../layouts/defaultLayout';
import { useMutation } from '@apollo/react-hooks';
import {
  BackPanel,
  FormBlock,
  FormBlockItem,
  FormInput,
  FormCTA,
  Button,
  Heading,
} from '../../../snippets';
import useGroups from '../../../hooks/useGroups';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import { LINK_GROUP } from '../../../../utils/links';

const CreateAccount = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);
  const { groupname } = useGroups();
  const tempData = registerVar();

  overwrite([
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
  ]);
  const countries = getNames().sort();
  const { register, handleSubmit, errors, watch, clearErrors } = useForm();
  const [registerMutation, { loading }] = useMutation(REGISTER);

  if (userVar().access_token) {
    return (
      <Redirect
        to={{
          pathname: `/${LINK_GROUP.AGENCY}/dashboard`,
          state: { referrer: `/${LINK_GROUP.AGENCY}/register` },
        }}
      />
    );
  }

  function RegisterUser(data) {
    delete data.confirmPassword;
    registerMutation({
      variables: {
        input: {
          ...data,
          ...tempData,
          type: groupname.toUpperCase(),
          phone: "''",
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (!data.register) {
          toast.error('Something is wrong!', {
            autoClose: 3000,
          });
        } else {
          registerVar({});
          const cookies = new Cookies();
          cookies.set('lh_user', data.register.id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          userVar(data.register);
          saveUserLocalstorage(data.register);
          saveLoginTypeLocalstorage(groupname.toUpperCase());
          return history.push(`/${groupname}/register/complete-profile`);
        }
      })
      .catch((e) => {
        let msg = 'Something went wrong!';
        console.error(e);
        if (
          'validation' in e?.graphQLErrors[0]?.extensions &&
          'input.email' in e?.graphQLErrors[0]?.extensions.validation
        ) {
          msg = 'The email has already been taken!';
        }
        toast.error(msg, { toastId: 'frRegErr', autoClose: 3000 });
      });
  }

  return (
    <DefaultLayout hideSwitchCompany>
      <BackPanel isSmall>
        <Heading>Create An Agency Account</Heading>
        <form
          className="form-v2 form-agency"
          onSubmit={handleSubmit(RegisterUser)}
        >
          <FormBlock className={'spacer-t'}>
            <FormBlockItem label={'First Name'}>
              <FormInput
                name="first_name"
                type="text"
                isRequired
                register={register}
                defaultValue={tempData.first_name || ''}
                error={errors.first_name ? 'Please enter first name' : null}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Last Name'}>
              <FormInput
                name="last_name"
                type="text"
                isRequired
                register={register}
                error={errors.last_name ? 'Please enter last name' : null}
                defaultValue={tempData.last_name || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Email Address'}>
              <FormInput
                name="email"
                type="email"
                isRequired
                register={register}
                error={
                  errors.email
                    ? errors.email.type === 'pattern'
                      ? 'Please enter a valid email address'
                      : 'Please enter an email'
                    : null
                }
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Company'}>
              <FormInput
                name="company"
                type="text"
                isRequired
                register={register}
                error={errors.company ? 'Please enter company name' : null}
                defaultValue={tempData.company || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Position'}>
              <FormInput
                name="position"
                type="text"
                isRequired
                register={register}
                error={errors.position ? 'Please enter position' : null}
                defaultValue={tempData.position || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Address Line 1'}>
              <FormInput
                name="address_1"
                type="text"
                isRequired
                register={register}
                error={errors.address_1 ? 'Please enter a valid address' : null}
                defaultValue={tempData.address_1 || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Address Line 2'}>
              <FormInput
                name="address_2"
                type="text"
                register={register}
                defaultValue={tempData.address_2 || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'City'}>
              <FormInput
                name="city"
                type="text"
                isRequired
                register={register}
                error={errors.city ? 'Please enter city' : null}
                defaultValue={tempData.city || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'County'}>
              <FormInput
                name="state"
                type="text"
                isRequired
                register={register}
                error={errors.state ? 'Please enter county' : null}
                defaultValue={tempData.state || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Postcode / Zip Code'}>
              <FormInput
                name="zip"
                type="text"
                isRequired
                register={register}
                error={errors.zip ? 'Please enter postcode / zip code' : null}
                defaultValue={tempData.zip || ''}
                clearErrors={clearErrors}
              />
            </FormBlockItem>
            <FormBlockItem label={'Country'}>
              <FormInput
                type="select"
                register={register}
                name="country"
                defaultValue={tempData.country ?? 'United Kingdom'}
                isRequired
                error={errors.country ? `Please select a country` : null}
                options={countries.map((country) => ({
                  value: country,
                }))}
              ></FormInput>
            </FormBlockItem>
            <FormBlockItem label={'Password'}>
              <FormInput
                register={register}
                type="password"
                name="password"
                isRequired
                error={errors.password ? 'Please enter a password' : null}
                clearErrors={clearErrors}
                watch={watch}
              />
            </FormBlockItem>
            <FormBlockItem label={'Confirm Password'}>
              <FormInput
                register={register}
                type="password"
                name="confirmPassword"
                isRequired
                error={
                  errors.confirmPassword ? 'Your passwords do no match' : null
                }
                clearErrors={clearErrors}
                watch={watch}
                settings={{
                  required: true,
                  validate: (val) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                }}
              />
            </FormBlockItem>
          </FormBlock>
          <FormCTA className={'space-t'}>
            <Button
              className="app-btn-submit"
              type="submit"
              disabled={loading}
              size="xl"
            >
              {loading ? 'Loading...' : 'Next'}
            </Button>
          </FormCTA>

          {/* <div className="onboarding__two-cols">
            <div className="form-v2__input-block onboarding__two-cols__inner">
              <div
                className={`form-v2__label ${
                  errors.first_name && 'form-v2__label-error'
                }`}
              >
                First Name
              </div>
              <div className="form-v2__input-wrapper">
                <input
                  className={`form-v2__input ${
                    errors.first_name && 'form-v2__error'
                  }`}
                  type="text"
                  autoComplete="false"
                  name="first_name"
                  defaultValue={tempData.first_name || ''}
                  ref={register({ required: true })}
                  placeholder={
                    errors.first_name && 'Please enter your first name'
                  }
                />
              </div>
            </div>

            <div className="form-v2__input-block onboarding__two-cols__inner">
              <div
                className={`form-v2__label ${
                  errors.last_name && 'form-v2__label-error'
                }`}
              >
                Last Name
              </div>
              <div className="form-v2__input-wrapper">
                <input
                  className={`form-v2__input ${
                    errors.last_name && 'form-v2__error'
                  }`}
                  type="text"
                  autoComplete="false"
                  name="last_name"
                  defaultValue={registerVar().last_name || ''}
                  placeholder={
                    errors.last_name && 'Please enter your last name'
                  }
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.email && 'form-v2__label-error'
              }`}
            >
              Email
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${errors.email && 'form-v2__error'}`}
                type="text"
                name="email"
                defaultValue={registerVar().email || ''}
                placeholder={
                  errors.email && 'Please enter a valid email address'
                }
                ref={register({
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                autoComplete="false"
              />
            </div>
          </div>

          <div style={{ clear: 'both' }} />
          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.company && 'form-v2__label-error'
              }`}
            >
              Company Name
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${
                  errors.company && 'form-v2__error'
                }`}
                placeholder={errors.company && 'Please enter your company name'}
                type="text"
                autoComplete="false"
                name="company"
                ref={register({ required: true })}
              />
            </div>
          </div>

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.position && 'form-v2__label-error'
              }`}
            >
              Position
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${
                  errors.position && 'form-v2__error'
                }`}
                placeholder={errors.position && 'Please enter a position'}
                type="text"
                autoComplete="false"
                name="position"
                ref={register({ required: true })}
              />
            </div>
          </div>

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.address_1 && 'form-v2__label-error'
              }`}
            >
              Address Line 1
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${
                  errors.address_1 && 'form-v2__error'
                }`}
                placeholder={errors.address_1 && 'Please enter a valid address'}
                type="text"
                autoComplete="false"
                name="address_1"
                ref={register({ required: true })}
              />
            </div>
          </div>

          <div className="form-v2__input-block">
            <div className="form-v2__label">Address Line 2</div>
            <div className="form-v2__input-wrapper">
              <input
                className="form-v2__input"
                type="text"
                autoComplete="false"
                name="address_2"
                ref={register({ required: false })}
              />
            </div>
          </div>
          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.city && 'form-v2__label-error'
              }`}
            >
              City
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${errors.city && 'form-v2__error'}`}
                placeholder={errors.city && 'Please enter city'}
                type="text"
                autoComplete="false"
                name="city"
                ref={register({ required: true })}
              />
            </div>
          </div>

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.state && 'form-v2__label-error'
              }`}
            >
              County
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${errors.state && 'form-v2__error'}`}
                placeholder={errors.state && 'Please enter county'}
                type="text"
                autoComplete="false"
                name="state"
                ref={register({ required: true })}
              />
            </div>
          </div>

          <div className="onboarding__two-cols">
            <div className="form-v2__input-block onboarding__two-cols__inner">
              <div
                className={`form-v2__label ${
                  errors.zip && 'form-v2__label-error'
                }`}
              >
                Postcode / Zip Code
              </div>
              <div className="form-v2__input-wrapper">
                <input
                  className={`form-v2__input ${errors.zip && 'form-v2__error'}`}
                  placeholder={errors.zip && 'Please enter a valid postcode / zip code'}
                  type="text"
                  autoComplete="false"
                  name="zip"
                  ref={register({ required: true })}
                />
              </div>
            </div>

            <div className="form-v2__input-block onboarding__two-cols__inner">
              <div
                className={`form-v2__label ${
                  errors.country && 'form-v2__label-error'
                }`}
              >
                Country
              </div>
              <div className="form-v2__input-wrapper">
                <select
                  className={`form-v2__input form-v2__country ${
                    errors.country && 'form-v2__error'
                  }`}
                  placeholder={errors.country && 'Please select a country'}
                  name="country"
                  list="countries"
                  id="country"
                  defaultValue="United Kingdom"
                  ref={register({ required: true })}
                >
                  <option value="" />
                  {countries.map((country) => (
                    <option
                      value={
                        country ===
                        'United Kingdom of Great Britain and Northern Ireland'
                          ? 'United Kingdom'
                          : country
                      }
                      key={
                        country ===
                        'United Kingdom of Great Britain and Northern Ireland'
                          ? 'United Kingdom'
                          : country
                      }
                    >
                      {country ===
                      'United Kingdom of Great Britain and Northern Ireland'
                        ? 'United Kingdom'
                        : country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div style={{ clear: 'both' }} />

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.password && 'form-v2__label-error'
              }`}
            >
              Password
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${
                  errors.password && 'form-v2__error'
                }`}
                placeholder={
                  errors.password && 'Password must be at least 8 characters'
                }
                type="password"
                id="onboarding-password"
                name="password"
                defaultValue={registerVar().password || ''}
                ref={register({ required: true, minLength: 8 })}
                autoComplete="false"
              />
              <div
                toggle="#onboarding-password"
                className="fas fa-eye form__toggle-password form__hidden"
              />
            </div>
          </div>

          <div className="form-v2__input-block">
            <div
              className={`form-v2__label ${
                errors.confirmPassword && 'form-v2__label-error'
              }`}
            >
              Confirm Password
            </div>
            <div className="form-v2__input-wrapper">
              <input
                className={`form-v2__input ${
                  errors.confirmPassword && 'form-v2__error'
                }`}
                placeholder={
                  errors.confirmPassword && 'Your passwords do no match!'
                }
                type="password"
                id="onboarding-cpassword"
                name="confirmPassword"
                defaultValue={registerVar().confirmPassword || ''}
                ref={register({
                  required: true,
                  validate: (val) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
                autoComplete="false"
              />
              <div
                toggle="#onboarding-password"
                className="fas fa-eye form__toggle-password form__hidden"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={!!loading}
            className="form-v2__btn form-v2__btn-agency-v2 d-block bg__gradient-1"
          >
            {loading ? 'Loading...' : 'Next'}
          </button>*/}
        </form>
      </BackPanel>
    </DefaultLayout>
  );
};

export default CreateAccount;
