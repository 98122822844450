import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getUser } from '../../utils/helpers';
import { AppContext } from '../../Store';
import Profile from './Profile';
import { LINK_GROUP } from '../../utils/links';

const Header = ({ isLoggedIn }) => {
  const [state] = useContext(AppContext);
  const [userTeams, setUserTeams] = useState(state?.userTeams);

  useEffect(() => {
    if (state?.userTeams !== undefined) {
      setUserTeams(state?.userTeams);
    }
  }, [state]);

  if (getUser().access_token) isLoggedIn = true;
  const location = useLocation();

  return (
    <>
      <div className="mobile-menu companny">
        <nav className="mobile-menu__inner">
          <ul className="mobile-menu__list">
            {getUser().status === 'ACTIVE' &&
              state?.activeCompany?.can_post_jobs && (
                <li className="mobile-menu__list__item-large">
                  <Link
                    to={`/${LINK_GROUP.COMPANY}/add-opportunity`}
                    className="button__highlight-white"
                  >
                    New Job Opportunity
                  </Link>
                </li>
              )}

            {isLoggedIn ? (
              <>
                <li className="mobile-menu__list__item-large">
                  {location.pathname.includes('dashboard') ? (
                    <a href={`/${LINK_GROUP.COMPANY}/dashboard`} className="">
                      Dashboard
                    </a>
                  ) : (
                    <Link to={`/${LINK_GROUP.COMPANY}/dashboard`} className="">
                      Dashboard
                    </Link>
                  )}
                </li>
                <li className="mobile-menu__list__item-large">
                  {location.pathname.includes('account') ? (
                    <a href={`/${LINK_GROUP.COMPANY}/account`} className="">
                      My Account
                    </a>
                  ) : (
                    <Link to={`/${LINK_GROUP.COMPANY}/account`} className="">
                      My Account
                    </Link>
                  )}
                </li>
              </>
            ) : (
              <>
                <li className="mobile-menu__list__item-large">
                  {location.pathname.includes('login') ? (
                    <Link to={`/${LINK_GROUP.COMPANY}/register`} className="">
                      Sign Up
                    </Link>
                  ) : (
                    <Link to={`/${LINK_GROUP.COMPANY}/login`} className="">
                      Login
                    </Link>
                  )}
                </li>
              </>
            )}
          </ul>
          <ul className="mobile-menu__list">
            {isLoggedIn && (
              <li className="mobile-menu__list__item-small">
                <Link to={`/${LINK_GROUP.COMPANY}/logout`} className="">
                  Sign Out
                </Link>
              </li>
            )}
          </ul>
          <div className="mobile-menu__inner__social">
            {/* <a href='/#' target='_self' className='footer__links__img'>
              <img
                src='/img/liberty-hive-logo-icon-white-small@2x.png'
                className='img img__social'
                alt=''
              />
            </a> */}
            <a
              href="https://www.instagram.com/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
              alt=""
            >
              <img
                src="/img/social-icon-instagram@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/libertyhive/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links__img"
            >
              <img
                src="/img/social-icon-linkedin@2x.png"
                className="img img__social"
                alt=""
              />
            </a>
          </div>
        </nav>
      </div>
      <div className="header-company">
        <div className="header-agency__inner container">
          <div className="row">
            <div className="header-agency__inner__logo col-5">
              <Link to={`/${LINK_GROUP.COMPANY}/login`}>
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__mobile"
                  alt=""
                />
              </Link>
              <Link to={`/${LINK_GROUP.COMPANY}/login`}>
                <img
                  src="/img/lh-header-logo.png"
                  className="img__logo__desktop"
                  alt=""
                />
              </Link>
            </div>
            <nav className="header-agency__inner__menu col-7 desktop">
              <ul className="header__list">
                {isLoggedIn ? (
                  <>
                    {getUser().status === 'ACTIVE' &&
                      state?.activeCompany?.can_post_jobs && (
                        <li className="header__list__item">
                          <Link
                            to={`/${LINK_GROUP.COMPANY}/add-opportunity`}
                            className="button__highlight-white"
                          >
                            New Job Opportunity
                          </Link>
                        </li>
                      )}
                    <li className="header__list__item">
                      {location.pathname.includes('dashboard') ? (
                        <a
                          href={`/${LINK_GROUP.COMPANY}/dashboard`}
                          className="header__link"
                        >
                          Dashboard
                        </a>
                      ) : (
                        <Link
                          to={`/${LINK_GROUP.COMPANY}/dashboard`}
                          className="header__link"
                        >
                          Dashboard
                        </Link>
                      )}
                    </li>

                    <li className="header__list__item">
                      {location.pathname.includes('account') ? (
                        <a
                          href={`/${LINK_GROUP.COMPANY}/account`}
                          className="header__link"
                        >
                          My Account
                        </a>
                      ) : (
                        <Link
                          to={`/${LINK_GROUP.COMPANY}/account`}
                          className="header__link"
                        >
                          My Account
                        </Link>
                      )}
                    </li>

                    {userTeams !== undefined && userTeams.length > 1 && (
                      <Profile teams={userTeams} />
                    )}
                  </>
                ) : (
                  <>
                    <li className="header__list__item">
                      <Link
                        to="/add-opportunity"
                        className="button__highlight-white"
                      >
                        Add Opportunity
                      </Link>
                    </li>
                    <li className="header__list__item">
                      {location.pathname.includes('login') ? (
                        <Link
                          to={`/${LINK_GROUP.COMPANY}/register`}
                          className="header__link"
                        >
                          Sign Up
                        </Link>
                      ) : (
                        <Link
                          to={`/${LINK_GROUP.COMPANY}/login`}
                          className="header__link"
                        >
                          Login
                        </Link>
                      )}
                    </li>
                  </>
                )}
              </ul>
            </nav>
            <div className="header-agency__inner__menu col-7 mobile-tablet">
              <div id="hamburger">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
