import React from 'react';
import { IconEnvelope } from '../../components/Icons';
import { AgencyHeader } from '../../components/Agency';
import Footer from '../../components/Footer';

export default function VerifyEmail() {
  return (
    <div className="lh-wrapper">
      <AgencyHeader />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <div className="card__block-with-icon">
                <div className="item--icon-block">
                  <IconEnvelope />
                </div>
                <div className="item--headline">Verify Your Email Address</div>
                <div className="item--description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas tristique condimentum ante, at tincidunt ante aliquam
                  eget.
                </div>
                <div>
                  <button
                    type="button"
                    className="form__btn btn form__btn-agency-v2 d-block mb-50 mt-0"
                  >
                    {'RESEND VERIFICATION EMAIL'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
