import React, { useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  BackPanel,
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormFieldAddOns,
  FormInput,
  Heading,
  Text,
} from '../../../snippets';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_SERVICES,
  GET_GENRES,
  GET_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
} from '../../../../graphql';
import { TYPE_CURRENCY } from '../../../placeholders/consultant/types';
import { LoadingSection, SectionForm, Switch } from '../../../components';
import { toast } from 'react-toastify';
import { SearchBox } from '@mapbox/search-js-react';
import CustomMultiselect from '../../../components/CustomMultiselect';
import { LINK_GROUP } from '../../../../utils/links';
import moment from 'moment';

export default function ProjectEditPage() {
  const { id } = useParams();
  const history = useHistory();
  const { register, handleSubmit, errors, watch, clearErrors } = useForm();
  const multiselectIndustryExperienceRef = useRef(null);
  const multiselectServiceRef = useRef(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [postDate, setPostDate] = useState(new Date());
  const [isUnpublished, setIsUnpublished] = useState(true);
  const [description, setDescription] = useState(null);
  const [jobLocation, setJobLocation] = useState(null);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    onCompleted: () => {
      const { description, location, services, genres, is_active, job_number } =
        opportunity;

      setPostDate(moment(job_number, 'DD/MM/YYYY').toDate());
      setDescription(description);
      setJobLocation(location);
      setIsUnpublished(!is_active);
      setSelectedServices(services.map(({ id }) => id));
      setSelectedIndustryExperience(genres.map(({ id }) => id));
    },
    onError: () => refetch(),
  });

  const { data: servicesData, loading: loadingServices } = useQuery(
    GET_SERVICES,
    {
      variables: {},
      onError: () =>
        toast.error('Could not get services', {
          toastId: 'closeGetServicesErr',
        }),
    }
  );

  const { data: genresData, loading: loadingGenres } = useQuery(GET_GENRES, {
    variables: {},
    onError: () =>
      toast.error('Could not get genres', { toastId: 'closeGetGenresErr' }),
  });

  const [updateOpportunity, { loading: loadingSubmit }] = useMutation(
    UPDATE_OPPORTUNITY,
    {
      onCompleted: () => {
        toast.success('Opportunity updated successfully');
        history.push(`/${LINK_GROUP.EMPLOYER}/dashboard`);
      },
      onError: (err) => {
        console.error(err);
        toast.dismiss();
        toast.error('Something went wrong!', { toastId: 'resErr' });
      },
    }
  );

  const handleServicesChange = (selectedList, _) => {
    const serviceIds = selectedList.map(({ id, key }) => id ?? key);
    setSelectedServices(serviceIds);
  };

  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));

  const preselectedServices = useCallback(
    () =>
      servicesData?.services?.filter((service) =>
        selectedServices.find((id) => id === service.id)
      ),
    [servicesData, selectedServices]
  );

  const preselectedIndustryExperience = useCallback(
    () =>
      genresData?.genres?.filter((genre) =>
        selectedIndustryExperience.find((id) => id === genre.id)
      ),
    [genresData, selectedIndustryExperience]
  );

  const submitUpdate = (data) => {
    toast.dismiss();

    if (
      description?.length === 0 ||
      selectedIndustryExperience?.length === 0 ||
      selectedServices?.length === 0
    ) {
      return;
    }

    const _data = {
      ...data,
      description: description,
      location: jobLocation,
      is_active: !isUnpublished,
      minimumBudget: parseFloat(data.minimumBudget),
      maximumBudget: parseFloat(data.maximumBudget),
      duration: parseInt(data.duration),
      services: {
        sync: selectedServices,
      },
      genres: {
        sync: selectedIndustryExperience,
      },
    };

    updateOpportunity({
      variables: {
        input: _data,
        id,
      },
    });
  };

  const watchFields = watch(['follow_up']);

  return (
    <DefaultLayout>
      {loading && <LoadingSection />}
      {!opportunity && !loading && (
        <BackPanel>
          <Heading>No project data available!</Heading>
        </BackPanel>
      )}
      {!loading && !!opportunity && (
        <SectionForm
          actionComponent={
            <Text>
              Fill out the details of the project which will be shown to our
              network of agencies.
            </Text>
          }
          headline="Edit Agency Project"
        >
          <form onSubmit={handleSubmit(submitUpdate)}>
            <FormBlock>
              <FormBlockItem className="mt-0" label={'Status'}>
                <div
                  className=" d-flex"
                  style={{ alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Text
                    as="span"
                    className={!isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(false)}
                    style={{
                      cursor: 'pointer',
                      marginRight: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Published
                  </Text>
                  <Switch
                    onChange={(e) => {
                      setIsUnpublished(!isUnpublished);
                    }}
                    sameActive
                    isChecked={isUnpublished}
                  />
                  <Text
                    as="span"
                    className={isUnpublished ? 'active text--mesh' : ''}
                    onClick={() => setIsUnpublished(true)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                      fontWeight: 400,
                    }}
                  >
                    Unpublished
                  </Text>
                </div>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Project Title'}>
                <FormInput
                  isRequired
                  type="text"
                  register={register}
                  name="title"
                  defaultValue={opportunity?.title}
                  error={errors.title ? 'Please enter title' : ''}
                  showErrorText
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Date Posted'}>
                <DatePicker
                  onChange={setPostDate}
                  value={postDate}
                  className="form__input date"
                  format="dd/M/y"
                  name="job_number"
                  required={false}
                  clearIcon={null}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
              </FormBlockItem>

              <FormBlockItem label={'Category Experience'}>
                {loadingGenres && <span>Loading experience...</span>}
                {genresData && (
                  <CustomMultiselect
                    options={
                      genresData?.genres?.map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                    }
                    onSelect={handleIndustryExperienceChange}
                    onRemove={handleIndustryExperienceChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Category Experience"
                    selectedValues={preselectedIndustryExperience()}
                    ref={multiselectIndustryExperienceRef}
                  />
                )}
                {selectedIndustryExperience.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select category experience'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Project Location'}>
                <SearchBox
                  accessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                  placeholder="Please enter location"
                  options={{
                    types: ['place', 'district', 'region'],
                  }}
                  onSuggestError={() => {
                    toast.dismiss();
                    toast.error('Failed to fetch address!', {
                      toastId: 'resErr',
                    });
                  }}
                  onRetrieve={(value) => {
                    const place =
                      value?.features[0]?.properties?.full_address ??
                      value?.features[0]?.properties?.place_formatted;
                    setJobLocation(place);
                  }}
                  theme={{
                    cssText: 'height: 43px;',
                    icons: {
                      addressMarker: '',
                      close: '',
                      search: '',
                    },
                    variables: {
                      border: 'none',
                      borderRadius: '9px',
                      boxShadow: 'none',
                      color: '#0e0e0e',
                      fontFamily: 'Jost, Arial, Helvetica, sans-serif',
                      fontWeight: 500,
                      // lineHeight: 2.6,
                      // padding: '5px 21px',
                      unit: 16,
                    },
                  }}
                  value={jobLocation}
                />
              </FormBlockItem>

              <FormBlockItem label={'Project Description'}>
                <FormInput
                  isRequired
                  type="wysiwyg"
                  name="description"
                  defaultValue={description}
                  error={
                    description?.length === 0
                      ? 'Please enter a description'
                      : null
                  }
                  showErrorText
                  maxLength={0}
                  clearErrors={clearErrors}
                  setValue={setDescription}
                  fieldClassName={`${
                    description?.length === 0 && 'error-state state-error'
                  }`}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Project Duration'}>
                <FormInput
                  type="select"
                  register={register}
                  name="duration"
                  options={[
                    {
                      value: 1,
                      label: '1-3 months',
                    },
                    {
                      value: 4,
                      label: '4-6 months',
                    },
                    {
                      value: 99,
                      label: 'Ongoing',
                    },
                  ]}
                  placeholder="Select duration"
                  defaultValue={opportunity?.duration}
                ></FormInput>
              </FormBlockItem>

              <FormBlockItem label={'Services Required'}>
                {loadingServices && <span>Loading services...</span>}
                {servicesData && (
                  <CustomMultiselect
                    options={
                      servicesData?.services.map(({ id, name }) => ({
                        key: id,
                        name: name,
                      })) ?? []
                    }
                    onSelect={handleServicesChange}
                    onRemove={handleServicesChange}
                    displayValue="name"
                    closeOnSelect={true}
                    avoidHighlightFirstOption
                    placeholder="Services"
                    selectedValues={preselectedServices()}
                    ref={multiselectServiceRef}
                  />
                )}
                {selectedServices.length === 0 && (
                  <FormFieldAddOns
                    style={{ marginTop: 0 }}
                    error={'Please select required services'}
                    showWarnError={true}
                  />
                )}
              </FormBlockItem>

              <FormBlockItem label={'Budget'}>
                <FormInput
                  type="checkbox"
                  register={register}
                  name="is_compensation_displayed"
                  checkboxChildren={<p>Show Compensation</p>}
                  defaultChecked={opportunity?.is_compensation_displayed}
                ></FormInput>

                <FormBlock isInline>
                  <FormBlockItem>
                    <FormInput
                      isRequired
                      type="select"
                      register={register}
                      name="compensation_currency"
                      defaultValue={
                        opportunity?.compensation_currency ??
                        TYPE_CURRENCY[0].value
                      }
                      options={TYPE_CURRENCY}
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      type="number"
                      register={register}
                      name="minimumBudget"
                      step={50}
                      defaultValue={opportunity?.minimumBudget}
                      error={
                        errors.minimumBudget
                          ? 'Please enter minimum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>

                  <FormBlockItem>
                    <FormInput
                      type="number"
                      register={register}
                      name="maximumBudget"
                      step={1000}
                      defaultValue={opportunity?.maximumBudget}
                      error={
                        errors.maximumBudget
                          ? 'Please enter maximum budget'
                          : ''
                      }
                      showErrorText
                    ></FormInput>
                  </FormBlockItem>
                </FormBlock>
              </FormBlockItem>

              <FormBlockItem className="mt-4" label={'Follow Up Message'}>
                <Text
                  className="mb-2"
                  style={{
                    color: '#AAAAAA',
                  }}
                >
                  Add a message to your applicants to let them know you have
                  received their application and/or add more information about
                  the recruitment process.
                </Text>
                <FormInput
                  isRequired
                  type="textarea"
                  register={register}
                  name="follow_up"
                  defaultValue={opportunity?.follow_up}
                  helpChildren={
                    <>
                      <span>
                        {'follow_up' in watchFields
                          ? watchFields.follow_up
                            ? watchFields.follow_up.length
                            : 0
                          : 0}
                      </span>{' '}
                      Characters
                    </>
                  }
                  error={
                    errors.follow_up ? 'Please enter a follow up message' : null
                  }
                ></FormInput>
              </FormBlockItem>

              {/* <FormBlockItem label={'Team User'}>
              <FormInput
                type="select"
                register={register}
                name=""
                options={
                  teamList?.map(({ user_id, team }) => ({
                    value: user_id,
                    label: `${team?.first_name} ${team?.last_name}`,
                  })) ?? []
                }
                placeholder="Select Team"
                defaultValue={opportunity?.posting_user}
              ></FormInput>
            </FormBlockItem> */}
            </FormBlock>

            <FormCTA spaceT extraSpace>
              <Button
                type="submit"
                className="w-full"
                size="xxl"
                disabled={loadingSubmit}
              >
                {loadingSubmit ? 'Loading...' : 'Save'}
              </Button>
            </FormCTA>
          </form>
        </SectionForm>
      )}
    </DefaultLayout>
  );
}
