import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CHECK_USER,
  GET_ALL_JOB_LEVELS,
  UPDATE_PROFILE,
  GET_INDUSTRIES,
} from '../../graphql';
import useAuth from './useAuth';
import { useCallback, useState } from 'react';
import useGroups from './useGroups';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser, saveUserLocalstorage } from '../../utils/helpers';
import { userVar } from '../../graphql/config';

export default function useProfile({ actionImageMandatory = false } = {}) {
  const history = useHistory();
  const { groupname } = useGroups();
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [
      CHECK_USER, // DocumentNode object parsed with gql
      'checkUser', // Query name
    ],
  });
  const { authMe: myData } = useAuth();

  const { data: { allJoblevels } = {}, loading: loadingRoles } =
    useQuery(GET_ALL_JOB_LEVELS);

  const { data: { industries = [] } = {}, loading: loadingIndustries } =
    useQuery(GET_INDUSTRIES);

  const [dataImg, setDataImg] = useState({
    filepath: null,
    error: false,
  });

  const setDataImageFile = useCallback((filepath) => {
    setDataImg((_prev) => {
      return {
        ..._prev,
        filepath,
        error: false,
      };
    });
  }, []);

  const setDataImageErrorStatus = useCallback((status = true) => {
    setDataImg((_prev) => {
      return {
        ..._prev,
        error: status,
      };
    });
  }, []);

  const checkProfileCompleted = useCallback(() => {
    if (myData) {
      if (myData.bio) {
        return history.push(`/${groupname}/dashboard`);
      }
    }
  }, [myData, groupname, history]);

  const editProfile = (data) => {
    toast.dismiss();
    if (dataImg.error) {
      console.error('X113:', dataImg.error);
      return toast.error('Please select a valid image file.');
    }
    if (actionImageMandatory && !dataImg.filepath) {
      setDataImageErrorStatus(true);
      return toast.error('Please select an image file.');
    }
    setDataImageErrorStatus(false);
    return updateProfile({
      variables: {
        input: {
          ...data,
          avatar: dataImg.filepath,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Profile completed!', { autoClose: 4000 });
        return history.push(`/${groupname}/dashboard`);
        // return history.push('/agency/subscription', {
        //   from: '/agency/complete-profile',
        // });
      })
      .catch((e) =>
        toast.error('Something went wrong!', { toastId: 'resErr' })
      );
  };

  return {
    checkProfileCompleted,
    updateProfile,
    loadingProfile: loading,
    myProfile: myData,
    editProfile,
    setDataImageFile,
    setDataImageErrorStatus,
    dataImg,
    allJoblevels,
    loadingRoles,
    industries,
    loadingIndustries,
  };
}
