const freelancer = [
  {
    label: 'Personal Information',
    link: 'personal-information',
  },
  {
    label: 'Edit Personal Information',
    link: 'edit-personal-information',
  },
  {
    label: 'View Profile',
    link: 'profile',
  },
  {
    label: 'Edit Profile',
    link: 'edit-profile',
  },
  {
    label: 'Notification Settings',
    link: 'notification-settings',
  },
  {
    label: 'Profile Privacy',
    link: 'profile-privacy',
  },
  {
    label: 'Change Password',
    link: 'change-password',
  },
  {
    label: 'Log Out',
    link: 'logout',
  },
];
const company = [
  {
    label: 'My Account',
    link: 'account',
    admin: false,
  },
  {
    label: 'View Profile',
    link: 'profile',
    admin: false,
  },
  {
    label: 'Edit Profile',
    link: 'edit-profile',
    admin: true,
  },
  {
    label: 'Manage Payment Info',
    link: 'manage-payment-info',
    admin: true,
  },
  {
    label: 'Purchase Job Posts',
    link: 'manage-credits',
    admin: true,
  },
  {
    label: 'Manage Team',
    link: 'manage-team',
    admin: true,
  },
  {
    label: 'Invitations',
    link: 'invitations',
    admin: true,
  },
  {
    label: 'Billing History',
    link: 'billing-history',
    admin: true,
  },
  {
    label: 'Change Password',
    link: 'change-password',
    admin: false,
  },
  {
    label: 'Log Out',
    link: 'logout',
    admin: false,
  },
];
const agency = [
  {
    link: 'account-information',
    label: 'Account Information',
    admin: true,
  },
  {
    link: 'edit-account-information',
    label: 'Edit Account Information',
    admin: true,
  },
  {
    link: 'profile',
    label: 'View Profile',
    admin: false,
  },
  {
    link: 'edit-profile',
    label: 'Edit Profile',
    admin: true,
  },
  {
    link: 'manage-team',
    label: 'Manage Team',
    admin: true,
  },
  {
    link: 'notification-settings',
    label: 'Notification Settings',
    admin: false,
  },
  {
    link: 'profile-privacy',
    label: 'Profile Privacy',
    admin: false,
  },
  {
    link: 'change-password',
    label: ' Change Password',
    admin: false,
  },
  {
    link: 'logout',
    label: 'Log Out',
    admin: false,
  },
];

export default {
  menu: {
    freelancer,
    candidate: freelancer,
    company,
    employer: company,
    agency,
  },
};
