import { LINK_GROUP } from '../utils/links';
import {
  LoginPage,
  DashboardCompanyPage,
  LogoutPage,
  CompanyProfilePage,
  CompanyEditProfilePage,
  RegisterPage,
  AccountInformationCompanyPage,
  ForgotPasswordPage,
  CompanyCompleteProfilePage,
  CompanyViewConsultantProfilePage,
  CompanyViewAgencyProfilePage,
  CreateOpportunityPage,
  EditOpportunityPage,
  CaseStudyDetailsPage,
  ResourcesPage,
  AgencyNetworkCompanyPage,
  CompanyBillingHistoryPage,
  CompanyManageTeamsPage,
  CompanyTeamRegisterPage,
  CompanyTeamRegisterProfilePage,
  CompanyTeamRegisterCompletePage,
  CompanyTeamWelcomePage,
  NotFoundPage,
  CreateProjectPage,
  EditProjectPage,
  ResetPasswordPage,
} from '../v2/pages';
import LandingPage from '../views/Company/LandingPage';
import CompleteProfile from '../views/Company/CompleteProfile';
import Dashboard from '../views/Company/Dashboard';
import CreateTeamAccount from '../views/Team/CreateAccount';
import CreateTeamAccountStep2 from '../views/Team/CreateAccountStep2';
import AdminImpersonateCompany from '../views/Company/AdminImpersonateCompany';
import BlogIndividual from '../views/Freelancer/BlogIndividual';
import SetNewPassword from '../views/Company/SetNewPassword';
import ManageTeams from '../views/Company/ManageTeams';
import ChangePasswordPage from '../v2/pages/change-password';
import ViewApplicantsPage from '../v2/pages/view-applicants';
import CompanyInvitationsPage from '../v2/pages/account-settings/company/invitations';

export const COMPANY_ROUTES = [
  {
    path: 'dashboard',
    exact: true,
    component: DashboardCompanyPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'dashboard/2',
    exact: true,
    component: Dashboard,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'login',
    component: LoginPage,
    exact: true,
  },
  {
    path: 'edit-profile',
    component: CompanyEditProfilePage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },

  {
    path: 'team/register/profile',
    component: CompanyTeamRegisterProfilePage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'team/register2/:id',
    component: CreateTeamAccount,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'team/register/:id',
    component: CompanyTeamRegisterPage,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'team/profile/register',
    component: CreateTeamAccountStep2,
    exact: true,
    shouldLogIn: false,
  },
  {
    path: 'team/welcome',
    component: CompanyTeamWelcomePage,
    exact: true,
    shouldLogIn: false,
  },
  {
    exact: true,
    shouldLogIn: false,
    path: 'team/profile/complete',
    component: CompanyTeamRegisterCompletePage,
    // component: CompleteTeamProfile,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'impersonate/:id',
    exact: true,
    component: AdminImpersonateCompany,
  },
  {
    path: 'register',
    exact: true,
    component: RegisterPage,
  },
  {
    path: 'register/:step',
    exact: true,
    component: RegisterPage,
  },
  {
    path: 'resources/:slug',
    exact: true,
    component: BlogIndividual,
  },
  {
    path: 'forgot-password',
    exact: true,
    component: ForgotPasswordPage,
  },
  {
    path: 'login',
    exact: true,
    component: LoginPage,
  },
  {
    path: 'logout',
    exact: true,
    component: LogoutPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'reset-password',
    exact: true,
    component: ResetPasswordPage,
  },
  {
    shouldLogIn: true,
    path: 'profile',
    exact: true,
    component: CompanyProfilePage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'change-password',
    exact: true,
    component: ChangePasswordPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    shouldLogIn: true,
    path: 'candidate-profile/:id',
    exact: true,
    component: CompanyViewConsultantProfilePage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    shouldLogIn: true,
    path: 'agency-profile/:id',
    exact: true,
    component: CompanyViewAgencyProfilePage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'complete-profile/2',
    exact: true,
    component: CompleteProfile,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'complete-profile',
    exact: true,
    component: CompanyCompleteProfilePage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'add-opportunity',
    exact: true,
    component: CreateOpportunityPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'edit-opportunity/:id',
    exact: true,
    component: EditOpportunityPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'add-project',
    exact: true,
    component: CreateProjectPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'edit-project/:id',
    exact: true,
    component: EditProjectPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'view-applicants/:id',
    exact: true,
    component: ViewApplicantsPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'account',
    exact: true,
    component: AccountInformationCompanyPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'billing-history',
    component: CompanyBillingHistoryPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'invitations',
    // component: Invitations,
    component: CompanyInvitationsPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'manage-team/v1',
    exact: true,
    component: ManageTeams,
    // component: CompanyManageTeamsPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: 'manage-team',
    // component: ManageTeams,
    component: CompanyManageTeamsPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: true,
  },
  {
    path: '/',
    exact: true,
    component: LandingPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
    shouldLogIn: false,
  },
  {
    path: 'agency-marketplace',
    exact: true,
    component: AgencyNetworkCompanyPage,
    shouldLogIn: true,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: 'case-study/:id',
    component: CaseStudyDetailsPage,
    exact: true,
    shouldLogIn: true,
    users: [LINK_GROUP.COMPANY.toUpperCase()],
  },
  {
    path: 'resources',
    exact: true,
    shouldLogIn: true,
    component: ResourcesPage,
    users: [LINK_GROUP.COMPANY.toUpperCase(), 'GUEST'],
  },
  {
    path: '404',
    component: NotFoundPage,
  },
];
