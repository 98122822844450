export default {
  /** Job Rates | New Job Opportunity */
  DateRate: [200, 1000],
  ProjectRate: [1000, 25000],
  YearRate: [20000, 200000],
};

export const accountPaths = [
  'profile',
  'edit-profile',
  'manage-payment-info',
  'manage-credits',
  'manage-team',
  'invitations',
  'billing-history',
  'change-password',
  'edit-personal-information',
  'notification-settings',
  'profile-privacy',
  'edit-account-information'
];
