import { useQuery } from '@apollo/react-hooks';
import { GET_CREDIT_HISTORY, GET_SUBSCRIPTION_HISTORY } from '../../graphql';
import { useContext, useState } from 'react';
import { AppContext } from '../../Store';
import { getUser } from '../../utils/helpers';

export default function useCredits() {
  const [state] = useContext(AppContext);
  const [balance, setBalance] = useState(0);
  const [billingList, setBillingList] = useState([]);

  const handleDownloadInvoice = (invoiceId) => {
    const url = `${process.env.REACT_APP_API_URL}/api/user/${
      getUser().id
    }/invoice/${invoiceId}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const {
    loading: balanceLoading,
    data: balanceData,
    refetch: refetchCredit,
  } = useQuery(GET_CREDIT_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
      agency: state?.activeCompany?.team_id,
    },
    onCompleted: (data) => {
      if (data.creditHistory.length > 0) {
        setBalance(data.creditHistory[0].balance);
      } else {
        setBalance(0);
      }
    },
    onError: () => refetchCredit(),
  });

  const { refetch: refetchCreditINOnly, loading: loadingBillingList } =
    useQuery(GET_CREDIT_HISTORY, {
      fetchPolicy: 'network-only',
      variables: {
        orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
        agency: state?.activeCompany?.team_id,
        type: 'IN',
      },
      onCompleted: (data) => {
        const combined = billingList.concat(
          data.creditHistory.map(({ qty, total, invoice_id, created_at }) => ({
            qty,
            total,
            invoice_id,
            created_at,
          }))
        );
        setBillingList(
          combined.sort((a, b) => {
            if (a.created_at > b.created_at) {
              return -1;
            }
            if (b.created_at > a.created_at) {
              return 1;
            }
            return 0;
          })
        );
      },
      onError: () => refetchCreditINOnly(),
    });

  const { refetch: refetchSubscription, loading: loadingSubcription } =
    useQuery(GET_SUBSCRIPTION_HISTORY, {
      fetchPolicy: 'network-only',
      variables: {
        orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
        agency: state?.activeCompany?.team_id,
      },
      onCompleted: (data) => {
        const combined = billingList.concat(
          data.subscriptionHistory.map(({ price, stripe_id, created_at }) => ({
            name: 'Monthly Subscription',
            total: price,
            invoice_id: stripe_id,
            created_at,
          }))
        );
        setBillingList(
          combined.sort((a, b) => {
            if (a.created_at > b.created_at) {
              return -1;
            }
            if (b.created_at > a.created_at) {
              return 1;
            }
            return 0;
          })
        );
      },
      onError: () => refetchSubscription(),
    });

  return {
    balance: {
      loading: balanceLoading,
      data: balanceData,
      current: balance,
      setBalance,
      handleDownloadInvoice,
      refetch: refetchCredit,
    },
    billing: {
      list: billingList,
      loading: loadingSubcription || loadingBillingList,
      handleDownloadInvoice,
    },
  };
}
