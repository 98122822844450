import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { GET_OPPORTUNITY } from '../../../graphql';
import { LINK_GROUP } from '../../../utils/links';
import DefaultLayout from '../../../v2/layouts/defaultLayout';
import { BackPanel, Heading, Link } from '../../../v2/snippets';
import { TablerSection } from '../../components';

const ViewApplicantsPage = ({
  match: {
    params: { id },
    url,
  },
}) => {
  const { data: { opportunity } = {}, refetch } = useQuery(GET_OPPORTUNITY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onCompleted: () => toast.dismiss(),
    onError: () => refetch(),
  });

  const dataApplicants = useMemo(() => {
    return opportunity?.applicants
      .filter((a) => a.user)
      .map(({ user, created_at }) => {
        let phone = user.phone;

        if (user?.agency_phone) {
          phone = user?.agency_phone?.map((p) => {
            return p?.attributes?.phone;
          })[0];
        }

        return [
          <>
            <div>
              {user.type === LINK_GROUP.AGENCY.toUpperCase()
                ? user.agency_name
                : user.name}
            </div>
          </>,
          created_at,
          user.email,
          phone,
          `/${LINK_GROUP.EMPLOYER}/${
            user.type === LINK_GROUP.AGENCY.toUpperCase()
              ? 'agency'
              : 'candidate'
          }-profile/${user.id}`,
        ];
      });
  }, [opportunity]);

  return (
    <DefaultLayout
      showBackButton
      backButtonUrl={`/${LINK_GROUP.EMPLOYER}/dashboard`}
    >
      <BackPanel>
        <div className="d-flex align-items-center gap-10">
          <Heading>
            {opportunity ? `${opportunity.title}` : 'Application Responses'}
          </Heading>
          <span className="h1-subheading">
            (
            {opportunity &&
            'applicants' in opportunity &&
            opportunity.applicants.length
              ? opportunity.applicants.filter((a) => a.user).length
              : 0}{' '}
            new)
          </span>
        </div>

        {dataApplicants && (
          <TablerSection
            className="spacer-t table-applicants"
            tables={dataApplicants}
            headers={['Name', 'Date Applied', 'Email', 'Phone', '']}
            tableProportion={[
              'tb-span-4',
              'tb-span-2',
              'tb-span-5',
              'tb-span-3',
              'tb-span-2 tb-push-right',
            ]}
            renderItem={(item, index) => {
              if (index === 4) {
                return (
                  <Link to={`${item}?back=${url}`} isMinimalism>
                    View Profile
                  </Link>
                );
              } else if (index === 1) {
                return moment(item).format('DD/MM/YYYY');
              }

              return <>{item}</>;
            }}
          ></TablerSection>
        )}
      </BackPanel>
    </DefaultLayout>
  );
};

export default ViewApplicantsPage;
