import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import currencyToSymbolMap from 'currency-symbol-map/map';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Header from '../../components/Freelancer/Header';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import AddEducationModal from '../../components/Freelancer/AddEducationModal';
import RangeSlider from '../../components/Company/RangeSlider';
import RemoveModal from '../../components/Freelancer/RemoveEducationOrExperienceModal';
import AccountSubmenuMobile from '../../components/Freelancer/AccountSubmenuMobile';
import AccountSubmenuDesktop from '../../components/Freelancer/AccountSubmenuDesktop';
import {
  arrToDropdownSelection,
  isValidDocument,
  getUser,
  saveUserLocalstorage,
} from '../../utils/helpers';
import {
  ATTACH_SKILL,
  ATTACH_GENRE,
  ATTACH_JOB_TYPE,
  GET_SKILLS,
  GET_GENRES,
  UPDATE_PROFILE,
  CREATE_RESUME,
} from '../../graphql';
import AddExperienceModal from '../../components/Freelancer/AddExperienceModal';
import { GET_CV } from '../../graphql/queries/cv';

import Error from '../../components/Error';
import EditEducationModal from '../../components/Freelancer/EditEducationModal';
import {
  currentEducationVar,
  currentExperienceVar,
  currentTypeVar,
  userVar,
} from '../../graphql/config';
import EditExperienceModal from '../../components/Freelancer/EditExperienceModal';
import CustomMultiselect from '../../v2/components/CustomMultiselect';

const ManageCV = ({ history }) => {
  useEffect(() => {
    jquery();
  }, []);

  const cvRef = useRef(null);
  const skillsRef = useRef(null);
  const genresRef = useRef(null);
  const jobtypesRef = useRef(null);
  const subJobtypesRef = useRef(null);
  const experiencesRef = useRef(null);
  ReactTooltip.rebuild();
  const { register, handleSubmit, errors } = useForm({
    shouldFocusError: true,
  });
  const [selected, setSelected] = useState({});
  const [jobType, setJobType] = useState('');
  const [subJobType, setSubJobType] = useState('');
  const [doc, setDoc] = useState({
    url: null,
    error: null,
  });
  const availableCurrencies = ['GBP', 'EUR', 'USD'];
  const currencies = Object.entries(
    Object.keys(currencyToSymbolMap)
      .filter((key) => availableCurrencies.includes(key))
      .reduce((obj, key) => {
        obj[key] = currencyToSymbolMap[key];
        return obj;
      }, {})
  );
  const [jobRate, setJobRate] = useState('');
  const [salary, setSalary] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('GBP');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [topSkills, setTopSkills] = useState([]);
  const [userEducations, setUserEducations] = useState([]);
  const [userExperiences, setUserExperiences] = useState([]);
  const [userResumes, setUserResumes] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const { data: { skills = [] } = {}, refetch: refetchSkill } = useQuery(
    GET_SKILLS,
    {
      variables: { name: '' },
      onError: () => refetchSkill(),
    }
  );

  const { data: { genres = [] } = {}, refetch: refetchGenre } = useQuery(
    GET_GENRES,
    {
      variables: { name: '' },
      onError: () => refetchGenre(),
    }
  );

  const allJobtypes = [
    {
      key: 'FREELANCE',
      name: 'Freelance',
      description: 'Less than six months',
    },
    {
      key: 'PERMANENT',
      name: 'Permanent',
      description: 'Full Time or Part Time',
    },
    {
      key: 'BOTH',
      name: 'Both',
    },
  ];

  const allSubJobtypes = [
    {
      key: 'FULLTIME',
      name: 'Full-time',
    },
    {
      key: 'PARTTIME',
      name: 'Part-time',
    },
    {
      key: 'BOTH_TIME',
      name: 'Both',
    },
  ];

  const optionsArrSkills = arrToDropdownSelection(skills);
  const optionsArrGenres = arrToDropdownSelection(genres);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [updateSkills] = useMutation(ATTACH_SKILL);
  const [updateGenres] = useMutation(ATTACH_GENRE);
  const [updateJobType] = useMutation(ATTACH_JOB_TYPE);
  const [updateResume] = useMutation(CREATE_RESUME);

  const {
    data: {
      me: {
        education = [],
        experiences = [],
        skills: existingSkills,
        jobTypes: existingJobTypes,
        genres: existingGenres,
        resumes = [],
        referee_1,
        referee_2,
        bio,
        linkedin,
        salary_currency,
        minimum_salary,
        maximum_salary,
        salary_unit,
      } = {},
    } = {},
    loading: loadingCV,
    refetch,
  } = useQuery(GET_CV, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (salary_unit) {
        setJobRate(salary_unit === 'YEAR' ? 'Year Rate' : 'Day Rate');
      }

      if (existingJobTypes.length) {
        const jobTypeKeys = existingJobTypes.map((t) => t.key);
        const jobTypeKey = [...jobTypeKeys].filter((j) =>
          allJobtypes.map((ajt) => ajt.key).includes(j)
        );
        const subJobTypeKey = [...jobTypeKeys].filter((j) =>
          allSubJobtypes.map((ajt) => ajt.key).includes(j)
        );

        if (jobTypeKey.length) {
          setJobType(jobTypeKey[0]);
        }

        if (subJobTypeKey.length) {
          setSubJobType(subJobTypeKey[0]);
        }
      }

      if (genres.length && skills.length) {
        setSelected({
          genres: arrToDropdownSelection(existingGenres),
          skills: arrToDropdownSelection(existingSkills),
        });
      }

      if (salary_currency) {
        setSelectedCurrency(salary_currency ?? 'GBP');
      }

      if (existingSkills.length) {
        setSelectedSkills(arrToDropdownSelection(existingSkills));
        setTopSkills(
          arrToDropdownSelection(existingSkills.filter((s) => s.pivot.is_top))
        );
      }

      setUserEducations(
        [...education].sort((a, b) => {
          const fromA = moment(a.from).format('YYYY');
          const fromB = moment(b.from).format('YYYY');
          return fromB - fromA;
        })
      );
      setUserExperiences(
        [...experiences].sort((a, b) => {
          const fromA = moment(a.from).format('YYYY');
          const fromB = moment(b.from).format('YYYY');
          return fromB - fromA;
        })
      );
      setUserResumes(
        [...resumes].sort((a, b) => moment(b.created_at) - moment(a.created_at))
      );
    },
    onError: () => refetch(),
  });

  const updateProfileRequest = (data) => {
    let errors = {};

    if (resumes.length === 0 && !doc.file) {
      errors.resumes = 'Please upload your CV!';
      cvRef.current.scrollIntoView();
    }

    if (userExperiences.length === 0) {
      errors.experiences = 'You must input at least 1 experience!';
      experiencesRef.current.scrollIntoView();
    }

    if (jobType === '') {
      errors.jobtype = 'You must input a job type consideration!';
      jobtypesRef.current.scrollIntoView();
    }

    if (subJobType === '') {
      errors.subjobtype = 'You must input a working hour consideration!';
      subJobtypesRef.current.scrollIntoView();
    }

    if (selected.genres.length === 0) {
      errors.genres = 'You must input at least 1 category experience!';
      genresRef.current.scrollIntoView();
    }

    if (selected.skills.length === 0) {
      errors.skills = 'You must input at least 1 skill!';
      skillsRef.current.scrollIntoView();
    }

    setErrorMessages(errors);

    if (Object.keys(errors).length !== 0) {
      return;
    }

    toast.dismiss();
    updateSkillsRequest();
    updateGenresRequest();
    updateJobTypeRequest();

    if (doc.file) {
      updateResumeRequest();
    }

    return updateProfile({
      variables: {
        input: {
          ...data,
          salary_currency: selectedCurrency,
          minimum_salary: salary[0],
          maximum_salary: salary[1],
          salary_unit: jobRate === 'Year Rate' ? 'YEAR' : 'DAY',
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Profile updated successfully!', { autoClose: 4000 });
        return history.push('/freelancer/profile');
      })
      .catch((e) =>
        toast.error('Could not update profile', { toastId: 'resErr' })
      );
  };

  const updateSkillsRequest = () => {
    toast.dismiss();

    return updateSkills({
      variables: {
        input: {
          sync: selected.skills.map((s) => s.key),
          isTop: selected.skills.map((s) =>
            topSkills.map((t) => t.key).includes(s.key)
          ),
        },
      },
    });
  };

  const updateGenresRequest = () => {
    toast.dismiss();
    return updateGenres({
      variables: {
        input: { sync: selected.genres.map((g) => g.key) },
      },
    });
  };

  const updateJobTypeRequest = () => {
    toast.dismiss();
    return updateJobType({
      variables: {
        input: {
          create: [
            Array.isArray(jobType) ? jobType[0] : jobType,
            Array.isArray(subJobType) ? subJobType[0] : subJobType,
          ],
        },
      },
    });
  };

  const updateResumeRequest = () => {
    toast.dismiss();
    return updateResume({
      variables: {
        input: {
          file: doc.file,
        },
      },
    }).then(() => {
      toast.dismiss();
      toast.success('CV uploaded! - ' + doc.file.name, { autoClose: 4000 });
    });
  };

  const style = {
    chips: {
      background: '#8C79B5',
    },
    searchBox: {
      border: 'none',
      borderBottom: '1px solid #ccc',
      borderRadius: '0px',
    },
    multiselectContainer: {
      color: '#8C79B5',
    },
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidDocs');

    const file = event.target.files[0];
    isValidDocument(file, (valid) => {
      if (!valid) {
        return toast.error('Please select a valid document file.', {
          toastId: 'invalidDocs',
        });
      }
      setDoc({
        url: valid.url,
        error: false,
        file: valid.file,
      });
    });
  };

  return (
    <>
      <AddEducationModal refetch={refetch} />
      <AddExperienceModal refetch={refetch} />
      <EditEducationModal refetch={refetch} />
      <EditExperienceModal refetch={refetch} />
      <RemoveModal refetch={refetch} />
      <div className="lh-wrapper">
        <Header isLoggedIn />
        <AccountSubmenuMobile />
      </div>
      <div className="container form__freelancer">
        <div className="row">
          <div className="col-12">
            <h1>Your profile</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <p className="mb-60">
              Add your qualifications, client/category experience, skills and
              references. We'll use this to build your profile which will be
              shown to employers.
            </p>
          </div>

          <div className="col-12 col-md-8 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="form__input-block mb-60">
                  <div className="col-12 m-0 p-0 add-opportunity-row__title">
                    <span className="txt__fw-600">About me </span>
                  </div>
                  <div className="col-12 m-0 p-0">
                    <textarea
                      rows="5"
                      // maxLength="1000"
                      name="bio"
                      className="form__textarea__border-light height__unset"
                      ref={register({ required: true })}
                      style={{ maxWidth: '100%' }}
                      defaultValue={bio}
                    />
                  </div>
                  {errors.bio && (
                    <Error text="This field is required" noMargin />
                  )}
                </div>

                {loadingCV && 'Loading Skills...'}
                <div>
                  <p className="mb-10 txt__fw-600">Skills</p>
                </div>
                {optionsArrSkills.length > 0 && selected.skills && (
                  <div className="form__input-block mb-40">
                    <div className="form__input-wrapper" ref={skillsRef}>
                      <CustomMultiselect
                        options={optionsArrSkills}
                        selectedValues={
                          selected.skills.length === 1
                            ? [...selected.skills]
                            : selected.skills
                        }
                        onSelect={(selectedList, selectedItem) => {
                          const temp = selected;
                          temp.skills = selectedList;
                          setSelected(temp);
                          setSelectedSkills(selectedList);
                        }}
                        onRemove={(selectedList, removedItem) => {
                          const temp = selected;
                          temp.skills = selectedList;
                          setSelected(temp);
                          setSelectedSkills(selectedList);
                        }}
                        displayValue="label"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Select Skills"
                        style={style}
                      />
                      {errorMessages.skills && (
                        <Error text={errorMessages.skills} noMargin />
                      )}
                    </div>
                  </div>
                )}
                {selectedSkills && selectedSkills.length > 0 && (
                  <div className="form__input-block mb-40">
                    <div className="form__input-wrapper">
                      <CustomMultiselect
                        options={selectedSkills}
                        selectedValues={topSkills}
                        onSelect={(selectedList, selectedItem) => {
                          setTopSkills(selectedList);
                        }}
                        onRemove={(selectedList, removedItem) => {
                          setTopSkills(selectedList);
                        }}
                        selectionLimit={3}
                        displayValue="label"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Select Top 3 Skills"
                        style={style}
                      />
                    </div>
                  </div>
                )}

                {loadingCV && 'Loading Category experience...'}
                <div>
                  <p className="mb-10 txt__fw-600">Category Experience</p>
                </div>
                {optionsArrGenres.length > 0 && selected.genres && (
                  <div className="form__input-block mb-40">
                    <div className="form__input-wrapper" ref={genresRef}>
                      <CustomMultiselect
                        options={optionsArrGenres}
                        selectedValues={
                          selected.genres.length === 1
                            ? [...selected.genres]
                            : selected.genres
                        }
                        onSelect={(selectedList, selectedItem) => {
                          const temp = selected;
                          temp.genres = selectedList;
                          setSelected(temp);
                        }}
                        onRemove={(selectedList, removedItem) => {
                          const temp = selected;
                          temp.genres = selectedList;
                          setSelected(temp);
                        }}
                        displayValue="label"
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Select Category Experience"
                        style={style}
                      />
                      {errorMessages.genres && (
                        <Error text={errorMessages.genres} noMargin />
                      )}
                    </div>
                  </div>
                )}

                {loadingCV && 'Loading Job Types...'}
                <div>
                  <p className="mb-10 txt__fw-600">Considering</p>
                </div>
                <div
                  className="row form__input-block mb-40"
                  style={{ marginBottom: 0 }}
                >
                  <div className="col-12 job-type" ref={jobtypesRef}>
                    {allJobtypes.map((jt, index) => (
                      <span
                        key={jt.key}
                        className={`job-type__card ${
                          jobType === jt.key ? 'job-type__card__active' : ''
                        }`}
                        onClick={() => setJobType(jt.key)}
                      >
                        {jt.name}
                      </span>
                    ))}
                  </div>
                </div>
                {errorMessages.jobtype && (
                  <Error text={errorMessages.jobtype} noMargin />
                )}

                <div>
                  <p className="mb-10 txt__fw-600">Working Hours</p>
                </div>
                <div
                  className="row form__input-block mb-40"
                  style={{ marginBottom: 0 }}
                >
                  <div className="col-12 work-hour" ref={subJobtypesRef}>
                    {allSubJobtypes.map((sjt) => (
                      <div key={sjt.key}>
                        <input
                          name="sub_type"
                          id={sjt.key}
                          type="radio"
                          value={sjt.key}
                          checked={subJobType === sjt.key}
                          onChange={() => {
                            setSubJobType(sjt.key);
                          }}
                        />
                        <label htmlFor={sjt.key}>{sjt.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                {errorMessages.subjobtype && (
                  <Error text={errorMessages.subjobtype} noMargin />
                )}

                {loadingCV && 'Loading Experience...'}
                <div>
                  <p className="mb-10 txt__fw-600">Experience</p>
                </div>
                {userExperiences.map(
                  ({ id, title, company, description, from, to, years }) => (
                    <div key={id}>
                      <h3 className="font-weight-bold mb-1">{title}</h3>
                      <p className="txt__fw-600 mb-0">{company}</p>
                      {from && (
                        <p className="mb-2 txt__light">
                          {moment(from).format('YYYY')} -{' '}
                          {to ? moment(to).format('YYYY') : 'Present'}
                        </p>
                      )}
                      <div className="mb-30">
                        <p className="mb-0 text-justify">{description}</p>
                        <a
                          href="/#"
                          className="txt__small txt__purple"
                          data-toggle="modal"
                          data-target="#edit_experience_modal"
                          onClick={() => {
                            currentExperienceVar({
                              id,
                              title,
                              company,
                              description,
                              from,
                              to,
                            });
                          }}
                        >
                          <u>Edit</u>
                        </a>
                        <a
                          href="/#"
                          className="txt__small txt__purple ml-3"
                          data-toggle="modal"
                          data-target="#remove_modal"
                          onClick={() => {
                            currentTypeVar({
                              type: 'experience',
                              id,
                            });
                          }}
                        >
                          <u>Remove</u>
                        </a>
                      </div>
                    </div>
                  )
                )}
                <div className="form__input-block mb-60">
                  <div className="form__input-block mb-60">
                    <div
                      ref={experiencesRef}
                      className="add-card"
                      data-toggle="modal"
                      data-target="#add_experience_modal"
                    >
                      <i className="fas fa-plus" /> Add Experience
                    </div>
                    {errorMessages.experiences && (
                      <Error text={errorMessages.experiences} noMargin />
                    )}
                    <span
                      className="round-btn absolute experience"
                      data-for="tooltip"
                      data-tip="Please put newest / most relevant qualification in first as this will appear at the top"
                    >
                      <i className="fas fa-question" />
                    </span>
                  </div>
                </div>

                {loadingCV && 'Loading Qualifications...'}
                <div className="form__heading mt-0 mb-20">
                  Qualifications - optional
                </div>
                {userEducations.map(
                  ({ id, qualification, description, from, to }) => (
                    <div key={id}>
                      <h3 className="font-weight-bold mb-1">{qualification}</h3>
                      <div className="mb-30">
                        <p className="mb-0 text-justify">{description}</p>
                        <a
                          href="/#"
                          className="txt__small txt__purple"
                          data-toggle="modal"
                          data-target="#edit_qualification_modal"
                          onClick={() => {
                            currentEducationVar({
                              id,
                              qualification,
                              description,
                              from,
                              to,
                            });
                          }}
                        >
                          <u>Edit</u>
                        </a>
                        <a
                          href="/#"
                          className="txt__small txt__purple ml-3"
                          data-toggle="modal"
                          data-target="#remove_modal"
                          onClick={() => {
                            currentTypeVar({
                              type: 'education',
                              id,
                            });
                          }}
                        >
                          <u>Remove</u>
                        </a>
                      </div>
                    </div>
                  )
                )}
                <div className="form__input-block mb-60">
                  <div className="form__input-block mb-60">
                    <div
                      className="add-card"
                      data-toggle="modal"
                      data-target="#add_qualification_modal"
                    >
                      <i className="fas fa-plus" /> Add Qualifications
                    </div>
                    <span
                      className="round-btn absolute education"
                      data-for="tooltip"
                      data-tip="Please put newest / most relevant qualification in first as this will appear at the top"
                    >
                      <i className="fas fa-question" />
                    </span>
                  </div>
                </div>

                {loadingCV && 'Loading CV...'}
                <div className="form__input-block mb-60">
                  <span className="txt__fw-600">LinkedIn Profile</span>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="linkedin"
                      ref={register({ required: false })}
                      defaultValue={linkedin}
                    />
                  </div>
                </div>

                {loadingCV && 'Loading CV...'}
                <div className="mb-3">
                  <p className="mb-10 txt__fw-600">Your CV</p>
                </div>
                {userResumes.length > 0 && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">File</th>
                          <th scope="col">Date uploaded</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {userResumes.map(
                          ({ id, file, fileUrl, created_at, active }) => (
                            <tr key={id}>
                              <td>
                                <p className="mb-0 text-justify">
                                  <a
                                    className="link-freelancer"
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file}
                                  </a>
                                </p>
                              </td>
                              <td>
                                <p>
                                  {moment(created_at).format(
                                    'YYYY-MM-DD h:m:s'
                                  )}
                                </p>
                              </td>
                              <td>
                                <a
                                  href="/#"
                                  className="txt__small txt__purple"
                                  data-toggle="modal"
                                  data-target="#remove_modal"
                                  onClick={() => {
                                    currentTypeVar({
                                      type: 'CV',
                                      id,
                                    });
                                  }}
                                >
                                  <u>Delete</u>
                                </a>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="form__input-block mb-60">
                  <div
                    className="add-card"
                    onClick={() => cvRef.current.click()}
                  >
                    <i className="fas fa-plus" /> Upload your CV
                  </div>
                  {errorMessages.resumes && (
                    <Error text={errorMessages.resumes} noMargin />
                  )}
                </div>
                <div className="mt-3 mb-40">
                  {doc.file && <span>{doc.file.name}</span>}
                  {doc.error && (
                    <Error text="Please select a valid document file" />
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={cvRef}
                  style={{ display: 'none' }}
                  onChange={(e) => onChangeFile(e)}
                />

                {loadingCV && 'Loading Salary Band...'}
                <div className="mb-3">
                  <p className="mb-10 txt__fw-600">Salary Bands</p>
                  <span>For Liberty Hive purposes only</span>
                </div>
                <div className="row mb-40">
                  <div className="col-md-3">
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <select
                          name="salary_currency"
                          value={selectedCurrency}
                          className="form__input form__country"
                          ref={register({ required: false })}
                          onChange={(e) => {
                            setSelectedCurrency(e.target.value);
                          }}
                        >
                          {currencies.map(([code, symbol]) => (
                            <option value={code} key={code}>
                              {symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      justifyContent: 'center',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <div className="form__input-block">
                      <div className="form__input-wrapper">
                        <RangeSlider
                          onUpdateCallback={(args) => setSalary(args)}
                          jobBudget={[minimum_salary, maximum_salary]}
                          jobRate={jobRate}
                          editMode={true}
                          freelance={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="compensation">
                      <span
                        className={`compensation__card ${
                          jobRate === 'Day Rate' && 'active-purple'
                        }`}
                        onClick={() => {
                          setJobRate('Day Rate');
                        }}
                      >
                        Day Rate
                      </span>

                      <span
                        className={`compensation__card ${
                          jobRate === 'Year Rate' && 'active-purple'
                        }`}
                        onClick={() => {
                          setJobRate('Year Rate');
                        }}
                      >
                        Annual Salary
                      </span>
                    </div>
                  </div>
                </div>

                {loadingCV && 'Loading Referees...'}
                <div className="mb-3">
                  <p className="mb-10 txt__fw-600">Referees</p>
                  <span>For Liberty Hive purposes only</span>
                </div>
                <div className="form__input-block">
                  <span className="txt__fw-600">Reference 1</span>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="referee_1"
                      ref={register({ required: false })}
                      defaultValue={referee_1}
                    />
                  </div>
                </div>
                <div className="form__input-block mb-3">
                  <span className="txt__fw-600">Reference 2</span>
                  <div className="form__input-wrapper">
                    <input
                      className="form__input"
                      type="text"
                      name="referee_2"
                      ref={register({ required: false })}
                      defaultValue={referee_2}
                    />
                  </div>
                </div>

                <div className="form__input-block">
                  <div className="form__input-block mb-60">
                    <button
                      type="submit"
                      disabled={loadingCV}
                      className="btn form__btn bg-freelancer mt-5"
                      onClick={handleSubmit(updateProfileRequest)}
                    >
                      {loading ? 'Loading...' : 'SAVE'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AccountSubmenuDesktop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageCV;
