import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import {
  GET_INVITATION,
  CHECK_REGISTERABLE_USER,
  PROCESS_INVITATION,
  REGISTER,
  CREATE_TEAM_USER,
} from '../../graphql';
import { registerVar, userVar } from '../../graphql/config';
import {
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../utils/helpers';
import jquery from '../../styles/js/app';
import DefaultLayout from '../../v2/layouts/defaultLayout';
import {
  FormBlock,
  FormBlockItem,
  FormInput,
  BackPanel,
  FormCTA,
  Button,
} from '../../v2/snippets';
import { LINK_GROUP } from '../../utils/links';

const AcceptInvite = ({ history }) => {
  const [isEnabled, setIsEnabled] = useState(true);
  useEffect(() => {
    jquery();
  }, []);

  const { token } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const { register, handleSubmit, errors, watch, clearErrors } = useForm();
  const [invitationEmail, setInvitationEmail] = useState(queryParams?.e);
  const {
    loading,
    error,
    data: invitationData,
  } = useQuery(GET_INVITATION, {
    variables: { token },
    onCompleted: () => {
      setInvitationEmail(invitationData?.getInvitation?.email);
      check();
    },
  });
  const [acceptInvite] = useMutation(PROCESS_INVITATION);
  const [registerMutation, { loading: submitLoading }] = useMutation(REGISTER);
  const [createTeamUser] = useMutation(CREATE_TEAM_USER);

  const [check, { data: checkUserData }] = useLazyQuery(
    CHECK_REGISTERABLE_USER,
    {
      variables: { email: invitationEmail },
      onCompleted: () => isUniqueEmail({ showFeedback: true }),
    }
  );

  const isUniqueEmail = () => {
    toast.dismiss();
    if (
      checkUserData?.checkRegisterableUser !== null &&
      checkUserData?.checkRegisterableUser !== undefined &&
      checkUserData?.checkRegisterableUser?.type !== 'FREELANCER'
    ) {
      addExistingUser();
    }
  };

  const addExistingUser = () => {
    toast.dismiss();
    createTeamUser({
      variables: {
        input: {
          email: invitationEmail,
          token: token,
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.createTeamUser?.team_id) {
          const cookies = new Cookies();
          cookies.set('lh_team', data?.createTeamUser?.team_id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }
        acceptInvitation();
      })
      .catch(() =>
        toast.error('Could not create team account', { toastId: 'appErr' })
      );
  };

  const acceptInvitation = () => {
    toast.dismiss();
    acceptInvite({
      variables: {
        email: invitationEmail,
        token: token,
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.processInvitation?.team_id) {
          const cookies = new Cookies();
          cookies.set('lh_team', data?.processInvitation?.team_id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }
        history.push({
          pathname: `/${LINK_GROUP.AGENCY}/dashboard`,
          state: {
            mode: 'Invitation_Accepted',
          },
        });
        toast.success('Invitation accepted successfully', { autoClose: 4000 });
      })
      .catch((e) =>
        toast.error('Could not accept invite', { toastId: 'appErr' })
      );
  };

  const registerUser = (data) => {
    toast.dismiss();
    setIsEnabled(false);
    delete data.confirmPassword;
    registerMutation({
      variables: {
        input: {
          ...data,
          ...registerVar(),
          type: 'GUEST',
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (!data.register) {
          toast.error('Something is wrong!');
        } else {
          registerVar({});
          const cookies = new Cookies();
          cookies.set('lh_user', data.register.id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          userVar(data.register);
          saveUserLocalstorage(data.register);
          saveLoginTypeLocalstorage('GUEST');
          /** Create UserTeam Association */
          addNewTeamMember();
        }
      })
      .catch((e) => {
        let msg = 'Something went wrong!';

        if (
          'validation' in e?.graphQLErrors[0]?.extensions &&
          'input.email' in e?.graphQLErrors[0]?.extensions?.validation
        ) {
          msg = 'The email has already been taken!';
        }

        toast.error(msg, { toastId: 'frRegErr' });
      });
  };

  const addNewTeamMember = () => {
    toast.dismiss();
    createTeamUser({
      variables: {
        input: {
          email: invitationEmail,
          token: token,
        },
      },
    })
      .then((res) => {
        const { data } = res;
        if (data?.createTeamUser?.team_id) {
          const cookies = new Cookies();
          cookies.set('lh_team', data?.createTeamUser?.team_id, {
            path: '/',
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
        }
        acceptInvitation();
      })
      .catch(() => {});
  };

  if (error)
    return toast.error('Something went wrong', { toastId: 'accInvErr' });

  return (
    <DefaultLayout showNavigation={false}>
      <BackPanel className="form-wrapper" isSmall>
        <div className="row">
          <div className="col-12">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div>
                <h1 style={{ fontSize: '20px' }}>
                  {invitationData?.getInvitation?.inviter?.agency_name} invited
                  you to join their team, complete the information below to
                  activate your account.
                </h1>
                <form
                  className="form form-agency"
                  onSubmit={handleSubmit(registerUser)}
                >
                  <input
                    type="hidden"
                    name="address_1"
                    defaultValue={
                      invitationData?.getInvitation?.inviter?.address_1
                    }
                    ref={register({ required: false })}
                  />
                  <input
                    type="hidden"
                    name="city"
                    defaultValue={invitationData?.getInvitation?.inviter?.city}
                    ref={register({ required: false })}
                  />
                  <input
                    type="hidden"
                    name="state"
                    defaultValue={invitationData?.getInvitation?.inviter?.state}
                    ref={register({ required: false })}
                  />
                  <input
                    type="hidden"
                    name="country"
                    defaultValue={
                      invitationData?.getInvitation?.inviter?.country
                    }
                    ref={register({ required: false })}
                  />
                  <input
                    type="hidden"
                    name="zip"
                    defaultValue={invitationData?.getInvitation?.inviter?.zip}
                    ref={register({ required: false })}
                  />
                  <input
                    type="hidden"
                    name="phone"
                    defaultValue={invitationData?.getInvitation?.inviter?.phone}
                    ref={register({ required: false })}
                  />
                  <div className="form-content">
                    <FormBlock>
                      <FormBlockItem label={'Agency'}>
                        <FormInput
                          name="agency"
                          type="text"
                          defaultValue={
                            invitationData?.getInvitation?.inviter?.agency_name
                          }
                          register={register}
                          disabled
                        />
                      </FormBlockItem>
                      <FormBlockItem label={'First Name'}>
                        <FormInput
                          type="text"
                          name="first_name"
                          isRequired
                          error={
                            errors.first_name
                              ? 'Please input your first name'
                              : ''
                          }
                          defaultValue={
                            registerVar().first_name ||
                            invitationData?.getInvitation?.first_name
                          }
                          register={register}
                          clearErrors={clearErrors}
                        />
                      </FormBlockItem>
                      <FormBlockItem label={'Last Name'}>
                        <FormInput
                          type="text"
                          name="last_name"
                          isRequired
                          error={
                            errors.last_name
                              ? 'Please input your last name'
                              : ''
                          }
                          defaultValue={
                            registerVar().last_name ||
                            invitationData?.getInvitation?.last_name
                          }
                          register={register}
                          clearErrors={clearErrors}
                        />
                      </FormBlockItem>
                      <FormBlockItem label={'Email Address'}>
                        <FormInput
                          type="text"
                          name="email"
                          isRequired
                          error={
                            errors.email
                              ? errors.email.type === 'pattern'
                                ? 'Please enter a valid email address'
                                : 'Please enter an email'
                              : null
                          }
                          register={register}
                          withEmailValidation
                          clearErrors={clearErrors}
                          defaultValue={
                            registerVar().email ||
                            invitationData?.getInvitation?.email
                          }
                        />
                      </FormBlockItem>
                      <FormBlockItem label={'Password'}>
                        <FormInput
                          register={register}
                          type="password"
                          name="password"
                          isRequired
                          clearErrors={clearErrors}
                          error={
                            errors.password ? 'Please enter a password' : null
                          }
                          settings={{ required: true, minLength: 8 }}
                        />
                      </FormBlockItem>
                      <FormBlockItem label={'Confirm Password'}>
                        <FormInput
                          register={register}
                          type="password"
                          name="confirmPassword"
                          clearErrors={clearErrors}
                          error={
                            errors.password
                              ? 'Your passwords do no match'
                              : null
                          }
                          settings={{
                            required: true,
                            validate: (val) => {
                              if (watch('password') !== val) {
                                return 'Your passwords do no match';
                              }
                            },
                          }}
                        />
                      </FormBlockItem>
                    </FormBlock>
                  </div>

                  <FormCTA className={'space-t'}>
                    <Button
                      className="app-btn-submit"
                      type="submit"
                      size="xl"
                      disabled={!isEnabled}
                    >
                      {submitLoading ? 'Loading...' : 'Join Team'}
                    </Button>
                  </FormCTA>
                </form>
              </div>
            )}
          </div>
        </div>
      </BackPanel>
    </DefaultLayout>
  );
};

export default AcceptInvite;
