import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import MyAccountLayout from './layout/MyAccountLayout';
import styled from 'styled-components';
import SendVerificationModal from './components/NotificationSettings/SendVerificationModal';
import PhoneVerificationModal from './components/NotificationSettings/PhoneVerificationModal';
import PhoneVerificationErrorModal from './components/NotificationSettings/PhoneVerificationErrorModal';
import VerificationConfirmationModal from './components/NotificationSettings/VerificationConfirmationModal';
import jquery from '../../styles/js/app';
import {
  getUser,
  logUserOut,
  getLoginUrl,
  saveUserLocalstorage,
} from '../../utils/helpers';
import { userVar } from '../../graphql/config';
import { GET_AUTH_USER, UPDATE_PROFILE } from '../../graphql';
import { Heading } from './CaseStudy';

const NotificationSettings = () => {
  useEffect(() => {
    jquery();
  }, []);

  const stages = ['Send_Verification', 'Phone_Verification', 'Error'];
  const [emailOpportunityAlertsActive, setEmailOpportunityAlertsActive] =
    useState(getUser()?.send_email && false);
  const [textNewAlertsActive, setTextNewAlertsActive] = useState(
    getUser()?.send_text_notification && false
  );
  const [verificationStage, setVerificationStage] = useState(stages[0]);
  const [textFrequency, setTextFrequency] = useState(
    getUser().notification_frequency && 'daily'
  );
  const [showModal, setShowModal] = useState(false);

  const { refetch } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ me }) => {
      if (!me) {
        logUserOut();
        window.location.href = getLoginUrl('FREELANCER');
      }
      setEmailOpportunityAlertsActive(me.send_email);
      setTextNewAlertsActive(me.send_text_notification);
    },
    onError: () => refetch(),
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const handleChecked = (checked, field) => {
    switch (field) {
      case 'Email_Opportunity_Alerts':
        updateProfile({
          variables: {
            input: {
              send_email: checked,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            setEmailOpportunityAlertsActive(checked);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;

      case 'Text_New_Alerts':
        if (checked) {
          setShowModal(true);
        } else {
          updateProfile({
            variables: {
              input: {
                send_text_notification: checked,
              },
            },
          })
            .then(({ data: { updateUser } }) => {
              const updatedUserObj = { ...getUser(), ...updateUser };
              userVar(updatedUserObj);
              saveUserLocalstorage(updatedUserObj);
              setTextNewAlertsActive(checked);
              toast.success('Your notification settings updated!', {
                autoClose: 2000,
              });
            })
            .catch(() => {
              toast.error('Something went wrong.', { autoClose: 5000 });
            });
        }
        break;

      default:
        break;
    }
  };

  const handleFrequency = ({ field }) => {
    setTextFrequency(field);
    switch (field) {
      case 'daily':
        updateProfile({
          variables: {
            input: {
              notification_frequency: field,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;
      case 'immediately':
        updateProfile({
          variables: {
            input: {
              notification_frequency: field,
            },
          },
        })
          .then(({ data: { updateUser } }) => {
            const updatedUserObj = { ...getUser(), ...updateUser };
            userVar(updatedUserObj);
            saveUserLocalstorage(updatedUserObj);
            toast.success('Your notification settings updated!', {
              autoClose: 2000,
            });
          })
          .catch(() => {
            toast.error('Something went wrong.', { autoClose: 5000 });
          });
        break;
      default:
        break;
    }
  };

  const verificationMode = (stage) => {
    switch (stage) {
      case stages[0]:
        return (
          <SendVerificationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[1])}
          />
        );
      case stages[1]:
        return (
          <PhoneVerificationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[2])}
            onBackCallback={() => setVerificationStage(stages[0])}
          />
        );
      case stages[2]:
        return (
          <VerificationConfirmationModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => {
              setTextNewAlertsActive(true);
              setShowModal(false);
            }}
          />
        );
      case stages[3]:
        return (
          <PhoneVerificationErrorModal
            onCloseCallback={() => setShowModal(false)}
            onSubmitCallback={() => setVerificationStage(stages[0])}
          />
        );
      default:
        break;
    }
  };

  return (
    <MyAccountLayout>
      {showModal && verificationMode(verificationStage)}
      <div className="black-card black-card__full mb-50 form-v2-agency">
        <Heading>View Profile</Heading>
        <div className="col-12 col-lg-7 mt-40">
          <div className="row justify-content-between align-items-center">
            <div className="col">Email Me New Opportunity Alerts</div>
            <div className="col-auto">
              <Switch
                height={15}
                width={30}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#71bfb2"
                onChange={(checked) => {
                  handleChecked(checked, 'Email_Opportunity_Alerts');
                }}
                checked={emailOpportunityAlertsActive}
              />
            </div>
          </div>
          <div className="row justify-content-between align-items-center mt-22x">
            <div className="col">Text Me New Alerts</div>
            <div className="col-auto">
              <Switch
                height={15}
                width={30}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#71bfb2"
                onChange={(checked) => {
                  handleChecked(checked, 'Text_New_Alerts');
                }}
                checked={textNewAlertsActive}
              />
            </div>
          </div>
          <div className="row justify-content-between align-items-center mt-22x">
            <div className="col-4">Frequency</div>
            <div className="col">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <CustomRadio
                    type="radio"
                    name="frequency"
                    className="square-radio"
                    checked={textFrequency === 'daily'}
                    onChange={({ currentTarget }) => {
                      const { checked } = currentTarget;

                      if (checked) {
                        handleFrequency({
                          field: 'daily',
                        });
                      }
                    }}
                  />
                  <label className="mb-0" htmlFor="active">
                    Daily
                  </label>
                </div>
                <div className="col-auto">
                  <CustomRadio
                    type="radio"
                    name="frequency"
                    className="square-radio"
                    checked={textFrequency === 'immediately'}
                    onChange={({ currentTarget }) => {
                      const { checked } = currentTarget;

                      if (checked) {
                        handleFrequency({
                          field: 'immediately',
                        });
                      }
                    }}
                  />
                  <label className="mb-0" htmlFor="active">
                    Immediately
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyAccountLayout>
  );
};

export default NotificationSettings;

const CustomRadio = styled.input`
  &.square-radio {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 3px;
    outline: none;
    background: var(--monochrome-color);
    position: relative;
    &:checked {
      background: var(--primary-brand);
    }
    &:checked:before {
      font-family: 'Font Awesome 5 Free';
      content: '\f00c';
      padding-right: 0;
      vertical-align: middle;
      font-weight: 600;
      font-size: 9px;
      background: unset;
      color: var(--netral-color);
      width: 100%;
      height: 100%;
      margin: unset;
      border-radius: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }
  }
`;
