import React from 'react';
import { getUser } from '../../utils/helpers';
import useGroups from './useGroups';
import { Redirect } from 'react-router-dom';

export default function useUser(redirectTo) {
  const { groupname } = useGroups();
  if (
    getUser().access_token &&
    (getUser().type.toLowerCase !== groupname || getUser().type === 'GUEST')
  ) {
    return (
      <Redirect
        to={{
          pathname: redirectTo ?? `/${groupname}/dashboard`,
          state: { referrer: `/${groupname}/login` },
        }}
      />
    );
  }
  return null;
}
