function generateNameby(content, by) {
  return {
    type: 'nameby',
    attributes: [
      {
        content: content,
        by: by
      },
    ],
  };
}
function generateText(text) {
  return {
    type: 'text',
    attributes: [
      {
        content: text,
      },
    ],
  };
}
function generateCTA(attributes = []) {
  return {
    type: 'cta',
    attributes,
  };
}
function generateCTACommon(view_applicant, editURL, closeAction) {
  return {
    type: 'cta',
    attributes: [
      {
        content: 'View Applicants',
        to: view_applicant,
      },
      {
        content: 'Edit',
        to: editURL,
      },
      {
        content: 'Close',
        action: closeAction,
      },
    ],
  };
}

function generateCTAAttribute(label, link, isAction = false) {
  return { content: label, [isAction ? 'action' : 'to']: link };
}

const TABLER_UTIL = {
  generateConntent: {
    nameby: generateNameby,
    text: generateText,
    cta: generateCTA,
    ctaCreateAttributes: generateCTAAttribute,
    ctaCommon: generateCTACommon,
  },
};
export default TABLER_UTIL;
