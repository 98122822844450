import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../Store';
import { getUser } from '../../utils/helpers';
import {
  UPDATE_PAYMENT_METHOD,
  UPDATE_PROFILE,
  UPDATE_TEAM_PAYMENT_METHOD,
  UPDATE_TEAM_PROFILE,
} from '../../graphql';
import { useMutation } from '@apollo/react-hooks';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

export default function useBilling() {
  const [state] = useContext(AppContext);
  const [billingDetails, setBillingDetails] = useState();
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingBilling, setLoadingBilling] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [error, setError] = useState(null);

  const isCompany = useMemo(() => {
    return getUser()?.type === 'COMPANY';
  }, []);

  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [updateTeamProfile] = useMutation(UPDATE_TEAM_PROFILE);
  const [updateTeamPaymentMethod] = useMutation(UPDATE_TEAM_PAYMENT_METHOD);

  const showError = useCallback(() => {
    return toast.error(error?.message ?? error, {
      toastId: 'startSubscription',
    });
  }, [error]);

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error, showError]);

  const handleUpdateAddress = async (event) => {
    event.preventDefault();

    setLoadingAddress(true);

    if (isCompany) {
      await updateProfile({
        variables: {
          input: {
            billing_address_1: billingDetails.address.line1,
            billing_address_2: billingDetails.address.line2,
            billing_city: billingDetails.address.city,
            billing_state: billingDetails.address.state,
            billing_country: billingDetails.address.country,
            billing_zip: billingDetails.address.postal_code,
          },
        },
      }).then(() => {
        setLoadingAddress(false);
        toast.dismiss();
        toast.success('Your payment info has been updated!', {
          autoClose: 4000,
        });
      });
    } else if (getUser()?.type === 'GUEST') {
      await updateTeamProfile({
        variables: {
          input: {
            billing_address_1: billingDetails.address.line1,
            billing_address_2: billingDetails.address.line2,
            billing_city: billingDetails.address.city,
            billing_state: billingDetails.address.state,
            billing_country: billingDetails.address.country,
            billing_zip: billingDetails.address.postal_code,
          },
          teamId: Number(state?.activeCompany?.team_id),
        },
      }).then(() => {
        setLoadingAddress(false);
        toast.dismiss();
        toast.success('Your payment info has been updated!', {
          autoClose: 4000,
        });
      });
    }

    setLoadingAddress(false);
    return true;
  };

  const handleOnSaveBilling = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return false;
    }
    if (error) {
      // showError(error);
      return false;
    }

    if (cardComplete) {
      setLoadingBilling(true);
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpire = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    try {
      // Use your card Element with other Stripe.js APIs
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: billingDetails,
      });
      // let res = false;

      if (payload.error) {
        setError(payload.error);
        setLoadingBilling(false);
        throw Error('payload_error');
      } else {
        if (isCompany) {
          await updatePaymentMethod({
            variables: {
              input: { payment_method_id: payload.paymentMethod.id },
            },
          })
            .then(() => {
              setLoadingBilling(false);
              toast.dismiss();
              toast.success('Your payment info has been updated!', {
                autoClose: 4000,
              });
              cardNumber.clear();
              cardExpire.clear();
              cardCvc.clear();

              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // res = true;
            })
            .catch((e) => {
              // setError(e.error);
              setLoadingBilling(false);
              setError('Failed to update team payment method');
              // res = false;
            });
          // return res;
        } else if (getUser()?.type === 'GUEST') {
          await updateTeamPaymentMethod({
            variables: {
              input: {
                payment_method_id: payload.paymentMethod.id,
              },
              teamId: Number(state?.activeCompany?.team_id),
            },
          })
            .then(() => {
              setLoadingBilling(false);
              toast.dismiss();
              toast.success('Your payment info has been updated!', {
                autoClose: 4000,
              });
              cardNumber.clear();
              cardExpire.clear();
              cardCvc.clear();

              // return history.push(`/${LINK_GROUP.COMPANY}/dashboard`);
              return true;
            })
            .catch((e) => {
              setError('Failed to update team payment method');
              setLoadingBilling(false);
              return false;
            })
            .finally(() => {
              setLoadingBilling(false);
            });
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      setLoadingBilling(false);
      setError('Failed to update team payment method');
      return false;
    }
  };

  const onChangeBillingAddress = useCallback(({ currentTarget: input }) => {
    setBillingDetails((_p) => {
      return {
        ..._p,
        address: {
          ..._p.address,
          [input.name]: input.value,
        },
      };
    });
  }, []);

  useEffect(() => {
    setBillingDetails({
      address: {
        line1:
          state?.activeCompany?.user?.billing_address_1 ??
          state?.activeCompany?.user?.address_1,
        line2:
          state?.activeCompany?.user?.billing_address_2 ??
          state?.activeCompany?.user?.address_2,
        city:
          state?.activeCompany?.user?.billing_city ??
          state?.activeCompany?.user?.city,
        state:
          state?.activeCompany?.user?.billing_state ??
          state?.activeCompany?.user?.state,
        postal_code:
          state?.activeCompany?.user?.billing_zip ??
          state?.activeCompany?.user?.zip,
        country: 'GB',
      },
    });
  }, [state]);

  return {
    billings: billingDetails,
    isCompany,
    handleUpdateAddress,
    handleOnSaveBilling,
    savingAddress: loadingAddress,
    savingBilling: loadingBilling,
    stripe,
    stripeElements: elements,
    setCardComplete,
    setError,
    onChangeBillingAddress,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
  };
}
