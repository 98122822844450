import gql from 'graphql-tag';

export const ADD_OPPORTUNITY = gql`
  mutation AddOpportunity($input: OpportunityCreate!) {
    createOpportunity(input: $input) {
      id
      title
    }
  }
`;

export const DELETE_OPPORTUNITY = gql`
  mutation DeleteOpportunity($id: ID!, $input: OpportunityDelete) {
    deleteOpportunity(id: $id, input: $input) {
      id
    }
  }
`;

export const RESTORE_OPPORTUNITY = gql`
  mutation RestoreOpportunity($id: ID!) {
    restoreOpportunity(id: $id) {
      id
    }
  }
`;

export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity($id: ID!, $input: OpportunityUpdate) {
    updateOpportunity(id: $id, input: $input) {
      id
    }
  }
`;

export const VIEW_OPPORTUNITY = gql`
  mutation ViewOpportunity($id: ID!) {
    viewOpportunity(id: $id) {
      id
      title
      description
      is_on_site
      is_remote
      location
      type
      sub_type
      level
      minimumBudget
      maximumBudget
      compensation_unit
      compensation_currency
      is_compensation_displayed
      is_public
      duration
      is_ir35
      application_text
      application_link
      created_at
      target
      genres {
        id
        name
      }
      skills {
        id
        name
      }
      services {
        id
        name
      }
      agency {
        id
        company
        avatar
      }
      applicants {
        id
        user(active: true) {
          id
        }
      }
    }
  }
`;

export const GET_TEAM_MEMBER_OPPORTUNITY = gql`
  mutation ViewTeamMemberOpportunity($userId: Int!, $teamId: Int!) {
    viewTeamMemberOpportunity(userId: $userId, teamId: $teamId) {
      agency {
        id
        avatar
        company
      }
      applicants {
        created_at
        id
        user(active: true) {
          id
          name
          email
          type
          agency_name
          contact_email
        }
        opportunity {
          deleted_at
          id
          title
        }
      }
      creator {
        id
        name
      }
      created_by
      created_at
      deleted_at
      id
      is_active
      job_number
      title
      view
      featured
      target
    }
  }
`;

export const GET_TEAM_OPPORTUNITY = gql`
  mutation ViewTeamOpportunity($teamId: Int!) {
    viewTeamOpportunity(teamId: $teamId) {
      agency {
        id
        avatar
        company
      }
      applicants {
        created_at
        id
        user(active: true) {
          id
          name
          email
          type
          agency_name
          contact_email
        }
        opportunity {
          deleted_at
          id
          title
          job_number
        }
      }
      creator {
        id
        name
      }
      created_by
      created_at
      deleted_at
      id
      is_active
      job_number
      title
      view
      featured
      target
    }
  }
`;

export const CREATE_APPLICANT = gql`
  mutation CreateApplicant($input: ApplicantCreate!) {
    createApplicant(input: $input) {
      id
    }
  }
`;
