import React, { useEffect, useRef, useState } from 'react';
import { IconUploadCloud } from './Icons';

export default function UploadNoPreview({
  onSetImage = (image) => {},
  isError,
  multiple = false,
  accept = 'image/*',
}) {
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const hiddenFileInput = useRef();

  useEffect(() => {
    if (multiple) {
      onSetImage(images);
    } else {
      onSetImage(image);
    }
  }, [image, onSetImage, images, multiple]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = (event) => {
    if (multiple) {
      onSetImage(event.target.files);
      for (let index = 0; index < event.target.files.length; index++) {
        setImages((prev) => [...prev, event.target.files[index]]);
        event.persist();
      }
    } else {
      setImage(event.target.files[0]);
      onSetImage(event.target.files[0]);
    }
  };

  return (
    <div className="field-v2__upload">
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleChangeImage}
        ref={hiddenFileInput}
        multiple={multiple}
        accept={accept}
      />

      <div
        className={`d-flex flex-col align-items-center`}
        onDragOver={handleDragOver}
        onClick={openFileInput}
      >
        <div
          className={`up-icon-cyrcle bg__gradient-1 bg-center bg-cover ${
            isError && 'bg-red bg-image-unset'
          }`}
        >
          <IconUploadCloud width={'47px'} />
        </div>
        <div
          className={`up-text txt__fs-14 text--400 ${
            isError && 'form-v2__label-error'
          }`}
        >
          Drag & Drop or{' '}
          <span className={`text--700 ${isError && 'form-v2__label-error'}`}>
            browse
          </span>{' '}
          to upload
        </div>
      </div>
    </div>
  );
}
