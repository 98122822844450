import { useLocation } from 'react-router-dom';
import { getGroupByPathname } from '../../utils/groups';
import { useMemo } from 'react';

export default function useGroups() {
  let { pathname } = useLocation();

  const groupname = useMemo(() => {
    if (typeof window !== 'undefined') {
      const first = pathname.split('/')[1];
      return (
        getGroupByPathname(first) ??
        localStorage?.getItem('loginType')?.toLowerCase()
      );
    }
  }, [pathname]);

  return { groupname };
}
