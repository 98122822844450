import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation ($input: LoginInput!) {
    login(input: $input) {
      name
      first_name
      last_name
      email
      type
      access_token
      id
      bio
      description
      company
      agency_name
      position
      address_1
      address_2
      city
      state
      country
      zip
      phone
      email
      avatar
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      teamType
      teamOwner
      isProfileComplete {
        complete
        missing
      }
      looking_for
      location
      payroll_status
      hear_us
      hear_us_additional
      ethnicity
      is_payment_active
      contact_email
      agency_location
      agency_phone {
        key
        attributes {
          phone
        }
      }
      pronouns
      language
      jobtitle
      start_date
      right_to_work
      notice_period
      vetted
    }
  }
`;

export const REGISTER = gql`
  mutation ($input: RegisterInput!) {
    register(input: $input) {
      id
      name
      first_name
      last_name
      email
      type
      access_token
      company
      position
      agency_name
      bio
      address_1
      address_2
      city
      state
      country
      zip
      phone
      email
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      isProfileComplete {
        complete
        missing
      }
      looking_for
      payroll_status
      hear_us
      hear_us_additional
      ethnicity
      location
      vetted
      agency_location
      agency_phone {
        key
        attributes {
          phone
        }
      }
    }
  }
`;

export const ADMIN_IMPERSONATE_USER = gql`
  mutation ($input: AdminImpersonateUserInput!) {
    adminImpersonateUser(input: $input) {
      name
      first_name
      last_name
      email
      type
      access_token
      bio
      description
      company
      agency_name
      position
      address_1
      address_2
      city
      state
      country
      zip
      phone
      avatar
      referee_1
      referee_2
      billing_address_1
      billing_address_2
      billing_city
      billing_state
      billing_country
      billing_zip
      privacy
      send_email
      send_text_notification
      notification_frequency
      phone_secondary
      status
      linkedin
      salary_currency
      minimum_salary
      maximum_salary
      salary_unit
      hear_us
      hear_us_additional
      isProfileComplete {
        complete
        missing
      }
      looking_for
      location
      payroll_status
      is_payment_active
      contact_email
      agency_location
      agency_phone {
        key
        attributes {
          phone
        }
      }
    }
  }
`;
