import { useQuery } from '@apollo/react-hooks';
import React, { useMemo } from 'react';
import { GET_ALL_AGENCY } from '../../../graphql';
import LoadingSection from '../LoadingSection/LoadingSection';
import { LINK_GROUP } from '../../../utils/links';
import { Button } from '../../snippets';
import { IMG_AGENCY_PLACEHOLDER } from '../../../utils/helpers';

export default function AgencyNetworkAgencyView({ filters = [] }) {
  const {
    data: { agencies = [] } = {},
    loading,
    refetch,
  } = useQuery(GET_ALL_AGENCY, {
    onError: () => refetch(),
  });

  console.log(filters);
  const agencyFilters =
    useMemo(() => {
      if (filters.length === 0) {
        return agencies;
      }
      const _temp = new Set([]);
      for (let f of filters) {
        const find = agencies.find((a) => {
          const s = a?.services?.find((n) => n.name === f);
          if (s) {
            return true;
          }
          return false;
        });
        if (find) {
          _temp.add(find);
        }
      }

      return Array.from(_temp);
    }, [filters, agencies]) ?? [];

  if (loading) {
    return <LoadingSection />;
  }

  return (
    <>
      <div className="panel-back panel-transparent">
        <div className="agency-list-view">
          {agencyFilters?.map(
            ({ avatar, id, agency_name: name, skills, city, country }) => {
              return (
                <div className="agency-list--item" key={id}>
                  <div className="list-item--meta-media">
                    <div className="featured-image--frame">
                      <img
                        src={avatar || IMG_AGENCY_PLACEHOLDER}
                        alt=""
                        className="featured-image--asset"
                      />
                    </div>
                  </div>
                  <div className="list-item--meta-information">
                    <div className="list-item--meta-name-location">
                      <div className="list-item--meta-name">{name}</div>
                      <div className="list-item--meta-location">
                        {city}, {country}
                      </div>
                    </div>
                    <div className="list-item--meta-skils">
                      {skills?.map((s) => (
                        <span key={s.name}>{s.name}</span>
                      ))}
                    </div>
                    <div className="list-item--meta-cta">
                      <Button
                        as="a"
                        to={`/${LINK_GROUP.EMPLOYER}/agency-profile/${id}`}
                        size="xxl"
                        variant="smaller w-700"
                        uppercase
                      >
                        View Profile
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
}
