import React, { useCallback, useEffect, useState } from 'react';
import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  Separator,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';

export default function Wysiwyg({
  name,
  defaultValue = '',
  onChange,
  maxLength = 1000,
  onlyTextFormating = false,
  className = '',
}) {
  const [content, setContent] = useState(defaultValue ?? '');
  const [contentLength, setContentLength] = useState(
    content.replace(/(&nbsp;|<([^>]+)>)/gi, '').length
  );

  const handleOnChange = (e) => {
    let currentContent = e.target.value;
    if (e.nativeEvent.inputType === 'insertFromPaste') {
      const regex = /(<([^>]+)>)/gi;
      currentContent = currentContent.replace(regex, '');
    }

    const currentContentLength = currentContent.replace(
      /(&nbsp;|<([^>]+)>)/gi,
      ''
    ).length;

    if (maxLength === 0) {
      setContent(currentContent);
      onChange(content);
    }

    if (currentContentLength <= maxLength) {
      setContent(currentContent);
      setContentLength(currentContentLength);
      onChange(currentContent);
    }
  };

  const formatsBar = useCallback(() => {
    let allowed = [
      <BtnBold key={'bold'} />,
      <BtnItalic key={'italic'} />,
      <BtnUnderline key={'underline'} />,
    ];

    if (!onlyTextFormating) {
      allowed = [
        ...allowed,
        <Separator key={'separator_1'} />,
        <BtnBulletList key={'bullet_list'} />,
        <BtnNumberedList key={'numbered_list'} />,
      ];
    }
    return allowed;
  }, [onlyTextFormating]);

  useEffect(() => {
    if (defaultValue && !content) {
      const valueLength = defaultValue.replace(
        /(&nbsp;|<([^>]+)>)/gi,
        ''
      ).length;
      setContent(defaultValue);
      setContentLength(valueLength);
    }
  }, [defaultValue, content]);

  return (
    <>
      <EditorProvider>
        <Editor
          name={name}
          value={content}
          onChange={handleOnChange}
          className={'field-input ' + className}
        >
          <Toolbar>{formatsBar()}</Toolbar>
        </Editor>
      </EditorProvider>
      {maxLength !== 0 && (
        <div className="field-add-ons">
          <div className="help-text">{<span>{contentLength}</span>} / 1000</div>
        </div>
      )}
    </>
  );
}
