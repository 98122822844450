import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import { LoadingSection, SectionViewOtherProfile } from '../../../components';
import { GET_USER } from '../../../../graphql';
import { useQuery } from '@apollo/react-hooks';
import CONTENTS_UTIL from '../../../../utils/contents';
import {
  TYPE_JOBTYPES,
  TYPE_SUBJOBTYPES,
  TYPE_WORK_LOCATION,
} from '../../../placeholders/consultant/types';
import { Button, Text } from '../../../snippets';
import queryString from 'query-string';

const br =
  '<br style="content: \'A\' !important; display: block !important; margin-bottom: 0.5rem !important;" />';

export default function ConsultantProfilePage({
  match: {
    params: { id },
  },
  ...props
}) {
  const parsedQuery = queryString.parse(props.location.search);

  const {
    data: { user } = {},
    loading,
    refetch,
  } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onError: () => refetch(),
  });
  const [userLocation, setUserLocation] = useState('');

  useEffect(() => {
    setUserLocation(
      `${user?.city}, ${user?.country} ${
        user?.location
          ? `/ ${
              TYPE_WORK_LOCATION.find(({ value }) => value === user?.location)
                ?.label ?? 'On site / Remote / Hybrid'
            }`
          : ''
      }`
    );
  }, [user]);

  const contents = useMemo(() => {
    if (!user) {
      return;
    }
    const {
      name,
      email,
      skills,
      jobTypes,
      location,
      linkedin,
      resumes,
      supportingDocuments,
      bio,
      genres,
      qualifications,
      otherQualifications,
    } = user;
    const softwareSkills = skills.filter(({ software }) => software) ?? [];
    const otherSkills = skills.filter(({ software }) => !software) ?? [];
    let types = [],
      workHour = [];

    if (jobTypes.length > 0) {
      const firstJobType = TYPE_JOBTYPES.filter((j) =>
        jobTypes.map(({ key }) => key).includes(j.key)
      );
      const secondJobType = TYPE_SUBJOBTYPES.filter((j) =>
        jobTypes.map(({ key }) => key).includes(j.key)
      );

      if (firstJobType.length > 0) {
        if (firstJobType[0]?.name === 'Both') {
          types = ['Permanent', 'Freelance / Consultancy'];
        } else {
          types = [firstJobType[0].name];
        }
      }

      if (secondJobType.length > 0) {
        if (secondJobType[0]?.name === 'Both') {
          workHour = ['Full-time', 'Part-time'];
        } else {
          workHour = [secondJobType[0].name];
        }
      }
    }

    return {
      desktop: CONTENTS_UTIL.generateDetails(
        [
          {
            title: 'Contant',
            button: (
              <Button
                scheme="secondary"
                className="form__btn btn-v2 mt-0"
                style={{ backgroundImage: '' }}
                onClick={() => {
                  window.location.href = `mailto:${email}`;
                }}
              >
                Contact
              </Button>
            ),
          },
          otherSkills?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Skills',
                otherSkills?.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          softwareSkills?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Software Skills',
                softwareSkills?.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          CONTENTS_UTIL.generateRowDetails(
            'Considering',
            types?.length > 0 ? types.join(br) : '-'
          ),
          CONTENTS_UTIL.generateRowDetails(
            'Work Hours',
            workHour?.length > 0 ? workHour.join(br) : '-'
          ),
          location?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Job Location',
                TYPE_WORK_LOCATION.find(({ value }) => value === location)
                  ?.label
              )
            : null,
          linkedin
            ? CONTENTS_UTIL.generateRowDetails(
                'LinkedIn',
                `<a href=${linkedin} target="_blank">View LinkedIn</a>`
              )
            : null,
          resumes?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'CV',
                `<a href=${resumes[0].fileUrl} target="_blank">View CV</a>`
              )
            : null,
          supportingDocuments?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Supporting Documents',
                supportingDocuments
                  .map(
                    ({ fileUrl, name }) =>
                      `<a href=${fileUrl} target="_blank">${name}</a>`
                  )
                  .join(br)
              )
            : null,
        ],
        [
          CONTENTS_UTIL.generateRowDetails(`About ${name}`, bio ?? '-'),
          genres?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Category Experience',
                genres.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          qualifications?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Qualifications',
                qualifications.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : 'null',
          otherQualifications?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Other Qualification',
                otherQualifications.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
        ]
      ),
      mobile: CONTENTS_UTIL.generateDetails(
        [
          CONTENTS_UTIL.generateRowDetails(`About ${name}`, bio ?? '-'),
          {
            title: 'Contant',
            button: (
              <Button
                scheme="secondary"
                className="form__btn btn-v2 mt-0"
                style={{ backgroundImage: '' }}
                onClick={() => {
                  window.location.href = `mailto:${email}`;
                }}
              >
                Contact
              </Button>
            ),
          },
          otherSkills?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Skills',
                otherSkills?.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          softwareSkills?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Software Skills',
                softwareSkills?.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          genres?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Category Experience',
                genres.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          qualifications?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Qualifications',
                qualifications.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
          otherQualifications?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Other Qualification',
                otherQualifications.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
        ],
        [
          CONTENTS_UTIL.generateRowDetails(
            'Considering',
            types?.length > 0 ? types.join(br) : '-'
          ),
          CONTENTS_UTIL.generateRowDetails(
            'Work Hours',
            workHour?.length > 0 ? workHour.join(br) : '-'
          ),
          location?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Job Location',
                TYPE_WORK_LOCATION.find(({ value }) => value === location)
                  ?.label
              )
            : null,
          linkedin
            ? CONTENTS_UTIL.generateRowDetails(
                'LinkedIn',
                `<a href=${linkedin} target="_blank">View LinkedIn</a>`
              )
            : null,
          resumes?.length
            ? CONTENTS_UTIL.generateRowDetails(
                'CV',
                `<a href=${resumes[0].fileUrl} target="_blank">View CV</a>`
              )
            : null,
          supportingDocuments?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Supporting Documents',
                supportingDocuments
                  .map(
                    ({ fileUrl, name }) =>
                      `<a href=${fileUrl} target="_blank">${name}</a>`
                  )
                  .join(br)
              )
            : null,
        ]
      ),
    };
  }, [user]);

  return (
    <DefaultLayout showBackButton={true} backButtonUrl={parsedQuery.back}>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionViewOtherProfile
          profileImage={
            user?.avatar || '/img/agency-picture-placeholder@2x.jpg'
          }
          imageRounded
          headline={user?.name}
          pronouns={user?.pronouns}
          subTitle={userLocation}
          desktop={contents?.desktop}
          mobile={contents?.mobile}
          vetted={user?.vetted}
        />
      )}
    </DefaultLayout>
  );
}
