import React from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
  Link,
} from '../../snippets';
import { overwrite, getNames } from 'country-list';

export default function TeamRegisterLayers2({
  heading = 'Personal Information',
  firsLastNameInline = false,
  companyOptional = false,
  positionOptional = false,
  forms = {
    errors: {},
    onSubmit: () => {},
    register: null,
    clearErrors: () => {},
    loading: false,
    input: {},
    setPhoneError: () => {},
    setPhoneNo: () => {},
    phoneError: false,
    tncError: false,
  },
}) {
  overwrite([
    { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' },
  ]);
  const countries = getNames().sort();
  const {
    errors,
    onSubmit,
    register,
    clearErrors,
    loading,
    input = {},
    setPhoneError,
    setPhoneNo,
    phoneError,
    tncError,
  } = forms || {};
  return (
    <form onSubmit={onSubmit}>
      <Heading as="h3" className="text-center mb-4">
        {heading}
      </Heading>
      <FormBlock>
        <FormBlockItem label={'First Name'}>
          <FormInput
            name="first_name"
            type="text"
            isRequired
            register={register}
            defaultValue={input?.first_name || ''}
            error={errors.first_name ? 'Please enter first name' : null}
            clearErrors={clearErrors}
          />
        </FormBlockItem>
        <FormBlockItem label={'Last Name'}>
          <FormInput
            name="last_name"
            type="text"
            isRequired
            register={register}
            error={errors.last_name ? 'Please enter last name' : null}
            defaultValue={input?.last_name || ''}
            clearErrors={clearErrors}
          />
        </FormBlockItem>
      </FormBlock>

      <FormBlock className={'space-t'}>
        <FormBlockItem
          label={'Employer Name' + (companyOptional ? ' (optional)' : '')}
        >
          <FormInput
            type="text"
            register={register}
            name="company"
            isRequired={!companyOptional}
            error={errors.company ? `Please enter employer name` : null}
            defaultValue={input?.name || ''}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem
          label={'Position' + (positionOptional ? ' (optional)' : '')}
        >
          <FormInput
            name="position"
            type="text"
            isRequired={!positionOptional}
            register={register}
            error={errors.position ? 'Please enter last name' : null}
            defaultValue={input?.position || ''}
            clearErrors={clearErrors}
          />
        </FormBlockItem>

        <FormBlockItem label={'Address Line 1'}>
          <FormInput
            type="text"
            register={register}
            name="address_1"
            isRequired
            error={errors.address_1 ? `Please enter an address` : null}
            defaultValue={input.address_1}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'Address Line 2'}>
          <FormInput
            type="text"
            register={register}
            name="address_2"
            defaultValue={input.address_2}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'City'}>
          <FormInput
            type="text"
            register={register}
            name="city"
            isRequired
            error={errors.city ? `Please enter city` : null}
            defaultValue={input.city}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'County'}>
          <FormInput
            type="text"
            register={register}
            name="state"
            defaultValue={input.state}
            isRequired
            error={errors.county ? `Please enter county` : null}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>
      </FormBlock>

      <FormBlock isInline={firsLastNameInline} className={'space-t'}>
        <FormBlockItem label={'Postcode / Zip Code'}>
          <FormInput
            type="text"
            register={register}
            name="zip"
            defaultValue={input.zip}
            isRequired
            error={errors.zip ? `Please enter postcode / zip code` : null}
            clearErrors={clearErrors}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem label={'Country'}>
          <FormInput
            type="select"
            register={register}
            name="country"
            defaultValue={input.country ?? 'United Kingdom'}
            isRequired
            error={errors.country ? `Please select a country` : null}
            options={countries.map((country) => ({
              value: country,
            }))}
          ></FormInput>
        </FormBlockItem>
      </FormBlock>

      <FormBlock className={'space-t'}>
        <FormBlockItem label={'Phone Number'}>
          <FormInput
            type="phone"
            isRequired
            register={register}
            defaultValue={input.phone}
            error={phoneError ? `00 0000 000 00` : null}
            name="phone"
            setPhoneNo={setPhoneNo}
            setPhoneError={setPhoneError}
          ></FormInput>
        </FormBlockItem>

        <FormBlockItem>
          <FormInput
            type="checkbox"
            register={register}
            defaultValue={input.agree}
            error={tncError ? `You must accept the terms and conditions` : null}
            name="tnc"
            checkboxChildren={
              <div className="tnc">
                I agree to the{' '}
                <Link
                  to={`${process.env.REACT_APP_API_URL}/pages/company-terms-conditions`}
                  external
                >
                  Terms and Conditions
                </Link>
              </div>
            }
          ></FormInput>
        </FormBlockItem>
      </FormBlock>
      <FormCTA className={'space-t'}>
        <Button
          className="app-btn-submit"
          type="submit"
          disabled={loading}
          size="xl"
        >
          {loading ? 'Loading...' : 'Next'}
        </Button>
      </FormCTA>
    </form>
  );
}
