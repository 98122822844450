import React, { useState, useEffect, useContext } from 'react';
import { getUser } from '../../../utils/helpers';
import { AppContext } from '../../../Store';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import Cookies from 'universal-cookie';
import 'reactjs-popup/dist/index.css';

const Profile = ({ teams }) => {
  const [associatedTeams, setAssociatedTeams] = useState([]);
  const [state, setState] = useContext(AppContext);
  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    if (teams !== undefined && teams.length > 0) {
      setAssociatedTeams(teams);
    }
  }, [teams]);

  return (
    <Popup
      trigger={
        <img
          src={
            state?.activeCompany?.user?.avatar ||
            '/img/profile-picture-placeholder@2x.jpg'
          }
          alt="Profile"
          style={{
            borderRadius: '50%',
            height: '35px',
            width: '35px',
            marginLeft: '16px',
          }}
        />
      }
      position={['bottom left']}
      closeOnDocumentClick
      arrowStyle={{
        display: 'none',
      }}
    >
      <div
        style={{
          minHeight: '300px',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              textAlign: 'center',
            }}
          >
            <span style={{ fontSize: '14px' }}>
              {state?.activeCompany?.user?.company}
            </span>
          </div>
          <div>
            <img
              src={
                state?.activeCompany?.user?.avatar ||
                '/img/profile-picture-placeholder@2x.jpg'
              }
              alt="Profile"
              style={{
                borderRadius: '50%',
                height: '60px',
                width: '64px',
                marginBottom: 10,
                marginTop: 10,
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {getUser()?.name}
            </span>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: 'gray',
              }}
            >
              {/* {getUser()?.email} */}
            </span>
          </div>
          <div
            style={{
              marginTop: '15px',
              height: '15px',
              width: '100%',
              borderBottomColor: 'lightgray',
              borderBottomWidth: '2px',
              borderBottomStyle: 'solid',
            }}
          />
          <div
            style={{
              borderTopColor: 'red',
              borderTopWidth: '2px',
              paddingTop: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                fontSize: '11px',
                fontWeight: '700',
                marginLeft: '7px',
                marginBottom: '5px',
                color: '#4a4747',
              }}
            >
              Other Teams
            </span>
            {associatedTeams.map((item, index) => (
              <div key={index}>
                {state?.activeCompany?.team_id !== item?.team_id && (
                  <div
                    style={{ background: 'transparent', border: 'none' }}
                    onClick={() => {
                      setState({ ...state, activeCompany: item });
                      localStorage.setItem('active_company', index);
                      cookies.set('active_company', index, {
                        path: '/',
                        domain: process.env.REACT_APP_COOKIE_DOMAIN,
                      });
                      history.push('/');
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ minWidth: '39px' }}>
                        <img
                          src={
                            item?.user?.avatar ||
                            '/img/profile-picture-placeholder@2x.jpg'
                          }
                          alt="Profile"
                          style={{
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                          }}
                        />
                      </div>
                      <div style={{ textAlign: 'left' }}>
                        <span>{item?.user?.company}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default Profile;
