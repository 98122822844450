import React from 'react';
import { Leading, Text } from '../../snippets';
import { generateClassName } from '../../../utils/helpers';

export default function ProfileDetails({
  contents,
  className,
  children,
  leftSideFull = false,
}) {
  return (
    <div className={generateClassName(['profile-details--grid', className])}>
      <div className="profile-details--grid-item" data-full-left={leftSideFull}>
        {contents?.left
          ?.filter((c) => c !== null)
          .map(({ title, value, type = 'text', maxWidth }) => (
            <div
              className="meta-item"
              key={title}
              data-max-width={maxWidth ? maxWidth : ''}
            >
              <Leading>{title}</Leading>
              {type === 'component' ? (
                <>{value()}</>
              ) : (
                <Text
                  as="div"
                  dangerouslySetInnerHTML={{ __html: value }}
                ></Text>
              )}
            </div>
          ))}
        {children}
      </div>
      {contents?.right && contents.right.length > 0 && (
        <div className="profile-details--grid-item">
          {contents?.right
            ?.filter((c) => c !== null)
            .map(({ title, value, type = 'text', maxWidth }) => (
              <div
                className="meta-item "
                key={title}
                data-max-width={maxWidth ? maxWidth : ''}
              >
                <Leading>{title}</Leading>
                {type === 'component' ? (
                  <>{value()}</>
                ) : (
                  <Text
                    as="div"
                    dangerouslySetInnerHTML={{ __html: value }}
                  ></Text>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
