import React, { Fragment } from 'react';
import { BackPanel, Heading, Link, Button } from '../../snippets';
import useGroups from '../../hooks/useGroups';
import { generateAssetImageURL } from '../../../utils/links';
import OpportunitiesCard from '../OpportunitiesForYou';
import { IMG_AGENCY_PLACEHOLDER } from '../../../utils/helpers';

export default function GridJobs({
  heading = 'Job Opportunities Just For You',
  type = 'recommendation',
  viewMoreUrl = null,
  buttonCopy = 'View More',
  data = [],
  user,
}) {
  const { groupname } = useGroups();

  if (data.length === 0) {
    return null;
  }

  return (
    <BackPanel>
      <div className="gridjobs-header">
        <Heading>{heading}</Heading>
        {viewMoreUrl && (
          <div className="app-hide-on-mobile">
            <Button
              as="link"
              to={viewMoreUrl}
              className="app-btn app-btn-primary app-btn--dashboard"
              isCapitalize
            >
              {buttonCopy}
            </Button>
          </div>
        )}
      </div>
      <div className={`gridjobs-grid${type === 'deals' ? ' type--deals' : ''}`}>
        {data.map((d, i) => {
          return (
            <Fragment key={i}>
              {type === 'opportunities' ? (
                <OpportunitiesCard {...d} user={user} key={i} />
              ) : (
                <div
                  className={`gridjobs-grid--item${
                    type === 'deals' ? ' type--deals' : ''
                  }`}
                  key={i}
                >
                  <Link
                    to={d.url}
                    className="company-logo"
                    external={d.externalLink ?? false}
                  >
                    <img
                      src={d.img === '' ? IMG_AGENCY_PLACEHOLDER : d.img}
                      alt=""
                    />
                  </Link>
                  <div className="grid-divider"></div>
                  <div className="company-info">
                    <div className="company-info--header">
                      <Link
                        to={d.url}
                        className="company-info--header_headline"
                        external={d.externalLink ?? false}
                      >
                        {d.headline.length > 25
                          ? `${d.headline.substring(0, 25)}...`
                          : d.headline}
                      </Link>
                      <div className="company-info--header_info">{d.info}</div>
                    </div>
                    <div className="company-info--time">
                      {type === 'deals' ? (
                        <Link
                          external={d.externalLink ?? false}
                          href={d.url}
                          className="app-btn-deals"
                        >
                          {groupname !== 'agency' ? (
                            <img
                              src={generateAssetImageURL('find-out-more.png')}
                              alt=""
                            />
                          ) : (
                            <p
                              style={{ marginBottom: 0 }}
                              className={'text_withbg'}
                              data-text="Find Out More"
                            >
                              Find Out More
                            </p>
                          )}
                        </Link>
                      ) : (
                        <div>
                          {d.since}
                          {d.status ? (
                            <p className="mb-0">
                              <b>{d.status}</b>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
        {viewMoreUrl && (
          <div className="app-hide-on-desktop w-full">
            <div className="gridjobs-cta">
              <a
                href={viewMoreUrl}
                className="app-btn app-btn-primary app-btn--dashboard"
              >
                {buttonCopy}
              </a>
            </div>
          </div>
        )}
      </div>
    </BackPanel>
  );
}
