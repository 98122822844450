import React from 'react';
import { useAppTheme } from '../../context/themeAppContext';
import ButtonClose from '../ButtonClose/ButtonClose';

export default function AppModal({ heading }) {
  const {
    action: { setCloseModal },
    state: {
      modal: { open, render },
    },
  } = useAppTheme();

  if (open) {
    return (
      <div className="app-modal">
        <div className="app-modal--mask" onClick={setCloseModal}></div>
        <div className="app-modal--container">
          <div className="app-modal--container--header">
            <ButtonClose clean onClick={setCloseModal} light />
          </div>
          {render()}
        </div>
      </div>
    );
  }
  return null;
}
