import { generateAssetImageURL, LINK_GROUP } from './links';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Cookies from 'universal-cookie';

dayjs.extend(relativeTime);

export const logUserOut = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('loginType');
};

export const getLoginUrl = (type, prev) => {
  let url;

  switch (type) {
    case 'FREELANCER':
      url = `/${LINK_GROUP.CANDIDATE}/login?redirectTo=${prev}`;
      break;
    case 'GUEST':
      url = `/${LINK_GROUP.AGENCY}/login?redirectTo=${prev}`;
      break;
    case 'COMPANY':
      url = `/${LINK_GROUP.EMPLOYER}/login?redirectTo=${prev}`;
      break;
    case 'AGENCY':
    default:
      url = `/${LINK_GROUP.AGENCY}/login?redirectTo=${prev}`;
      break;
  }

  return url;
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  if (!user) return {};
  try {
    return JSON.parse(user);
  } catch {
    console.error(
      'Failed to JSON.Parse user from local storage, received: ',
      user
    );
    return {};
  }
};

export const saveUserLocalstorage = (user) =>
  localStorage.setItem('user', JSON.stringify(user));

export const getLoginType = () => {
  const loginType = localStorage.getItem('loginType');
  if (!loginType) return {};
  try {
    return loginType;
  } catch {
    console.error(
      'Failed to JSON.Parse loginType from local storage, received: ',
      loginType
    );
    return {};
  }
};

export const saveLoginTypeLocalstorage = (loginType) => {
  let type;

  if (loginType === LINK_GROUP.COMPANY.toUpperCase()) {
    type = LINK_GROUP.EMPLOYER;
  } else if (loginType === LINK_GROUP.FREELANCER.toUpperCase()) {
    type = LINK_GROUP.CANDIDATE;
  } else {
    type = loginType;
  }

  localStorage.setItem('loginType', type);
};

export const formatValidationErrors = (errObj) => {
  let errors = '';
  Object.values(errObj).forEach((error) => {
    errors = `${errors} ${error}`;
  });
  return errors;
};

export const getId = (arr, value) => {
  let id = null;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].name === value) {
      id = arr[i].id;
      break;
    }
  }
  return id || false;
};

export const getIds = (arr) => {
  const ids = [];
  if (arr.length > 0) {
    arr.map((item) => ids.push(Number(item.id)));
  }
  return ids;
};

export const isValidDocument = (file, callback) => {
  if (!file || !file.name.toLowerCase().match(/\.(doc|docx|pdf)$/)) {
    return callback(false);
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);

  return callback({ url: URL.createObjectURL(file), file });
};

export const isValidImage = (file, callback) => {
  const reader = new FileReader();

  if (!file || !file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
    return callback(false);
  }

  reader.onload = (e) => {
    const imgFile = new Image();
    imgFile.onerror = () => {
      callback(false);
    };
    imgFile.src = e.target.result;
  };
  reader.readAsDataURL(file);

  return callback({ url: URL.createObjectURL(file), file });
};

export const formatDate = (date) => {
  let days = date.split(' ').shift();
  days = days === 'a' ? '1' : days;
  return `${days} ${date.split(' ').pop()}`;
};

export const getOpportunityGenreOrSkillNames = (arr) => {
  const nameArr = [];
  arr.map((item) => nameArr.push(item.name));
  return nameArr.join(', ');
};

export const getNameArr = (arr) => {
  const nameArr = [];
  arr.map(({ id }) => nameArr.push(id));
  return nameArr;
};

export const getOpportunityLocation = (isOnsite, isRemote, location) => {
  let result = '';
  if (isOnsite && isRemote) {
    result = 'Hybrid';
  } else if (isOnsite && !isRemote) {
    result = 'Onsite';
  } else if (!isOnsite && isRemote) {
    result = 'Remote';
  }

  return `${result} ${location ? `(${location})` : ''}`;
};

export const capitalizeFirstLetter = (str) => {
  str = str.toLowerCase().replace(/_/gi, ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const inputProps = {
  name: 'phone',
  required: true,
  autoFocus: false,
};

export const inputStyle = {
  width: '100%',
  height: '43px',
  outline: 'none',
  border: 'none',
  borderBottom: '1px solid black',
  paddingLeft: '80px',
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};
export const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const reactTagsFreelancerStyles = {
  tagInputField: 'form__input__search',
  tag: 'freelancer-tag',
  activeSuggestion: 'freelancer-active-suggestion',
};

export const arrToDropdownSelection = (arr) => {
  const selectionArr = [];
  arr.map(({ id, name }) => selectionArr.push({ key: id, label: name }));
  return selectionArr;
};

export const arrToDropdownSelectionV2 = (arr) => {
  const selectionArr = [];
  arr.map(({ id, name }) => selectionArr.push({ value: id, label: name }));
  return selectionArr;
};

export const arrToDropdownSelectionValueOnly = (arr) => {
  const selectionArr = [];
  arr.map(({ name }) => selectionArr.push({ value: name }));
  return selectionArr;
};

export const getIdsFromSelection = (arr, selection) => {
  const idArr = [];
  selection.forEach((item) => {
    idArr.push(getId(arr, item.label));
  });
  return idArr;
};

export const userHasApplied = (applicants) => {
  const applicant = applicants.find(
    (applicant) =>
      parseInt(applicant.user.id) === parseInt(getUser().id) ||
      parseInt(applicant.user.id) === parseInt(getUser().teamOwner)
  );
  return typeof applicant !== 'undefined';
};

export const clone = (items) =>
  items.map((item) => (Array.isArray(item) ? clone(item) : item));

export const localPrevLink = {
  setLink: (item) => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    localStorage.setItem('prevLink', item);
  },
  getLink: () => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    return localStorage.getItem('prevLink') || null;
  },
  removeLink: () => {
    if (typeof localStorage === 'undefined') {
      return null;
    }
    return localStorage.removeItem('prevLink');
  },
};

export const jobLevelsMultiSelectStyle = {
  chips: {
    background: '#237B9F',
  },
  searchBox: {
    border: '1px solid #aaa',
    borderRadius: '0px',
  },
  multiselectContainer: {
    color: '#237B9F',
  },
};

export const IMG_PLACEHOLDER = '/img/profile-picture-placeholder@2x.jpg';
export const IMG_AGENCY_PLACEHOLDER = generateAssetImageURL(
  'agency-placeholder-square.jpg'
);

export function generateClassName(args = []) {
  return args?.filter((a) => a && (a !== '' || a !== ' ')).join(' ');
}

export function humanReadableTime(dateTime) {
  if (!dateTime) {
    return 'Datetime was not suplied!';
  }
  return formatDate(dayjs(dateTime, 'YYYY-MM-DD HH:mm:ss').fromNow(true));
}

export function checkAndModifyPhoneNumber(phoneNumber) {
  // Check if there is more than one '+' character
  if ((phoneNumber.match(/\+/g) || []).length > 1) {
    // Remove the first two characters
    return phoneNumber.substring(2);
  }
  return phoneNumber;
}

export function handleClickOutside(event, ref, onSet) {
  if (ref.current && !ref.current.contains(event.target)) {
    onSet(false);
  }
}

export const availabilityByGroupname = {
  [LINK_GROUP.AGENCY]: LINK_GROUP.AGENCY.toUpperCase(),
  [LINK_GROUP.COMPANY]: LINK_GROUP.AGENCY.toUpperCase(),
  [LINK_GROUP.FREELANCER]: LINK_GROUP.FREELANCER.toUpperCase(),
};

const cookies = new Cookies();
const storageActiveCompanyName = 'active_company';
const getStorageData = () => {
  let data = cookies.get(storageActiveCompanyName, {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  if (!data) {
    data = localStorage.getItem(storageActiveCompanyName);
    if (data) {
      data = JSON.parse(data);
    }
  }

  return data;
};

const getStorageActiveCompany = (userID) => {
  let activeCompany = getStorageData();

  if (activeCompany) {
    let data = activeCompany;
    if (typeof data !== 'object') {
      data = JSON.parse(data);
      if (!data.team_id) {
        return {};
      }
      return null;
    }

    const find = data?.find((d) => Number(d.userID) === Number(userID));

    return find ?? null;
  }
  return null;
};

export const CompanyBrowserStorage = {
  name: storageActiveCompanyName,
  set: (userID, teamID, activeLog = 'noLog') => {
    let state = getStorageData() || [];
    const data = getStorageActiveCompany(userID);

    if (typeof state === 'string') {
      state = [];
    }

    if (data?.userID) {
      state = state.filter((s) => Number(s.userID) !== Number(userID));
    }

    state.push({ userID, teamID });

    const _storeObject = JSON.stringify(state);

    localStorage.setItem(storageActiveCompanyName, _storeObject);

    cookies.set(storageActiveCompanyName, _storeObject, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  },

  getActiveCompanyID: (userID) => {
    const data = getStorageActiveCompany(userID);
    if (data) {
      return data.teamID;
    }
    return null;
  },

  getActiveCompany: (userID, teams = []) => {
    if (teams.length === 0) {
      return null;
    }

    const getTeamID = getStorageActiveCompany(userID)?.teamID || null;

    if (getTeamID) {
      return (
        teams?.find((t) => Number(t.team_id) === Number(getTeamID)) ?? null
      );
    }

    return null;
  },

  delete: () => {
    localStorage.removeItem(storageActiveCompanyName);
    cookies.remove(storageActiveCompanyName, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  },
};

export const getRandomGradient = (groupName) => {
  const colors = {
    agency: ['#9C6AF9', '#FA8282', '#F9DA73'],
    freelancer: ['#4c3bcf', '#6B8AFE', '#3DC2EC'],
    candidate: ['#4c3bcf', '#6B8AFE', '#3DC2EC'],
    company: ['#6B8AFE', '#61C9DA', '#49E0A0'],
    employer: ['#6B8AFE', '#61C9DA', '#49E0A0'],
  };

  // Retrieve the colors array for the specified group
  const selectedGroup = colors[groupName];

  // Set the stop percentages for smooth blending
  const percentage1 = 0;
  const percentage2 = 50;
  const percentage3 = 100;

  // Randomize the gradient's size and position
  const shape = 'circle';
  let positionX = Math.floor(Math.random() * 100); // Random position on the X-axis
  let positionY = Math.floor(Math.random() * 100); // Random position on the Y-axis

  // Ensure the difference between positionX and positionY is at least 70
  if (Math.abs(positionX - positionY) < 70) {
    positionY = (positionX + 70) % 100; // Adjust positionY to ensure the difference is at least 70
  }

  // Generate the random radial gradient
  return `radial-gradient(${shape} at ${positionX}% ${positionY}%,
    ${selectedGroup[0]} ${percentage1}%,
    ${selectedGroup[1]} ${percentage2}%,
    ${selectedGroup[2]} ${percentage3}%)`;
};

export const generateErrorPasswordText = (errors, key = 'password') => {
  return errors[key]
    ? errors[key].type === 'pattern'
      ? 'Password must contain 1 special character'
      : 'Password must be at least 8 characters'
    : null;
};

export const validatePhone = (phone) => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  if (!regex.test(phone)) {
    return true;
  }
  return false;
};

export const updateUrl = (value, paramName) => {
  const url = new URL(window.location.href);
  url.searchParams.set(paramName, value.toString());
  window.history.pushState({}, '', url);
};

export const clearQueryParams = () => {
  const url = new URL(window.location.href); // Create a URL object with the current location
  url.search = ''; // Clears all query params
  window.history.pushState({}, '', url.toString()); // Update the URL without reloading the page
};

export const fetchLabel = (list, id) => {
  const item = list.find((i) => i.id === id || i.key === id || i.value === id);
  return item ? item.name ?? item.label ?? item.value : '';
};
