import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROFILE } from '../../../../graphql';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  Heading,
} from '../../../snippets';
import { getUser, saveUserLocalstorage } from '../../../../utils/helpers';
import { userVar } from '../../../../graphql/config';

const VerificationConfirmationModal = ({ onSubmitCallback }) => {
  const { handleSubmit } = useForm();
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const verificationConfirmation = () => {
    updateProfile({
      variables: {
        input: {
          send_text_notification: true,
        },
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => toast.error('Something went wrong.', { autoClose: 5000 }));
  };

  return (
    <form
      autoComplete="off"
      className="form"
      onSubmit={handleSubmit(verificationConfirmation)}
      style={{
        width: '100%',
      }}
    >
      <Heading>SMS Notification</Heading>

      <FormBlock className="mt-4">
        <FormBlockItem
          label={"Thank you for verifying your number. You're all set!"}
        />
      </FormBlock>

      <FormCTA spaceT>
        <Button type="submit" className="w-full" size="xxl">
          Close
        </Button>
      </FormCTA>
    </form>
  );
};

export default VerificationConfirmationModal;
