import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { SEND_VERIFICATION_CODE } from '../../../../graphql';
import {
  Button,
  FormBlock,
  FormInput,
  FormBlockItem,
  FormCTA,
  Heading,
} from '../../../snippets';
import Error from '../../../../components/Error';

const SendVerificationModal = ({ onSubmitCallback }) => {
  const { register, handleSubmit } = useForm();

  const [error, setError] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATION_CODE
  );

  const verifyPhoneNumber = () => {
    if (!phoneNo) {
      setError('Please enter a valid phone number');
      return;
    }

    sendVerificationCode({
      variables: {
        phone: phoneNo,
      },
    })
      .then(() => {
        toast.success("We've sent you a verification code!", {
          autoClose: 2000,
        });
        onSubmitCallback();
      })
      .catch(() => {
        toast.error('Something went wrong.', { autoClose: 5000 });
      });
  };

  return (
    <form
      autoComplete="off"
      className="form"
      onSubmit={handleSubmit(verifyPhoneNumber)}
      style={{
        width: '100%',
      }}
    >
      <Heading>SMS Notification</Heading>

      <FormBlock className="mt-4">
        <FormBlockItem
          label={
            "Please enter the number where you'd like to receive notifications."
          }
        >
          <FormInput
            isRequired
            type="phone"
            register={register}
            name="phone"
            setPhoneNo={(val) => {
              setError('');
              setPhoneNo(val);
            }}
          ></FormInput>
          {error && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: '30px',
              }}
            >
              <Error text={error} />
            </div>
          )}
        </FormBlockItem>
      </FormBlock>

      <FormCTA spaceT extraSpace>
        <Button type="submit" className="w-full" size="xxl" disabled={loading}>
          {loading ? 'Loading...' : 'Send Verification'}
        </Button>
      </FormCTA>
    </form>
  );
};

export default SendVerificationModal;
