import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import SendVerificationModal from '../../components/NotificationSettings/SendVerificationModal';
import PhoneVerificationModal from '../../components/NotificationSettings/PhoneVerificationModal';
import VerificationConfirmationModal from '../../components/NotificationSettings/VerificationConfirmationModal';
import { getUser, saveUserLocalstorage } from '../../../utils/helpers';
import { userVar } from '../../../graphql/config';
import { UPDATE_PROFILE } from '../../../graphql';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, FormBlock, FormBlockItem, Heading } from '../../snippets';
import { CustomCheckbox, Switch } from '../../components';
import { useAppTheme } from '../../context/themeAppContext';

const NotificationSettingsPage = () => {
  const stages = ['Send_Verification', 'Phone_Verification', 'Error'];
  const {
    action: { setOpenModal, setCloseModal },
  } = useAppTheme();
  const [emailOpportunityAlertsActive, setEmailOpportunityAlertsActive] =
    useState(getUser().send_email);
  const [textNewAlertsActive, setTextNewAlertsActive] = useState(
    getUser().send_text_notification
  );
  const [verificationStage, setVerificationStage] = useState(null);
  const [textFrequency, setTextFrequency] = useState(
    getUser().notification_frequency
  );

  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const updateUserNotificationSetting = (input) => {
    updateProfile({
      variables: {
        input: input,
      },
    })
      .then(({ data: { updateUser } }) => {
        const updatedUserObj = { ...getUser(), ...updateUser };
        userVar(updatedUserObj);
        saveUserLocalstorage(updatedUserObj);
        toast.success('Your notification settings updated!', {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error('Something went wrong.', { autoClose: 5000 });
      });
  };

  useEffect(() => {
    switch (verificationStage) {
      case stages[0]:
        setOpenModal(() => (
          <SendVerificationModal
            onSubmitCallback={() => {
              setCloseModal();
              setVerificationStage(stages[1]);
            }}
          />
        ));
        break;
      case stages[1]:
        setOpenModal(() => (
          <PhoneVerificationModal
            onSubmitCallback={() => {
              setTextNewAlertsActive(true);
              setCloseModal();
              setVerificationStage(stages[2]);
            }}
            onBackCallback={() => {
              setCloseModal();
              setVerificationStage(stages[0]);
            }}
          />
        ));
        break;
      case stages[2]:
        setOpenModal(() => (
          <VerificationConfirmationModal
            onSubmitCallback={() => {
              setTextNewAlertsActive(true);
              setCloseModal();
            }}
          />
        ));
        break;
      default:
        break;
    }
  }, [verificationStage]);

  return (
    <DefaultLayout showNavigation showSubmenu>
      <BackPanel>
        <Heading>Notification Settings</Heading>

        <FormBlock isInline>
          <FormBlockItem
            label="Email Me New Opportunity Alerts"
            isInline
            className="gap-12"
          >
            <Switch
              isChecked={emailOpportunityAlertsActive}
              onChange={(e) => {
                setEmailOpportunityAlertsActive(e.target.checked);
                updateUserNotificationSetting({ send_email: e.target.checked });
              }}
            />
          </FormBlockItem>
        </FormBlock>

        <FormBlock isInline>
          <FormBlockItem label="Text Me New Alerts" isInline className="gap-12">
            <Switch
              isChecked={textNewAlertsActive}
              onChange={(e) => {
                if (e.target.checked) {
                  setVerificationStage(stages[0]);
                } else {
                  setTextNewAlertsActive(false);
                  updateUserNotificationSetting({
                    send_text_notification: false,
                  });
                }
              }}
            />
          </FormBlockItem>
        </FormBlock>

        <FormBlock isInline>
          <FormBlockItem
            label="Frequency"
            isInline
            className="gap-12"
          ></FormBlockItem>
        </FormBlock>

        <FormBlock>
          <FormBlockItem isInline className="ml-2 gap-12">
            <CustomCheckbox
              name="daily-input"
              checked={textFrequency === 'daily'}
              onChange={(value) => {
                if (!value) {
                  setTextFrequency('immediately');
                  updateUserNotificationSetting({
                    notification_frequency: 'immediately',
                  });
                } else {
                  setTextFrequency('daily');
                  updateUserNotificationSetting({
                    notification_frequency: 'daily',
                  });
                }
              }}
              label="Daily"
            />
            <CustomCheckbox
              name="immediately-input"
              checked={textFrequency === 'immediately'}
              onChange={(value) => {
                if (!value) {
                  setTextFrequency('daily');
                  updateUserNotificationSetting({
                    notification_frequency: 'daily',
                  });
                } else {
                  setTextFrequency('immediately');
                  updateUserNotificationSetting({
                    notification_frequency: 'immediately',
                  });
                }
              }}
              label="Immediately"
            />
          </FormBlockItem>
        </FormBlock>
      </BackPanel>
    </DefaultLayout>
  );
};

export default NotificationSettingsPage;
