import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { registerVar } from '../graphql/config';
import HeaderBlog from '../components/Company/HeaderBlog';
import Header from '../components/Freelancer/Header';
import Footer from '../components/Footer';
import { logUserOut } from '../utils/helpers';
import { LINK_GROUP } from '../utils/links';

const MarketingRegister = ({ history }) => {
  useEffect(() => {}, []);

  const message = 'Loading...';

  function GetQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function ShowHeader(type) {
    switch (type) {
      case LINK_GROUP.FREELANCER:
        return <Header />;

      case LINK_GROUP.COMPANY:
      default:
        return <HeaderBlog />;
    }
  }

  const query = GetQuery();
  const email = query.get('email');
  const type = query.get('type');

  if (email && type) {
    logUserOut();
    registerVar({
      email: email.replace(/\s+/g, '+').toLowerCase(),
    });

    history.push(`/${type}/register`);
  }

  return (
    <div className="lh-wrapper">
      {ShowHeader(type)}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="onboarding">
              <span>{message}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketingRegister;
