import React, { Fragment, useMemo } from 'react';
import { BackPanel, Button, Heading, Text } from '../../snippets';
import { FileUploader, VettedIcon } from '..';
import useGroups from '../../hooks/useGroups';
import { getRandomGradient } from '../../../utils/helpers';

export default function SectionProfile({
  actionComponent: ActionComponent,
  children,
  headline = '',
  profilePictureHeading,
  imageRounded = false,
  contentFull = false,
  editURL = null,
  editLabel,
  isEditor = false,
  setImg = () => {},
  editDefaultImage = null,
  profileImage = null,
  hideImage = false,
  noPanel = false,
  uppercaseActionLabel = false,
  vetted = false,
  cover = false,
}) {
  const { groupname } = useGroups();

  const Panel = useMemo(() => {
    return noPanel ? Fragment : BackPanel;
  }, [noPanel]);

  const gradient = useMemo(() => {
    return getRandomGradient(groupname);
  }, [groupname]);

  return (
    <Panel>
      <Heading>{headline}</Heading>
      <div className="profile-layout">
        <div className="l-side">
          {!hideImage && (
            <div className={`profile-block--avatar`}>
              {profilePictureHeading && (
                <div className="avatar-meta-name">{profilePictureHeading}</div>
              )}
              {isEditor || editDefaultImage ? (
                <div className="avatar-meta-image-square">
                  <div className="aspect-ratio-square">
                    <FileUploader
                      onSetImage={(file) => {
                        setImg(file);
                      }}
                      defaultImage={editDefaultImage}
                      noFrame={isEditor}
                      maxFileSize={5e6}
                      imageRounded={imageRounded}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={`avatar-meta-image${
                    imageRounded ? ' full-rounded' : '-square'
                  }`}
                >
                  <div className="aspect-ratio-square">
                    {profileImage && (
                      <img
                        src={profileImage}
                        className={cover ? 'cover' : ''}
                        alt="Avatar"
                      />
                    )}
                  </div>
                </div>
              )}

              {vetted && (
                <div className="profile-block--vetted">
                  <VettedIcon
                    gradientColor={['#3DC2EC', '#6B8AFE', '#4c3bcf']}
                  />
                  <Text
                    as="span"
                    className="active"
                    style={{
                      backgroundImage: gradient,
                    }}
                  >
                    LH Vetted
                  </Text>
                </div>
              )}
            </div>
          )}
          <div className="profile-action--block">
            {ActionComponent}
            {editURL && (
              <Button
                as="link"
                size="xxl"
                to={editURL}
                className="w-full app-btn--text-xs"
                minradii
                uppercase={uppercaseActionLabel}
              >
                {editLabel ?? 'Edit Profile'}
              </Button>
            )}
          </div>
        </div>
        <div className={`r-side${contentFull ? ' full-size' : ''}`}>
          {children}
        </div>
      </div>
    </Panel>
  );
}
