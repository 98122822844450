import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { LOGIN } from '../../graphql';
import { userVar } from '../../graphql/config';
import {
  getUser,
  saveUserLocalstorage,
  saveLoginTypeLocalstorage,
} from '../../utils/helpers';
import { AgencyHeader } from '../../components/Agency';
import Footer from '../../components/Footer';
import jquery from '../../styles/js/app';
import { LINK_GROUP } from '../../utils/links';
import { IconGoogleWhite } from '../../components/Icons';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ history, location }) => {
  useEffect(() => {
    jquery();
  }, []);
  const { register, handleSubmit, errors } = useForm();
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [isEnabled, setIsEnabled] = useState(true);
  const redirectTo = useQuery().get('redirectTo');

  if (
    getUser().access_token &&
    (getUser().type === 'AGENCY' || getUser().type === 'GUEST')
  ) {
    return (
      <Redirect
        to={{
          pathname: redirectTo ?? `/${LINK_GROUP.AGENCY}/dashboard`,
          state: { referrer: `/${LINK_GROUP.AGENCY}/login` },
        }}
      />
    );
  }

  const openInNewTab = (url) => {
    localStorage.setItem('userType', LINK_GROUP.AGENCY);
    window.location.replace(url);
  };

  const loginUser = (data) => {
    toast.dismiss();
    setIsEnabled(false);
    loginMutation({
      variables: { input: { email: data.email, password: data.password } },
    })
      .then(({ data: { login } }) => {
        if (!login) {
          setIsEnabled(true);
          return toast.error('Wrong email / password combination!', {
            toastId: 'agLogErr',
            autoClose: 3000,
          });
        }
        userVar(login);
        saveUserLocalstorage(login);
        const cookies = new Cookies();
        cookies.set('lh_user', login.id, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        saveLoginTypeLocalstorage('AGENCY');
        history.push(redirectTo ?? `/${LINK_GROUP.AGENCY}/dashboard`);
        // subHistory({
        //   variables: {
        //     agency: data.login.id,
        //   },
        // });
      })
      .catch((e) => {
        console.error(e.message);
        setIsEnabled(true);
        toast.error('Something went wrong!', { toastId: 'agLogErr' });
      });
  };

  return (
    <div className="lh-wrapper bg__gradient-1 bg-center bg-cover">
      <AgencyHeader />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="black-card">
              <h1 className="black-card__heading mb-40">
                {location.state && location.state.passwordReset
                  ? 'Your password has been reset. Please log in.'
                  : 'Login'}
              </h1>
              <form
                className="form-v2 form-agency"
                onSubmit={handleSubmit(loginUser)}
              >
                <div className="form-v2__input-block">
                  <div
                    className={`form-v2__label mt-0 ${
                      errors.email && 'form-v2__label-error'
                    }`}
                  >
                    Email Address
                  </div>
                  <div className={`form-v2__input-wrapper`}>
                    <input
                      className={`form-v2__input ${
                        errors.email && 'form-v2__error'
                      }`}
                      placeholder={
                        errors.email && 'Please enter a valid email address'
                      }
                      type="text"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      autoComplete="false"
                    />
                  </div>
                </div>
                <div className="form-v2__input-block">
                  <div
                    className={`form-v2__label mt-0 ${
                      errors.password && 'form-v2__label-error'
                    }`}
                  >
                    Password
                  </div>
                  <div className="form-v2__input-wrapper">
                    <input
                      className={`form-v2__input ${
                        errors.password && 'form-v2__error'
                      }`}
                      placeholder={
                        errors.password &&
                        'Password must be at least 8 characters'
                      }
                      type="password"
                      id="onboarding-password"
                      name="password"
                      ref={register({ required: true })}
                      autoComplete="false"
                    />
                    <div
                      toggle="#onboarding-password"
                      className="fas fa-eye form-v2__toggle-password form-v2__hidden"
                    />
                  </div>
                  <div className="form-v2__forgetpassword">
                    <Link
                      to={'/' + LINK_GROUP.AGENCY + '/forgot-password'}
                      className="link-agency"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!isEnabled}
                  className="form-v2__btn form-v2__btn-agency-v2 d-block bg__gradient-1"
                >
                  {loading ? 'Loading...' : 'Login'}
                </button>
                <p className="center txt__fs-14 txt__fw-500">
                  Or Sign In Using
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => {
                      openInNewTab(
                        `${process.env.REACT_APP_API_URL}/redirect/google`
                      );
                    }}
                    className="btn-google-small mb-30"
                  >
                    <IconGoogleWhite />
                  </button>
                </div>

                {(!location.state || location.state.referrer === '/') && (
                  <div className="d-flex flex-col justify-content-between center">
                    <Link
                      to={'/' + LINK_GROUP.AGENCY + '/register'}
                      className="text__white txt__fw-700"
                    >
                      Need an account?
                    </Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
