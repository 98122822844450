import React from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../snippets'; 
import GoogleSignInAction from '../GoogleSignInAction/GoogleSignInAction'; 
import { generateErrorPasswordText } from '../../../utils/helpers';

export default function TeamRegisterLayers({
  heading = 'Login information',
  forms = { 
    errors: {},
    onSubmit: () => {},
    register: null,
    clearErrors: () => {},
    watch: (v) => {},
    setValue: () => {},
    loading: false,
    disabled: false,
    input: {
      email: '',
      password: ''
    }
  }
}) {
  return (
    // handleSubmit(CheckEmail)
    <form onSubmit={forms.onSubmit}>
      <Heading as="h3" className="text-center mb-4">
        {heading}
      </Heading>
      <FormBlock>
        <FormBlockItem label={'Email Address'}>
          <FormInput
            name="email"
            type="email"
            disabled
            isRequired
            register={forms.register}
            defaultValue={forms.input.email ?? ''}
            error={
              forms.errors.email
                ? forms.errors.email?.type === 'pattern'
                  ? 'Please enter a valid email address'
                  : 'Please enter an email'
                : null
            }
            clearErrors={forms.clearErrors}
          />
        </FormBlockItem>
        <FormBlockItem label={'Password'}>
          <FormInput
            register={forms.register}
            type="password"
            name="password"
            isRequired
            error={generateErrorPasswordText(forms.errors)}
            clearErrors={forms.clearErrors}
            watch={forms.watch}
            setValue={forms.setValue}
            showErrorText
          />
        </FormBlockItem>
      </FormBlock>
      <FormCTA className={'space-t'}>
        <Button
          className="app-btn-submit"
          type="submit"
          disabled={forms.disabled || forms.loading}
          size="xl"
        >
          {forms.loading ? 'Loading...' : 'Register'}
        </Button>
      </FormCTA>
      <GoogleSignInAction heading="Or Sign Up Using" />
    </form>
  );
}
