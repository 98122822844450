import gql from 'graphql-tag';

export const GET_DEAL = gql`
  query Deal($id: ID!) {
    deal(id: $id) {
      id
      title
      slug
      subtitle
      image
      link
      content
      created_at
    }
  }
`;

export const GET_ALL_DEALS = gql`
  query AllDeals(
    $orderBy: [QueryDealsOrderByOrderByClause!]
    $where: QueryDealsWhereWhereConditions
  ) {
    allDeals(orderBy: $orderBy, where: $where) {
      id
      title
      slug
      subtitle
      image
      link
      created_at
    }
  }
`;

export const GET_DEALS = gql`
  query Deals(
    $orderBy: [QueryDealsOrderByOrderByClause!]
    $where: QueryDealsWhereWhereConditions
  ) {
    deals(orderBy: $orderBy, where: $where) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        title
        slug
        subtitle
        image
        link
        created_at
      }
    }
  }
`;
