import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_GROUP } from '../../utils/links';

const basepath = LINK_GROUP.AGENCY;

export const AgencySubmenuLinks = [
  {
    path: 'account-information',
    label: 'Account Information',
  },
  {
    path: 'edit-account-information',
    label: 'Edit Account Information',
  },
  {
    path: 'profile',
    label: 'View Profile',
  },
  {
    path: 'edit-profile',
    label: 'Edit Profile',
  },
  {
    path: 'manage-team',
    label: 'Manage Team',
  },
  {
    path: 'notification-settings',
    label: 'Notification Settings',
  },
  {
    path: 'profile-privacy',
    label: 'Profile Privacy',
  },
  {
    path: 'change-password',
    label: ' Change Password',
  },
  {
    path: 'logout',
    label: 'Log Out',
  },
];

const AccountSubmenuDesktop = () => (
  <div className="col-submenu bg-light">
    <div className="account-submenu__desktop submenu-v2">
      <div className="account-submenu__desktop__heading txt text-right  txt__fw-900">
        Account Settings
      </div>
      <ul className="account-submenu__list">
        {/*  */}
        {AgencySubmenuLinks.map(({ path, label }) => {
          const pathname = [basepath, path].join('/');
          return (
            <li className="account-submenu__list__item text-right" key={path}>
              <Link to={`/${pathname}`} className="link">
                {label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  </div>
);

export default AccountSubmenuDesktop;
