import React from 'react'

export default function GIcon() {
  return (
    <svg width="1em" height="1.06em" viewBox="0 0 17 18">
        <path d="M16.5208 8.93245C16.5208 13.7397 13.237 17.1252 8.39583 17.1252C3.72396 17.1252 0 13.4012 0 8.72933C0 4.09131 3.72396 0.333496 8.39583 0.333496C10.6302 0.333496 12.5599 1.17985 14.0156 2.53402L11.7135 4.73454C8.73437 1.85693 3.18229 4.0236 3.18229 8.72933C3.18229 11.6746 5.51823 14.0444 8.39583 14.0444C11.7135 14.0444 12.9661 11.6746 13.1354 10.422H8.39583V7.54443H16.3854C16.4531 7.98454 16.5208 8.39079 16.5208 8.93245Z" fill="currentColor"/>
    </svg>

  )
}
