import React, { useMemo } from 'react';

export default function Heading({
  as = 'h2',
  className = '',
  children,
  upsize = false,
}) {
  const classNames = useMemo(() => {
    const cl = [`text--${as.toLowerCase()}`, className];
    if (upsize) {
      cl.push('up-size');
    }
    return cl.join(' ');
  }, [as, className, upsize]);

  const HeadingElType = useMemo(() => {
    return as;
  }, [as]);

  return <HeadingElType className={classNames}>{children}</HeadingElType>;
}
