import React, { useContext, useMemo } from 'react';
import useGroups from '../../hooks/useGroups';
import { useAppTheme } from '../../context/themeAppContext';
import { BackPanel, Link } from '../../snippets';
import { InstagramIcon, LinkedinIcon } from '../../icons';
import { getRandomGradient, getUser } from '../../../utils/helpers';
import { LINK_GROUP } from '../../../utils/links';
import { AppContext } from '../../../Store';
import { accountPaths } from '../../../constants';

export default function Sidenav() {
  const { groupname } = useGroups();
  const {
    state: {
      drawer: { open },
    },
  } = useAppTheme();

  const [state] = useContext(AppContext);

  const isLoggedIn = getUser().access_token || false;
  const isCompany =
    groupname === LINK_GROUP.COMPANY || groupname === LINK_GROUP.EMPLOYER;

  const accountNavPath = {
    [LINK_GROUP.AGENCY]: 'profile',
    [LINK_GROUP.FREELANCER]: 'personal-information',
    [LINK_GROUP.CANDIDATE]: 'personal-information',
    [LINK_GROUP.COMPANY]: 'account',
    [LINK_GROUP.EMPLOYER]: 'account',
  };

  const links = useMemo(() => {
    const links = [];
    if (isLoggedIn) {
      if (isCompany && state?.activeCompany?.can_post_jobs) {
        links.push(
          {
            label: 'New Job Opportunity',
            to: `/${groupname}/add-opportunity`,
            className: 'sidenav-menu--item app-btn',
          },
          {
            label: 'New Agency Project',
            to: `/${groupname}/add-project`,
            className: 'sidenav-menu--item app-btn',
          }
        );
      }

      // if (isCompany) {
      //   links.push({
      //     label: 'Agency Marketplace',
      //     to: `/${groupname}/agency-marketplace`,
      //     className: 'sidenav-menu--item app-btn',
      //   });
      // }

      if (groupname === LINK_GROUP.AGENCY) {
        links.push({
          label: 'Case Studies',
          to: `/${groupname}/case-studies`,
          className: 'sidenav-menu--item app-btn',
        });
      }

      links.push({
        label: 'Dashboard',
        to: `/${groupname}/dashboard`,
      });

      links.push({
        label: 'My Account',
        to: `/${groupname}/${accountNavPath[groupname] ?? 'profile'}`,
      });

      links.push({
        label: 'Logout',
        to: `/${groupname}/logout`,
      });
    } else {
      links.push({
        label: 'Login',
        to: `/${groupname}/login`,
      });
    }

    return links;
  }, [isLoggedIn, isCompany, groupname, state, accountNavPath]);

  return (
    <div className={`sidenav${open ? ' open' : ''}`}>
      <div className="d-flex justify-content-center w-full">
        <BackPanel className="panel-transparent w-full">
          <div className="sidenav-menu">
            <div className="sidenav-menu__links">
              {links.map(({ to, label }) => (
                <Link
                  key={to}
                  isNavLink
                  to={to}
                  className="sidenav-menu--item app-btn"
                  activeStyle={{
                    backgroundImage: getRandomGradient(groupname),
                  }}
                  isActive={(match, location) => {
                    if (match) {
                      return true;
                    }

                    const tos = to.substring(1).split('/');
                    const locations = location.pathname.substring(1).split('/');

                    if (
                      (((groupname === LINK_GROUP.COMPANY ||
                        groupname === LINK_GROUP.EMPLOYER) &&
                        tos[1] === 'account') ||
                        ((groupname === LINK_GROUP.FREELANCER ||
                          groupname === LINK_GROUP.CANDIDATE) &&
                          tos[1] === 'personal-information') ||
                        (groupname === LINK_GROUP.AGENCY &&
                          tos[1] === 'profile')) &&
                      accountPaths.includes(locations[1])
                    ) {
                      return true;
                    }

                    return false;
                  }}
                >
                  {label}
                </Link>
              ))}
            </div>
            <div className="footer-social">
              <Link
                to="https://instagram.com"
                className="footer-social--item"
                external
              >
                <InstagramIcon />
              </Link>
              <Link
                to="https://likedin.com"
                className="footer-social--item"
                external
              >
                <LinkedinIcon />
              </Link>
            </div>
          </div>
        </BackPanel>
      </div>
    </div>
  );
}
