import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  generateClassName,
  updateUrl,
  clearQueryParams,
} from '../../../utils/helpers';
import { CheckedIcon, SearchIcon } from '../../icons';
import { useQuery } from '@apollo/react-hooks';
import { GET_SERVICES } from '../../../graphql';
import LoadingSection from '../LoadingSection/LoadingSection';
import { Button, FormBlock, FormBlockItem } from '../../snippets';
import { AppContext } from '../../../Store';
import AppMultiselect from '../AppMultiselect/AppMultiselect';

export default function ServicesFilter({
  noSpaceBottom = true,
  hideClear = false,
  onSetFilter = () => {},
  clear,
  setClear,
}) {
  const [selected, setSelected] = useState([]);
  const [reset, setReset] = useState(false);
  const [state] = useContext(AppContext);

  useEffect(() => {
    onSetFilter(selected?.map((s) => s?.name ?? ''));
  }, [selected, onSetFilter]);

  const [multiselectRef, setMultiselectRef] = useState(null);

  const { data: { services = [] } = {}, loading } = useQuery(GET_SERVICES);

  const handleSelectService = useCallback((options) => {
    setSelected(options);
    updateUrl(options.map((item) => item.id).join(), 'service');
  }, []);

  useEffect(() => {
    if (selected.length) {
      updateUrl(selected.map((item) => item.id).join(), 'service');
    }
  }, [selected]);

  const handleOnReset = useCallback(() => {
    setSelected([]);
    setReset(true);
    clearQueryParams();
  }, []);

  useEffect(() => {
    if (clear) {
      setSelected([]);
      setReset(true);
      clearQueryParams();
    }
  }, [clear, state, multiselectRef]);

  const filterServices = useCallback(
    (serviceIds) => {
      const arr = serviceIds.split(',').map(String);
      const selected = services?.filter((item) => arr.includes(item.id));
      if (selected?.length) {
        setSelected(selected);
      }
    },
    [services]
  );

  const getUrlParams = useCallback(() => {
    const url = new URL(window.location);
    const service = url.searchParams.get('service');

    if (service) {
      filterServices(service);
    }
  }, [filterServices]);

  useEffect(() => {
    getUrlParams();
  }, [services, getUrlParams]);

  if (loading) {
    return <LoadingSection />;
  }

  return (
    <>
      <div
        className={generateClassName([
          'section-chips',
          noSpaceBottom ? 'mb-0' : '',
        ])}
      >
        <div className="row mb-2">
          <div className="col-lg-4 col-12">
            <FormBlock>
              <FormBlockItem className="multiselect-with-icon">
                <SearchIcon />
                <AppMultiselect
                  options={services}
                  onSelect={handleSelectService}
                  displayValue="name"
                  closeOnSelect={false}
                  placeholder="Services"
                  selectedValues={selected}
                  setAppRef={setMultiselectRef}
                  hideChip
                />
              </FormBlockItem>
            </FormBlock>
          </div>
          {!hideClear && (
            <div className="col-lg col-12">
              <Button onClick={handleOnReset} text="normal">
                Clear Filters
              </Button>
            </div>
          )}
        </div>
        <div className="section-chips--blocks">
          {selected.map((select) => {
            return (
              <Button
                key={select.id}
                noGradient
                variant={'transparent'}
                onClick={() => {
                  multiselectRef.current.resetSelectedValues();
                  setSelected((_prev) => {
                    const set = new Set(_prev);

                    if (set.has(select)) {
                      set.delete(select);
                    } else {
                      set.add(select);
                    }

                    return Array.from(set);
                  });
                }}
              >
                <div
                  className={generateClassName([
                    'app-badge app-badge-primary as-checkbox',
                    'active',
                  ])}
                >
                  <div className="check-wrapper">
                    <CheckedIcon />
                  </div>
                  <span>{select.name}</span>
                </div>
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
}
