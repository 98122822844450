import React from 'react';
import { GET_CASE_STUDY } from '../../../graphql';
import { Heading, BackPanel, Button, Link, Text } from '../../snippets';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import DefaultLayout from '../../layouts/defaultLayout';
import ReactPlayer from 'react-player';
import useGroups from '../../hooks/useGroups';
import { ChevronLeftIcon } from '../../icons';

const CaseStudyDetails = () => {
  const { id } = useParams();
  const { data: { caseStudy = {} } = {} } = useQuery(GET_CASE_STUDY, {
    variables: {
      id,
    },
  });
  const { groupname } = useGroups();
  const history = useHistory();

  return (
    <DefaultLayout showCaseStudyEdit goBack>
      <div
        className="container w-full mb-20 d-lg-none"
        style={{ maxWidth: '360px' }}
      >
        <Button
          onClick={() => history.goBack()}
          className="app-btn btn-nav w-full  d-flex align-items-center justify-content-center gap-10 text-capitalize"
          scheme="black"
          size="full"
          style={{ fontSize: '18px' }}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </div>
      {groupname === 'agency' && (
        <div
          className="container w-full mb-20 d-lg-none"
          style={{ maxWidth: '360px' }}
        >
          <Button
            as="link"
            to={`/${groupname}/case-study/edit/${id}`}
            className="app-btn btn-nav w-full d-block text-capitalize"
            scheme="black"
            size="full"
            style={{ fontSize: '18px' }}
          >
            Edit Case Study
          </Button>
        </div>
      )}
      <BackPanel smallPaddingTop>
        <HeadingCont>
          <div>
            <Heading className="text-uppercase">{caseStudy.title}</Heading>
            <h4>{caseStudy?.agency?.agency_name}</h4>
          </div>
          <img
            src={
              caseStudy?.agency?.avatar ||
              '/img/agency-picture-placeholder@2x.jpg'
            }
            alt="logo"
          />
        </HeadingCont>
        <HeroCont>
          <img src={caseStudy.thumbnail} alt="" />
          <div className="copy">
            <Text
              as="div"
              dangerouslySetInnerHTML={{ __html: caseStudy.description }}
            ></Text>

            {caseStudy.external_url && (
              <Link
                to={`${
                  caseStudy.external_url.includes('https://')
                    ? caseStudy.external_url
                    : 'https://' + caseStudy.external_url
                }`}
                external
              >
                <Button className="app-btn btn-nav text--spacing-1 w-full">
                  VIEW PROJECT
                </Button>
              </Link>
            )}
          </div>
        </HeroCont>
      </BackPanel>
      <BackPanel smallPaddingTop>
        <ContentContainer className="d-flex flex-column gap-100">
          <div className="aspect-ratio-box">
            <ReactPlayer
              className="react-player"
              url={caseStudy.video_url}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
          {caseStudy.content?.map((item) => {
            if (item.layout === 'image') {
              return <img src={item.attributes.image} alt="" key={item.key} />;
            } else {
              return (
                <ReactPlayer
                  url={item.attributes.url}
                  width={'100%'}
                  height={'100%'}
                  key={item.key}
                  controls={true}
                />
              );
            }
          })}
        </ContentContainer>
      </BackPanel>
    </DefaultLayout>
  );
};

export default CaseStudyDetails;

const ContentContainer = styled.div`
  gap: 87px;
  img {
    border-radius: 6px;
  }
  video {
    border-radius: 6px;
  }
  .aspect-ratio-box {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    iframe {
      border-radius: 6px;
    }
  }
  @media (min-width: 768px) {
    gap: 100px;
    img {
      border-radius: 12px;
    }
    video {
      border-radius: 12px;
    }
    .react-player {
      iframe {
        border-radius: 12px;
      }
    }
  }
`;

const HeroCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 58px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 47px;
  }
  .copy {
    max-width: 100%;
    p {
      font-size: 16px;
      line-height: 21.82px;
      font-weight: 400;
    }
    button {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 8px;
      color: #ffffff;
      font-weight: 800;
      font-size: 16px;
      margin-top: 50px;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    margin-top: 46px;
    img {
      height: 350px;
      width: 48%;
      margin-bottom: 0;
    }
    .copy {
      max-width: 100%;
      width: 410px;
      button {
        width: 148px;
        height: 38px;
        border-radius: 6px;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1224px) {
    .copy {
      width: 500px;
    }
  }
  @media (min-width: 1400px) {
    img {
      height: 436px;
      width: 532px;
    }
  }
`;

const HeadingCont = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    text-transform: capitalize;
    font-size: 30px;
  }
  h4 {
    margin: 0;
    color: white;
    font-size: 16px;
    line-height: 21.82px;
    font-weight: 700;
    margin-top: 15px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
  }
  @media (min-width: 768px) {
    img {
      width: 47px;
      height: 47px;
    }
  }
`;
