import React, { Fragment } from 'react';
import { generateClassName } from '../../../utils/helpers';
import { Link } from '../../snippets';

export function TablerRender({ item }) {
  switch (item.type) {
    case 'nameby':
      return (
        <>
          {item.attributes.map((a, i) => {
            return (
              <Fragment key={i}>
                <p>{a.content}</p>
                <p className="text--small text-indent-left text--med">{a.by}</p>
              </Fragment>
            );
          })}
        </>
      );

    case 'cta':
      return (
        <div className="tabler-cta-grid">
          {item.attributes.map((a, i) => {
            if (a.to) {
              return (
                <Link to={a.to} className="app-link-minimalism" key={i}>
                  {a.content}
                </Link>
              );
            }
            return (
              <Link
                to={a.to}
                className="app-link-minimalism"
                key={i}
                onClick={() => a.action()}
              >
                {a.content}
              </Link>
            );
          })}
        </div>
      );
    default:
      return (
        <>
          {item.attributes.map((a, i) => {
            return <span key={i}>{a.content}</span>;
          })}
        </>
      );
  }
}

export default function TablerSectionGrid({
  id = '',
  headers = [],
  tables = [],
  className = '',
  tableProportion = [],
  footerComponent = null,
  renderItem = (item) => {},
  ...rest
}) {
  return (
    <div
      className={generateClassName(['app-tabler-hide-on-mobile', className])}
    >
      <div className="app-tabler space-t">
        {headers.map((h, i) => (
          <div
            className={generateClassName(
              [
                'app-tabler--column-grid',
                'app-tabler--column-grid-header',
                tableProportion[i],
              ] ?? ''
            )}
            key={i}
          >
            {h}
          </div>
        ))}
        {tables.map((t, i) =>
          t.map((c, j) => (
            <div
              key={j}
              className={generateClassName(
                [
                  'app-tabler--column-grid app-tabler--column-grid-body',
                  tableProportion[j],
                  j === t.length - 1
                    ? 'last-column-grid'
                    : j === 0
                    ? 'first-column-grid'
                    : '',
                ] ?? ''
              )}
            >
              <div className="tb-body-info">{renderItem(c)}</div>
            </div>
          ))
        )}
        {footerComponent && <div className="tabler-footer"></div>}
      </div>
    </div>
  );
}
