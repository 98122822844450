import gql from 'graphql-tag';

export const GET_GENRES = gql`
  query Genres($name: String, $industries: [ID!]) {
    genres(name: $name, industries: $industries) {
      id
      name
    }
  }
`;
