import React, { useEffect, useState } from 'react';
import { InstagramIcon, LinkedinIcon } from '../../icons';
import { LINK_GROUP } from '../../../utils/links';
import { GET_LANDING_PAGES } from '../../../graphql';
import { useQuery } from '@apollo/react-hooks';
import { getUser } from '../../../utils/helpers';
import { Link } from '../../snippets';

export default function Footer() {
  const [filteredLandingPages, setFilteredLandingPages] = useState([]);
  const { data = { landingPages: [] } } = useQuery(GET_LANDING_PAGES);
  const { landingPages = [] } = data;

  useEffect(() => {
    if (landingPages !== undefined && landingPages.length > 0) {
      const present = landingPages.find((o) => o?.display_in_footer === 1);
      if (present !== undefined) {
        setFilteredLandingPages(
          landingPages.filter((o) => o?.display_in_footer === 1)
        );
      }
    }
  }, [landingPages]);
  return (
    <footer className="app-footer">
      <div className="footer-container">
        <div className="footer--item">
          <div className="footer-logo">
            <img src="/assets/images/lh-logo-v2.png" alt="" />
          </div>
          <div className="footer-copy">&copy; 2024 Liberty Hive.</div>
          <div className="footer-copy last">All rights reserved</div>
          <div className="footer-list-menu">
            {urls.legal.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link to={link.slug}>{link.name}</Link>
                </div>
              );
            })}
          </div>
          <div className="footer-social">
            <Link
              to="https://instagram.com"
              className="footer-social--item"
              external
            >
              <InstagramIcon />
            </Link>
            <Link
              to="https://likedin.com"
              className="footer-social--item"
              external
            >
              <LinkedinIcon />
            </Link>
          </div>
        </div>
        <div className="footer--item">
          <div className="footer-list-menu--heading">Candidates</div>
          <div className="footer-list-menu">
            {urls.consultants.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link to={link.slug} external={link.slug.includes('http')}>
                    {link.name}
                  </Link>
                </div>
              );
            })}
            <div className="footer-list-menu--item">
              {getUser().access_token ? (
                getUser().type === 'FREELANCER' && (
                  <Link to={`/${LINK_GROUP.CANDIDATE}/dashboard`}>
                    Dashboard
                  </Link>
                )
              ) : (
                <Link to={`/${LINK_GROUP.CANDIDATE}/login`}>
                  Log In/Sign Up
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="footer--item">
          <div className="footer-list-menu--heading">Agency Marketplace</div>
          <div className="footer-list-menu">
            {urls.agencies.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link to={link.slug} external={link.slug.includes('http')}>
                    {link.name}
                  </Link>
                </div>
              );
            })}
            <div className="footer-list-menu--item">
              {getUser().access_token ? (
                getUser().type === 'AGENCY' && (
                  <Link to={`/${LINK_GROUP.AGENCY}/dashboard`}>Dashboard</Link>
                )
              ) : (
                <Link to={`/${LINK_GROUP.AGENCY}/login`}>Log In/Sign Up</Link>
              )}
            </div>
          </div>
        </div>
        <div className="footer--item">
          <div className="footer-list-menu--heading">Employers</div>
          <div className="footer-list-menu">
            {urls.companies.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link to={link.slug} external={link.slug.includes('http')}>
                    {link.name}
                  </Link>
                </div>
              );
            })}
            <div className="footer-list-menu--item">
              {getUser().access_token ? (
                getUser().type === 'COMPANY' && (
                  <Link to={`/${LINK_GROUP.EMPLOYER}/dashboard`}>
                    Dashboard
                  </Link>
                )
              ) : (
                <Link to={`/${LINK_GROUP.EMPLOYER}/login`}>Log In/Sign Up</Link>
              )}
            </div>
          </div>
        </div>
        <div className="footer--item">
          <div className="footer-list-menu--heading">Liberty Hive</div>
          <div className="footer-list-menu">
            {urls.libertyHive.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link to={link.slug} external={link.slug.includes('http')}>
                    {link.name}
                  </Link>
                </div>
              );
            })}
            <div className="footer-list-menu--item">
              {/* <Link to={`/${LINK_GROUP.AGENCY}/login`}>Log In / Sign Up</Link> */}
              {getUser().access_token ? (
                getUser().type === 'AGENCY' && (
                  <Link to={`/${LINK_GROUP.AGENCY}/dashboard`}>Dashboard</Link>
                )
              ) : (
                <Link to={`/${LINK_GROUP.AGENCY}/login`}>Log In/Sign Up</Link>
              )}
            </div>
          </div>
        </div>
        <div className="footer--item">
          <div className="footer-list-menu">
            {filteredLandingPages.map((link, i) => {
              return (
                <div className="footer-list-menu--item" key={link.name}>
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/landing/${link.slug}`}
                    external
                  >
                    {link.name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
}

const urls = {
  legal: [
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/privacy-policy`,
      name: 'Privacy Policy',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/platform-terms`,
      name: 'Platform Terms',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/cookies`,
      name: 'Cookies',
    },
  ],
  consultants: [
    {
      slug: `${process.env.REACT_APP_API_URL}#how-it-works`,
      name: 'How It Works',
    },
    {
      slug: `/${LINK_GROUP.CANDIDATE}/dashboard`,
      name: 'Find A Job',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/terms-conditions`,
      name: 'Terms & Conditions',
    },
  ],
  agencies: [
    {
      slug: `${process.env.REACT_APP_API_URL}#how-it-works`,
      name: 'How It Works',
    },
    {
      slug: `/${LINK_GROUP.AGENCY}/dashboard`,
      name: 'Find A Job',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/terms-conditions`,
      name: 'Terms & Conditions',
    },
  ],
  companies: [
    {
      slug: `${process.env.REACT_APP_API_URL}#how-it-works`,
      name: 'How It Works',
    },
    {
      slug: `/${LINK_GROUP.EMPLOYER}/add-opportunity`,
      name: 'Add An Opportunity',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/company-terms-conditions`,
      name: 'Terms & Conditions',
    },
  ],
  libertyHive: [
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/about-us`,
      name: 'About Us',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/pricing`,
      name: 'Pricing',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/faqs`,
      name: 'FAQs',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/blog`,
      name: 'Blog',
    },
    {
      slug: `${process.env.REACT_APP_API_URL}/pages/get-in-touch`,
      name: 'Contact Us',
    },
  ],
};
