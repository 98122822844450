import React, { useEffect, useRef, useState } from 'react';
import { IconUploadCloud } from '../../../components/Icons';
import { generateClassName, isValidImage } from '../../../utils/helpers';
import { toast } from 'react-toastify';
import ButtonClose from '../ButtonClose/ButtonClose';
import { generateAssetImageURL } from '../../../utils/links';
import useGroups from '../../hooks/useGroups';

import styled, { css } from 'styled-components';

export const UploadContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  max-height: 131px;
  ${({ isError }) =>
    isError &&
    css`
      border-color: var(--error-primary);
    `}
  ${({ fixSize }) =>
    fixSize &&
    css`
      height: 244px;
      width: 244px;
      max-height: 244px;
    `}
  ${({ noOutline }) =>
    css`
      border: ${noOutline ? 'unset' : '1px solid #fff'};
    `}
`;

export default function FileUploader({
  onSetImage = (image) => {},
  isError = false,
  defaultImage,
  setOnError = () => {},
  helpText = 'Drag & Drop or <i>browse</i> to upload',
  isImage = true,
  smallSize = false,
  noFrame = false,
  finished = false,
  setFinished = () => {},
  maxFileSize = null,
  imageRounded = false,
}) {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const hiddenFileInput = useRef();

  const { groupname } = useGroups();

  useEffect(() => {
    if (finished) {
      setPreviewImage(null);
      setFinished(false);
    }
  }, [finished, setFinished]);

  useEffect(() => {
    onSetImage(image);
  }, [image, onSetImage]);

  useEffect(() => {
    if (defaultImage) {
      setPreviewImage(defaultImage);
    }
  }, [defaultImage]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileInput = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toast.dismiss('invalidImage');

    setFinished(false);
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (maxFileSize && file.size > maxFileSize) {
      const maxFileSizeString = Math.ceil(maxFileSize / 1024 / 1024);
      return toast.error(
        `Your image's file size is too large (max ${maxFileSizeString} MB)`,
        {
          toastId: 'invalidImage',
        }
      );
    }

    if (isImage) {
      isValidImage(file, (res) => {
        if (!res) {
          setOnError(true);
          return toast.error(
            `We only support JPEGs and PNGs for image upload`,
            {
              toastId: 'invalidImage',
            }
          );
        }

        setPreviewImage(URL.createObjectURL(file));
      });
    } else {
      setPreviewImage(file.name);
    }

    setImage(file);
  };

  return (
    <>
      <div className="form-uploader-block">
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeImage}
          ref={hiddenFileInput}
        />
        <div className={`up-container${isError ? ' error-state' : ''}`}>
          <div
            className="up-row"
            onDragOver={handleDragOver}
            onDrop={handleChangeImage}
            onClick={openFileInput}
          >
            {previewImage ? (
              <>
                {isImage ? (
                  <img
                    src={previewImage}
                    className={`object-fit-cover ${
                      imageRounded ? 'full-rounded' : ''
                    }`}
                    alt=""
                  />
                ) : (
                  <>
                    <div className="up-text">{previewImage}</div>
                    <div style={{ position: 'absolute', bottom: '20px' }}>
                      <span className={`${isError ? ' text-error' : ''}`}>
                        Drag & Drop or <b>browse</b> to Replace
                      </span>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className={generateClassName([
                    'up-icon-cyrcle',
                    smallSize ? ' size-xs' : '',
                  ])}
                >
                  <IconUploadCloud
                    width={smallSize ? '31.7px' : '47px'}
                    height={smallSize ? '22.19px' : '32px'}
                  />
                </div>
                <div
                  className={`up-text${isError ? ' text-error' : ''}`}
                  dangerouslySetInnerHTML={{ __html: helpText }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
      {previewImage && isImage && (
        <div
          className={generateClassName([
            'frame-substract',
            noFrame ? ' no-frame' : '',
          ])}
        >
          {!noFrame && (
            <img
              src={generateAssetImageURL(`subtract-${groupname}.png`)}
              alt=""
              onClick={openFileInput}
            />
          )}

          <ButtonClose
            onClick={() => {
              onSetImage(null);
              setPreviewImage(null);
              setImage(null);
            }}
          />
        </div>
      )}
    </>
  );
}
