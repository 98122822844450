import { useQuery, useMutation } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useGroups from '../../hooks/useGroups';
import {
  GridCards,
  GridJobs,
  LoadingSection,
  OpportunityApply,
  ProfileDetails,
  SectionProfile,
} from '../../components';
import { useAppTheme } from '../../context/themeAppContext';
import DefaultLayout from '../../layouts/defaultLayout';
import { Button } from '../../snippets';
import { VIEW_OPPORTUNITY, GET_AUTH_USER } from '../../../graphql';
import CONTENTS_UTIL from '../../../utils/contents';
import {
  capitalizeFirstLetter,
  getOpportunityLocation,
  humanReadableTime,
  userHasApplied,
} from '../../../utils/helpers';
import useArticles from '../../hooks/useArticles';
import useDeals from '../../hooks/useDeals';
import { LINK_GROUP } from '../../../utils/links';

const br =
  '<br style="content: \'A\' !important; display: block !important; margin-bottom: 0.5rem !important;" />';

export default function OpportunityDetailPage({
  match: {
    params: { id },
  },
}) {
  const {
    action: { setOpenModal },
  } = useAppTheme();
  const { groupname } = useGroups();
  const [hasApplied, setHasApplied] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [messages, setMessages] = useState([]);
  const [applicationLink, setApplicationLink] = useState(false);
  const [viewOpportunity, { called, data, loading }] = useMutation(
    VIEW_OPPORTUNITY,
    {
      onError: (e) => {
        toast.error('Something went wrong!', { toastId: 'errOppDet' });
      },
    }
  );

  const { data: userData, refetch } = useQuery(GET_AUTH_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => checkProfileCompleted(),
    onError: () => refetch(),
  });

  const { articles, loading: loadingArticles } = useArticles({
    availability: ['AGENCY', 'FREELANCER_AND_AGENCY'],
    first: 4,
  });

  const { deals, loading: loadingDeals } = useDeals({ first: 6 });

  const dealsData = useMemo(() => {
    let _limit = 6;

    if (deals.length < _limit) {
      _limit = deals?.length;
    }

    const data = deals?.slice(0, _limit);

    return (
      data?.map(({ created_at, image, link, subtitle, title }) => {
        return {
          img: image ?? null,
          headline: title ?? 'NA',
          info: subtitle ?? '-',
          since: humanReadableTime(created_at),
          url: link,
          externalLink: true,
        };
      }) ?? []
    );
  }, [deals]);

  if (!called && data === undefined && id) {
    viewOpportunity({
      variables: {
        id,
      },
    });
  }

  const checkProfileCompleted = () => {
    if (userData.me) {
      if (groupname === LINK_GROUP.CANDIDATE) {
        setIsProfileComplete(userData?.me?.isProfileComplete?.complete);
        setMessages(userData?.me?.isProfileComplete?.missing);
      } else if (groupname === LINK_GROUP.AGENCY) {
        setIsProfileComplete(true);
        setMessages([]);
      }
    }
  };

  const contents = useMemo(() => {
    if (!data?.viewOpportunity) {
      return;
    }
    const {
      viewOpportunity: {
        agency,
        genres,
        is_on_site,
        is_remote,
        location,
        type,
        sub_type,
        level,
        skills,
        services,
        created_at,
        description,
        minimumBudget,
        maximumBudget,
        is_compensation_displayed,
        compensation_unit,
        compensation_currency,
        applicants,
        application_link,
        target,
      },
    } = data;
    setHasApplied(userHasApplied(applicants));
    setApplicationLink(
      typeof application_link !== 'undefined' && application_link
    );

    return CONTENTS_UTIL.generateDetails(
      [
        CONTENTS_UTIL.generateRowDetails(
          'Employer',
          agency?.company
            ? `<a href="/${groupname}/company-profile/${agency?.id}">${agency?.company}</a>`
            : '-'
        ),
        genres?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Category Experience',
              genres?.map(({ name }) => `<span>${name}</span>`).join(br)
            )
          : null,
        CONTENTS_UTIL.generateRowDetails(
          'Location',
          getOpportunityLocation(is_on_site, is_remote, location) ?? '-'
        ),
        target === 'job'
          ? CONTENTS_UTIL.generateRowDetails(
              'Job Type',
              [type, sub_type].map((t) => capitalizeFirstLetter(t)).join(br)
            )
          : null,
        target === 'job'
          ? CONTENTS_UTIL.generateRowDetails(
              'Experience Level',
              capitalizeFirstLetter(level) ?? '-'
            )
          : null,
        target === 'project'
          ? services?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Services',
                services?.map(({ name }) => `<span >${name}</span>`).join(br)
              )
            : null
          : skills?.length > 0
          ? CONTENTS_UTIL.generateRowDetails(
              'Skills',
              skills?.map(({ name }) => `<span>${name}</span>`).join(br)
            )
          : null,

        CONTENTS_UTIL.generateRowDetails(
          'Date Published',
          dayjs(created_at, 'YY-MM-DD').fromNow() ?? '-'
        ),
      ],
      [
        CONTENTS_UTIL.generateRowDetails(`About the role`, description ?? '-'),
        is_compensation_displayed &&
          compensation_currency &&
          minimumBudget &&
          CONTENTS_UTIL.generateRowDetails(
            'Compensation',
            `${compensation_currency} ${minimumBudget.toLocaleString()} ${
              maximumBudget && ' — ' + maximumBudget.toLocaleString()
            } ${compensation_unit === 'DAY' ? 'daily' : 'annually'}`
          ),
      ]
    );
  }, [data, groupname]);

  const communityArticles = useMemo(() => {
    return articles.map((a) => {
      return {
        img: a.image,
        headline: a.title,
        url: `/${groupname}/resources/${a.slug}`,
      };
    });
  }, [articles, groupname]);

  const ProfileIncomplete = ({ missing }) => {
    return (
      <>
        <h2>Please complete your profile to apply to this job.</h2>
        <br />
        <p>Adding skills helps improve the accuracy of job matches.</p>
        <br />
        <span className="txt-red">Required information missing:</span>
        <br />
        {missing.map((m) => (
          <>
            <span>{m.charAt(0).toUpperCase() + m.slice(1)}</span>
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <DefaultLayout goBack>
      {loading && <LoadingSection />}
      {!loading && (
        <SectionProfile
          contentFull
          profileImage={
            data?.viewOpportunity?.agency?.avatar ||
            '/img/agency-picture-placeholder@2x.jpg'
          }
          actionComponent={
            <Button
              className="w-full text-uppercase app-btn-min-radii"
              size="xxl"
              disabled={hasApplied}
              onClick={() => {
                if (isProfileComplete) {
                  setOpenModal(() => (
                    <OpportunityApply
                      applicationLink={applicationLink}
                      id={data?.viewOpportunity?.id}
                    />
                  ));
                } else {
                  setOpenModal(() => <ProfileIncomplete missing={messages} />);
                }
              }}
            >
              {hasApplied ? 'Already Applied' : 'Apply Now'}
            </Button>
          }
          headline={data?.viewOpportunity?.title}
        >
          <ProfileDetails contents={contents}></ProfileDetails>
        </SectionProfile>
      )}
      {loadingArticles && <LoadingSection />}
      {!loadingArticles && (
        <GridCards
          type="community"
          heading="Liberty Hive Community"
          viewMoreUrl={`/${groupname}/resources`}
          id="resources"
          data={communityArticles}
        />
      )}
      {loadingDeals && <LoadingSection />}
      {!loadingDeals && (
        <GridJobs
          type="deals"
          heading="Deals For You"
          data={dealsData}
          viewMoreUrl={`/${groupname}/deals`}
        />
      )}
    </DefaultLayout>
  );
}
