function generateProfileDetails(left = [], right = []) {
  return {
    left,
    right,
  };
}
function generateRowDetails(title = null, value, maxWidth = null) {
  return {
    title,
    value,
    maxWidth,
  };
}
function generateRowComponent(title, render = () => null, maxWidth = null) {
  return {
    title,
    value: render,
    type: 'component',
    maxWidth,
  };
}

function generateAddress(address_1, address_2, city, state, country, zip) {
  return `<p class="text--p mb-2">${address_1 ?? ''}</p>
            ${address_2 ? `<p class="text--p mb-2">${address_2}</p>` : ''}
            <p class="text--p mb-2">${city ?? ''}</p>
            <p class="text--p mb-2">${state ?? ''}</p>
            <p class="text--p mb-2">${country ?? ''}</p>
            <p class="text--p mb-2">${zip ?? ''}</p>`;
}
const CONTENTS_UTIL = {
  generateDetails: generateProfileDetails,
  generateRowDetails: generateRowDetails,
  generateAddress,
  generateRowComponent,
};

export default CONTENTS_UTIL;
