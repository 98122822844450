import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from '../../../layouts/defaultLayout';
import {
  GridCaseStudies,
  LoadingSection,
  SectionViewOtherProfile,
} from '../../../components';
import { GET_USER } from '../../../../graphql';
import { useQuery } from '@apollo/react-hooks';
import CONTENTS_UTIL from '../../../../utils/contents';
import { TYPE_WORK_LOCATION } from '../../../placeholders/consultant/types';
import {
  Button,
  FormBlock,
  FormBlockItem,
  Leading,
  Text,
} from '../../../snippets';
import queryString from 'query-string';

export default function AgencyProfilePage({
  match: {
    params: { id },
  },
  ...props
}) {
  const parsedQuery = queryString.parse(props.location.search);

  const {
    data: { user } = {},
    loading,
    refetch,
  } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onError: () => refetch(),
  });
  const [userLocation, setUserLocation] = useState('');

  useEffect(() => {
    setUserLocation(
      `${user?.city}, ${user?.country} ${
        user?.location
          ? `/ ${
              TYPE_WORK_LOCATION.find(({ value }) => value === user?.location)
                ?.label ?? 'On site / Remote / Hybrid'
            }`
          : ''
      }`
    );
  }, [user]);

  const contents = useMemo(() => {
    if (!user) {
      return;
    }
    const { email, bio, genres, agency_phone } = user;

    return {
      desktop: CONTENTS_UTIL.generateDetails(
        [
          {
            title: 'Contact',
            button: (
              <Button
                scheme="secondary"
                className="form__btn btn-v2 mt-0"
                style={{ backgroundImage: '' }}
                onClick={() => {
                  window.location.href = `mailto:${email}`;
                }}
              >
                Contact
              </Button>
            ),
          },

          CONTENTS_UTIL.generateRowComponent('', () => {
            return (
              <FormBlock isInline>
                {genres?.length > 0 && (
                  <FormBlockItem>
                    <Leading>Category Experience</Leading>
                    <div className="mt-3">
                      {genres.map(({ name }) => (
                        <Text style={{ marginBottom: '10px' }} key={name}>
                          {name}
                        </Text>
                      ))}
                    </div>
                  </FormBlockItem>
                )}

                {agency_phone?.length > 0 && (
                  <FormBlockItem>
                    <Leading>Contact Information</Leading>
                    <div className="mt-3">
                      {agency_phone?.map(({ key, attributes }) => (
                        <Text style={{ marginBottom: '10px' }} key={key}>
                          {attributes?.phone}
                        </Text>
                      ))}
                    </div>
                  </FormBlockItem>
                )}
              </FormBlock>
            );
          }),
        ],
        [CONTENTS_UTIL.generateRowDetails(null, bio ?? '-')]
      ),
      mobile: CONTENTS_UTIL.generateDetails(
        [
          {
            title: 'Contact',
            button: (
              <Button
                scheme="secondary"
                className="form__btn btn-v2 mt-0"
                style={{ backgroundImage: '' }}
                onClick={() => {
                  window.location.href = `mailto:${email}`;
                }}
              >
                Contact
              </Button>
            ),
          },
          genres?.length > 0
            ? CONTENTS_UTIL.generateRowDetails(
                'Category Experience',
                genres.map(({ name }) => (
                  <Text style={{ marginBottom: '10px' }} key={name}>
                    {name}
                  </Text>
                ))
              )
            : null,
        ],
        [CONTENTS_UTIL.generateRowDetails(null, bio ?? '-')]
      ),
    };
  }, [user]);

  const caseStudiesData = useMemo(() => {
    return (
      user?.caseStudies?.map(({ id, thumbnail, description, title }) => {
        return {
          img: thumbnail ?? null,
          headline: title ?? 'NA',
          info: description ?? '-',
          externalLink: true,
          id: id,
        };
      }) ?? []
    );
  }, [user]);

  return (
    <DefaultLayout showBackButton={true} backButtonUrl={parsedQuery.back}>
      {loading && <LoadingSection />}
      {!loading && (
        <>
          <SectionViewOtherProfile
            profileImage={
              user?.avatar || '/img/agency-picture-placeholder@2x.jpg'
            }
            headline={user?.agency_name}
            subTitle={userLocation}
            desktop={contents?.desktop}
            mobile={contents?.mobile}
          />
          <GridCaseStudies
            type="casestudy"
            heading="Case Studies"
            data={caseStudiesData}
            titleOnly
          />
        </>
      )}
    </DefaultLayout>
  );
}
