import { useQuery } from '@apollo/react-hooks';
import React, { useMemo } from 'react';
import DefaultLayout from '../../layouts/defaultLayout';
import { BackPanel, Heading } from '../../snippets';
import { GET_DEALS } from '../../../graphql';
import { Deal } from '../../components';

export default function OpportunitiesPage() {
  const {
    data: { deals: { data: deals = [] } = {} } = {},
    refetch: refetchDeal,
  } = useQuery(GET_DEALS, {
    variables: {
      orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
    },
    onError: () => refetchDeal(),
  });

  const dealsData = useMemo(() => {
    return (
      deals?.map(({ image, link, subtitle, title }) => {
        return {
          img: image ?? null,
          headline: title ?? 'NA',
          info: subtitle ?? '-',
          url: link,
          externalLink: true,
        };
      }) ?? []
    );
  }, [deals]);

  return (
    <DefaultLayout>
      <BackPanel>
        <Heading>Deals For You</Heading>
        <div className="deals-container">
          {dealsData.map((d, i) => {
            return <Deal {...d} key={i} />;
          })}
        </div>
      </BackPanel>
    </DefaultLayout>
  );
}
