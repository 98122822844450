import { useQuery } from '@apollo/react-hooks';
import React, { useMemo } from 'react';
import { GET_CASE_STUDIES_WITH_AGENCY } from '../../../graphql';
import GridCards from '../GridCards/GridCards';
import LoadingSection from '../LoadingSection/LoadingSection';
import { LINK_GROUP } from '../../../utils/links';
import { IMG_PLACEHOLDER } from '../../../utils/helpers';
export default function AgencyNetworkCaseStudy({ filters = [] }) {
  const {
    data: { caseStudies: { data: caseStudies = [] } = {} } = {},
    loading,
    refetch,
  } = useQuery(GET_CASE_STUDIES_WITH_AGENCY, {
    variables: {
      first: 100,
      page: 1,
    },
    onError: () => refetch(),
  });

  const caseStudyFilters =
    useMemo(() => {
      if (filters.length === 0) {
        return caseStudies;
      }
      const _temp = new Set([]);
      for (let f of filters) {
        const find = caseStudies.find((a) => {
          const s = a?.agency?.services?.find((n) => n.name === f);
          if (s) {
            return true;
          }
          return false;
        });
        if (find) {
          _temp.add(find);
        }
      }

      return Array.from(_temp);
    }, [filters, caseStudies]) ?? [];

  if (loading) {
    return <LoadingSection />;
  }
  return (
    <>
      <GridCards
        data={caseStudyFilters.map((c) => ({
          img: c.thumbnail || IMG_PLACEHOLDER,
          name: c.agency.company,
          headline: c.title,
          url: `/${LINK_GROUP.EMPLOYER}/case-study/${c.id}`,
        }))}
        lightRadius
        withBottomSpace
        fullWidthMobile
        noPanel
        heading=""
      />
    </>
  );
}
