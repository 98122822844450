import React from 'react';
import { generateClassName } from '../../../utils/helpers';

export default function Leading({
  className = '',
  children,
  as: AsType = 'h4',
  ...rest
}) {
  return (
    <AsType
      className={generateClassName(['text--leading', className])}
      {...rest}
    >
      {children}
    </AsType>
  );
}
