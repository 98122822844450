import React, { Fragment, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  UPDATE_PROFILE,
  GET_GENRES,
  ATTACH_GENRE,
  GET_SERVICES,
  ATTACH_SERVICE,
} from '../../../../graphql';
import { userVar } from '../../../../graphql/config';
import {
  inputStyle,
  saveUserLocalstorage,
  getUser,
} from '../../../../utils/helpers';
import 'react-phone-input-2/lib/plain.css';
import { IconPlusSmall } from '../../../../components/Icons';
import UploadFieldV2 from '../../../../components/UploadFieldV2';
import DefaultLayout from '../../../layouts/defaultLayout';
import { BackPanel, FormFieldUpload } from '../../../snippets';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../../snippets';
import CustomMultiselect from '../../../components/CustomMultiselect';

const MAX_LOCATION = 2;
const MAX_PHONE = 4;

const CreateAccountSkills = ({ history }) => {
  const { data: genresData, loading: loadingGenres } = useQuery(GET_GENRES);
  const { data: servicesData } = useQuery(GET_SERVICES);
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [attachGenres] = useMutation(ATTACH_GENRE);
  const [attachServices] = useMutation(ATTACH_SERVICE);
  const { register, handleSubmit, errors, clearErrors, getValues } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [logo, setLogo] = useState(null);
  const [bio, setBio] = useState(null);
  const [isLogoError, setIsLogoError] = useState(false);
  const [phone, setPhoneNo] = useState(['']);
  const [inputLocation, setInputLocation] = useState(['']);
  const [inputPhone, setInputPhone] = useState(['']);
  const [selectedIndustryExperience, setSelectedIndustryExperience] = useState(
    []
  );
  const [selectedServices, setSelectedServices] = useState([]);
  const multiselectIndustryExperienceRef = useRef(null);
  const multiselectServiceRef = useRef(null);
  const handleIndustryExperienceChange = (selectedList, _) =>
    setSelectedIndustryExperience(selectedList.map(({ id, key }) => id ?? key));
  const handleServiceChange = (selectedList, _) =>
    setSelectedServices(selectedList.map(({ id, key }) => id ?? key));

  const attachAgencyGenres = () => {
    toast.dismiss();

    return attachGenres({
      variables: {
        input: {
          sync: selectedIndustryExperience,
        },
      },
    });
  };

  const attachAgencyServices = () => {
    toast.dismiss();

    return attachServices({
      variables: {
        input: {
          sync: selectedServices,
        },
      },
    });
  };

  const editProfile = (data) => {
    phone.forEach((p) => {
      validatePhone(p);
    });

    if (!phoneError && phone.length > 0) {
      attachAgencyGenres();
      attachAgencyServices();
      return updateProfile({
        variables: {
          input: {
            ...data,
            bio,
            agency_location: data?.agency_location.join(','),
            agency_phone: phone
              .map((p) => {
                if (p.includes('+')) {
                  return `${p}`;
                } else {
                  return `+${p}`;
                }
              })
              .join(','),
            avatar: logo,
          },
        },
      })
        .then(({ data: { updateUser } }) => {
          setSubmitted(false);
          const updatedUserObj = { ...getUser(), ...updateUser };
          userVar(updatedUserObj);
          saveUserLocalstorage(updatedUserObj);
          return history.push('/agency/dashboard');
        })
        .catch((e) => {
          setSubmitted(false);
          console.error(e.message);
          toast.error('Something went wrong!', { toastId: 'resErr' });
        });
    }
  };

  const validatePhone = (phone) => {
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)) {
      return setPhoneError(true);
    }
    return setPhoneError(false);
  };

  function handleAddMoreLocation() {
    setInputLocation((prev) => {
      if (prev.length > MAX_LOCATION) {
        return prev;
      }
      return [...prev, ''];
    });
  }

  function handleAddMorePhone() {
    setInputPhone((prev) => {
      if (prev.length > MAX_PHONE) {
        return prev;
      }
      return [...prev, ''];
    });
  }

  function handleRemoveLocation(idxAt) {
    setInputLocation((prev) => {
      const _curr = prev.slice(0);
      _curr.splice(idxAt, 1);
      return _curr;
    });
  }

  function handleRemovePhone(idxAt) {
    setInputPhone((prev) => {
      const _curr = prev.slice(0);
      _curr.splice(idxAt, 1);
      return _curr;
    });
  }

  const submitForm = () => {
    setSubmitted(true);

    if (!logo) {
      setIsLogoError(true);
      return;
    }

    editProfile({
      ...getValues(),
      bio,
    });
  };

  return (
    <DefaultLayout hideSwitchCompany>
      <BackPanel isSmall>
        <Heading>Tell Us About Your Agency</Heading>
        <FormBlock className={'spacer-t'}>
          <FormBlockItem className="relative" label={'Add Your Agency Logo'}>
            <FormFieldUpload
              noOutline
              square
              center
              setImg={setLogo}
              error={isLogoError ? 'You must upload an agency logo' : null}
              showWarnError
              onError={setIsLogoError}
              maxFileSize={8388608}
            ></FormFieldUpload>
          </FormBlockItem>

          <FormBlockItem label={'Agency Name'}>
            <FormInput
              name="agency_name"
              type="text"
              isRequired
              register={register}
              error={errors.agency_name ? 'Please enter agency name' : null}
              clearErrors={clearErrors}
            />
          </FormBlockItem>
          <FormBlockItem label={'Locations (City / Remote)'}>
            {inputLocation?.map((a, i) => {
              return (
                <Fragment key={i + a}>
                  <div className={`${i > 0 ? 'mt-16' : ''} row`}>
                    <div className="col">
                      <FormInput
                        name={`agency_location[${i}]`}
                        type="text"
                        isRequired
                        register={register}
                        error={
                          errors.agency_location
                            ? 'Please enter your location'
                            : null
                        }
                        clearErrors={clearErrors}
                      />
                    </div>
                    {i > 0 && (
                      <div className="col-auto pl-0">
                        <button
                          type="button"
                          className="btn btn-secondary fs-28x leading-none h-full"
                          onClick={() => handleRemoveLocation(i)}
                        >
                          -
                        </button>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
            {inputLocation.length < MAX_LOCATION && (
              <button
                className="btn__add-more-v2"
                onClick={handleAddMoreLocation}
              >
                <IconPlusSmall />
                <div className="text__white txt__fw-400 txt__fs-14">
                  Add Another Location
                </div>
              </button>
            )}
          </FormBlockItem>
          <FormBlockItem label={'Short Bio'}>
            <FormInput
              isRequired
              type="wysiwyg"
              name="bio"
              error={
                submitted && (!bio || bio?.length === 0)
                  ? `Please add your agency profile bio`
                  : null
              }
              showErrorText
              maxLength={1000}
              clearErrors={clearErrors}
              setValue={setBio}
              fieldClassName={`${
                submitted && (!bio || bio?.length === 0)
                  ? 'error-state state-error'
                  : ''
              }`}
            ></FormInput>
          </FormBlockItem>

          <FormBlockItem label={'Phone Number'}>
            {inputPhone?.map((a, i) => {
              return (
                <Fragment key={i + a}>
                  <div className={`${i > 0 ? 'mt-16' : ''} row`}>
                    <div className="col">
                      <PhoneInput
                        value={a}
                        country="gb"
                        autoFormat
                        countryCodeEditable={false}
                        inputProps={{
                          name: `phone[${i}]`,
                          required: true,
                          autoFocus: false,
                        }}
                        inputStyle={inputStyle}
                        inputClass={'form-v2__input with-important '}
                        containerClass="form-v2__input-wrapper"
                        buttonClass="field-v2__phone-button"
                        onChange={(phone) => {
                          setPhoneNo((prev) => {
                            prev[i] = phone;
                            return prev;
                          });
                        }}
                        dropdownStyle={{
                          background: '#fff',
                          color: 'black',
                          borderBottomLeftRadius: '12px',
                          borderBottomRightRadius: '12px',
                        }}
                        placeholder="44 116 456 5566"
                      />
                    </div>
                    {i > 0 && (
                      <div className="col-auto pl-0">
                        <button
                          type="button"
                          className="btn btn-secondary fs-28x leading-none h-full"
                          onClick={() => handleRemovePhone(i)}
                        >
                          -
                        </button>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
            {inputPhone.length < MAX_PHONE && (
              <button
                className="btn__add-more-v2"
                onClick={handleAddMorePhone}
                type="button"
              >
                <div>
                  <IconPlusSmall />
                </div>
                <div className="text__white txt__fw-400 txt__fs-14">
                  Add Another Phone Number
                </div>
              </button>
            )}
          </FormBlockItem>

          <FormBlockItem label={'Services'}>
            {loadingGenres && <span>Loading...</span>}
            {servicesData && (
              <CustomMultiselect
                required
                options={
                  servicesData?.services?.map(({ id, name }) => ({
                    key: id,
                    name: name,
                  })) ?? []
                }
                onSelect={handleServiceChange}
                onRemove={handleServiceChange}
                displayValue="name"
                closeOnSelect={true}
                avoidHighlightFirstOption
                placeholder="Service"
                ref={multiselectServiceRef}
              />
            )}
          </FormBlockItem>

          <FormBlockItem label={'Select Your Areas Of Expertise'}>
            {loadingGenres && <span>Loading...</span>}
            {genresData && (
              <CustomMultiselect
                required
                options={
                  genresData?.genres?.map(({ id, name }) => ({
                    key: id,
                    name: name,
                  })) ?? []
                }
                onSelect={handleIndustryExperienceChange}
                onRemove={handleIndustryExperienceChange}
                displayValue="name"
                closeOnSelect={true}
                avoidHighlightFirstOption
                placeholder="Category Experience"
                ref={multiselectIndustryExperienceRef}
              />
            )}
          </FormBlockItem>

          <FormCTA spaceT extraSpace>
            <Button
              type="submit"
              className="w-full"
              size="xxl"
              disabled={loading}
              onClick={submitForm}
            >
              {loading ? 'Loading...' : 'Save'}
            </Button>
          </FormCTA>
        </FormBlock>
      </BackPanel>
    </DefaultLayout>
  );
};

export default CreateAccountSkills;
