import React, { useEffect, useState } from 'react';
import {
  Button,
  FormBlock,
  FormBlockItem,
  FormCTA,
  FormInput,
  Heading,
} from '../../snippets';
import { useForm } from 'react-hook-form';
import GoogleSignInAction from '../GoogleSignInAction/GoogleSignInAction';
import useGroups from '../../hooks/useGroups';
import { registerVar, userVar } from '../../../graphql/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { CHECK_USER } from '../../../graphql';
import { toast } from 'react-toastify';
import { useHistory, Redirect } from 'react-router-dom';
import { generateErrorPasswordText } from '../../../utils/helpers';

export default function RegisterLayer1({
  heading = 'Login information',
  setLoading = (val) => {},
}) {
  const history = useHistory();
  const { groupname } = useGroups();
  const { register, handleSubmit, errors, clearErrors, watch, setValue } =
    useForm();
  const [credentials, setCredentials] = useState({});

  const [check, { data, loading, refetch }] = useLazyQuery(CHECK_USER, {
    onCompleted: () => isUniqueEmail(),
    onError: () => refetch(),
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const isUniqueEmail = () => {
    toast.dismiss();
    if (data.checkUser) {
      registerVar(credentials);
      return history.push(`/${groupname}/register/2`);
    }
    toast.error('Email already exists!', {
      toastId: 'emXFrlcr',
      autoClose: 3000,
    });
  };

  if (userVar().access_token) {
    return (
      <Redirect
        to={{
          pathname: `/${groupname}/dashboard`,
          state: { referrer: `/${groupname}/register` },
        }}
      />
    );
  }

  const CheckEmail = (input) => {
    setCredentials(input);
    check({
      variables: {
        email: input.email,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(CheckEmail)}>
      <Heading as="h3" className="text-center mb-4">
        {heading}
      </Heading>
      <FormBlock>
        <FormBlockItem label={'Email Address'}>
          <FormInput
            name="email"
            type="email"
            isRequired
            register={register}
            error={
              errors.email
                ? errors.email.type === 'pattern'
                  ? 'Please enter a valid email address'
                  : 'Please enter an email'
                : null
            }
            clearErrors={clearErrors}
          />
        </FormBlockItem>
        <FormBlockItem label={'Password'}>
          <FormInput
            register={register}
            type="password"
            name="password"
            isRequired
            clearErrors={clearErrors}
            watch={watch}
            setValue={setValue}
            settings={{
              minLength: 8,
              required: true,
            }}
            error={generateErrorPasswordText(errors)}
            showErrorText
          />
        </FormBlockItem>
      </FormBlock>
      <FormCTA className={'space-t'}>
        <Button
          className="app-btn-submit"
          type="submit"
          disabled={loading}
          size="xl"
        >
          {loading ? 'Loading...' : 'Register'}
        </Button>
      </FormCTA>
      <GoogleSignInAction heading="Or Sign Up Using" />
    </form>
  );
}
