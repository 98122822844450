import React, { useEffect } from 'react';
import styled from 'styled-components';
import jquery from '../../styles/js/app';
import { Heading } from './CaseStudy';
import MyAccountLayout from './layout/MyAccountLayout';

const AccountInformation = () => {
  useEffect(() => {
    jquery();
  }, []);

  return (
    <MyAccountLayout>
      <div className="black-card black-card__full mb-50">
        <Heading>Manage Team</Heading>
      </div>
    </MyAccountLayout>
  );
};
export default AccountInformation;

export const ImageUploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 53px;
  img {
    width: 100%;
    height: 360px;
    object-fit: contain;
  }
  a {
    width: 100%;
  }
  button {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 800;
    margin-inline: auto;
    letter-spacing: 5%;
    color: #ffffff;
    background-repeat: no-repeat;
  }
  @media (min-width: 1024px) {
    width: 200px;
    button {
      font-size: 12px;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }
  @media (min-width: 1400px) {
    width: 224px;
    img {
      width: 224px;
      height: 224px;
    }
  }
`;
