import React from 'react';
import { BackPanel, Heading, Link, Text, Button } from '../../snippets';
import { generateClassName } from '../../../utils/helpers';

function GridContent({
  data,
  lightRadius = false,
  withBottomSpace = false,
  heading = 'Liberty Hive Community',
  viewMoreUrl = null,
  viewMoreLabel = ' View More',
  fullWidthMobile = false,
}) {
  return (
    <>
      <div className="gridjobs-header">
        {heading !== '' && <Heading>{heading}</Heading>}
        {viewMoreUrl && (
          <div className="app-hide-on-mobile">
            <Button
              as="link"
              to={viewMoreUrl}
              className="app-btn app-btn-primary app-btn--dashboard"
              isCapitalize
            >
              {viewMoreLabel}
            </Button>
          </div>
        )}
      </div>
      <div className={generateClassName(['gridcards-grid'])}>
        {data?.map((d, i) => {
          return (
            <div
              className={generateClassName([
                'gridcards-grid--item',
                fullWidthMobile ? 'full-width-mobile' : '',
                withBottomSpace ? 'with-bottom-space' : '',
              ])}
              key={i}
            >
              <Link
                to={d?.url ?? '#'}
                className={generateClassName([
                  'grid-item--image',
                  lightRadius ? 'light-radius' : '',
                ])}
              >
                <img src={d.img} alt={d.headline} />
              </Link>
              <div className="grid-item--divider"></div>
              <Link to={d?.url ?? '#'} className="grid-item--hedline">
                {d.name && (
                  <Text as="p" className="h-name">
                    {d.name}
                  </Text>
                )}
                {d.headline && <Text as="span">{d.headline}</Text>}
              </Link>
            </div>
          );
        })}
        {viewMoreUrl && (
          <div className="app-hide-on-desktop w-full">
            <div className="gridjobs-cta">
              <Link
                to={viewMoreUrl}
                className="app-btn app-btn-primary app-btn--dashboard"
                isCapitalize
              >
                {viewMoreLabel}
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default function GridCards({
  heading = 'Liberty Hive Community',
  viewMoreUrl = null,
  viewMoreLabel = ' View More',
  id = '',
  data,
  lightRadius = false,
  withBottomSpace = false,
  noPanel = false,
  fullWidthMobile = false,
  boldTitle = false,
}) {
  const accptedProps = {
    heading,
    viewMoreUrl,
    viewMoreLabel,
    data,
    lightRadius,
    withBottomSpace,
    fullWidthMobile,
    boldTitle,
  };
  return noPanel ? (
    <GridContent {...accptedProps} />
  ) : (
    <BackPanel id={id}>
      <GridContent {...accptedProps} />
    </BackPanel>
  );
}
