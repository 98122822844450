import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { AppContext } from '../../Store';
import { GET_TEAM_USERS } from '../../graphql';
import jquery from '../../styles/js/app';
import { Heading } from './CaseStudy';
import MyAccountLayout from './layout/MyAccountLayout';

const AccountInformation = () => {
  useEffect(() => {
    jquery();
  }, []);

  const [agencyState] = useContext(AppContext);

  const img =
    agencyState?.activeCompany?.user?.avatar ||
    '/img/profile-picture-placeholder@2x.jpg';

  const { data: { viewUserTeam } = {} } = useQuery(GET_TEAM_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      team_id: agencyState?.activeCompany?.team_id,
    },
  });

  return (
    <MyAccountLayout>
      <div className="black-card black-card__full mb-50">
        <Heading>Account Information</Heading>
        <div className="mt-40 d-flex justify-content-between flex-column flex-lg-row">
          <ImageUploadContainer>
            <img src={img} w="224" h="224" alt="" />
            <Link to="/agency/edit-account-information">
              <button className="bg__gradient-1 bg-cover">EDIT PROFILE</button>
            </Link>
          </ImageUploadContainer>
          <FormContainer>
            <div>
              <h3>Agency Name</h3>
              <p>{agencyState?.activeCompany?.user?.agency_name}</p>
            </div>
            <div>
              <h3>About Us</h3>
              <p>{agencyState?.activeCompany?.user?.bio}</p>
            </div>
            <div>
              <h3>Address</h3>
              <p>{agencyState?.activeCompany?.user?.address_1}</p>
              {agencyState?.activeCompany?.user?.address_2 && (
                <p>{agencyState?.activeCompany?.user?.address_2}</p>
              )}
            </div>
            <div>
              <h3>City</h3>
              <p>{agencyState?.activeCompany?.user?.city}</p>
            </div>
            <div>
              <h3>County</h3>
              <p>{agencyState?.activeCompany?.user?.state}</p>
            </div>
            <div>
              <h3>Country</h3>
              <p>{agencyState?.activeCompany?.user?.country}</p>
            </div>
            <div>
              <h3>Postcode / Zip Code</h3>
              <p>{agencyState?.activeCompany?.user?.zip}</p>
            </div>
          </FormContainer>
          <FormContainer>
            <div>
              <h3>Team Members</h3>
              {viewUserTeam?.map((userTeam) => (
                <p key={userTeam?.id}>
                  {userTeam?.team?.first_name} {userTeam?.team?.last_name}
                </p>
              ))}
            </div>
            <div>
              <h3>Phone Number</h3>
              {agencyState?.activeCompany?.user?.agency_phone?.map((ph) => (
                <p key={ph?.key}>{ph?.attributes?.phone}</p>
              )) ?? '-'}
              <p></p>
            </div>
            <div>
              <h3>Email Address</h3>
              <p>{agencyState?.activeCompany?.user?.contact_email}</p>
            </div>
          </FormContainer>
        </div>
      </div>
    </MyAccountLayout>
  );
};
export default AccountInformation;

export const ImageUploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 53px;
  img {
    width: 100%;
    height: 360px;
    object-fit: contain;
  }
  a {
    width: 100%;
  }
  button {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 800;
    margin-inline: auto;
    letter-spacing: 5%;
    color: #ffffff;
    background-repeat: no-repeat;
  }
  @media (min-width: 1024px) {
    width: 200px;
    button {
      font-size: 12px;
    }
    img {
      width: 200px;
      height: 200px;
    }
  }
  @media (min-width: 1400px) {
    width: 224px;
    img {
      width: 224px;
      height: 224px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  h3 {
    font-size: 20px;
    font-weight: 900;
    color: #ffffff;
    margin-bottom: 14px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 19.1px;
  }
  @media (min-width: 1024px) {
    max-width: 270px;
  }
  @media (min-width: 1340px) {
    max-width: 309px;
  }
`;
