import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/js/loader';
import 'bootstrap/scss/bootstrap.scss';
import './styles/sass/app.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import Routes from './routes';
import client from './graphql/config';
import ScrollToTop from './components/ScrollToTop';
import AppContextProvider from './Store';
import './v2/styles/main.scss';

Sentry.init({
  dsn: 'https://898248cfb1994b9ab3f84e7c7ac9d076@o4505368865996800.ingest.sentry.io/4505368867045376',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^http:\/\/libertyhive-dev\.us-west-1\.elasticbeanstalk\.com/,
      ],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [`${process.env.REACT_APP_API_URL}/graphql`],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate:
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.1 : 1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ToastContainer autoClose={3000} position="top-center" />
      <Router>
        <ScrollToTop />
        <AppContextProvider apolloClient={client}>
          <Routes />
        </AppContextProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
