import React, { useCallback, useContext, useState } from 'react';

const ThemeAppContext = React.createContext();

export function ThemeAppProvider({ children }) {
  const [attributes, setState] = useState({
    drawer: {
      open: false,
    },
    modal: {
      open: false,
      lastState: 'initial',
      render: () => <></>,
    },
  });

  const setOpenDrawer = useCallback(() => {
    setState((_prev) => {
      document.body.classList.add('modal-open');
      return {
        ..._prev,
        drawer: { open: true },
      };
    });
  }, []);

  const setCloseDrawer = useCallback(() => {
    setState((_prev) => {
      document.body.classList.remove('modal-open');
      return {
        ..._prev,
        drawer: { open: false },
      };
    });
  }, []);

  const setOpenModal = useCallback((component) => {
    setState((_prev) => {
      document.body.classList.add('modal-open');
      return {
        ..._prev,
        modal: { ..._prev.modal, open: true, render: component ?? _prev.modal.render },
      };
    });
  }, []);

  const setCloseModal = useCallback(() => {
    setState((_prev) => {
      document.body.classList.remove('modal-open');
      return {
        ..._prev,
        modal: { ..._prev.modal, open: false, render: _prev.modal.render },
      };
    });
  }, []);

  const updateModalLastState = useCallback((stateName = 'closed') => {
    setState((_prev) => {
      document.body.classList.remove('modal-open');
      return {
        ..._prev,
        modal: {..._prev.modal, lastState: stateName },
      };
    });
  }, []);

  return (
    <ThemeAppContext.Provider
      value={{
        attributes,
        action: {
          setOpenDrawer,
          setCloseDrawer,
          setOpenModal,
          setCloseModal,
          updateModalLastState
        },
      }}
    >
      {children}
    </ThemeAppContext.Provider>
  );
}

export const useAppTheme = () => {
  const { attributes, action } = useContext(ThemeAppContext);
  return { state: attributes, action };
};
