import React from 'react';
import { Link } from '../../snippets';
import { useMediaPredicate } from 'react-media-hook';
import { IMG_AGENCY_PLACEHOLDER } from '../../../utils/helpers';

const OpportunitiesCard = ({
  headline,
  info,
  genres,
  skills,
  user,
  type,
  img,
  url,
  since,
}) => {
  const inBoth = (l1 = [], l2 = []) =>
    l1.filter((a) => l2.some((b) => a.id === b.id && a.name === b.name));
  const overlappingGenres = inBoth(user?.genres, genres) ?? [];
  const overlappingSkills = inBoth(user?.skills, skills) ?? [];
  const overlappingJobType = user?.jobTypes.filter((x) => x.key === type) ?? [];
  const tags = [
    ...overlappingJobType,
    ...overlappingGenres,
    ...overlappingSkills,
  ];

  /** Checks Current ScreenWidth */
  const smallerThan992 = useMediaPredicate('(max-width: 992px)');

  return (
    <div className={`gridjobs-grid--item`}>
      <Link to={url} className="company-logo">
        <img src={img === '' ? IMG_AGENCY_PLACEHOLDER : img} alt="" />
      </Link>
      <div className="grid-divider"></div>
      <div className="company-info py-0">
        <div className="company-info--header">
          <Link to={url} className="company-info--header_headline">
            {headline.length > 25
              ? `${headline.substring(0, 25)}...`
              : headline}
          </Link>
          <div className="company-info--header_info">{info}</div>
          {tags.length > 0 && (
            <div style={{ width: '100%' }}>
              <div className="company-info--header--chips">
                {tags.slice(0, smallerThan992 ? 3 : 4).map((chip) => (
                  <div
                    key={chip.name}
                    className="company-info--header--chips_chip"
                  >
                    {chip.name.length > 25
                      ? `${chip.name.substring(0, 25)}...`
                      : chip.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="company-info--time">
          <div>{since}</div>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesCard;
