import React, { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { GET_ARTICLE, GET_ARTICLES } from '../../graphql';
import DefaultLayout from '../../v2/layouts/defaultLayout';
import { BackPanel } from '../../v2/snippets';
import useGroups from '../../v2/hooks/useGroups';
import moment from 'moment/moment';
import { GridCards, LoadingSection } from '../../v2/components';
import { LINK_GROUP } from '../../utils/links';
import { availabilityByGroupname } from '../../utils/helpers';

const BlogIndividual = (props) => {
  const { groupname } = useGroups();

  const {
    match: {
      params: { slug },
    },
  } = props;

  const { loading, error, data, refetch } = useQuery(GET_ARTICLE, {
    variables: { slug },
    onError: () => refetch(),
  });

  const [
    fetchArticles,
    { loading: loadingArticles, error: errorArticles, data: dataArticles },
  ] = useLazyQuery(GET_ARTICLES);

  useEffect(() => {
    if (data && groupname) {
      fetchArticles({
        variables: {
          availability: [
            availabilityByGroupname[groupname],
            LINK_GROUP.FREELANCER_AND_AGENCY.toUpperCase(),
          ],
          orderBy: [{ column: 'CREATED_AT', order: 'DESC' }],
          excludeSlugs: [data.article.slug],
        },
      });
    }
  }, [data, groupname, fetchArticles]);

  const otherArticles = useMemo(() => {
    return (dataArticles?.articles.data || []).map((elm) => {
      return {
        img: elm?.image ?? null,
        headline: elm.title ?? 'NA',
        url: `/${groupname}/resources/${elm.slug}`,
      };
    });
  }, [dataArticles, groupname]);

  if (error || errorArticles) return toast.error(error || errorArticles);

  return (
    <DefaultLayout goBack>
      <BackPanel>
        {loading && <div>Loading...</div>}
        {data && (
          <div>
            <h1 className="mb-5">{data.article?.title}</h1>
            <div className="row">
              <div className="col-12 col-lg-6">
                <img src={data.article?.image} alt="" className="w-100 mb-30" />
                <div
                  className="txt__fw-600 mb-70 blog-content"
                  dangerouslySetInnerHTML={{ __html: data.article?.content }}
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="pl-lg-5">
                  <h4 className="h5 mb-3">Date Posted</h4>
                  <p className="text-sm mb-0">
                    {moment(data.article?.created_at).format(
                      'MM/DD/y - hh:mm A'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </BackPanel>

      <BackPanel>
        {loadingArticles ? (
          <LoadingSection />
        ) : (
          <>
            <GridCards
              noPanel
              type="community"
              heading="Other Blog Posts"
              data={otherArticles}
              lightRadius
              withBottomSpace
              fullWidthMobile
              boldTitle
            />
          </>
        )}
      </BackPanel>
    </DefaultLayout>
  );
};

export default BlogIndividual;
